/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React from 'react';
import DataGrid, { Row, RowRendererProps } from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import update from 'immutability-helper';
import { isMobile } from 'react-device-detect';
import {
    stringEmptyOrUndefined,
    isObjectEmptyOrNot,
    NoRecordFound,
    formatHeaderNameWithOutSpace,
    dateCellFormatter,
    typeCellFormatter,
    noteCellFormatter,
    nameCellFormatter,
    ownerCellFormatter,
    formatDate,
    dateTimeDisplayFormatter,
    createTagElement,
    // getDocumentIdFromArray,
    ObjectPropertyToLowerCase,
    ObjectPropertyToUpperCase,
    formatPathName,
    // removeCheckboxClass,
    handleColumnsReorder,
    handleClickOutside,
    headerDataFormating,
    customizedWidth,
    userPermission,
    getTagFromStorage,
    getDocumentsOwners,
    sortFields,
    sortMethod,
    // generateId,
    GetFieldData,
    showErrorToast,
    faxCellFormatter,
    rowHeightDataGrid,
    updateAllactions,
    getPageFromPath,
    formatDateToUtc,
} from '../commonfunction';
// import * from '../../components/commonfunction';
import TagAction from '../tag/TagAction';
import DropDownEditor from '../owner/DropDownEditor';
import { SelectColumn } from './Column';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import ToggleGroupFormatter from './ToggleGroupFormatter';
import OutboxRowSelComponent from './outbox/OutboxRowSelComponent';
import { addDocumentOwners, getCount } from '../../pages/api/api';
import {
    setDocOwner,
    setSelectedRow,
    setOwner,
    updateList,
    getListaction,
    updateOutboxState,
    showLoader,
    setRowData,
    outboxPage,
    getCountData,
    outboxGrouping,
    initialOutboxGrouping,
} from '../../pages/_redux/actions/outboxActions';
import { getProcessCount } from '../../pages/_redux/authCrud';
import { GridState } from '../variable';
import { RowRenderer } from './RowRenderer';
import { Button, Modal } from 'react-bootstrap';
import { DraggablePaginatedHeaderRenderer } from './DraggablePaginatedHeaderRenderer';
import { toast } from 'react-toastify';
import LockBar from '../LockBar';

const _ = require('lodash');

class DataGridComponent extends React.Component {
    constructor(props) {
        super(props);
        const defaultQueryParams = {
            pageNumber: 1,
            pageSize: 25,
        };
        this.state = {
            ...GridState,
            filterTxt:
                this.props.filterTxt !== undefined ? this.props.filterTxt : '',
            tableDesign: this.props.tableDesign,
            refreshDataGrid: false,
            perPage: this.props.perPage,
            customGroupedData: [],
            listingFor: 'outbox',
            selectTags: 'temp',
            refreshGrouping: true,
            queryData: { ...defaultQueryParams, ...props.query },
            customFilters: [],
            showAllConfirmation: false,
            retainShowAllChecked:
                localStorage.getItem('retainShowAllChecked') === 'true'
                    ? true
                    : false,
            lockBarTimeout: null,
            lockBarPosition: null,
        };
        this.tagActionRef = React.createRef();
        this.initialConfigExecAfterState =
            this.initialConfigExecAfterState.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.createGroupedData = this.createGroupedData.bind(this);
        this.removeAllGrouping = this.removeAllGrouping.bind(this);
        this.resetPopOverData = this.resetPopOverData.bind(this);
        this.addCustomFilter = this.addCustomFilter.bind(this);
        this.removeColumnFilters = this.removeColumnFilters.bind(this);
        this.sortRows = this.sortRows.bind(this);
        this.removeColumnGrouping = this.removeColumnGrouping.bind(this);
        this.hasAppliedFilters = this.hasAppliedFilters.bind(this);
        this.gridRef = React.createRef();
        this.groupRef = React.createRef();
    }

    componentWillUnmount() {
        this.props.detailBigViewExpandObj.setData(true);
    }

    async componentDidMount() {
        this.props.initialOutboxGrouping(); // INITIALIZE GROUPING

        // Set state data only at Expand and Collapse
        if (this.props.detailBigViewExpandObj.data) {
            await this.setState({
                ...this.props.stateData,
                allFilteredRowData: this.props.outbox_page?.allFilteredRowData,
                processId: this.props.stateData.processId,

                statusId: this.props.stateData.statusId,
                selectedTagDocId: this.props.selectedTagDocId,
                selected_tag: this.props.selected_tag,
                selectedColumn: this.props.selectedColumn,
                tagIndex: this.props.tagIndex,
                totalCount: this.props.outbox.outbox.totalCount,
                perPage:
                    this.props.perPage !== undefined
                        ? this.props.perPage
                        : window.innerHeight < 825
                        ? 10
                        : 25,
                refreshDataGrid: true,
            });
        } else {
            this.setState({
                refreshDataGrid: true,
            });
        }

        if (
            localStorage.getItem('retainShowAllChecked') === 'true' &&
            this?.props?.outbox?.outbox?.totalCount !== undefined
        ) {
            this.setState({ perPage: this.props.outbox.outbox.totalCount });
        }

        //This if for statiscts page
        if (this.state.rowData) {
            await this.props.outboxPage({
                allRowData: this.state.rowData,
                filteredData: this.state.rowData,
            });
        }
        await this.getTableData();
        // Expand Collapse ScrollToPosition

        if (
            this.props.detailBigViewExpandObj.data &&
            this.props.outbox_page !== undefined &&
            this.props.outbox_page.rowID !== undefined &&
            this.props.outbox_page.rowID !== '' &&
            this.props.outbox_page.rowID !== null &&
            this.state.totalCount > 10
        ) {
            let positionId = this.props.outbox_page.rowID;
            if (this.props.currentPage !== 0) {
                positionId = this.props.outbox_page.rowID; //-this.props.perPage * this.props.currentPage;
            }
            setTimeout(() => {
                this.gridRef.current?.scrollToRow(positionId);
            }, 1000);
        }

        if (
            localStorage.getItem('retainShowAllChecked') === 'true' &&
            this?.props?.outbox?.outbox?.totalCount !== undefined
        ) {
            this.setState({ perPage: this.props.outbox.outbox.totalCount });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.filteredRowData !== this.state.rowData) {
            this.setState({
                filteredRowData: this.state.rowData,
            });
        }

        if (prevProps.currentMenuName !== this.props.currentMenuName) {
            this.setState({ refreshGrouping: true, customFilters: [] });
        }

        if (this.state.refreshGrouping === true) {
            let pathname = this.props.location.pathname;
            pathname = getPageFromPath(pathname);
            let filter_options = this.props.grouping[pathname];
            if (filter_options) {
                this.setState({ refreshGrouping: false });
                if (filter_options?.data) {
                    setTimeout(() => {
                        this.createGroupedData(
                            {
                                title: filter_options.data.title,
                                value: filter_options.data.value,
                            },
                            filter_options.type,
                            true,
                            filter_options.groupBy,
                            false,
                            filter_options.expandedGroupIds
                        );
                    }, 0);
                }
            }
        }
        if (this.props.statisticsTable === true) {
            await this.props.updateState({
                statisticsTable: false,
            });
            await this.refresh_whole();
        }
        if (
            this.props.outbox_page.hasOwnProperty('refreshTable') &&
            prevProps.outbox_page.refreshTable !==
                this.props.outbox_page.refreshTable
        ) {
            this.setState({ currentPage: 0 });
            // this.setState({ perPage: 10 });
            this.setState({ refreshDataGrid: false });
            this.setState({ statictics: true });
            this.getTableData('statistics');
            // setTimeout(() => {
            //     this.getTableData('statistics');
            // }, 500);
        }

        if (
            this.props.outbox_page.hasOwnProperty('byAging') &&
            this.props.outbox_page.hasOwnProperty('byAgingData') &&
            this.props.outbox_page.hasOwnProperty('token') &&
            this.props.outbox_page.hasOwnProperty('dataRows') &&
            this.props.outbox_page.byAging === true &&
            prevProps.outbox_page.token !== this.props.outbox_page.token
        ) {
            const filteredData = [];
            this.props.outbox_page.dataRows.map((allrows) => {
                this.props.outbox_page.byAgingData.map((filterRows) => {
                    if (filterRows.doc_id === allrows.id) {
                        filteredData.push(allrows);
                    }
                });
            });

            // this.setState({currentPage: 0,});
            this.setState({ currentPage: 0 });
            // this.setState({ perPage: 10 });
            this.setState({ refreshDataGrid: false });
            this.setState({ statictics: true });
            this.props.outboxData.dataTable.dataRows = filteredData;
            // this.props.outboxData.dataTable.filteredData = filteredData;
            setTimeout(() => {
                this.getTableData('statistics');
            }, 1000);

            // this.setState({
            //     rowData:this.props.outbox_page.byAgingData,
            //    // filteredRowData:this.props.outbox_page.byAgingData,
            //     currentPage: 0,
            //     perPage: 10,
            //     totalCount: this.props.outbox_page.byAgingData.length,
            //     refreshDataGrid:false
            // },()=>{
            //     this.setState({ refreshDataGrid:true})
            // })
        }

        if (prevState.rowData !== this.state.rowData) {
            this.props.outboxPage({
                allRowData: this.state.rowData,
            });
        }

        if (String(prevState.filterTxt) !== String(this.props.filterTxt)) {
            this.setState(
                {
                    filterTxt: this.props.filterTxt,
                },
                () => {
                    this.initialConfigExecAfterState();
                }
            );
        }
        // TAGUPDATE EVENT
        if (
            String(prevState.selectedTagDocId) !==
            String(this.props.selectedTagDocId)
        ) {
            this.setState({
                selectedTagDocId: this.props.selectedTagDocId,
                selected_tag: this.props.selected_tag,
                selectedColumn: this.props.selectedColumn,
                tagIndex: this.props.tagIndex,
            });
        }
        // UPDATE STATE EVENT

        if (String(prevProps.stateToken) !== String(this.props.stateToken)) {
            if (window.innerHeight > 657 && this.state.perPage === 10) {
                // need to check
                // this.setState({ perPage: 25 });
            }

            // if enable this get table data then row click scroll will come top when per page is 25
            // stateToken Varaible  denotes that the COMPONENT STATE varaible was changed
            await this.setState(
                {
                    ...this.props.stateData, // Need to find the param and add that only.
                },
                () => {
                    if (
                        this.props.outboxData !== undefined &&
                        this.props.stateData.rowDetails !== undefined &&
                        this.props.stateData.tagIndex !== undefined &&
                        this.props.outboxData.dataTable !== undefined &&
                        this.props.outboxData.dataTable.dataRows !== undefined
                    ) {
                        this.props.outboxData.dataTable.dataRows.map((data) => {
                            let deliveryIndexPosition;
                            let faxIndexPosition;

                            this.props.outboxData.dataTable?.headerColumns.map(
                                (data, index) => {
                                    if (
                                        data.name.toLowerCase() ===
                                        'delivery method'
                                    ) {
                                        deliveryIndexPosition = index;
                                    }
                                    if (
                                        data.name.toLowerCase() === 'fax number'
                                    ) {
                                        faxIndexPosition = index;
                                    }
                                    //return data;
                                }
                            );

                            if (
                                data.id ===
                                this.props.stateData.rowDetails.doc_id
                            ) {
                                if (
                                    data.values[deliveryIndexPosition] !==
                                        this.props.stateData.rowDetails
                                            .deliverymethod ||
                                    data.values[
                                        this.props.stateData.tagIndex
                                    ] ===
                                        this.props.stateData.rowDetails
                                            .tags_data ||
                                    data.values[faxIndexPosition] !==
                                        this.props.stateData.rowDetails
                                            .faxnumber_rawdata ||
                                    this.props.outbox_page.deliveryUpdate ===
                                        true
                                ) {
                                    /* */
                                    this.props.outboxPage({
                                        deliveryUpdate: false,
                                    });
                                    this.getTableData();

                                    // setTimeout(() => {
                                    //     if (
                                    //     this.props.outbox_page !== undefined &&
                                    //     this.props.outbox_page.rowID !==
                                    //         undefined &&
                                    //     this.state.totalCount > 1
                                    // ) {
                                    //     let positionId =
                                    //         this.props.outbox_page.rowID;
                                    //                                   //     if (this.props.currentPage !== 0) {
                                    //         positionId =
                                    //             this.props.outbox_page.rowID -
                                    //             this.props.perPage *
                                    //                 this.props.currentPage;
                                    //     }
                                    //     // setTimeout(() => {
                                    //         this.gridRef.current?.scrollToRow(
                                    //             positionId
                                    //         );
                                    //
                                    //     // }, 1000);
                                    // }
                                    // }, 1000);
                                }
                            }
                        });
                    }
                }
            );
        }

        if (
            this.props.outbox_page.hasOwnProperty('refreshForFax') &&
            String(prevProps.outbox_page.refreshForFax) !==
                String(this.props.outbox_page.refreshForFax)
        ) {
            this.setState({ refreshDataGrid: false }, () => {
                this.setState({ refreshDataGrid: true });
            });
            this.getTableData();
        }

        // REINITIALIZE TABLE, WHEN API UPDATES
        if (String(prevProps.dataToken) !== String(this.props.dataToken)) {
            // dataToken Varaible  denotes that  API Is Call Again
            const { processId, statusId } = this.props;
            const filtersJson = localStorage.getItem(
                `customFilters_${processId}_${statusId}`
            );
            const filters = filtersJson ? JSON.parse(filtersJson) : [];
            const sortingsJson = localStorage.getItem(
                `sorting_${processId}_${statusId}`
            );
            const storedSortings = sortingsJson ? JSON.parse(sortingsJson) : {};

            this.setState(
                {
                    tableDesign: this.props.tableDesign,
                    customFilters: filters,
                    sortColumn: storedSortings?.sortColumn ?? '',
                    sortDirection: storedSortings?.sortDirection ?? 'NONE',
                },
                () => {
                    this.getTableData(1);
                }
            );
        }

        if (
            String(prevProps.outbox.owners) !== String(this.props.outbox.owners)
        ) {
            const owners = getDocumentsOwners(this.props.outbox.owners);
            this.props.setDocOwner(owners);
        }
        if (
            String(prevProps.outbox.owner) !==
                String(this.props.outbox.owner) &&
            String(this.props.outbox.owner) !== ''
        ) {
            this.handleOwnerChange(this.props.outbox.owner);
            this.props.setOwner('');
        }

        if (this.props.outbox_page.deleteRow) {
            setTimeout(() => {
                let gridDelete = true;

                this.props.count.map(async (data, index) => {
                    if (data.statuses[0].processId === this.state.processId) {
                        await data.statuses.map((item, idx) => {
                            if (item.statusId === this.state.statusId) {
                                if (
                                    item.count !==
                                    this.props.outbox.outboxpage.stateData
                                        .rowData.length
                                ) {
                                    gridDelete = false;
                                }
                            }
                        });
                    }
                });
                if (this.props.outbox.stateData.filterTxt !== '') {
                    let paginatedRowData = this.getRowData(
                        0,
                        this.state.perPage,
                        this.props.outbox.outboxpage.allFilteredRowData
                    );
                    this.setState({
                        allFilteredRowData:
                            this.props.outbox.outboxpage.allFilteredRowData,
                        filteredRowData: paginatedRowData,

                        totalCount:
                            this.props.outbox.outboxpage.allFilteredRowData
                                .length,
                    });
                    /* this.props.updateOutboxState({
                        filteredRowData: paginatedRowData,
                    }); */
                    this.props.outboxPage({
                        deleteRow: false,
                    });
                } else {
                    if (gridDelete === true) {
                        let paginatedRowData = this.getRowData(
                            0,
                            this.state.perPage,
                            this.props.outbox.outboxpage.stateData.rowData
                        );
                        this.setState({
                            rowData:
                                this.props.outbox.outboxpage.stateData.rowData,
                            filteredRowData: paginatedRowData,
                            totalCount:
                                this.props.outbox.outboxpage.stateData.rowData
                                    .length,
                        });
                        /*  this.props.updateOutboxState({
                            rowData: this.props.outbox.outboxpage.stateData.rowData,
                            filteredRowData: paginatedRowData,
                            }); */
                        this.props.outboxPage({
                            deleteRow: false,
                        });
                        this.nextPage('', this.state.currentPage);
                    } else {
                        this.props.outboxPage({
                            deleteRow: false,
                        });
                    }
                }
            }, 1000);
        }
    }

    handleOwnerChange = (val) => {
        let ownData;
        let value;
        let owner_id;
        if (val === null) {
            value = '';
            owner_id = '';
        } else {
            ownData = val.split('+');
            value = ownData[0];
            owner_id = ownData[1];
        }

        this.setState({ ownerValue: value });
        const rowsToUpdate = [];
        const rowDocs = [];
        let docIds = [];
        let processId = this.state.processId;
        let statusId = this.state.statusId;
        if (String(this.state.processId) !== '') {
            processId = this.state.processId;
        }
        if (String(this.state.statusId) !== '') {
            statusId = this.state.statusId;
        }
        /* this.state.selectedRows.forEach((rowIndex, i) => {
            rowsToUpdate.push(rowIndex);
            rowDocs.push(this.state.rowData[rowIndex]);
        }); */
        if (rowsToUpdate.length > 0) {
            this.props.setOwner('');
            this.props.setSelectedRow('');
        }
        /*  rowDocs.forEach((data) => {
            docIds.push(data.doc_id);
        }); */

        if (
            this.props.outbox.selectedRow &&
            this.props.outbox.selectedRow !== ''
        ) {
            docIds = [];
            docIds.push(this.props.outbox.selectedRow);
            this.state.rowData.forEach((data, index) => {
                if (data.doc_id === this.props.outbox.selectedRow) {
                    rowsToUpdate.push(index);
                }
            });
            this.props.setOwner('');
            // this.props.setSelectedRow('');
        }

        window.scroll({ top: 0, behavior: 'smooth' });
        const newStatus = {};
        newStatus.processId = processId;
        newStatus.statusId = this.state.menu_inbox;
        const params = {};
        params.processId = processId;
        params.statusId = statusId;
        params.owner_id = owner_id;
        params.docIds = docIds;
        params.actionId = this.state.ownerActionId;
        params.fieldId = this.state.ownerFieldId;
        params.statusArr = newStatus;
        let status = '';
        addDocumentOwners(params)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let result = data.results;
                if (result[0].actionSucceeded === true) {
                    if (this.props.outbox.outbox.dataTable !== undefined) {
                        let ownerIndex = '';
                        this.props.outbox.outbox.dataTable.headerColumns.forEach(
                            (data, index) => {
                                if (data.name.toLowerCase() === 'owner') {
                                    ownerIndex = index;
                                }
                            }
                        );
                        const tableData = this.props.outbox.outbox;
                        const rawData =
                            this.props.outbox.outbox.dataTable.dataRows.slice();
                        this.props.outbox.outbox.dataTable.dataRows.map(
                            (data, index) => {
                                if (docIds.includes(data.id)) {
                                    const updated = {};
                                    updated[ownerIndex] = value;
                                    data.values.map((row, idx) => {
                                        if (idx === ownerIndex) {
                                            const rowToUpdate =
                                                rawData[index].values;
                                            const updatedRaw = update(
                                                rowToUpdate,
                                                { $merge: updated }
                                            );
                                            rawData[index].values = updatedRaw;
                                        }
                                        return row;
                                    });
                                }
                                return data;
                            }
                        );
                        this.props.updateList('');
                        tableData.dataTable.dataRows = rawData;
                        this.setState({
                            ownerValue: '',
                            selectedRows: new Set(),
                        });
                        this.props.updateList(tableData);
                    }
                    const updated = {};
                    updated.owner = ownerCellFormatter(
                        value,
                        updated.doc_id,
                        this.state?.allActions?.hasOwnProperty('assign_to_user')
                            ? true
                            : false,
                        'outbox'
                    );
                    updated.owner_rawdata = value;
                    const rowData = this.state.rowData.slice();
                    rowsToUpdate.map((data) => {
                        const rowToUpdate = rowData[data];
                        const updatedRow = update(rowToUpdate, {
                            $merge: updated,
                        });
                        rowData[data] = updatedRow;
                        this.setState(
                            {
                                rowData: rowData,
                            },
                            () => {
                                // const fields = sortFields(
                                //     this.state.sortColumn,
                                //     this.state.sortDirection,
                                //     this.state.dateFormatFields
                                // );
                                let columnDataType;
                                this.state.header.map((item, index) => {
                                    if (item.key === this.state.sortColumn) {
                                        columnDataType = item.colname;
                                    }
                                });
                                let paginatedRowData = rowData;
                                if (this.state.filterTxt.trim() !== '') {
                                    this.resetPopOverData(paginatedRowData);
                                } else {
                                    this.resetPopOverData();
                                }
                                // paginatedRowData = _.orderBy(
                                //     paginatedRowData,
                                //     fields.sortfn,
                                //     fields.orderBy
                                // );
                                if (this.state.groupBy.length === 0) {
                                    const startIndex =
                                        parseInt(this.state.currentPage, 10) *
                                        parseInt(this.state.perPage, 10);
                                    const endIndex =
                                        startIndex +
                                        parseInt(this.state.perPage, 10);
                                    paginatedRowData = this.getRowData(
                                        startIndex,
                                        endIndex,
                                        paginatedRowData
                                    );
                                }
                                this.setState(
                                    {
                                        filteredRowData: paginatedRowData,
                                        selectedRows: new Set(),
                                        ownerValue: '',
                                    },
                                    () => {
                                        this.props.updateOutboxState(
                                            //modified by geetha ...state
                                            {
                                                filteredRowData:
                                                    paginatedRowData,
                                                rowData: this.state.rowData,
                                                selectedRows: new Set(),
                                                ownerValue: '',
                                            }
                                        );
                                    }
                                );
                            }
                        );
                        return { rowData };
                    });
                    //showSuccessToast('Owner Changed Successfully')
                } else {
                    showErrorToast(`Doc id ${docIds} is Locked`);
                }
            })

            .then((data) => {
                if (status === 400 || status === 401) {
                    showErrorToast(data.message);
                }
            });
    };

    sortRows(sortColumn, sortDirection) {
        let columnDataType;
        this.state.header.map((item, index) => {
            if (item.key === sortColumn) {
                columnDataType = item.colname;
            }
        });
        if (sortDirection !== 'NONE') {
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                if (openState) {
                    openState[names] = false;
                }
            }
            const { processId, statusId, queryData, currentPage, perPage } =
                this.state;
            if (processId != null && statusId != null) {
                queryData.pageNumber = 1;
                queryData.pageSize = perPage;
                queryData.sortColumn = sortColumn;
                queryData.sortOrder = sortDirection;
                queryData.filters = JSON.stringify(this.state.customFilters);

                this.props.getListaction(
                    processId,
                    statusId,
                    this.state.calingFrom,
                    queryData
                );
                this.setState({
                    currentPage: 0,
                    sortDirection: sortDirection,
                    sortColumn: sortColumn,
                    defaultPopOpenState: openState,
                });

                localStorage.setItem(
                    `sorting_${processId}_${statusId}`,
                    JSON.stringify({
                        sortColumn,
                        sortDirection,
                    })
                );
            }
        } else {
            const { processId, statusId, queryData, currentPage, perPage } =
                this.state;
            if (processId != null && statusId != null) {
                queryData.pageNumber = 1;
                queryData.pageSize = perPage;
                queryData.sortColumn = '';
                queryData.sortOrder = '';
                queryData.filters = JSON.stringify(this.state.customFilters);

                this.props.getListaction(
                    processId,
                    statusId,
                    this.state.calingFrom,
                    queryData
                );
                this.setState({
                    currentPage: 0,
                    sortDirection: sortDirection,
                    sortColumn: sortColumn,
                });

                localStorage.setItem(
                    `sorting_${processId}_${statusId}`,
                    JSON.stringify({
                        sortColumn: '',
                        sortDirection: 'NONE',
                    })
                );
            }
        }
    }

    refresh_whole() {
        const processId = this.state.processId;
        const statusId = this.state.statusId;
        if (processId !== '' && statusId !== '') {
            this.props.getListaction(processId, statusId);
        }

        const count = [];
        const oldCount = this.props.count;
        getProcessCount(processId)
            .then((response) => {
                // status = response.status;
                return response.json();
            })
            .then((data) => {
                count.push(data);
                oldCount.map((old_count) => {
                    if (
                        old_count.process.processId !== data.process.processId
                    ) {
                        count.push(old_count);
                    }
                    return old_count;
                });
                this.props.getCountData(count);
            });
        getCount(processId, statusId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('refresh_count', data.count);
                let countData = localStorage.getItem(
                    `CountData${data.processId}`
                );
                if (countData !== null) {
                    let totalCount = 0;
                    countData = JSON.parse(countData);
                    if (countData.statuses !== undefined) {
                        countData.statuses.map((arrayval) => {
                            if (
                                arrayval.processId === data.processId &&
                                arrayval.statusId === data.statusId
                            ) {
                                arrayval.count = data.count;
                            }
                            totalCount += arrayval.count;
                            return arrayval;
                        });
                    }
                    if (countData.process !== undefined) {
                        countData.process.count = totalCount;
                    }
                    localStorage.setItem(
                        `CountData${data.processId}`,
                        JSON.stringify(countData)
                    );
                }
            });

        this.getTableData();
    }

    addCustomFilter(filter) {
        const filterIndex = this.state.customFilters.findIndex(
            (existingFilter) =>
                existingFilter.filterColumn === filter.filterColumn
        );

        if (filterIndex === -1) {
            this.setState(
                { customFilters: [...this.state.customFilters, filter] },
                () => {
                    this.updateFilters();
                }
            );
        } else {
            if (filter.filterString.length === 0) {
                const updatedFilters = this.state.customFilters.filter(
                    (_, index) => index !== filterIndex
                );

                const updatedFiltersTrimmed = updatedFilters.map((filter) => {
                    return {
                        ...filter,
                        filterString: filter.filterString.map((str) =>
                            str.trim()
                        ),
                    };
                });

                this.setState({ customFilters: updatedFiltersTrimmed }, () => {
                    this.updateFilters();
                });
            } else {
                const updatedFilters = [...this.state.customFilters];

                updatedFilters[filterIndex] = {
                    ...updatedFilters[filterIndex],
                    filterString: [...filter.filterString],
                };

                const updatedFiltersTrimmed = updatedFilters.map((filter) => {
                    return {
                        ...filter,
                        filterString: filter.filterString.map((str) =>
                            str.trim()
                        ),
                    };
                });

                this.setState({ customFilters: updatedFiltersTrimmed }, () => {
                    this.updateFilters();
                });
            }
        }
    }

    updateFilters() {
        const { processId, statusId, queryData, currentPage, perPage } =
            this.state;
        if (processId != null && statusId != null) {
            const filters = JSON.stringify(this.state.customFilters);
            queryData.pageNumber = 1;
            queryData.pageSize = perPage;
            queryData.filters = filters;
            queryData.sortColumn = this.state.sortColumn;
            queryData.sortOrder = this.state.sortDirection;

            localStorage.setItem(
                `customFilters_${processId}_${statusId}`,
                filters
            );
            this.props.getListaction(
                processId,
                statusId,
                this.state.calingFrom,
                queryData
            );
            this.setState({ currentPage: 0 });
        }
    }

    draggableColumns() {
        const headerRenderer = (props) => {
            return (
                <DraggablePaginatedHeaderRenderer
                    {...props}
                    {...this.state}
                    setGrouping={this.createGroupedData}
                    onColumnsReorder={handleColumnsReorder.bind(this)}
                    handleClickOutside={handleClickOutside}
                    sortFields={this.sortRows}
                    customFilters={this.state.customFilters}
                    onAddCustomFilter={this.addCustomFilter}
                    onColumnFilterClear={this.removeColumnFilters}
                    removeColumnGrouping={this.removeColumnGrouping}
                />
            );
        };
        let header = this.state.header;
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        if (header.length > 0 && !isMobile) {
            // HEADER REORDER AND WIDTH SETTING  FROM LOCALSTORAGE
            /* let headerReorder = localStorage.getItem(
                `header-reorder-${pathname}`
            );
            let headerWidth = localStorage.getItem(`header-width-${pathname}`); */

            const pathNameTempReorder = `header-reorder-${this.state.listingFor}-${pathname}`;
            const pathNameTempWidth = `header-width-${this.state.listingFor}-${pathname}`;

            let headerReorder = localStorage.getItem(`${pathNameTempReorder}`);
            let headerWidth = localStorage.getItem(`${pathNameTempWidth}`);

            if (headerReorder !== null && headerReorder !== '') {
                headerReorder = JSON.parse(headerReorder);
                header = headerDataFormating({ headerReorder, header });
            }
            if (headerWidth !== null && headerWidth !== '') {
                headerWidth = JSON.parse(headerWidth);
                header = headerDataFormating({ headerWidth, header });
            }
        } // END
        if (header.length === 0) {
            header = this.state.header;
        }
        header = header.filter((data) => {
            data.rowGroup = false;
            if (data.groupFormatter !== undefined) {
                delete data.groupFormatter; // issue fixes for grouping
            }
            return data;
        });
        return header.map((c) => {
            if (this.state.groupBy.length > 0) {
                c.rowGroup = this.state.groupBy.indexOf(c.key) !== -1;
                if (c.rowGroup) {
                    c.width = c.width > 200 ? c.width : 200;
                    c.groupFormatter = ToggleGroupFormatter.bind(this);
                }
            }
            if (c.key === 'owner') {
                c.editor = DropDownEditor.bind(this, {
                    parentComp: 'outbox',
                });
                c.editorOptions = { createPortal: true, editOnClick: true };
                c.editable = true;
            }
            if (c.key === 'select-row') {
                c.selectedCount = Array.from(this.state.selectedRows).length;
            }
            c.row_transition = this.state.row_transition;
            if (
                c.key === 'id' ||
                c.key === 'select-row' ||
                this.state.tableDesign === 'simple'
            )
                return c;
            return { ...c, headerRenderer: headerRenderer };
        });
    }

    createGroupedData(
        data,
        type = '',
        mode = false,
        group_By,
        fromProps,
        expand
    ) {
        this.setState({ groupByData: group_By ? group_By : {} });
        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);
        let filter = { type: type, mode: mode };
        filter.data = data;
        filter.groupBy = this.state.groupByData;
        filter.pathname = pathname;
        this.setState({ customGroupedData: [] });
        const groupByData = this.state.groupByData;

        let expandedGroupIds;
        if (expand === undefined) {
            let filter_options = this.props.grouping[pathname];
            if (filter_options) {
                expandedGroupIds = new Set(filter_options.expandedGroupIds);
            } else {
                expandedGroupIds = new Set();
            }
        } else {
            expandedGroupIds = new Set(expand);
        }
        // const { expandedGroupIds } = this.state;
        for (const property in groupByData) {
            if (groupByData) {
                groupByData[property] = groupByData[property].map((data) =>
                    data.trim().toLowerCase()
                );
            }
        }
        const str_pad = (num) => {
            const str1 = '';
            return str1.padStart(num, '_');
        };
        if (String(type) === 'ALL') {
            // SELECT ALL option in POPOVER
            groupByData[data.title] = [];
            let collection = data.value;
            collection = collection.map((v) => v.trim().toLowerCase());
            groupByData[data.title] = mode ? collection : [];
            const groupBy = [];
            const openState = this.state.defaultPopOpenState;
            for (const names in openState) {
                if (openState) {
                    openState[names] = false;
                }
            }
            for (const props in groupByData) {
                if (groupByData[props].length > 0) {
                    groupBy.push(props);
                }
            }
            let groupingValueData = Object.values(groupByData);
            groupingValueData = groupingValueData.filter((v) => v.length > 0);
            const expandedArray = Array.from(expandedGroupIds);
            const lastIndexData =
                groupingValueData[groupingValueData.length - 1];
            const str = str_pad(2);
            if (lastIndexData !== undefined) {
                if (expandedArray.length > 0) {
                    if (
                        lastIndexData !== undefined &&
                        lastIndexData.length > 0
                    ) {
                        expandedArray.forEach((v1) => {
                            lastIndexData.forEach((v2) => {
                                v2 =
                                    v2.charAt(0).toUpperCase() +
                                    v2.slice(1, v2.length);
                                expandedGroupIds.add(v2);
                                const parentIndexCount = v1.split('__').length;
                                if (parentIndexCount <= groupBy.length) {
                                    expandedGroupIds.add(`${v1}${str}${v2}`);
                                }
                            });
                        });
                    }
                } else {
                    lastIndexData.forEach((v2) => {
                        v2 =
                            v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                        if (!expandedGroupIds.has(v2)) {
                            expandedGroupIds.add(v2);
                        }
                    });
                }
            }
            let rowData = this.state.rowData;
            if (!mode && !(groupBy.length > 0)) {
                rowData = _.slice(rowData, 0, this.state.perPage);
            }
            if (this.state.filterTxt !== '') {
                rowData = this.state.filteredRowData;
            }

            filter.expandedGroupIds = Array.from(expandedGroupIds);
            this.props.outboxGrouping(filter); //STORE GROUPING IN REDUCER

            this.setState({
                groupBy: groupBy,
                groupByData: groupByData,
                defaultOpen: groupBy.length > 0,
                groupLoader: groupBy.length > 0,
                filteredRowData: rowData,
                // filteredRowData: [],
                selectedRows: new Set(),
                tempExpandedGroupIds: new Set(),
                defaultPopOpenState: openState,
                reInitiateGrid: true,
            });
            if (mode === false) {
                this.resetPopOverData(this.state.rowData);
            }
            return;
        } // End SELECT ALL
        if (
            data.title !== undefined &&
            this.state.groupByData[data.title] === undefined
        ) {
            // Check , value already exist, if not,  assign it to Array

            groupByData[data.title] = [];
        }
        const val = data.value.trim().toLowerCase();
        if (
            String(groupByData[data.title].indexOf(val)) === String(-1) &&
            mode
        ) {
            // mode = true, add value to array

            groupByData[data.title].push(val);
        } else if (!mode) {
            // mode = false , Remove value

            const index = groupByData[data.title].indexOf(val);
            groupByData[data.title].splice(index, 1);
        }

        const groupBy = [];
        const openState = this.state.defaultPopOpenState; // FOR POPOVER OPEN or CLOSE
        for (const names in openState) {
            if (openState) {
                openState[names] = false; // CLOSING ALL POPOVER
            }
        }
        openState[data.title] = fromProps; // OPENING CURRENT POPOVER
        const groupedValueData = [];
        for (const props in groupByData) {
            if (groupByData[props].length > 0) {
                groupBy.push(props);
                groupByData[props].forEach((d) => {
                    groupedValueData.push(d);
                });
            }
        }
        let groupingValueData = Object.values(groupByData);
        groupingValueData = groupingValueData.filter((v) => v.length > 0);
        const expandedArray = Array.from(expandedGroupIds);
        const lastIndexData = groupingValueData[groupingValueData.length - 1];
        const str = str_pad(2);
        if (lastIndexData !== undefined) {
            // Generate Grouping Expansion Id
            if (expandedArray.length > 0) {
                if (lastIndexData !== undefined && lastIndexData.length > 0) {
                    expandedArray.forEach((v1) => {
                        lastIndexData.forEach((v2) => {
                            v2 =
                                v2.charAt(0).toUpperCase() +
                                v2.slice(1, v2.length);
                            expandedGroupIds.add(v2);
                            const parentIndexCount = v1.split('__').length;
                            if (parentIndexCount <= groupBy.length) {
                                expandedGroupIds.add(`${v1}${str}${v2}`);
                            }
                        });
                    });
                }
            } else {
                lastIndexData.forEach((v2) => {
                    v2 = v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                    if (!expandedGroupIds.has(v2)) {
                        expandedGroupIds.add(v2);
                    }
                });
            }
        } // End
        let rowData = this.state?.rowData ?? [];

        if (String(groupBy.length) === String(0)) {
            rowData = _.slice(rowData, 0, this.state.perPage); // Paginating the data
        }

        if (
            String(this.state.filterTxt) !== '' &&
            this.state.allFilteredRowData !== undefined
        ) {
            rowData = this.state.allFilteredRowData;
        }

        if (
            String(this.state.filterTxt) !== '' &&
            this.state.allFilteredRowData === undefined
        ) {
            rowData = this.props.outbox_page.allFilteredRowData;
        }

        filter.expandedGroupIds = Array.from(expandedGroupIds);
        this.props.outboxGrouping(filter); //STORE GROUPING IN REDUCER

        this.setState({
            groupBy: groupBy, // REQUIRED STATE FOR GROUPING
            groupByData: groupByData,
            defaultOpen: groupBy.length > 0,
            groupLoader: groupBy.length > 0,
            filteredRowData: rowData,
            selectedRows: new Set(),
            defaultPopOpenState: openState,
            reInitiateGrid: true,
            refreshDataGrid: true,
        });

        if (mode === false) {
            this.resetPopOverData(this.state.rowData);
        }
    }

    async getTableData(from = '') {
        this.setState({ refreshDataGrid: false });
        const uniqueData = {};
        const outboxData = this.props.outboxData;

        if (
            stringEmptyOrUndefined(outboxData) &&
            isObjectEmptyOrNot(outboxData)
        ) {
            let permission = userPermission({
                processId: outboxData.processId,
                statusId: outboxData.statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });

            permission = { ...permission, show_pdf: true, show_notes: true }; // default true
            const fieldDataObj = GetFieldData({
                configData: this.props.configData,
            });

            this.setState({ ownerFieldId: fieldDataObj.ownerFieldId });
            if (outboxData.dataTable !== undefined) {
                let { dataRows, headerColumns } = outboxData.dataTable;
                const rowData = [];
                let header = [];
                const uniqueTagName = new Set();
                let dateFormatFields = [];

                // For duplicate header column handle here
                const headerColumnsData = [];
                const headerColumnsData2 = [];
                let nameunique = '';
                let nameunique2 = '';

                let headerColumnsName = [];
                headerColumns.forEach((data, index) => {
                    headerColumnsName.push(data.name);
                });

                const counts = {};
                headerColumnsName.forEach(function (x) {
                    counts[x] = (counts[x] || 0) + 1;
                });

                for (const property in counts) {
                    if (counts[property] > 1) {
                        let totalCount = counts[property];
                        let i = 1;
                        let initiateValue = ' I';
                        if (i <= totalCount) {
                            // eslint-disable-next-line no-loop-func
                            headerColumns.forEach((data, index) => {
                                if (data.name === property) {
                                    if (i === 1) {
                                        nameunique2 = data.name;
                                    } else {
                                        nameunique2 = data.name + initiateValue;
                                    }
                                    const headerArray = {
                                        name: nameunique2,
                                        fieldId: data.fieldId,
                                        datatype: data.datatype,
                                    };
                                    i++;
                                    initiateValue = initiateValue + 'I';
                                    headerColumnsData2.push(headerArray);
                                }
                            });
                        }
                    }
                }

                headerColumns.forEach((data, index) => {
                    if (counts[data.name] === 1) {
                        nameunique = data.name;
                    } else if (counts[data.name] > 1) {
                        headerColumnsData2.forEach((property, index) => {
                            if (property.fieldId === data.fieldId) {
                                nameunique = property.name;
                            }
                        });
                    }
                    const headerArray = {
                        name: nameunique,
                        fieldId: data.fieldId,
                        datatype: data.datatype,
                    };

                    headerColumnsData.push(headerArray);
                });
                headerColumns = headerColumnsData;

                // duplicate header end

                headerColumns.forEach((data, index) => {
                    if (data.name.toUpperCase() === 'OWNER') {
                        this.props.outboxPage({
                            ownerIndex: index,
                        });
                    }

                    const headerName = formatHeaderNameWithOutSpace(data.name);
                    uniqueData[headerName] = new Set();
                    if (
                        data.datatype === 'Date' ||
                        data.datatype === 'DateTime'
                    ) {
                        dateFormatFields = [...dateFormatFields, headerName];
                    }
                    let headerObj = {
                        key: formatHeaderNameWithOutSpace(data.name),
                        name: data.name,
                        colname: data.datatype,
                        sortable: true,
                        draggable: true,
                        resizable: true,
                        width: 150,
                        className: data.name,
                    };
                    switch (data.name.trim().toUpperCase()) {
                        case 'TAGS':
                            headerObj = {
                                ...headerObj,
                                ...{
                                    width: 60,
                                    editorOptions: {
                                        createPortal: true,
                                    },
                                },
                            };
                            break;
                        case 'OWNER':
                            headerObj = {
                                ...headerObj,
                                ...{
                                    editor: DropDownEditor.bind(this, {
                                        parentComp: 'outbox',
                                    }),
                                    className: 'ownercolumn',
                                    width: 100,
                                    editable: true,
                                    editorOptions: {
                                        createPortal: true,
                                        editOnClick:
                                            this.state?.allActions?.hasOwnProperty(
                                                'assign_to_user'
                                            )
                                                ? true
                                                : false, // For Single Click Open
                                    },
                                },
                            };
                            break;
                        case 'TYPE':
                            headerObj = {
                                ...headerObj,
                                ...{
                                    sortable: false,
                                },
                            };
                            break;
                        default:
                            headerObj = {
                                ...headerObj,
                                ...{
                                    width: 150,
                                    editable: false,
                                },
                            };
                            break;
                    }
                    header.push(headerObj);
                });

                //worked for new display name by kalpana - start
                let displayNameObj = {
                    key: 'displayname',
                    name: 'Display Name',
                    colname: 'AlphaNumeric',
                    sortable: true,
                    draggable: true,
                    resizable: true,
                    width: 150,
                    //  frozen: false,
                    className: 'Display Name',
                };
                header.push(displayNameObj);
                uniqueData['displayname'] = new Set();
                //worked for new display name by kalpana - end

                const uniqueRow = [];
                this.setState({ totalColumn: header });

                if (dataRows) {
                    dataRows.forEach((data, index) => {
                        const obj = {};
                        obj.doc_id = data.id;
                        obj.tag_id = index;
                        obj.id = index;
                        obj.displayname = data.displayName; //worked for new display name by kalpana
                        data?.values?.forEach((row, i) => {
                            if (header[i] !== undefined) {
                                obj[header[i].key] = row.trim();
                                obj.processId = this.props.processId;
                                obj.statusId = this.props.statusId;
                                switch (header[i].key) {
                                    //worked for new display name by kalpana - start
                                    case 'displayname':
                                        row = data.displayName;
                                        //worked for new display name by kalpana - end
                                        break;
                                    case 'tags':
                                        obj.tags_data = row;

                                        break;
                                    case 'date&batch':
                                        // eslint-disable-next-line no-case-declarations
                                        const datebatch = row.split('#');
                                        obj.date = datebatch.shift().trim();
                                        obj.batch = datebatch.pop().trim();
                                        obj.date_batch = row.trim();
                                        obj[header[i].key] = row;
                                        obj[header[i].key] =
                                            dateCellFormatter(row);
                                        break;
                                    case 'faxnumber':
                                        obj[header[i].key] = row;
                                        obj[header[i].key] =
                                            faxCellFormatter(row);
                                        obj.faxnumber_rawdata = row.trim();
                                        this.state.modifiedColumnsNames.add(
                                            header[i].key
                                        );
                                        break;
                                    case 'type':
                                        obj[header[i].key] = row;
                                        obj.type_rawdata = row.trim();
                                        obj[header[i].key] =
                                            typeCellFormatter(row);
                                        obj.type_rawdata = row.trim();
                                        this.state.modifiedColumnsNames.add(
                                            header[i].key
                                        );
                                        break;
                                    case 'lastnote':
                                        obj[header[i].key] = row;
                                        obj[header[i].key] =
                                            noteCellFormatter(row);
                                        obj.lastnote_rawdata = row.trim();
                                        this.state.modifiedColumnsNames.add(
                                            header[i].key
                                        );
                                        break;
                                    case 'name':
                                        obj[header[i].key] = row;
                                        obj.name_rawdata = row.trim();
                                        this.state.modifiedColumnsNames.add(
                                            header[i].key
                                        );
                                        break;
                                    case 'owner':
                                        // eslint-disable-next-line vars-on-top, no-var
                                        var ownerValue = '';
                                        // eslint-disable-next-line vars-on-top, no-var
                                        var ownerId = [];
                                        if (this.props.ddl.owner !== '') {
                                            this.props.ddl.owner.map(
                                                (ownerData) => {
                                                    ownerId.push(
                                                        ownerData.ownerId
                                                    );
                                                    if (
                                                        ownerData.ownerId ===
                                                        row
                                                    ) {
                                                        ownerValue =
                                                            ownerData.ownerName;
                                                    }
                                                    return ownerData;
                                                }
                                            );
                                        }

                                        if (ownerId.includes(row)) {
                                            this.props.ddl.owner.map(
                                                (ownerData) => {
                                                    if (
                                                        ownerData.ownerId ===
                                                        row
                                                    ) {
                                                        ownerValue =
                                                            ownerData.ownerName;
                                                    }
                                                    return ownerData;
                                                }
                                            );
                                        } else {
                                            ownerValue = row;
                                        }

                                        // eslint-disable-next-line no-case-declarations
                                        const nameData =
                                            ownerValue.split('#')[0] !==
                                            undefined
                                                ? ownerValue.split('#')[0]
                                                : ownerValue; // issue fixes
                                        obj.owner_rawdata = nameData;
                                        // obj[header[i].key] = ownerCellFormatter(
                                        //     ownerValue,
                                        //     obj.doc_id,
                                        //     true,
                                        //     'outbox'
                                        // );

                                        this.state.modifiedColumnsNames.add(
                                            header[i].key
                                        );
                                        // obj.editable = true;
                                        // row = ownerValue;
                                        break;
                                    case 'documenttype':
                                        obj.documenttype_rawdata = row;
                                        this.state.modifiedColumnsNames.add(
                                            header[i].key
                                        );
                                        break;
                                    default:
                                        obj.editable = false;
                                        break;
                                }
                                switch (header[i].colname) {
                                    case 'Date':
                                        obj[`${header[i].key}_rawdata`] =
                                            formatDateToUtc(
                                                row,
                                                'MM/DD/YYYY hh:mm:ss A'
                                            );
                                        obj[`${header[i].key}_onlyDate`] =
                                            formatDateToUtc(row);
                                        // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                        obj[header[i].key] =
                                            formatDateToUtc(row);
                                        if (header[i].key === 'date&batch') {
                                            obj[header[i].key] =
                                                dateCellFormatter(row);
                                        }

                                        if (row !== undefined) {
                                            uniqueData[header[i].key].add(
                                                formatDateToUtc(row)
                                            );
                                        }
                                        break;
                                    case 'DateTime':
                                        // pls note we r reciving UTC date time('2023-06-29T09:58:42Z') so convert to local date time
                                        let dateVal = '';
                                        const date = new Date(row);
                                        const offset = date.getTimezoneOffset();
                                        const localDate = new Date(
                                            date.getTime() - offset * 60 * 1000
                                        );
                                        dateVal = formatDate(
                                            localDate,
                                            'MM/DD/YYYY HH:mm:ss'
                                        );

                                        obj[`${header[i].key}_rawdata`] =
                                            formatDate(
                                                row,
                                                'MM/DD/YYYY HH:mm:ss'
                                            );
                                        obj[`${header[i].key}_onlyDate`] =
                                            formatDate(
                                                row,
                                                'MM/DD/YYYY HH:mm:ss'
                                            );

                                        // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                        obj[header[i].key] = formatDate(
                                            row,
                                            'MM/DD/YYYY HH:mm:ss'
                                        );

                                        if (header[i].key === 'date&batch') {
                                            obj[header[i].key] =
                                                dateCellFormatter(row);
                                        }
                                        if (row !== undefined) {
                                            uniqueData[header[i].key].add(
                                                //dateTimeDisplayFormatter(row)
                                                formatDate(
                                                    row,
                                                    'MM/DD/YYYY HH:mm:ss'
                                                )
                                            );
                                        }
                                        break;
                                    default:
                                        if (header[i].key === 'owner') {
                                            row =
                                                row.split('#')[0] !== undefined
                                                    ? row.split('#')[0]
                                                    : row; // issue fixes
                                        }
                                        if (row !== undefined) {
                                            uniqueData[header[i].key].add(row);
                                        }
                                        break;
                                }
                            }
                        });
                        //rowData.push(obj);

                        if (!uniqueRow.includes(data.id)) {
                            uniqueRow.push(data.id);
                            rowData.push(obj);
                        }
                    });
                }

                // regrouping data
                let groupByData = {};
                Object.keys(this.state.groupByData).forEach((key) => {
                    if (uniqueData.hasOwnProperty(key)) {
                        groupByData[key] = uniqueData[key];
                    }
                });

                const groupedKeys = Object.keys(groupByData);
                groupedKeys.forEach((key) => {
                    this.createGroupedData(
                        { title: groupedKeys, value: [] },
                        'ALL',
                        false
                    );
                });

                // use createGroupedData
                Object.keys(groupByData).forEach((key) => {
                    const groupValues = Array.from(groupByData[key]) ?? [];
                    if (groupValues.length > 0) {
                        groupValues.forEach((value) => {
                            if (!!value) {
                                this.createGroupedData(
                                    { title: key, value: value },
                                    '',
                                    true,
                                    '',
                                    true
                                );
                            }
                        });
                    }
                });

                // added recently to avoid duplicates

                if (rowData.length !== 0) {
                    if (from === 1) {
                        const myEle = document.getElementById('tracking_count');
                        if (myEle && rowData.length !== 0) {
                            document.getElementById(
                                'tracking_count'
                            ).innerHTML = rowData.length;
                            document.getElementById(
                                'tracking_count'
                            ).style.display = 'inherit';
                        }
                    }
                }

                this.setState({
                    totalCount: this.props.outbox.outbox.totalCount,
                });

                let paginatedRowData = rowData;
                // const fields = sortFields(
                //     this.state.sortColumn,
                //     this.state.sortDirection,
                //     this.state.dateFormatFields
                // );
                // paginatedRowData = _.orderBy(
                //     paginatedRowData,
                //     fields.sortfn,
                //     fields.orderBy
                // ); // Performing sorting
                let columnDataType;
                this.state.header.map((item, index) => {
                    if (item.key === this.state.sortColumn) {
                        columnDataType = item.colname;
                    }
                });

                if (this.state.groupBy.length === 0 || from === 'statistics') {
                    // pagination'

                    const startIndex =
                        parseInt(this.state.currentPage, 10) *
                        parseInt(this.state.perPage, 10);
                    const endIndex =
                        startIndex + parseInt(this.state.perPage, 10);
                    paginatedRowData = this.getRowData(
                        startIndex,
                        endIndex,
                        paginatedRowData
                    ); // Performing Pagination
                    // End
                }
                if (isMobile && window.screen.width <= 960) {
                    header = header.filter(
                        (data) =>
                            data.key !== 'select-row' && data.name.trim() !== ''
                    );

                    const documentTitle = header.filter(
                        (data) =>
                            data.key === 'displayname' ||
                            data.key === 'documentname' ||
                            data.key === 'name' //worked for new display name by kalpana
                    );
                    const docIndex = header.findIndex(
                        (data) =>
                            data.key === 'displayname' ||
                            data.key === 'documentname' ||
                            data.key === 'name' //worked for new display name by kalpana
                    );

                    header.splice(docIndex, 1);
                    const ArrowFormatter = {
                        key: 'toggle',
                        name: '',
                        colname: '',
                        width: 10,
                        className: 'arrow-mobile',
                        formatter: ({ row }) => {
                            const docid = row.doc_id;
                            const openedRowId = this.state.openedRowId;
                            const toggle = () => {
                                if (openedRowId[docid] === undefined) {
                                    openedRowId[docid] = true;
                                    this.setState({ openedRowId: openedRowId });
                                } else {
                                    openedRowId[docid] = !openedRowId[docid];
                                    this.setState({ openedRowId: openedRowId });
                                }
                            };
                            const icon =
                                openedRowId[docid] === true
                                    ? 'fa fa-chevron-up'
                                    : 'fa fa-chevron-right';
                            return (
                                <div
                                    onClick={() => toggle()}
                                    className='toggle-it d-flex align-items-center'
                                >
                                    {' '}
                                    <i
                                        className={`${icon}`}
                                        aria-hidden='true'
                                    />{' '}
                                </div>
                            );
                        },
                    };
                    documentTitle.push(ArrowFormatter);
                    header = documentTitle.concat(header); // Adding Document Name at the beginning
                } else {
                    header = header.filter(
                        (data) =>
                            data.key !== 'select-row' &&
                            data.name.trim() !== '' &&
                            data.name.trim() !== 'Document Name' &&
                            data.name.trim() !== 'Display Name'
                    );
                }
                header = [SelectColumn].concat(header); // Adding Checkbox at the beginning

                //removed by geetha
                /*   if (
                    this.props.configData !== undefined &&
                    this.props.ddl.tags !== undefined
                ) {
                    const { menu_tags, newTagColorArray } =
                        await getTagFromStorage(
                            this.props.configData,
                            this.props.ddl.tags
                        );
                    this.setState({
                       menu_tags,
                        newTagColorArray,
                    });
                }  */
                if (this.state.filterTxt.trim() !== '') {
                    // If Search Text Present

                    this.setState(
                        {
                            ...this.state,
                            rowData: rowData,
                        },
                        () => {
                            /* this.props.updateOutboxState({
                                 rowData: rowData,
                            }); */

                            // paginatedRowData = this.filterDataGrid(
                            //     this.state.filterTxt,
                            //     true
                            // ); // performing search
                            // const fields = sortFields(
                            //     this.state.sortColumn,
                            //     this.state.sortDirection,
                            //     this.state.dateFormatFields
                            // );
                            // paginatedRowData = _.orderBy(
                            //     paginatedRowData,
                            //     fields.sortfn,
                            //     fields.orderBy
                            // ); // Performing sorting
                            let columnDataType;
                            this.state.header.map((item, index) => {
                                if (item.key === this.state.sortColumn) {
                                    columnDataType = item.colname;
                                }
                            });
                            if (this.state.groupBy.length === 0) {
                                const startIndex =
                                    parseInt(this.state.currentPage, 10) *
                                    parseInt(this.state.perPage, 10);
                                const endIndex =
                                    startIndex +
                                    parseInt(this.state.perPage, 10);
                                paginatedRowData = this.getRowData(
                                    startIndex,
                                    endIndex,
                                    paginatedRowData
                                ); // Performing Pagination
                            }
                            this.setState({
                                filteredRowData: paginatedRowData,
                                header: header,
                                dateFormatFields,
                                modifiedColumnsNames:
                                    this.state.modifiedColumnsNames,
                                currentStateMenuName: stringEmptyOrUndefined(
                                    this.props.currentMenuName
                                )
                                    ? this.props.currentMenuName
                                    : null,
                                ...permission,
                                // menu_tags,
                                // newTagColorArray,
                                refreshDataGrid: true,
                                statictics: false,
                            });
                        }
                    );
                } else {
                    this.setState(
                        {
                            rowData: rowData,
                            header: header,
                            filteredRowData: paginatedRowData,
                            // totalCount: rowData.length,
                            uniqueData: uniqueData,
                            uniqueTagNameValues: uniqueTagName,
                            dateFormatFields,
                            modifiedColumnsNames:
                                this.state.modifiedColumnsNames,
                            currentStateMenuName: stringEmptyOrUndefined(
                                this.props.currentMenuName
                            )
                                ? this.props.currentMenuName
                                : null,
                            ...permission,
                            // menu_tags,
                            // newTagColorArray,
                            refreshDataGrid: true,
                            statictics: false,
                            totalCount: this.props.outbox.outbox.totalCount,
                        },
                        () => {
                            /*this.props.updateOutboxState({
                                 //...this.state.GridState
                            })   */
                        }
                    );
                }
            }
        }
    }

    initialConfigExecAfterState(
        params = {
            searchMode: false,
        }
    ) {
        const allRowsStat = this.props.outbox_page.initialData;
        let paginatedRowData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            // paginatedRowData = this.filterDataGrid(
            //     this.state.filterTxt,
            //     params.searchMode
            // );

            this.setState({
                allFilteredRowData: paginatedRowData,
                totalCount: this.props.outbox.outbox.totalCount,
                selectedRows: new Set(),
            });
            this.props.outboxPage({
                allFilteredRowData: paginatedRowData,
            });
            let inputData = this.state.filterTxt;
            inputData = inputData.toLowerCase();
            inputData = inputData.trim();
            const filteredData = _.filter(allRowsStat, function (data) {
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        // eslint-disable-next-line no-continue
                        continue;
                    } // Excluding few Property from filter
                    passed =
                        String(data[property]).toLowerCase().match(inputData) !=
                            null &&
                        String(data[property]).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                }
                return passed;
            });
            this.props.outboxPage({
                // allRowData: paginatedRowData
                filteredData: filteredData,
            });
            if (filteredData.length === 0) {
                this.props.outboxPage({
                    allRowData: filteredData,
                });
            }
        } else {
            this.props.outboxPage({
                // allRowData: paginatedRowData
                filteredData: allRowsStat,
            });
            const pathname = window.location.pathname.split('/');
            if (this.props.statistics_display === true) {
                if (
                    pathname.length > 2 &&
                    pathname[2] === 'hold-for-signature'
                ) {
                    this.setState({
                        rowData: allRowsStat,
                        filteredData: allRowsStat,
                    });
                    paginatedRowData = allRowsStat;
                }
            }
            if (this.state.groupBy.length === 0) {
                const startIndex = 0;
                const endIndex = startIndex + parseInt(this.state.perPage, 10);
                if (this.state.sortColumn) {
                    let columnDataType;
                    this.state.header.map((item, index) => {
                        if (item.key === this.state.sortColumn) {
                            columnDataType = item.colname;
                        }
                    });
                }
                // const fields = sortFields(
                //     this.state.sortColumn,
                //     this.state.sortDirection,
                //     this.state.dateFormatFields
                // );
                // paginatedRowData = _.orderBy(
                //     paginatedRowData,
                //     fields.sortfn,
                //     fields.orderBy
                // ); // Sorting
                paginatedRowData = _.slice(
                    paginatedRowData,
                    startIndex,
                    endIndex
                ); // Pagination
            }

            this.setState(
                {
                    filteredRowData: paginatedRowData,
                    currentPage: 0,
                    perPage: this.state.perPage,
                    totalCount: this.props.outbox.outbox.totalCount,
                },
                () => {
                    this.resetPopOverData();
                }
            );
        }
    }

    /*
     *Paging Operation with Search ( if present ) + Sort ( if Present )
     */
    handleChangeRowsPerPage(event) {
        if (localStorage.getItem('retainShowAllChecked') === 'true') {
            localStorage.setItem('retainShowAllChecked', 'false');
            this.setState({ showAllChecked: false });
            toast.success(
                "'Load All Data' option is disabled. You can enable it using 'Show All' button",
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }

        const endIndex = parseInt(event.target.value, 10);
        const { processId, statusId, queryData } = this.state;
        queryData.pageNumber = 1;
        queryData.pageSize = endIndex;
        queryData.fullTextSearchString = this.state.filterTxt;

        this.props.getListaction(
            processId,
            statusId,
            this.state.calingFrom,
            queryData
        );

        let paginatedRowData = this.state.rowData;
        let columnDataType;
        this.state.header.map((item, index) => {
            if (item.key === this.state.sortColumn) {
                columnDataType = item.colname;
            }
        });
        // const fields = sortFields(
        //     this.state.sortColumn,
        //     this.state.sortDirection,
        //     this.state.dateFormatFields
        // );
        if (this.state.filterTxt.trim() !== '') {
            //paginatedRowData = this.filterDataGrid(this.state.filterTxt); // Performing Search
            // paginatedRowData = _.orderBy(
            //     paginatedRowData,
            //     fields.sortfn,
            //     fields.orderBy
            // ); // Performing Sorting
        } else {
            // paginatedRowData = _.orderBy(
            //     paginatedRowData,
            //     fields.sortfn,
            //     fields.orderBy
            // ); // Performing Sorting
        }

        //paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex); // Performing Pagination
        this.setState({
            filteredRowData: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
    }

    /*
     *Pagination, Moving to Next page + Search (if present ) + Sort Operation
     */
    nextPage(event, newPage) {
        const {
            processId,
            statusId,
            queryData,
            currentPage,
            perPage,
            filterTxt,
        } = this.state;
        if (processId != null && statusId != null && newPage != currentPage) {
            queryData.pageNumber = newPage + 1;
            queryData.pageSize = perPage;
            queryData.fullTextSearchString = filterTxt;

            var response = this.props.getListaction(
                processId,
                statusId,
                this.state.calingFrom,
                queryData
            );

            const startIndex =
                parseInt(newPage, 10) * parseInt(this.state.perPage, 10);
            const endIndex = startIndex + parseInt(this.state.perPage, 10);
            let paginatedRowData = this.state.rowData;

            this.setState({
                filteredRowData: paginatedRowData,
                currentPage: newPage,
                totalCount: this.state.totalCount,
            });
        }
        return newPage;
    }

    /*
     *Search Operation
     */
    filterDataGrid(searchText = '', preventState = false) {
        // Fixes for ReEx issue
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        const isValid = (str) => {
            //return !/[~`!#$%\^&*+=\-\[\]\\';{}|\\"<>\?]/g.test(str);
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText) === false) {
            // Blocking Special Character
            return [];
        }

        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        const { processId, statusId, queryData, currentPage, perPage } =
            this.state;
        queryData.pageNumber = currentPage + 1;
        queryData.pageSize = perPage;
        queryData.fullTextSearchString = inputData;

        this.props.getListaction(
            processId,
            statusId,
            this.state.calingFrom,
            queryData
        );

        return this.state.rowData;
    }

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        //endIndex = endIndex < 10 ? 10 : endIndex; // if perPage is less than 10
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    createGroupByRows(groupedData, indexProp) {
        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            if (String(property) === String(this.state.sortColumn)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.groupByData[property] =
                    this.state.sortDirection === 'ASC'
                        ? rowValue.sort()
                        : rowValue.sort().reverse(); // Performing Sorting
            }
        }
        // End
        let customGroupedData = [];
        const groupResult = {};
        let dateFieldColumn = '';
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        if (String(indexProp) === 'tags') {
            const emptyTagRowNames = [];
            for (let [property, rowValue] of Object.entries(
                this.state.groupByData
            )) {
                const index = this.state.groupBy.indexOf(property);
                if (index !== -1 && String(rowValue.length) === 0) {
                    this.state.groupBy.splice(index, 1);
                }
                if (String(property) === String(indexProp)) {
                    groupedData.forEach((data) => {
                        let tagData = String(data.tags_data).split('|');
                        tagData = tagData.map((data) =>
                            data.trim().toLowerCase()
                        );
                        rowValue = rowValue.map((data) =>
                            data.trim().toLowerCase()
                        );
                        rowValue.forEach((tag) => {
                            if (tagData.indexOf(tag) !== -1) {
                                if (groupResult[tag] === undefined) {
                                    groupResult[tag] = [];
                                }
                                groupResult[tag].push(data);
                            } else {
                                emptyTagRowNames.push(tag);
                            }
                        });
                    });
                    //Need to check here , since we r using both sort
                    const fields = sortFields(
                        this.state.sortColumn,
                        this.state.sortDirection,
                        this.state.dateFormatFields
                    );
                    for (const property in groupResult) {
                        groupResult[property] = _.orderBy(
                            groupResult[property],
                            fields.sortfn,
                            fields.orderBy
                        );
                    }
                    let columnDataType;
                    this.state.header.map((item, index) => {
                        if (item.key === this.state.sortColumn) {
                            columnDataType = item.colname;
                        }
                    });
                }
            }

            this.render();
            const emptyData = {};
            if (emptyTagRowNames.length > 0) {
                emptyTagRowNames.forEach((name) => {
                    emptyData[name] = [];
                });
            }
            // eslint-disable-next-line vars-on-top
            var result = { ...emptyData, ...groupResult };
            result = ObjectPropertyToUpperCase(
                result,
                this.state.sortDirection,
                this.state.groupByData
            );

            return result;
        }

        // Check If it is Date Field And Group It
        const emptyDateBatch = [];
        if (this.state.dateFormatFields.indexOf(indexProp) !== -1) {
            const index = this.state.dateFormatFields.indexOf(indexProp);
            dateFieldColumn = this.state.dateFormatFields[index];
            if (dateFieldColumn === 'date&batch') {
                customGroupedData = _.groupBy(groupedData, 'date_batch');
            } else {
                customGroupedData = _.groupBy(
                    groupedData,
                    `${dateFieldColumn}_onlyDate`
                );
            }
        } else if (modifiedColumnsNames.indexOf(indexProp) !== -1) {
            customGroupedData = _.groupBy(groupedData, `${indexProp}_rawdata`);
        } else {
            customGroupedData = _.groupBy(groupedData, `${indexProp}`);
        }

        customGroupedData = ObjectPropertyToLowerCase(customGroupedData);

        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            const index = this.state.groupBy.indexOf(property);
            if (index !== -1 && rowValue.length === 0) {
                this.state.groupBy.splice(index, 1);
            }

            if (String(property) === String(indexProp)) {
                rowValue.forEach((data, i) => {
                    const dateData = data.trim();
                    if (customGroupedData[dateData] !== undefined) {
                        const fields = sortFields(
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields
                        );
                        customGroupedData[dateData] = _.orderBy(
                            customGroupedData[dateData],
                            fields.sortfn,
                            fields.orderBy
                        );
                        let columnDataType;
                        this.state.header.map((item, index) => {
                            if (item.key === this.state.sortColumn) {
                                columnDataType = item.colname;
                            }
                        });
                        groupResult[data] = customGroupedData[dateData];
                    } else {
                        emptyDateBatch.push(dateData);
                    }
                });
            }
        }

        const empty = {};
        if (emptyDateBatch.length > 0) {
            emptyDateBatch.forEach((name) => {
                empty[name] = [];
            });
        }
        this.render();
        // eslint-disable-next-line vars-on-top, no-redeclare, block-scoped-var
        var result = { ...empty, ...groupResult };

        // eslint-disable-next-line block-scoped-var
        result = ObjectPropertyToUpperCase(
            // eslint-disable-next-line block-scoped-var
            result,
            this.state.sortDirection,
            this.state.groupByData
        );

        if (this.state.customGroupedData.length === 0) {
            // eslint-disable-next-line block-scoped-var
            this.setState({ customGroupedData: result }, () => {
                const temp = Object.values(this.state.customGroupedData);
                const tempArray = [];
                temp.map((data) => {
                    data.map((array) => {
                        tempArray.push(array);
                    });
                });

                this.makeChildUniqueData(tempArray);
            });
        }
        // eslint-disable-next-line block-scoped-var
        return result;
    }

    removeAllFilters() {
        const { processId, statusId, queryData, currentPage, perPage } =
            this.state;
        if (processId != null && statusId != null) {
            queryData.pageNumber = 1;
            queryData.pageSize = perPage;
            queryData.filters = [];

            localStorage.setItem(`customFilters_${processId}_${statusId}`, []);
            localStorage.setItem(
                `sorting_${processId}_${statusId}`,
                JSON.stringify({ sortColumn: '', sortDirection: 'NONE' })
            );
            this.props.getListaction(
                processId,
                statusId,
                this.state.calingFrom,
                queryData
            );
            this.setState({
                currentPage: 0,
                customFilters: [],
                sortColumn: '',
                sortDirection: 'NONE',
            });
        }
    }

    removeColumnFilters(columnName) {
        const updatedFilters = this.state.customFilters.filter(
            (filter) => filter.filterColumn !== columnName
        );

        this.setState(
            {
                currentPage: 0,
                customFilters: updatedFilters,
            },
            () => {
                this.updateFilters();
            }
        );
    }

    removeColumnGrouping(columnName) {
        if (this.state.groupBy.length === 1) {
            this.removeAllGrouping();
            return;
        } else {
            const groupBy = this.state.groupBy.filter(
                (group) => group !== columnName
            );

            const groupByData = { ...this.state.groupByData };
            delete groupByData[columnName];

            let pathname = this.props.location.pathname;
            pathname = getPageFromPath(pathname);
            let filter = this.props.grouping[pathname];

            if (filter && filter?.groupBy) {
                filter.groupBy = groupByData;
            }

            this.props.outboxGrouping(filter);

            this.setState({
                groupBy,
                groupByData,
                reInitiateGrid: true,
                refreshDataGrid: true,
            });
        }
    }

    removeAllGrouping() {
        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);
        let filter = {};
        filter.pathname = pathname;
        this.props.outboxGrouping(filter);
        this.setState({ refreshDataGrid: false });
        let paginatedRowData = this.state.rowData;
        if (this.state.filterTxt !== '') {
            paginatedRowData = this.state.filteredRowData;
        }
        const startIndex =
            parseInt(this.state.currentPage, 10) *
            parseInt(this.state.perPage, 10);
        const endIndex = startIndex + parseInt(this.state.perPage, 10);
        paginatedRowData = this.getRowData(
            startIndex,
            endIndex,
            paginatedRowData
        );
        this.setState(
            {
                groupByData: {},
                groupBy: [],
                filteredRowData: this.state.filteredRowData,
                filterTxt: this.state.filterTxt,
                groupingData: {},
                tempExpandedGroupIds: new Set(),
                expandedGroupIds: new Set(),
                rowClickClass: this.state.row_transition
                    ? this.state.rowClickClass
                    : [],
                customFilters: this.state.customFilters,
                reInitiateGrid: true,
                refreshDataGrid: true,
            },
            () => {
                //modified by geetha ...state
                this.props.updateOutboxState({
                    customGroupedData: this.state.customGroupedData,
                    sortDirection: this.state.sortDirection,
                    sortColumn: this.state.sortColumn,
                    statictics: this.state.statictics,
                    row_transition: this.state.row_transition,
                    modifiedColumnsNames: this.state.modifiedColumnsNames,
                    header: this.state.header,
                    currentStateMenuName: this.state.currentStateMenuName,
                    dateFormatFields: this.state.dateFormatFields,
                    totalCount: this.state.totalCount,
                    tableDesign: this.state.tableDesign,
                    uniqueData: this.state.uniqueData,
                    perPage: this.state.perPage,
                    currentPage: this.state.currentPage,
                    filterTxt: this.state.filterTxt,
                    rowData: this.state.rowData,
                    groupByData: {},
                    groupBy: [],
                    filteredRowData: this.state.filteredRowData,
                    groupingData: {},
                    tempExpandedGroupIds: new Set(),
                    expandedGroupIds: new Set(),
                    rowClickClass: this.state.row_transition
                        ? this.state.rowClickClass
                        : [],
                    reInitiateGrid: true,
                    refreshDataGrid: true,
                });
            }
        );
    }

    // Tag Functions
    refreshTag = async () => {
        this.setState({
            tagLoader: true,
            // refresh_table: false,
            refresh_table: true,
        });
    };

    enableTagLoader = () => {
        this.setState({ tagLoader: true });
    };

    disableTagLoader = () => {
        this.setState({ tagLoader: false });
    };

    hasAppliedGrouping = () => {
        return Object.keys(this.state.groupByData).length > 0;
    };

    tagActionUpdate = (tags) => {
        const { doc_id } = this.state.filteredRowData[this.state.tagIndex];
        const { tagElement } = createTagElement(
            tags,
            this.props.configData,
            this.state?.allAction?.hasOwnProperty('update_tag') ? true : false,
            '',
            this.props.ddl.tags
        );
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.filteredRowData[this.state.tagIndex].tags = tagElement;
        let tagIndexPosition = '';

        this.props.outbox?.outbox?.dataTable?.headerColumns?.map(
            (data, index) => {
                if (data.name.toLowerCase() === 'tags') {
                    tagIndexPosition = index;
                }
            }
        );
        this.props.outbox.outbox.dataTable.dataRows.map((data, index) => {
            if (data.id === doc_id) {
                this.props.outbox.outbox.dataTable.dataRows[index].values[
                    tagIndexPosition
                ] = tags;
            }
        });
        // this.props.outbox.outbox.dataTable.dataRows[this.state.tagIndex].values[0] = tags;
        // Modified by sathish
        this.state.rowData.map((data) => {
            if (data.doc_id === doc_id) {
                data.tags = tagElement;
                data.tags_data = tags;
            }
            return data;
        });
        // End
        let paginatedRowData = this.state.filteredRowData;
        const updateFilter = () => {
            if (this.state.filterTxt.trim() !== '') {
                // const fields = sortFields(
                //     this.state.sortColumn,
                //     this.state.sortDirection,
                //     this.state.dateFormatFields
                // );
                // paginatedRowData = this.filterDataGrid(
                //     this.state.filterTxt,
                //     true
                // );
                let columnDataType;
                this.state.header.map((item, index) => {
                    if (item.key === this.state.sortColumn) {
                        columnDataType = item.colname;
                    }
                });
                // paginatedRowData = _.orderBy(
                //     paginatedRowData,
                //     fields.sortfn,
                //     fields.orderBy
                // );
                const startIndex =
                    parseInt(this.state.currentPage, 10) *
                    parseInt(this.state.perPage, 10);
                const endIndex = startIndex + parseInt(this.state.perPage, 10);
                paginatedRowData = _.slice(
                    paginatedRowData,
                    startIndex,
                    endIndex
                );
                const tagOptions = document.querySelector('#tag_options');
                if (tagOptions !== null) {
                    tagOptions.setAttribute(
                        'style',
                        `
							border: 1px solid black;
							background-color: white;
							position: absolute;
							margin: ${tagOptions.style.margin};
							top: ${tagOptions.style.top};
							left: ${tagOptions.style.left};
							
						`
                    );
                }
            }
            this.setState(
                {
                    filteredRowData: paginatedRowData,
                    tagLoader: false,
                },
                () => {
                    this.props.updateList(this.props.outbox.outbox);

                    this.props.updateOutboxState({
                        filteredRowData: paginatedRowData,
                        tagLoader: false,
                        rowData: this.state.rowData,
                    });
                }
            );
        };

        // if(this.props.row_transition !== undefined && this.props.row_transition === true){
        //     this.props.updateState({
        //         row_transition: false,
        //     })
        //     setTimeout(() => {
        //         this.props.updateState({
        //         row_transition: true,
        //         })
        //     },100)
        // }

        this.resetPopOverData();
        this.setState(
            {
                rowData: this.state.rowData,
            },
            () => {
                updateFilter();
            }
        );
    };

    //Common Function used to regenerate Popover Data while filtering
    resetPopOverData(filteredData = []) {
        const uniqueData = {};
        const uniqueTagNameValues = new Set();
        let makeData = this.state.rowData;
        if (this.state.filterTxt.trim() !== '') {
            makeData = filteredData;
        }

        // makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (uniqueData[property] === undefined) {
                    uniqueData[property] = new Set();
                }
                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        uniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        this.props.outboxData.dataTable?.headerColumns.map(
                            (item, idx) => {
                                if (item.datatype === 'DateTime') {
                                    if (
                                        formatHeaderNameWithOutSpace(
                                            item.name
                                        ) === property
                                    ) {
                                        uniqueData[property].add(
                                            formatDate(
                                                data[`${property}_rawdata`],
                                                'MM/DD/YYYY HH:mm:ss'
                                            )
                                        );
                                    }
                                } else {
                                    uniqueData[property].add(
                                        formatDate(
                                            data[`${property}_rawdata`],
                                            'MM/DD/YYYY'
                                        )
                                    );
                                }
                            }
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    uniqueTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    uniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    uniqueData[property].add(data[property]);
                }
            }
        });
        this.setState(
            {
                uniqueData,
                uniqueTagNameValues,
            },
            () => {}
        );
    }

    makeChildUniqueData = (filteredData = []) => {
        const childUniqueData = {};
        const childTagNameValues = new Set();
        let makeData = this.state.rowData;
        makeData = filteredData;
        const modifiedColumnsNames = Array.from(
            this.state.modifiedColumnsNames
        );
        makeData.forEach((data, i) => {
            for (const property in data) {
                if (childUniqueData[property] === undefined) {
                    childUniqueData[property] = new Set();
                }

                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        childUniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        childUniqueData[property].add(
                            formatDate(
                                data[`${property}_rawdata`],
                                'MM/DD/YYYY'
                            )
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    childTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    childUniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    childUniqueData[property].add(data[property]);
                }
            }
        });

        this.setState({
            childUniqueData,
            childTagNameValues,
        });
    };

    handleRowMouseEnter = (event, row) => {
        const lockCell =
            event.target.className.includes('rdg-cell-frozen') &&
            event.currentTarget.classList.contains('locked');

        if (!lockCell) {
            this.props.setLockBarVisibility(false);
            return;
        }
        const rowRect = event.target.getBoundingClientRect();
        if (this.state.lockBarTimeout) {
            clearTimeout(this.state.lockBarTimeout);
            this.setState({ lockBarTimeout: null });
        }

        this.props.setLockBarVisibility(true);
        this.setState({
            hoveredRowId: row.doc_id,
            lockBarPosition: {
                top: rowRect.top,
                left: rowRect.left,
            },
            isMouseOverLockedCell: true,
        });
    };

    handleRowMouseLeave = () => {
        const timeout = setTimeout(() => {
            if (!this.state.isMouseOverLockedCell) {
                this.props.setLockBarVisibility(false);
                this.setState({ lockBarPosition: null });
            }
        }, 1000);
        this.setState({
            lockBarTimeout: timeout,
            isMouseOverLockedCell: false,
        });
    };

    handleUnlock = () => {
        this.props.onUnlock(this.state.hoveredRowId);
    };

    myRowRender = (props) => {
        const tagData = createTagElement(
            props.row.tags_data,
            this.props.configData,
            true,
            '',
            this.props.ddl.tags
        );
        const ownerData = ownerCellFormatter(
            props.row.owner_rawdata,
            props.row.doc_id,
            this.state?.allActions?.hasOwnProperty('assign_to_user')
                ? true
                : false,
            'outbox'
        );

        const nameData = nameCellFormatter(props.row.name_rawdata);

        const documentTypeData = typeCellFormatter(
            props.row.documenttype_rawdata
        );

        props.row.owner = Object.create(ownerData);
        props.row.tags = Object.create(tagData.tagElement);
        props.row.name = Object.create(nameData);
        props.row.documenttype = Object.create(documentTypeData);

        return (
            <>
                <Row
                    {...props}
                    onMouseEnter={(event) =>
                        this.handleRowMouseEnter(event, props.row)
                    }
                    onMouseLeave={this.handleRowMouseLeave}
                />
            </>
        );
    };

    onShowAll = () => {
        this.setState({
            showAllConfirmation: false,
            perPage: 50000,
            currentPage: 0,
            queryData: {
                ...this.state.queryData,
                pageNumber: 1,
                pageSize: this.state.totalCount,
            },
        });

        const { processId, statusId, queryData } = this.state;

        queryData.pageNumber = 1;
        queryData.pageSize = 50000;
        queryData.fullTextSearchString = this.state.filterTxt;

        this.props.getListaction(
            processId,
            statusId,
            this.state.calingFrom,
            queryData
        );

        if (this.state.retainShowAllChecked) {
            localStorage.setItem('retainShowAllChecked', true);
        } else {
            localStorage.setItem('retainShowAllChecked', false);
        }
    };

    hasAppliedFilters = () => {
        const nonEmptyFilters = this.state.customFilters.filter((filter) =>
            filter.filterString.some((input) => input.trim() !== '')
        );
        return nonEmptyFilters;
    };

    render() {
        let onRowClick = () => {};
        let onSelectedRowsChange = () => {};
        // if (stringEmptyOrUndefined(this.props.rowHeightfn)) {
        //     rowHeightFun = this.props.rowHeightfn;
        // }
        if (stringEmptyOrUndefined(this.props.onRowClick)) {
            onRowClick = this.props.onRowClick;
        }

        if (stringEmptyOrUndefined(this.props.onSelectedRowsChange)) {
            onSelectedRowsChange = this.props.onSelectedRowsChange;
        }

        let stateRowData = this.state.rowData;

        stateRowData.forEach((data, index) => {
            data.rowId = index;
            return data;
        });

        // Normal Height Calc
        let defaultHeight = 560;
        if (stateRowData.length <= 10) {
            // eslint-disable-next-line eqeqeq
            if (stateRowData.length == 0) {
                defaultHeight = 130;
            } else {
                defaultHeight = stateRowData.length * 50 + 70;
            }
        }
        // if (stateRowData.length > 10) {
        //     if (document.getElementById('inbox_bottom_menu')) {
        //         var element = document.getElementById('inbox_bottom_menu');
        //         element.classList.add('sticky');
        //     }
        // } else if (
        //     stateRowData.length <= 10 &&
        //     this.state.row_transition !== true
        // ) {
        //     if (document.getElementById('inbox_bottom_menu')) {
        //         var element = document.getElementById('inbox_bottom_menu');
        //         element.classList.remove('sticky');
        //     }
        // }

        if (stateRowData.length > 10) {
            if (this.state.row_transition === true) {
                defaultHeight = 10 * 50 + 70 + 410;
            } else if (this.state.groupBy.length > 0) {
                defaultHeight = this.state.groupByData * 50 + 70;
                // defaultHeight = stateRowData.length * 50 + 70;
            } else {
                defaultHeight = stateRowData.length * 50 + 70;
            }
            // defaultHeight = 570;
        }

        // End
        // let reBuildGrid = {};
        // if (this.state.reInitiateGrid) {
        //     // Need to Set this value to TRUE , if we face ROW COLLAPSE or DATAGRID Design issue
        //     reBuildGrid = { key: Math.random() };
        // }

        let responsiveGrid = {};
        const toggleRowElement = (params) => {
            const { row } = params;
            const docid = row.doc_id;
            const openedRowId = this.state.openedRowId;
            if (openedRowId[docid] === undefined) {
                openedRowId[docid] = true;
                this.setState({ openedRowId: openedRowId });
            } else {
                openedRowId[docid] = !openedRowId[docid];
                this.setState({ openedRowId: openedRowId });
            }
        };

        // isMobile
        if (isMobile && window.screen.width <= 960) {
            responsiveGrid = {
                rowRenderer: (props) => {
                    return (
                        <RowRenderer
                            {...props}
                            fullHeaderData={this.state.header}
                            openedRowId={this.state.openedRowId}
                            modifiedColumnsNames={Array.from(
                                this.state.modifiedColumnsNames
                            )}
                            dateFormatFields={Array.from(
                                this.state.dateFormatFields
                            )}
                            toggleRowElement={toggleRowElement}
                        />
                    );
                },
                rowHeight: (args) => {
                    const { type, row } = args;

                    let heightVal = this.state.header.length * 30;
                    if (
                        this.state.header.length >= 15 &&
                        this.state.header.length <= 19
                    ) {
                        heightVal = this.state.header.length * 30;
                    } else if (
                        this.state.header.length < 15 &&
                        this.state.header.length >= 12
                    )
                        heightVal = this.state.header.length * 35;
                    else if (
                        this.state.header.length < 12 &&
                        this.state.header.length >= 10
                    )
                        heightVal = this.state.header.length * 40;
                    else if (
                        this.state.header.length < 10 &&
                        this.state.header.length > 4
                    )
                        heightVal = (this.state.header.length - 1) * 45;
                    // the below four line show compile error please check it
                    // else this.state.header.length * 42;

                    if (type === 'GROUP') {
                        return 70;
                    }

                    if (
                        this.state.openedRowId[row.doc_id] !== undefined &&
                        this.state.openedRowId[row.doc_id] === true
                    ) {
                        return heightVal;
                    }
                    return 60;
                },
                headerRowHeight: 0,
            };
        }

        const hasAppliedFilters = () => {
            const nonEmptyFilters = this.state.customFilters.filter((filter) =>
                filter.filterString.some((input) => input.trim() !== '')
            );
            return nonEmptyFilters;
        };

        const appliedFilters = this.hasAppliedFilters();

        // defaultHeight = (73 * window.innerHeight) / 100;
        defaultHeight = window.innerHeight - 280;
        defaultHeight =
            appliedFilters?.length >= 1 ? defaultHeight - 32 : defaultHeight;
        defaultHeight =
            this.state.groupBy.length > 0 ? defaultHeight - 32 : defaultHeight;

        // if (window.innerHeight < 700) {
        //     defaultHeight = window.innerHeight + 30;
        // }

        return (
            <>
                {this.hasAppliedGrouping() > 0 ? (
                    <div
                        className='remove-grouping-all'
                        style={{ cursor: 'pointer' }}
                    >
                        <div className='count_no d-none'>
                            <span>12</span>
                        </div>
                        <i className='fa fa-list-alt' aria-hidden='true' />
                        <span
                            title='Remove Grouping'
                            onClick={() => this.removeAllGrouping()}
                        >
                            Remove Grouping{' '}
                        </span>
                    </div>
                ) : null}
                {appliedFilters?.length >= 1 ? (
                    <div
                        className='remove-filters-all'
                        style={{ cursor: 'pointer' }}
                    >
                        <div className='count_no d-none'>
                            <span>12</span>
                        </div>
                        <i className='fa fa-list-alt' aria-hidden='true' />
                        <span
                            title='Remove Filters'
                            onClick={() => this.removeAllFilters()}
                        >
                            Remove Filters{' '}
                        </span>
                    </div>
                ) : null}
                {/* <Spinner show={true} /> */}
                {
                    // Remove By S1008, For maintain Grid state for scrollIssue
                    //this.state.refreshDataGrid ?
                    <DndProvider backend={HTML5Backend}>
                        <DataGrid
                            // {...reBuildGrid}
                            // added to check scroll issue 12-10-2022
                            detailBigViewExpand={this.props.detailBigViewExpand}
                            ref={this.gridRef}
                            columns={this.draggableColumns()}
                            rows={stateRowData}
                            rowKeyGetter={(row) => {
                                return row.id;
                            }}
                            rowsCount={stateRowData.length}
                            emptyRowsRenderer={NoRecordFound.bind(
                                this,
                                this.props.loading
                            )}
                            groupBy={this.state.groupBy}
                            expandedGroupIds={this.state.tempExpandedGroupIds}
                            onExpandedGroupIdsChange={(groupId) => {
                                this.setState({
                                    expandedGroupIds: groupId,
                                    tempExpandedGroupIds: groupId,
                                });
                            }}
                            rowRenderer={this.myRowRender}
                            selectedRows={this.state.selectedRows}
                            sortColumns={this.state.sortColumns}
                            onSortColumnsChange={(sortColumns) => {
                                this.setState({ sortColumns: sortColumns });
                            }}
                            style={{ height: defaultHeight }}
                            className='fill-grid'
                            rowClass={(row) => {
                                let className = '';
                                let lockListIds = JSON.parse(
                                    localStorage.getItem('documentLocks') ||
                                        '[]'
                                );
                                const isLocked =
                                    this.props.lockedDocIds.includes(
                                        row.doc_id
                                    ) && !lockListIds.includes(row.doc_id);
                                if (isLocked) {
                                    className = 'locked';
                                }
                                if (
                                    this.state.rowClickClass.indexOf(row.id) !==
                                    -1
                                ) {
                                    if (
                                        Number(this.props.outbox_page.rowID) !==
                                        Number(row.rowId)
                                    ) {
                                        this.props.outboxPage({
                                            rowID: row.rowId,
                                        });
                                    }
                                    className = 'row-selected-once';
                                    if (isLocked) {
                                        className += ' locked';
                                    }
                                }
                                return className.trim();
                            }}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true,
                                frozen: false,
                            }}
                            rowHeight={rowHeightDataGrid}
                            rowGrouper={(groupedData, indexProp) =>
                                this.createGroupByRows(groupedData, indexProp)
                            }
                            onRowClick={(rowDetails, rowHeader) => {
                                onRowClick(rowDetails, rowHeader, this.state);
                            }}
                            onColumnResize={(idx, width) => {
                                customizedWidth(
                                    idx,
                                    width,
                                    this.state.header,
                                    this.props.location.pathname,
                                    this.state.listingFor
                                );
                            }}
                            onSelectedRowsChange={(rows) => {
                                const selectedRows = rows;
                                this.setState(
                                    {
                                        selectedRows: selectedRows,
                                    },
                                    () => {
                                        onSelectedRowsChange(
                                            selectedRows,
                                            this.state
                                        );
                                    }
                                );
                            }}
                            headerRowHeight={60}
                            {...responsiveGrid}
                        />
                        {this.state.hoveredRowId &&
                            this.props.lockedDocIds.includes(
                                this.state.hoveredRowId
                            ) &&
                            this.props.lockBarVisible &&
                            this.state.lockBarPosition && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: '550px',
                                        minWidth: '400px',
                                        top: `${
                                            this.state.lockBarPosition.top - 76
                                        }px`,
                                        left: document.body.classList.contains(
                                            'doc-small-view'
                                        )
                                            ? `${
                                                  this.state.lockBarPosition
                                                      .left - 74
                                              }px`
                                            : `${
                                                  this.state.lockBarPosition
                                                      .left - 254
                                              }px`,
                                    }}
                                >
                                    <LockBar
                                        docId={this.state.hoveredRowId}
                                        isCalledFromGrid={true}
                                        onUnlock={this.handleUnlock}
                                    />
                                </div>
                            )}
                    </DndProvider>
                }
                <div ref={this.tagActionRef}>
                    {/* eslint-disable-next-line eqeqeq */}
                    {this.state.selectedColumn == 'tags' &&
                    this.props.tagRefresh === true ? (
                        <>
                            <TagAction
                                key={this.state.selected_tag}
                                selected_tag={this.state.selected_tag}
                                docId={this.state.selectedTagDocId}
                                refresh={() => {
                                    this.refreshTag();
                                }}
                                loader={this.state.tagLoader}
                                trueLoader={() => {
                                    this.enableTagLoader();
                                }}
                                falseLoader={() => {
                                    this.disableTagLoader();
                                }}
                                tagActionUpdate={this.tagActionUpdate.bind(
                                    this
                                )}
                                tagPopup={this.props.tagPopup}
                                processId={this.state.processId}
                                statusId={this.state.statusId}
                                allActions={this.props.allActions}
                            />
                        </>
                    ) : null}
                </div>

                <Modal
                    show={this.state.showAllConfirmation}
                    onHide={() => {
                        this.setState({ showAllConfirmation: false });
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start history_header'
                    >
                        <Modal.Title className='d-flex d-flex wrap_history align-items-stretch justify-content-between'>
                            <div>Loading All Data</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h6 className='font-weight-bold mb-3'>
                                Please note that loading all the data might take
                                some time.
                            </h6>
                            <div className='checkbox-inline pl-0'>
                                <label className='checkbox my-3 mr-2 p-0'>
                                    <input
                                        type='checkbox'
                                        className='checked'
                                        name='Checkboxes3_1'
                                        onChange={() => {
                                            this.setState({
                                                retainShowAllChecked:
                                                    !this.state
                                                        .retainShowAllChecked,
                                            });
                                        }}
                                        checked={
                                            this.state.retainShowAllChecked
                                        }
                                    />
                                    <span></span>Remember my choice
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    title='Cancel'
                                    variant='secondary'
                                    onClick={() => {
                                        this.setState({
                                            showAllConfirmation: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                <div
                                    className='bulk'
                                    style={{ display: 'flex' }}
                                >
                                    <Button
                                        variant='success'
                                        title='Save'
                                        onClick={this.onShowAll}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                {this.state.rowData.length > 0 ? (
                    <div className='d-flex justify-content-end align-items-center pagination-background'>
                        <div>
                            <Button
                                className='empty'
                                variant='success'
                                onClick={(event) =>
                                    this.setState({
                                        showAllConfirmation: true,
                                    })
                                }
                            >
                                Show All
                            </Button>
                        </div>
                        <TablePagination
                            component='div'
                            count={this.props.outbox.outbox.totalCount}
                            page={parseInt(this.state.currentPage, 10)}
                            rowsPerPage={
                                localStorage.getItem('retainShowAllChecked') ===
                                'true'
                                    ? 50000
                                    : parseInt(this.state.perPage, 10)
                            }
                            onRowsPerPageChange={(event) =>
                                this.handleChangeRowsPerPage(event)
                            }
                            onPageChange={this.nextPage.bind(this)}
                            rowsPerPageOptions={[
                                10,
                                25,
                                50,
                                100,
                                { value: 50000, label: 'All' },
                            ]}
                        />
                    </div>
                ) : null}
                {Array.from(this.state.selectedRows).length > 0 ? (
                    <OutboxRowSelComponent
                        rowTransition={() => {
                            this.props.rowTransition();
                        }}
                        lockedStatus={() => {
                            this.lockedStatus();
                        }}
                        rowData={this.state.rowData}
                        selectedRows={this.state.selectedRows}
                        refreshTable={this.props.refreshTable}
                        refresh_whole={() => {
                            this.refresh_whole();
                        }}
                        updateState={(state) => {
                            //hidden by geetha
                            this.setState({
                                ...state,
                            });
                        }}
                        allActions={this.state.allActions}
                        resetPopOverData={(data) => {
                            this.resetPopOverData(data);
                        }}
                        fromSearch={this.props.fromSearch}
                        calingFrom={this.props.calingFrom}
                        query={this.props.query}
                        owenerRow={this.props.ownerRow}
                    />
                ) : null}
            </>
        );
    }
}
const mapDispatchToProps = {
    setDocOwner,
    setOwner,
    setSelectedRow,
    updateList,
    getListaction,
    updateOutboxState,
    showLoader,
    outboxPage,
    setRowData: (param) => setRowData(param),
    getCountData: getCountData,
    outboxGrouping,
    initialOutboxGrouping,
};

const mapStateToProps = (state, ownProps) => {
    return {
        outboxData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.outbox
            : {},
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        processId: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.processId
            : null,
        statusId: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.statusId
            : null,
        pageData: stringEmptyOrUndefined(state.persist.pageData)
            ? state.persist.pageData
            : {},
        currentMenuName: state.persist.currentMenuName,
        outbox: state.outbox,
        // processId: state.outbox.processId,
        // statusId: state.outbox.statusId,
        dataToken: state.outbox.dataToken,
        stateToken: state.outbox.stateToken,
        stateData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData
            : {},
        loading: state.outbox.loading,
        outbox_page: state.outbox.outboxpage,
        stateDataToken: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData.stateToken
            : {},
        count: state.persist.count,
        ddl: state.persistDDL.DDL,
        grouping: state.outbox.grouping,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DataGridComponent))
);
