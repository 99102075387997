import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    deleteDocument,
    getConfigurationData,
    recordSetSearch,
    configurationRecordSetId,
} from '../../api/api';
import {
    showSuccessToast,
    removeCheckboxClass,
    getDropDownDataFromApi,
    normalErrorToast,
    stringEmptyOrUndefined,
    formatHeaderNameWithOutSpace,
    GetFieldData,
    BranchIdSearch,
    encodeParameter,
} from '../../../components/commonfunction';
import { getProcessCount } from '../../_redux/authCrud';
import {
    getCountData,
    updateInboxState,
    getListaction,
} from '../../_redux/actions/inboxActions';
import { getConfigData } from '../../_redux/actions/configActions';
import { toast } from 'react-toastify';

export class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: {
                errorDeleteSelect: '',
                errorDeleteComment: '',
            },
            commentsData: {},
            branchData: {},
            openDelete:
                this.props.openDelete !== undefined
                    ? this.props.openDelete
                    : false,
            deleteComments: '',
            deleteBranch: localStorage.getItem('selectedBranch') || '',
            rowsToDelete: [],
            deleteSelectVal: '',
            commentTextCount: 50,
        };
    }
    configurationRecordSet(id) {
        configurationRecordSetId(id)
            .then(async (data) => {
                localStorage.setItem('configData', JSON.stringify(data));
                if (this.props.getConfigData !== undefined) {
                    await this.props.getConfigData(data);
                    // this.props.getConfigData()
                }
            })
            .catch((error) => {
                // Handle the encountered error
                console.error(error);
            });
    }
    async componentDidMount() {
        const configData = getConfigurationData();

        if (this.props.configData) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                async (item) => {
                    if (item.name === 'Branch Data') {
                        await this.configurationRecordSet(item.id);
                    }
                }
            );
        }

        const fieldData = GetFieldData({
            configData: this.props.configData,
        });

        this.setState({
            branchid: fieldData.branch,
            branchIdFiledID: fieldData.branch_id,
        });
        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('branch')) {
                getDropDownDataFromApi(fieldData.branch).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }
        }

        if (configData.Description !== undefined) {
            this.setState({
                commentsData: configData.Description,
            });
        }

        if (
            this.props.calingFrom !== 'actionBar' &&
            this.props.rowsToDelete &&
            this.props.rowsToDelete.length !== 0 &&
            this.state.deleteBranch !== this.props?.rowsToDelete[0]?.branch
        ) {
            this.setState({
                deleteBranch: this.props.rowsToDelete[0].branch,
            });
        }

        // Check the item availability in the branch and set aside the item if there is only one.
        // console.log('this.props.ddl.branch', this.props.ddl.branch)
        if (this.props.ddl.branch) {
            this.deleteSelectChange({
                target: { value: this.props.ddl.branch[0] },
            });
        }
    }
    componentDidUpdate() {}
    closeDeletePopUp(forceClose = false) {
        this.setState({
            openDelete: false,
            deleteSelectVal: '',
            errorMsg: Object.assign(this.state.errorMsg, {
                errorDeleteSelect: '',
                errorDeleteComment: '',
            }),
        });
        const params = {
            rowsToDelete: [],
            removeRow: false,
        };
        if (forceClose === true) {
            params.rowsToDelete = this.props.rowsToDelete;
            params.removeRow = true;
        }
        this.props.closeDeletePopUp(params);
    }

    deleteSelectChange = (event) => {
        const selectedBranch = event.target.value;
        this.setState({ deleteBranch: selectedBranch });
        localStorage.setItem('selectedBranch', selectedBranch);
    };

    handleDeleteText = (event) => {
        const comment = event.target.value;
        const count = 50 - event.target.value.length;
        if (comment.length > 50) {
            event.preventDefault();
            this.setState({
                commentTextCount: 0,
            });
        } else {
            this.setState({
                deleteComments: comment,
            });
            this.setState({
                commentTextCount: count,
            });
        }
    };
    getBranchId = async (value) => {
        let branchFiledId = '';
        this.props.configData.fields.forEach((data) => {
            if (data.name === 'Branch') {
                branchFiledId = data.fieldId;
            }
        });

        const searchText = `fieldSearch=${branchFiledId}=${encodeParameter(
            value
        )}`;
        let recordsetId = '';

        await this.props.configuredRecordsets.forEach((data) => {
            if (data.name === 'Branch Data') {
                recordsetId = data.recordsetId;
            }
        });

        return recordSetSearch(recordsetId, searchText)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Error in recordSetSearch');
            })
            .then((data) => {
                return BranchIdSearch(data);
            });
    };

    successAction = (name, docIdArray) => {
        const count = [];
        const oldCount = this.props.count;
        getProcessCount(this.props.processId)
            .then((response) => {
                // status = response.status;
                return response.json();
            })
            .then((data) => {
                count.push(data);
                oldCount.forEach((old_count) => {
                    if (
                        old_count.process.processId !== data.process.processId
                    ) {
                        count.push(old_count);
                    }
                });
                this.props.getCountData(count);
            });
        if (name === 'successDocId') {
            showSuccessToast('Records Deleted Successfully');
        } else {
            let message;
            if (docIdArray.length === 1) {
                message = `Doc Id  ${docIdArray[0]}  is Deleted Successfully`;
            } else {
                message = `Doc Ids `;
                docIdArray.map(async (item, idx) => {
                    if (
                        idx !== docIdArray.length - 1 &&
                        idx !== docIdArray.length - 2
                    ) {
                        message += `${item},`;
                    } else if (idx === docIdArray.length - 2) {
                        message += `${item}`;
                    } else {
                        message += ` and ${item}`;
                    }
                });
                message += ` are Deleted Successfully`;
            }
            showSuccessToast(message);
        }

        this.props.getListaction(
            this.props.inbox.inbox.processId,
            this.props.inbox.inbox.statusId
        );
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        if (this.props.calingFrom === 'actionBar') {
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
            this.props.updateInboxState({
                selectedRows: new Set(),
                row_transition: false,
                perPage: window.innerHeight < 825 ? 10 : 25,
                currentPage: 0,
            });
        }
        if (this.props.refreshTable !== undefined) {
            this.props.refreshTable();
        }
        this.setState(
            () => {
                return {
                    rowsToDelete: [],
                    deleteComments: [],
                    deleteBranch: [],
                    errorMsg: Object.assign(this.state.errorMsg, {
                        errorDeleteSelect: '',
                        errorDeleteComment: '',
                    }),
                };
            },
            () => {
                this.closeDeletePopUp(true);
            }
        );
        removeCheckboxClass();
    };
    handleDeleteOperation = async () => {
        if (
            this.state.deleteBranch === '' ||
            this.state.deleteComments === ''
        ) {
            this.setState({
                validateEdit: true,
            });
            normalErrorToast('Please fill the mandatory fields');
        } else {
            const docIds = [];
            const fields = [];
            const { processId } = this.props;
            const { statusId } = this.props;
            const actionId =
                this.props.inbox.stateData.allActions.delete.actionId;
            const newStatus = {
                processId,
                statusId,
                actionId,
            };
            this.props.rowsToDelete.forEach((data) => {
                docIds.push(data.doc_id);
            });
            let branchID;

            if (this.state.deleteBranch) {
                await this.getBranchId(this.state.deleteBranch)
                    .then((branch_id) => {
                        branchID = branch_id;
                    })
                    .catch((error) => {
                        console.log('Error:', error);
                    });

                fields.push({
                    fieldId: this.state.branchIdFiledID,
                    value: branchID ? branchID : '',
                });
            }
            if (this.state.deleteBranch) {
                fields.push({
                    fieldId: this.state.branchid,
                    value: this.state.deleteBranch,
                });
            }
            if (this.state.deleteComments) {
                fields.push({
                    fieldId: this.state.commentsData.fieldId, // just name as comment data but description field id
                    value: this.state.deleteComments,
                });
            }
            this.setState({ main_spinner: true });
            window.scroll({ top: 0, behavior: 'smooth' });
            let successDocId = [];
            let failedDocId = [];
            let successInFailDocId = [];
            const deleteData = deleteDocument({
                processId,
                statusId,
                actionId,
                docIds,
                fields,
                newStatus,
            });
            deleteData
                .then(async (response) => {
                    let result = await response.json();
                    return result;
                })
                .then(async (data) => {
                    if (data.hadFailures === false) {
                        await data.results.map(async (item, idx) => {
                            if (item.actionSucceeded === true) {
                                successDocId.push(item.docId);
                            }
                        });
                    }
                    if (data.hadFailures === true) {
                        await data.results.map(async (item, idx) => {
                            if (item.actionSucceeded === false) {
                                failedDocId.push(item.docId);
                            } else {
                                successInFailDocId.push(item.docId);
                            }
                        });
                    }
                    if (successDocId.length !== 0) {
                        this.successAction('successDocId');
                    }
                    if (failedDocId.length !== 0) {
                        const errorMessage = data.results[0].message.toString();
                        toast.error(errorMessage, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        if (successInFailDocId.length !== 0) {
                            this.successAction(
                                'successInFailDocId',
                                successInFailDocId
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.error(`Error: ${error}`);
                    this.setState({
                        rowsToDelete: [],
                        deleteComments: [],
                        deleteBranch: [],
                    });
                });
        }
    };
    render() {
        return (
            <>
                <Modal
                    className='delete-popup'
                    show={this.props.openDelete}
                    onHide={() => {
                        this.closeDeletePopUp.bind(this, false);
                    }}
                    animation={false}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i
                                    title='Delete'
                                    className='fas fa-trash-alt mr-3'
                                />
                                Delete File
                            </div>
                            <i
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.closeDeletePopUp.bind(
                                    this,
                                    false
                                )}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='deletes'>
                        <h6>
                            You are about to delete{' '}
                            {this.props.rowsToDelete.length === 1
                                ? 'this file.'
                                : `${this.props.rowsToDelete.length} files.`}{' '}
                            Are you sure you want to do this?
                        </h6>
                        <span>
                            Deleted{' '}
                            {this.props.rowsToDelete.length === 1
                                ? 'file'
                                : 'files'}{' '}
                            can be found in the Deleted section for up to 30
                            days
                        </span>
                        <div className='form-group row '>
                            <div className='col-lg-4 mt-5 transition-select'>
                                <label className='pl-5'> BRANCH </label>
                                <select
                                    id='delete_selectbrand'
                                    className={
                                        this.state.validateEdit === true &&
                                        this.state.deleteBranch === ''
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    /*onChange={(e) => {
                                        this.deleteSelectChange(e);
                                    }}*/
                                    onChange={(event) => {
                                        this.deleteSelectChange(event);
                                    }}
                                    value={
                                        this.state.deleteBranch
                                            ? this.state.deleteBranch
                                            : ''
                                    }
                                    /*  disabled={this.props.ddl.branch.length == 1} */
                                    /*value={
                                    this.state.deleteBranch === ''
                                        ? ''
                                        : this.state.deleteBranch
                                }*/
                                >
                                    <option value=''>Select</option>
                                    {this.props.ddl.branch !== undefined
                                        ? this.props.ddl.branch.map(
                                              (data, index) => (
                                                  <option
                                                      key={index}
                                                      value={data}
                                                      style={{
                                                          display:
                                                              data !== ''
                                                                  ? 'block'
                                                                  : 'none',
                                                      }}
                                                  >
                                                      {data}
                                                  </option>
                                              )
                                          )
                                        : null}
                                </select>
                                <i
                                    className='fa-light fa-chevron-down'
                                    style={{ pointerEvents: 'none' }}
                                ></i>
                                {this.state.deleteBranch === '' &&
                                this.state.validateEdit === true ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Branch is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        <div className='form-group row mb-0'>
                            <div className='col-lg-12 mt-3 transition-select'>
                                <label className='pl-5'>DESCRIPTION </label>
                                <textarea
                                    id='delete_comments'
                                    className={
                                        this.state.validateEdit === true &&
                                        this.state.deleteComments === ''
                                            ? 'form-control error-field'
                                            : 'form-control'
                                    }
                                    maxLength='50'
                                    onChange={(event) => {
                                        this.handleDeleteText(event);
                                    }}
                                />
                                <div className='maximum-length'>
                                    <span className='float-left'>
                                        Maximum Length: 50
                                    </span>
                                    <span className='float-right'>
                                        Remaining :{' '}
                                        {this.state.commentTextCount}
                                    </span>
                                </div>
                                {this.state.deleteComments === '' &&
                                this.state.validateEdit === true ? (
                                    <p
                                        className='error m-0'
                                        style={{ color: 'red' }}
                                    >
                                        Description is required.
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block delete_btn'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    title='Cancel'
                                    variant='secondary'
                                    onClick={this.closeDeletePopUp.bind(
                                        this,
                                        false
                                    )}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                <Button
                                    title='Yes, Delete'
                                    disabled={
                                        this.state.deleteComments === '' ||
                                        this.state.deleteBranch === ''
                                    }
                                    className='danger'
                                    onClick={this.handleDeleteOperation}
                                >
                                    Yes, Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

// export default DeleteModal;

const mapDispatchToProps = {
    getCountData,
    updateInboxState,
    getListaction,
    getConfigData: getConfigData,
};
const mapStateToProps = (state) => {
    return {
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        ddl: state.persistDDL.DDL,
        inbox: state.inbox,
        rowData: state.inbox.rowData,
        count: state.persist.count,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
