import React from 'react';
import { Field, ErrorMessage, FormikProps } from 'formik';
import DatePicker from 'react-date-picker';
import { OptionType } from '../../../eSign/models/interfaces';

export interface SignerPatientProps {
    formik: FormikProps<any>;
    signerRole: OptionType[];
}

const SignerPatientForm: React.FC<SignerPatientProps> = ({
    formik,
    signerRole,
}) => {
    return (
        <div>
            <div className='realted_document'>
                <div
                    className={
                        'with-bg-recent header_row mb-4 justify-content-center'
                    }
                    style={{
                        backgroundColor: '#007DBC',
                    }}
                >
                    <div className='header_title'>
                        <i className='fa-regular' aria-hidden='true' />
                        Signer
                    </div>
                </div>
            </div>
            <div className='row grid-row-group mb-4'>
                <div className='col-xl-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        FIRST NAME
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field
                        type='text'
                        name='signerFirstName'
                        className={`form-control ${
                            formik.touched.signerFirstName &&
                            formik.errors.signerFirstName
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        name='signerFirstName'
                        component='div'
                        className='error m-0'
                    />
                </div>
                <div className='col-md-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        LAST NAME
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field
                        type='text'
                        name='signerLastName'
                        className={`form-control ${
                            formik.touched.signerLastName &&
                            formik.errors.signerLastName
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        name='signerLastName'
                        component='div'
                        className='error m-0'
                    />
                </div>
                <div className='col-md-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        EMAIL
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field
                        type='email'
                        name='signerEmail'
                        className={`form-control ${
                            formik.touched.signerEmail &&
                            formik.errors.signerEmail
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        name='signerEmail'
                        component='div'
                        className='error m-0'
                    />
                </div>

                <div className='col-md-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        ROLE OF SIGNER
                        <i
                            title='Open'
                            className='fa-regular fa-chevron-down fa-align-center'
                            aria-hidden='true'
                            style={{ marginTop: '-3px' }}
                        />
                    </label>
                    <Field
                        as='select'
                        name='signerRole'
                        className={`form-control ${
                            formik.touched.signerRole &&
                            formik.errors.signerRole
                                ? 'is-invalid'
                                : ''
                        }`}
                    >
                        <option value=''>Select...</option>
                        {signerRole?.map((option: OptionType) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Field>

                    <ErrorMessage
                        name='signerRole'
                        component='div'
                        className='error m-0'
                    />
                </div>
            </div>
            <div className='realted_document'>
                <div
                    className={
                        'with-bg-recent header_row mb-4 justify-content-center'
                    }
                    style={{
                        backgroundColor: '#007DBC',
                    }}
                >
                    <div className='header_title'>
                        <i className='fa-regular' aria-hidden='true' />
                        Patient
                    </div>
                </div>
            </div>
            <div className='row grid-row-group mb-4'>
                <div className='col-md-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        FIRST NAME
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field
                        type='text'
                        name='patientFirstName' 
                        onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue("patientFirstName", value);
                            formik.setFieldValue("patientName", value + " " + formik.values.patientLastName); // Update both fields with the same value
                        }}
                        className={`form-control ${
                            formik.touched.patientFirstName &&
                            formik.errors.patientFirstName
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        name='patientFirstName'
                        component='div'
                        className='error m-0'
                    />
                </div>
                <div className='col-md-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        LAST NAME
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field
                        type='text'
                        name='patientLastName'
                        onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue("patientLastName", value);
                            formik.setFieldValue("patientName",  formik.values.patientFirstName + " " + value); // Update both fields with the same value
                        }}
                        className={`form-control ${
                            formik.touched.patientLastName &&
                            formik.errors.patientLastName
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        name='patientLastName'
                        component='div'
                        className='error m-0'
                    />
                </div>
                <div className='col-md-6 transition-select form-control-iconless'  style={{ display: 'none' }} >
                    <label className='pl-5 mb-1'>
                        PATIENT NAME
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field
                        type='text'
                        name='patientName' 
                      
                        
                        className={`form-control ${
                            formik.touched.patientName &&
                            formik.errors.patientName
                                ? 'is-invalid'
                                : ''
                        }`}
                    />
                    <ErrorMessage
                        name='patientName'
                        component='div'
                        className='error m-0'
                    />
                </div>
                <div className='col-md-6 transition-select form-control-iconless'>
                    <label className='pl-5 mb-1'>
                        DATE OF BIRTH
                        <span style={{ color: 'red' }}> *</span>
                    </label>
                    <Field name='patientDOB'>
                        {({ field, form }: any) => (
                            <DatePicker
                                className='date-picker form-control position-relative d-flex align-items-center'
                                format='MM/dd/y'
                                onChange={(date: Date) => {
                                    form.setFieldValue(field.name, date);
                                }}
                                value={
                                    field.value ? new Date(field.value) : null
                                }
                                maxDate={new Date()}
                            />
                        )}
                    </Field>
                    <ErrorMessage
                        name='patientDOB'
                        component='div'
                        className='error m-0'
                    />
                </div>
            </div>
        </div>
    );
};

export default SignerPatientForm;
