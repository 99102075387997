import { format } from "date-fns";

export const mapSignerPatientDataToApiData = (values: any, fields: any[]) => {
    const apiData = [];

    for (const field of fields) {
        let value;

        switch (field.name) {
            case 'Signer First Name':
                value = values.signerFirstName;
                break;
            case 'Signer Last Name':
                value = values.signerLastName;
                break;
            case 'Signer Email':
                value = values.signerEmail;
                break;
            case 'Signer Role':
                value = values.signerRole;
                break;
            case 'Patient First Name':
                value = values.patientFirstName;
                break;
            case 'Patient Last Name':
                value = values.patientLastName;
                break;
            case 'Patient Name':
                value = values.patientName;
                break;
            case 'Date of Birth':
                value = values.patientDOB
                    ? format(new Date(values.patientDOB), 'yyyy-MM-dd')
                    : '';
                break;
            default:
                continue;
        }
        apiData.push({ fieldId: field.fieldId, value });
    }
    return apiData;
};

