/* eslint-disable array-callback-return */
import * as moment from 'moment-timezone';
import { toast } from 'react-toastify';
import React from 'react';
import { saveAs } from 'save-as';
import JSZip from 'jszip';
// import { useSelector } from 'react-redux';
import {
    unlockDocument,
    getColorCodes,
    downloadDoc,
    getCount,
    GetDropdownData,
    configurationWithStatus,
    recordSetSearch,
    getTags,
    relatedDoc,
} from '../pages/api/api';
import { getProcessCount } from '../pages/_redux/authCrud';
import { getActions, getAllActions, getConfig } from '../../_metronic/_helpers';

toast.configure();
moment.suppressDeprecationWarnings = true;

export const IS_MOBILE_DEVICE =
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf('Mobi') > -1;
// Format date
export function formatDate(date, format = 'MM/DD/YYYY hh:mm:ss') {
    if (date !== '' && date !== ' ' && date !== null && date !== undefined)
        return moment(new Date(date)).format(format);
    else return '';
}

export function formatDateToUtc(date, formatString = 'MM/DD/YYYY') {
    if (date !== '' && date !== ' ' && date !== null && date !== undefined) {
        return moment.utc(date).format(formatString);
    } else return '';
}

export function getActionsByProcessAndStatus(config, processId, statusId) {
    var actions = getAllActions(null, processId, statusId);

    return actions;
}

export function formatHeaderNameWithOutSpace(name) {
    /* let temp = String(name).replace(/\s/g, '').toLowerCase();
    return temp; */
    if (name !== undefined) {
        const temp1 = name.trim().toLowerCase();
        const outString = temp1.replace(
            // eslint-disable-next-line no-useless-escape
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ''
        );

        const temp = String(outString).replace(/\s/g, '');
        return temp;
    }
}

export function formatNameWithSpaceToUnderscore(name) {
    // const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
    // let temp = String(name).replace(regex, '').trim().toLowerCase();
    // temp = String(temp).replace(/\s/g, '_');
    // return String(temp).replace(/\//g, '_');
    if (name !== '' && name !== undefined) {
        const temp1 = name.trim().toLowerCase();
        const outString = temp1.replace(
            // eslint-disable-next-line no-useless-escape
            /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            '_'
        );

        let temp = String(outString).replace(/\s/g, '_');
        temp = String(temp).replace('___', '_');
        temp = String(temp).replace('__', '_');
        return temp;
    } else {
        return '';
    }

    // return String(name).replace(/\s/g, '_').trim().toLowerCase();
}

export function formatNameWithSpaceTohipen(name) {
    // const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
    // let temp = String(name).replace(regex, '').trim().toLowerCase();
    // temp = String(temp).replace(/\s/g, '_');
    // return String(temp).replace(/\//g, '_');
    if (name !== '' && name !== undefined) {
        const temp1 = name.trim().toLowerCase();
        const outString = temp1.replace(
            // eslint-disable-next-line no-useless-escape
            /[`~!@#$%^&*()|+\_=?;:'",.<>\{\}\[\]\\\/]/gi,
            '-'
        );

        let temp = String(outString).replace(/\s/g, '-');
        temp = String(temp).replace('---', '-');
        temp = String(temp).replace('--', '-');
        return temp;
    } else {
        return '';
    }

    // return String(name).replace(/\s/g, '_').trim().toLowerCase();
}

export function ownerClickOutside(event) {
    const owner = document.querySelector('#auto-div');
    let style = '';
    // let sum = owner.style.top + 500;
    if (owner !== null && !owner.contains(event.target)) {
        style = `
				border: 1px solid black;
				background-color: white;
				position: absolute;
				margin: ${owner.style.margin};
				top: ${owner.style.top};
				left: ${owner.style.left};
				display: none;
			`;
        owner.setAttribute('style', style);
    }
}

// Display Error

export function showErrorToast(message) {
    // message = message.split(':').pop();
    if (message.indexOf(':') !== -1) {
        message = message.split(':').pop();
    }
    let last2 = message.slice(-2);
    if (last2 === '"}') {
        message = message.slice(0, -2);
    }
    let last1 = message.slice(-1);
    if (last1 === '}') {
        message = message.slice(0, -1);
    }
    toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

// Display Success
export function showSuccessToast(message) {
    if (message.indexOf(':') !== -1) {
        message = message.split(':').pop();
    }

    toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function normalSuccessToast(message) {
    toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function normalErrorToast(message) {
    toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function formatPathName(name) {
    const nameData = name.split('/').filter((data) => data.trim() !== '');
    return nameData.join('-');
}

export function getPageFromPath(name) {
    const nameData = name.split('/');
    const counting = nameData.length;

    return nameData[counting - 1];
}

export function allStorage() {
    const values = {};
    const keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) {
        values[keys[i]] = localStorage.getItem(keys[i]);
    }
    return values;
}

export function getRowDataIndex(data) {
    const IndexData = {};
    data.forEach((v, i) => {
        IndexData[v.doc_id] = i;
    });
    return IndexData;
}

export function getDocumentIdFromArray(data, idArray) {
    const row = [];
    data.forEach((v, i) => {
        if (idArray.indexOf(v.id) !== -1) {
            row.push(v);
        }
    });
    return row;
}

// UnLock All Document in Local Storage
export function unlockAllDocumentInStorage() {
    const localStorageData = allStorage();
    let documentId = [];
    for (const name in localStorageData) {
        if (
            String(name).includes('locked-document-id') ||
            String(name).includes('locked-document-id')
        ) {
            documentId.push(localStorageData[name]);
        }
    }
    if (documentId.length > 0) {
        documentId = documentId.filter((data) => {
            return data !== null;
        });
        const promiseData = [];
        documentId.forEach((docid) => {
            promiseData.push(unlockDocument(docid));
        });
        return Promise.all(promiseData);
    }
    return Promise.all([Promise.resolve('Error - Something went wrong - ')]);
}

export function removeCheckboxClass() {
    const node = document.querySelector('.rdg-checkbox-input');
    if (node !== null) {
        node.classList.remove('header-checkbox');
    }
}

export const handlerOwner = function (e) {
    const elementPosition = e.target.parentElement.getBoundingClientRect();
    const tappedY = e.clientY;
    const tappedX = e.clientX;
    const overAll = document.getElementById('right-aside');

    const overAllView = parseInt(overAll.offsetHeight);
    const viewTop = overAll.getBoundingClientRect().y;
    const elementTop = elementPosition.top;
    setTimeout(function () {
        const availableID = document.getElementById('auto-div');
        if (availableID) {
            availableID.classList.remove('auto-div-default');
            document.getElementById('auto-div').style.display = 'none';
            document.getElementById('auto-div').style.left = `${tappedX}px`; // added by kalpana
            document.getElementById('auto-div').style.zindex = '3000';
            document.getElementById('auto-div').style.position = 'absolute';
            document.getElementById('auto-div').style.display = 'block'; // added by kalpana
            document.getElementById('owner_auto_search').style.display =
                'block';
            document.getElementById('owner_auto_search').style.visibility =
                'visible';
            document.getElementById('owner_auto_input').focus();
            const tagHeight = document.getElementById('auto-div').offsetHeight;

            const topPosition =
                tappedY - 50 + tagHeight > overAllView
                    ? tappedY - tagHeight < viewTop
                        ? viewTop
                        : tappedY - tagHeight
                    : tappedY;

            document.getElementById('auto-div').style.top = `${topPosition}px`;
        }
    }, 1000);
    setTimeout(function () {
        const availableID = document.getElementById(
            'owner_auto_search_options'
        );
        if (availableID) {
            document.getElementById('owner_auto_search').style.display =
                'block';
            document.getElementById('owner_auto_search_options').style.display =
                'block';
        }
    }, 1000);
};

export function NoRecordFound(loading, state = 0) {
    return (
        <>
            {loading !== undefined && loading === true ? (
                <div className='react-grid-Empty'> Loading... </div>
            ) : state === 'location' ? (
                <div className='no_result norecordfound'>
                    <span>No Location Found</span>
                </div>
            ) : state === 'Physician' ? (
                <div className='no_result norecordfound'>
                    <span>No Physician Found</span>
                </div>
            ) : (
                <div className='no_result norecordfound'>
                    <span>No Records Found</span>
                </div>
            )}
        </>
    );
}

export const ownerCellFormatter = (
    value,
    doc_id,
    show_owner = false,
    menuName = ''
) => {
    const pathname = formatPathName(window.location.pathname);
    let displayName = '';
    let bg_color = '';
    let name_array = [];
    const color_code = getColorCodes();
    if (value) {
        // value !== '' && value !== undefined hidden by krish
        name_array = value.split(' ');
        if (name_array.length > 1) {
            displayName = `${name_array[0]
                .charAt(0)
                .toUpperCase()}${name_array[1].charAt(0).toUpperCase()}`;
        } else {
            const username = value.substring(0, 3);

            if (username === 'WV-') {
                // displayName = `${name_array[0].substring(3,2).toUpperCase()}`;
                displayName = `${value[3].toUpperCase()}${value[4].toUpperCase()}`;
                /* displayName = `${name_array[0]
                    .charAt(0)
                    .toUpperCase()}${name_array[0].charAt(1).toUpperCase()}`; */
            } else {
                displayName = `${name_array[0]
                    .charAt(0)
                    .toUpperCase()}${name_array[0].charAt(1).toUpperCase()}`;
            }
        }
    }
    let ownerColor = [];
    if (menuName === 'inbox') {
        ownerColor = JSON.parse(localStorage.getItem('owner_bg_color'));
    } else if (menuName === 'outbox') {
        ownerColor = JSON.parse(localStorage.getItem('owner_bg_color_outbox'));
    } else if (menuName === 'coordinator') {
        ownerColor = JSON.parse(localStorage.getItem('coordinator_bg_color'));
    }

    if (
        ownerColor !== null &&
        Array.isArray(ownerColor) &&
        menuName === 'inbox'
    ) {
        ownerColor.forEach((owner) => {
            if (owner.name === value) {
                bg_color = owner.colorCode;
            }
        });
    } else if (ownerColor !== null && menuName === 'outbox') {
        ownerColor.forEach((owner) => {
            if (owner.ownerName === value) {
                bg_color = owner.bg_color;
            }
        });
    } else if (ownerColor !== null && menuName === 'coordinator') {
        ownerColor.forEach((owner) => {
            if (owner.name === value) {
                bg_color = owner.colorCode;
            }
        });
    } else {
        //bg_color = color_code[Math.floor(Math.random() * 15)];
    }

    if (displayName !== '') {
        return (
            <div
                id={`ownerCell-${doc_id}`}
                className='owner-grid'
                style={{
                    width: localStorage.getItem(`ownerCurrentWidth-${pathname}`)
                        ? localStorage.getItem(`ownerCurrentWidth-${pathname}`)
                        : '160px',
                }}
                onClick={show_owner ? handlerOwner : undefined}
            >
                <div
                    className='owner-name-list owner-logo'
                    id={`owner-logo-${doc_id}`}
                    onClick={show_owner ? handlerOwner : undefined}
                    title={value}
                    style={{ background: bg_color }}
                >
                    <span style={{ display: 'inline' }}>
                        <span className='d-flex justify-content-center'>
                            {displayName}
                        </span>
                    </span>
                </div>
            </div>
        );
    }
    return (
        <div
            id={`ownerCell-${doc_id}`}
            className={show_owner ? 'owner-grid' : 'owner-grid disabled'}
            style={{
                width: localStorage.getItem(`ownerCurrentWidth-${pathname}`)
                    ? localStorage.getItem(`ownerCurrentWidth-${pathname}`)
                    : '160px',
            }}
            onClick={show_owner ? handlerOwner : undefined}
        >
            <div
                className='no-user-icon owner-logo'
                id={`owner-logo-${doc_id}`}
                onClick={show_owner ? handlerOwner : undefined}
                title=''
            >
                <span>
                    <span>
                        <i className='fas fa-user-plus' />
                    </span>
                </span>
            </div>
        </div>
    );
};
// Worked for autocomplete owners

export const groupDisplayFormatter = (value, format = 0) => {
    if (value !== '' && value !== undefined) {
        /* let dateVal = '';
        dateVal = formatDate(new Date(value), 'MM/DD/YYYY hh:mm:ss');
        return (
            <div title={dateVal}>
                <span>{dateVal}</span>
            </div>
        ); */
        /* value.map(function (val, label) {
            if(typeof val === 'object' && val !== null){	
                val.map(function (vall, key) {
                });
            }
        }); */
        let str = '';
        // eslint-disable-next-line no-unused-vars
        for (const [p, val] of Object.entries(value)) {
            // str += `${p}::${JSON.stringify(val)}\n`;
            for (const [k, v] of Object.entries(val)) {
                if (k === 'name') {
                    str += `${v},`;
                }
            }
        }
        str = str.slice(0, -1);
        if (format === 1) {
            return str;
        } else {
            return (
                <div className='groups' title={str}>
                    <span className='temp-group'>{str}</span>
                </div>
            );
        }

        // return JSON.stringify(value);
    } else {
        return '';
    }
};

export const dateDisplayFormatter = (value) => {
    if (value !== '' && value !== undefined) {
        let dateVal = '';
        dateVal = formatDate(new Date(value), 'MM/DD/YYYY hh:mm:ss');
        return (
            <div title={dateVal}>
                <span>{dateVal}</span>
            </div>
        );
    }
};

export const dateTimeDisplayFormatter = (value) => {
    // pls note we r reciving UTC date time('2023-06-29T09:58:42Z') so convert to local date time
    if (value !== '' && value !== undefined) {
        let dateVal = '';
        const date = new Date(value);
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - offset * 60 * 1000);
        dateVal = formatDate(localDate, 'MM/DD/YYYY HH:mm:ss');
        return (
            <div title={dateVal}>
                <span>{dateVal}</span>
            </div>
        );
    }
};

export const dateCellFormatter = (value) => {
    if (value !== '' && value !== undefined) {
        const datebatch = value.split('#');
        const date = datebatch.shift().trim();
        const batch = datebatch.pop().trim();
        const dateVal = formatDate(new Date(date), 'MM/DD/YYYY');
        return (
            <div title={value}>
                <span>{dateVal}</span>{' '}
                <span className='batch-number'>#{batch}</span>
            </div>
        );
    }
    return <div />;
};

export const typeCellFormatter = (value) => {
    if (value !== '' && value !== undefined) {
        return (
            <div title={value}>
                <span>
                    <i className='fas fa-file-medical-alt' /> {value}
                </span>
            </div>
        );
    }
    return <div />;
};

export const noteCellFormatter = (value, display = '') => {
    if (value !== '' && value !== undefined) {
        if (display === 'normal') {
            let c_row = value.replace(/<br>/g, '\n');
            c_row = c_row.replace(/<BR>/g, '\n');
            c_row = c_row.replace(/<BR\/>/g, '\n');
            c_row = c_row.replace(/<br\/>/g, '\n');
            return (
                <div className='last-note-wrap'>
                    <div className='apply-wrap' title={c_row}>
                        <span
                            className='first_name last-note'
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: c_row }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className='last-note-wrap'>
                <div className='apply-wrap' title={value}>
                    <div className='last-note'> {value}</div>
                </div>
            </div>
        );
    }
    return <div />;
};

export const nameCellFormatter = (value) => {
    if (value !== '' && value !== undefined) {
        return (
            <div className='apply-wrap document-name' title={value}>
                {' '}
                {value}
            </div>
        );
    }
    return <div />;
};

export const tagsCellFormatter = (value) => {
    let title = '';
    const title_arr = [];
    if (value !== '' && value !== undefined) {
        if (value.props.children) {
            value.props.children.forEach((data) => {
                title_arr.push(data.props.children.props.children);
            });
            title = title_arr.join(', ');
        }
    }
    return <div title={title}>{value}</div>;
};

export const handler = function (e) {
    const overAll = document.getElementById('right-aside');
    const overAllView = parseInt(overAll.style.height);
    const elementPosition = e.target.parentElement.getBoundingClientRect();
    const tappedX = e.clientX;
    //const leftPosition = tappedX - elementPosition.left + 54;
    const menu_width = document
        .getElementById('right-aside')
        .getBoundingClientRect().x;
    const fullWidth = window.innerWidth - 200; //tags-options div width is 200
    const leftPosition =
        tappedX > fullWidth ? tappedX - menu_width - 200 : tappedX - menu_width;
    const elementCenter = elementPosition.y - 55;
    setTimeout(function () {
        const availableID = document.getElementById('tag_options');
        if (availableID) {
            document.getElementById('tag_options').style.margin = '0px';
            document.getElementById(
                'tag_options'
            ).style.left = `${leftPosition}px`;
            document.getElementById('tag_options').style.display = 'block';
            const tagHeight =
                document.getElementById('tag_options').offsetHeight;
            const topPosition =
                elementCenter + tagHeight > overAllView
                    ? elementCenter - tagHeight - 10 < 0
                        ? 0
                        : elementCenter - tagHeight - 10
                    : elementCenter;
            document.getElementById(
                'tag_options'
            ).style.top = `${topPosition}px`;
        }
    }, 500);
};

export const stringEmptyOrUndefined = (data) => {
    return data !== undefined && data !== null && String(data).trim() !== '';
};
export const isObjectEmptyOrNot = (data) => {
    return Object.keys(data).length > 0;
};
export const rowHeightDataGrid = (args) => {
    const { type } = args;
    if (type === 'GROUP') {
        return 70;
    }
    return 50;
};

export function createTagElement(
    data,
    configData,
    show_tag = false,
    path_name = '',
    ddl_tag = [],
    color
) {
    // console.log('createTagElement');
    // console.log('data', data);

    if (path_name === '') {
        path_name = window.location.pathname;
    }

    const pathname = formatPathName(path_name);

    if (data !== '' && data !== undefined) {
        let tag_array = [];
        if (data.charAt(0) === '|') {
            data = data.substring(1);
        }
        if (data.charAt(data.length - 1) === '|') {
            data = data.substring(0, data.length - 1);
        }

        tag_array = data.split('|');
        let dataArray = tag_array.filter(function (str) {
            return /\S/.test(str);
        });

        let result = JSON.parse(localStorage.getItem('tagList'));
        const newTagArray = [];
        const onlyConfigTags = [];

        let titleTag = '';
        if (result !== undefined && result && result.length > 0) {
            result.forEach((tagdata) => {
                newTagArray[tagdata.name.toLowerCase()] = tagdata.color;
            });
        }

        if (ddl_tag !== undefined && ddl_tag.length > 0) {
            ddl_tag.forEach((tagdata) => {
                if (tag_array.includes(tagdata)) {
                    onlyConfigTags.push(tagdata);
                }
            });
            titleTag = dataArray.join(', ');
        }

        // console.log('dataArray', dataArray);

        const tagElement = (
            <>
                <div
                    title={titleTag}
                    className='tags-list'
                    onClick={show_tag ? handler : undefined}
                    style={{
                        width:
                            localStorage.getItem(
                                `tagsCurrentWidth-${pathname}`
                            ) !== undefined &&
                            localStorage.getItem(
                                `tagsCurrentWidth-${pathname}`
                            ) !== null
                                ? String(
                                      localStorage.getItem(
                                          `tagsCurrentWidth-${pathname}`
                                      )
                                  )
                                : '160px',
                    }}
                >
                    {dataArray.map((value, index) => (
                        <span
                            key={index}
                            style={{
                                backgroundColor: color
                                    ? color
                                    : newTagArray[value.toLowerCase()]
                                    ? newTagArray[value.toLowerCase()]
                                    : '#7F96A5',
                                display: 'inline',
                            }}
                        >
                            <span style={{ fontSize: '11px' }}>{value}</span>
                        </span>
                    ))}
                </div>
            </>
        );

        return {
            tagElement,
            newTagColorArray: newTagArray,
        };
    }

    const tagElement = (
        <>
            <div
                className='tags-list'
                onClick={show_tag ? handler : undefined}
                style={{
                    width:
                        localStorage.getItem(`tagsCurrentWidth-${pathname}`) !==
                            undefined &&
                        localStorage.getItem(`tagsCurrentWidth-${pathname}`) !==
                            null &&
                        localStorage.getItem(`tagsCurrentWidth-${pathname}`) !==
                            ''
                            ? localStorage.getItem(
                                  `tagsCurrentWidth-${pathname}`
                              )
                            : '160px',
                }}
            />
        </>
    );

    return {
        tagElement,
        newTagColorArray: [],
    };
}

export function ObjectPropertyToLowerCase(params) {
    const newParams = {};
    // eslint-disable-next-line guard-for-in
    for (const prop in params) {
        newParams[prop.trim().toLowerCase()] = params[prop];
    }
    return newParams;
}
export function ObjectPropertyToUpperCase(params, sortDirection, groupByData) {
    const newParams = {};
    let returnParams = {};
    const keys = [];
    let rowCount = 0;
    // eslint-disable-next-line guard-for-in
    for (const prop in params) {
        rowCount = params[prop].length + rowCount;
        let name = prop.trim();
        name = name.charAt(0).toUpperCase() + name.slice(1, name.length);
        newParams[name] = params[prop];
        keys.push(name);
    }
    // Height Calculation For Grouping Only
    let defaultHeight = 630;
    // let groupCount = 0;
    // for (const property in groupByData) {
    //     groupCount += groupByData[property].length;
    // }
    // // if (rowCount < 10) {
    //  defaultHeight = rowCount * 50 + groupCount * 70 + 70;

    defaultHeight = window.innerHeight - 270;
    // }
    // defaultHeight = 10 * 50 + 70 + 410;
    setTimeout(() => {
        const treeGridElement = document.querySelector('div[role=treegrid]');
        if (treeGridElement !== null) {
            // treeGridElement.style.height = `${defaultHeight}px`;
            treeGridElement.style.height = defaultHeight;
        }
    });
    if (sortDirection.trim() === 'NONE' || sortDirection.trim() === '') {
        keys.sort();
        keys.forEach((name) => {
            returnParams[name] = newParams[name];
        });
    } else {
        returnParams = newParams;
    }
    return returnParams;
}

export function handleColumnsReorder(sourceKey, targetKey) {
    if (typeof this.state === 'undefined') {
        return;
    }
    const sourceColumnIndex = this.state.header.findIndex(
        (c) => c.key === sourceKey
    );
    const targetColumnIndex = this.state.header.findIndex(
        (c) => c.key === targetKey
    );
    const reorderedColumns = [...this.state.header];

    reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
    );

    const columnHeader = [];
    reorderedColumns.forEach((data) => {
        columnHeader.push({
            draggable: data.draggable,
            editable: data.editable,
            key: data.key,
            width: data.width,
            name: data.name,
            colname: data.colname,
            resizable: data.resizable,
            sortable: data.sortable,
            // frozen:true
        });
    });
    let { pathname } = this.props.location;
    pathname = formatPathName(pathname);
    if (this.state.listingFor !== undefined && this.state.listingFor !== null) {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
        var headerReorderPath = `header-reorder-${this.state.listingFor}-${pathname}`;
    } else {
        // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
        var headerReorderPath = `header-reorder-${pathname}`;
    }
    // eslint-disable-next-line block-scoped-var
    localStorage.setItem(`${headerReorderPath}`, JSON.stringify(columnHeader));
    this.setState(reorderedColumns);
    this.setState({ header: reorderedColumns });
}

export function handleClickOutside(event, forceHide = false) {
    let style = '';
    const gridElement = document.querySelector('div[role=grid]');
    const treeGridElement = document.querySelector('div[role=treegrid]');
    const tagOptions = document.querySelector('#tag_options');
    const popElement = document.getElementsByClassName('tag_options');
    let popEl;
    if (document.getElementById('tag_options')) {
        for (let i = 0; i < popElement.length; i++) {
            popEl = popElement[i];
            const isClickInside = popEl.contains(event.target);
            if (!isClickInside) {
                style = `
					border: 1px solid black;
					background-color: white;
					position: absolute;
					margin: ${tagOptions.style.margin};
					top: ${tagOptions.style.top};
					left: ${tagOptions.style.left};
					display: none;
				`;
                tagOptions.setAttribute('style', style);
                return;
            }
        }
    }
    if (tagOptions !== null && tagOptions.contains(event.target)) {
        return;
    }
    if (forceHide && tagOptions !== null) {
        style = `
					border: 1px solid black;
					background-color: white;
					position: absolute;
					margin: ${tagOptions.style.margin};
					top: ${tagOptions.style.top};
					left: ${tagOptions.style.left};
					display: none;
				`;
        tagOptions.setAttribute('style', style);
        return;
    }
    if (
        gridElement !== null &&
        !gridElement.contains(event.target) &&
        tagOptions !== null
    ) {
        style = `
					border: 1px solid black;
					background-color: white;
					position: absolute;
					margin: ${tagOptions.style.margin};
					top: ${tagOptions.style.top};
					left: ${tagOptions.style.left};
					display: none;
				`;
        tagOptions.setAttribute('style', style);
    } else if (
        treeGridElement !== null &&
        !treeGridElement.contains(event.target) &&
        tagOptions !== null
    ) {
        style = `
					border: 1px solid black;
					background-color: white;
					position: absolute;
					margin: ${tagOptions.style.margin};
					top: ${tagOptions.style.top};
					left: ${tagOptions.style.left};
					display: none;
				`;
        tagOptions.setAttribute('style', style);
    }
}

export function headerDataFormating(params) {
    const { headerReorder } = params;
    const { headerWidth } = params;
    const { header } = params;
    const findHeaderKey = (key) => {
        return header.find((data) => data.key === key);
    };
    let newHeader = [];
    if (headerReorder !== null && headerReorder !== undefined) {
        // Get data from LocalStorage
        headerReorder.forEach((headerData) => {
            const headerOriginal = findHeaderKey(headerData.key);
            newHeader.push({
                ...headerOriginal,
                ...headerData,
            });
        });
    } else if (headerWidth !== null && headerWidth !== undefined) {
        // Get data from LocalStorage
        const findHeaderKey = (key) => {
            return headerWidth.find((data) => data.key === key);
        };
        header.forEach((headerData) => {
            const headerOriginal = findHeaderKey(headerData.key);
            newHeader.push({
                ...headerData,
                ...headerOriginal,
            });
        });
    }
    // Get data Not in Local Storage, if new Column comes from API
    const resultHeader = header.filter((data, i) => {
        return !newHeader.some((headerData) => headerData.key === data.key);
    });
    newHeader = [...newHeader, ...resultHeader];
    return newHeader;
}

export function customizedWidth(idx, width, header, path, listingFor = '') {
    // if (width < 100) {
    //     return false;
    // }

    let pathname = path;
    pathname = formatPathName(pathname);
    let headerReorder = '';
    let headerWidth = '';
    if (listingFor !== '') {
        headerReorder = localStorage.getItem(
            `header-reorder-${listingFor}-${pathname}`
        );
        headerWidth = localStorage.getItem(
            `header-width-${listingFor}-${pathname}`
        );
    } else {
        headerReorder = localStorage.getItem(`header-reorder-${pathname}`);
        headerWidth = localStorage.getItem(`header-width-${pathname}`);
    }

    if (headerReorder !== null && headerReorder !== '') {
        headerReorder = JSON.parse(headerReorder);
        header = headerDataFormating({ headerReorder, header });
    }
    if (headerWidth !== null && headerWidth !== '') {
        headerWidth = JSON.parse(headerWidth);
        header = headerDataFormating({ headerWidth, header });
    }
    if (header[idx] !== undefined) {
        if (header[idx].key === 'owner') {
            const owner = document.getElementsByClassName('owner-grid');
            for (let i = 0; i < owner.length; i++) {
                owner[i].style.width = `${width}px`;
            }
            // width = `${width}px`;
            localStorage.setItem(`ownerCurrentWidth-${pathname}`, `${width}px`);
        }
        if (header[idx].key === 'tags') {
            const tag = document.getElementsByClassName('tags-list');
            for (let i = 0; i < tag.length; i++) {
                tag[i].style.width = `${width}px`;
            }
            // width = `${width}px`;
            // localStorage.setItem('tagsCurrentWidth', `${width}px`);
            localStorage.setItem(`tagsCurrentWidth-${pathname}`, `${width}px`);
        }
    }
    if (header[idx] !== undefined) {
        const alterHeader = header[idx];
        alterHeader.width = width;
        header[idx] = alterHeader;
        const columnHeader = [];
        header.forEach((data) => {
            columnHeader.push({
                draggable: data.draggable,
                editable: data.editable,
                key: data.key,
                width: data.width,
                name: data.name,
                colname: data.colname,
                resizable: data.resizable,
                sortable: data.sortable,
                formatter: data.formatter,
            });
        });

        if (listingFor !== '') {
            localStorage.setItem(
                `header-width-${listingFor}-${pathname}`,
                JSON.stringify(columnHeader)
            );
        } else {
            localStorage.setItem(
                `header-width-${pathname}`,
                JSON.stringify(columnHeader)
            );
        }
    }
}

export function getOwnersList(docOwner) {
    const owners = [];
    const ownerColor = [];
    // const color_code = getColorCodes();
    if (docOwner.length > 0) {
        docOwner.forEach((data) => {
            owners.push({
                id: data.ownerId,
                title: (
                    <>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                            id={data.ownerId}
                            data={data.realName}
                            style={{
                                borderRadius: '25px',
                                backgroundColor: data.backgroundColor,
                                color: 'white',
                            }}
                        >
                            {data.displayName}
                        </button>
                        &nbsp;
                        <span className='owner-full-name'>{data.realName}</span>
                    </>
                ),
                name: data.realName,
                label: data.realName,
                colorCode: data.backgroundColor,
                ownerId: data.ownerId,
            });
            ownerColor.push({
                id: data.id,
                name: data.realName,
                label: data.realName,
                colorCode: data.backgroundColor,
                ownerId: data.ownerId,
            });
        });
    }
    localStorage.setItem('owner_bg_color', JSON.stringify(ownerColor));
    return owners;
}
export function getCoordinatorList(docOwner) {
    const owners = [];
    const ownerColor = [];
    // const color_code = getColorCodes();
    if (docOwner.length > 0) {
        docOwner.forEach((data) => {
            owners.push({
                id: data.coordinator_id,
                title: (
                    <>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                            id={data.coordinator_id}
                            data={data.realName}
                            style={{
                                borderRadius: '25px',
                                backgroundColor: data.backgroundColor,
                                color: 'white',
                            }}
                        >
                            {data.displayName}
                        </button>
                        &nbsp;
                        <span className='owner-full-name'>{data.realName}</span>
                    </>
                ),
                name: data.realName,
                label: data.realName,
                colorCode: data.backgroundColor,
                coordinator_id: data.coordinator_id,
            });
            ownerColor.push({
                id: data.id,
                name: data.realName,
                label: data.realName,
                colorCode: data.backgroundColor,
                coordinator_id: data.coordinator_id,
            });
        });
    }
    localStorage.setItem('coordinator_bg_color', JSON.stringify(ownerColor));
    return owners;
}

export function getDocumentsOwners(ownerData) {
    if (ownerData === undefined) {
        return [];
    }
    const result = [];
    if (ownerData.length > 0 && ownerData.code !== 0) {
        ownerData.forEach((data, index) => {
            // let name = data.realName.split(' ');

            const name = data.ownerName.split(' ');
            let displayname = '';

            if (name.length > 1) {
                displayname = `${name[0].charAt(0).toUpperCase()}${name[1]
                    .charAt(0)
                    .toUpperCase()}`;
            } else {
                const username = data.ownerName.substring(0, 3);

                if (username === 'WV-') {
                    // displayName = `${name_array[0].substring(3,2).toUpperCase()}`;
                    displayname = `${data.ownerName[3].toUpperCase()}${data.ownerName[4].toUpperCase()}`;
                    /* displayName = `${name_array[0]
                        .charAt(0)
                        .toUpperCase()}${name_array[0].charAt(1).toUpperCase()}`; */
                } else {
                    displayname = `${name[0].charAt(0).toUpperCase()}${name[0]
                        .charAt(1)
                        .toUpperCase()}`;
                }
            }
            result.push({
                realName: data.ownerName,
                displayName: displayname,
                ownerId: data.ownerId,
                id: index,
                title: displayname,
                backgroundColor: data.bg_color,
            });
        });
        return getOwnersList(result);
    }
}
export function getDocumentsCoordinator(ownerData) {
    if (ownerData === undefined) {
        return [];
    }
    const result = [];
    if (ownerData.length > 0 && ownerData.code !== 0) {
        ownerData.forEach((data, index) => {
            // let name = data.realName.split(' ');

            const name = data.coordinator.split(' ');
            let displayname = '';
            if (name.length > 1) {
                displayname = `${name[0].charAt(0).toUpperCase()}${name[1]
                    .charAt(0)
                    .toUpperCase()}`;
            } else {
                displayname = `${name[0].charAt(0).toUpperCase()}${name[0]
                    .charAt(1)
                    .toUpperCase()}`;
            }
            result.push({
                realName: data.coordinator,
                displayName: displayname,
                coordinator_id: data.coordinator_id,
                id: data.id,
                title: displayname,
                backgroundColor: data.colorCode,
            });
        });
        return getCoordinatorList(result);
    }
}
export function userPermission(params) {
    const { processId, statusId, menu_name, configData } = params;
    const statusArray = [];
    let permissions = {
        show_owner: false,
        show_tag: false,
        show_branch: false,
        show_group: false,
        show_inbox: false,
        show_notes: false,
        show_pdf: false,
        show_delete: false,
        show_print: false,
        show_download: false,
        // show_updateFax:false,
        processId,
        statusId,
        statusArray: [],
        branchActionId: null,
        groupActionId: null,
        deleteActionId: null,
        ownerActionId: null,
        // updateFaxActionId:null,
    };
    const allStatusArray = [];
    let processName;
    // const config = getConfig();
    const config = configData;
    if (
        stringEmptyOrUndefined(config) &&
        stringEmptyOrUndefined(config.menuItems)
    ) {
        config.menuItems.forEach((data) => {
            if (data.menuName === 'Inbox' && data.processes.length > 0) {
                data.processes.forEach((value) => {
                    if (value.statuses.length > 0) {
                        value.statuses.forEach((val, index) => {
                            allStatusArray.push({
                                id: val.statusId,
                                name: val.name,
                            });
                        });
                    }

                    if (String(value.processId) === String(processId)) {
                        processName = value.processName;
                    }
                });
            }
        });
    }
    if (
        stringEmptyOrUndefined(processId) &&
        stringEmptyOrUndefined(processId)
    ) {
        const actions = getActions(menu_name, processId, statusId);

        let allActions = {};
        allActions = getAllActions(menu_name, processId, statusId);
        permissions = { ...permissions, allActions };

        if (actions.length > 0) {
            if (allStatusArray.length > 0) {
                // Modified on 13092021 - by Sathish, Owner icon not showing issue
                allStatusArray.forEach((data) => {
                    if (actions.indexOf(data.name) !== -1) {
                        statusArray.push(data);
                    }
                });
                permissions = { ...permissions, statusArray };
            }
            if (actions.indexOf('Change Branch') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_branch: true,
                        branchActionId: actions.indexOf('Change Branch'),
                    },
                };
            }
            if (actions.indexOf('Reassign to Group') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_group: true,
                        groupActionId: actions.indexOf('Reassign to Group'),
                    },
                };
            }
            /* if (actions.indexOf('Update Fax Details') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_updateFax: true,
                        updateFaxActionId: actions.indexOf('Update Fax Details'),
                    },
                };
            } */
            if (actions.indexOf('Incoming Faxes') !== -1) {
                permissions = { ...permissions, ...{ show_inbox: true } };
            }
            if (actions.indexOf('Delete') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_delete: true,
                        deleteActionId: actions.indexOf('Delete'),
                    },
                };
            }
            if (actions.indexOf('Update Tag') !== -1) {
                permissions = { ...permissions, ...{ show_tag: true } };
            }
            if (actions.indexOf('Assign to User') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_owner: true,
                        ownerActionId: actions.indexOf('Assign to User'),
                    },
                };
            }
            if (actions.indexOf('Note') !== -1) {
                permissions = { ...permissions, ...{ show_notes: true } };
            }

            permissions = {
                ...permissions,
                ...{ show_inbox: true },
                processName: processName,
            };
        }
    }

    return permissions;
}

//can be removed
export function userPermissionsNotUSed(params) {
    const { processId, statusId, menu_name, configData } = params;
    const statusArray = [];
    let permissions = {
        show_owner: false,
        show_tag: false,
        show_branch: false,
        show_group: false,
        show_inbox: false,
        show_notes: false,
        show_pdf: false,
        show_delete: false,
        show_print: false,
        show_download: false,
        // show_updateFax:false,
        processId,
        statusId,
        statusArray: [],
        branchActionId: null,
        groupActionId: null,
        deleteActionId: null,
        ownerActionId: null,
        // updateFaxActionId:null,
    };
    const allStatusArray = [];
    // const config = getConfig();
    const config = configData;
    if (
        stringEmptyOrUndefined(config) &&
        stringEmptyOrUndefined(config.menuItems)
    ) {
        config.menuItems.forEach((data) => {
            if (data.menuName === 'Inbox' && data.processes.length > 0) {
                data.processes.forEach((value) => {
                    if (value.statuses.length > 0) {
                        if (value.processName === 'Inbound') {
                            value.statuses.forEach((val, index) => {
                                allStatusArray.push({
                                    id: val.statusId,
                                    name: val.name,
                                });
                            });
                        }
                    }
                });
            }
        });
    }
    if (
        stringEmptyOrUndefined(processId) &&
        stringEmptyOrUndefined(processId)
    ) {
        const actions = getActions(menu_name, processId, statusId);

        let allActions = {};
        allActions = getAllActions(menu_name, processId, statusId);
        permissions = { ...permissions, allActions };
        if (actions.length > 0) {
            if (allStatusArray.length > 0) {
                // Modified on 13092021 - by Sathish, Owner icon not showing issue
                allStatusArray.forEach((data) => {
                    if (actions.indexOf(data.name) !== -1) {
                        statusArray.push(data);
                    }
                });
                permissions = { ...permissions, statusArray };
            }
            if (actions.indexOf('Change Branch') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_branch: true,
                        branchActionId: actions.indexOf('Change Branch'),
                    },
                };
            }
            if (actions.indexOf('Reassign to Group') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_group: true,
                        groupActionId: actions.indexOf('Reassign to Group'),
                    },
                };
            }
            /* if (actions.indexOf('Update Fax Details') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_updateFax: true,
                        updateFaxActionId: actions.indexOf('Update Fax Details'),
                    },
                };
            } */
            if (actions.indexOf('Incoming Faxes') !== -1) {
                //Dont know why they enable show inbox true here
                permissions = { ...permissions, ...{ show_inbox: true } };
            }
            if (actions.indexOf('Delete') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_delete: true,
                        deleteActionId: actions.indexOf('Delete'),
                    },
                };
            }
            if (actions.indexOf('Update Tag') !== -1) {
                permissions = { ...permissions, ...{ show_tag: true } };
            }
            if (actions.indexOf('Assign to User') !== -1) {
                permissions = {
                    ...permissions,
                    ...{
                        show_owner: true,
                        ownerActionId: actions.indexOf('Assign to User'),
                    },
                };
            }
            if (actions.indexOf('Note') !== -1) {
                permissions = { ...permissions, ...{ show_notes: true } };
            }

            permissions = { ...permissions, ...{ show_inbox: true } };
        }
    }

    return permissions;
}

export async function getTagFromStorage(configData, ddlTag) {
    const config = configData;
    if (
        config !== null &&
        config !== undefined &&
        ddlTag !== null &&
        ddlTag !== undefined
    ) {
        const newTagColorArray = [];
        var menu_tags = [];
        //menu_tags = config.tags;
        menu_tags = JSON.parse(localStorage.getItem('tagList'));

        var ttempTagArray = [];
        ddlTag.map((tttag, ttindex) => {
            const item = findTagPresent(tttag, menu_tags);
            if (item) {
                item.tagId = ttindex;
                ttempTagArray.push(item);
                newTagColorArray[item.name] = item.color;
            } else {
                let newTags = {};
                newTags.name = tttag;
                newTags.tagId = ttindex;
                newTags.color = '#12cbc4';
                ttempTagArray.push(newTags);
                newTagColorArray[newTags.name] = '#12cbc4';
            }
        });

        var menu_tags = ttempTagArray;
        return {
            menu_tags,
            newTagColorArray,
        };
    }
}

function findTagPresent(tag, result) {
    return result.find((item) => {
        return item.name === tag ? item : null;
    });
}

export function getNotes(configData, processId, statusId) {
    let noteTypes = '';

    if (configData !== undefined && configData.menuItems.length > 0) {
        configData.menuItems.forEach((data) => {
            if (data.processes && data.processes.length > 0) {
                data.processes.forEach((value) => {
                    if (String(value.processId) === String(processId)) {
                        if (value.statuses && value.statuses.length > 0) {
                            value.statuses.forEach((val) => {
                                if (String(val.statusId) === String(statusId)) {
                                    noteTypes = val.noteTypes;
                                }
                            });
                        }
                    }
                });
            }
        });
    }
    return noteTypes;
}

export function closeDeletePopUp(param) {
    let { removeRow, rowsToDelete, rowData, filteredRowData } = param;
    if (removeRow !== undefined && removeRow === true) {
        if (removeRow && rowsToDelete.length > 0) {
            const docIds = [];
            rowsToDelete.forEach((data) => {
                docIds.push(data.doc_id);
            });
            rowData = rowData.filter((data) => {
                return !(docIds.indexOf(data.doc_id) !== -1);
            });
            filteredRowData = filteredRowData.filter((data) => {
                return !(docIds.indexOf(data.doc_id) !== -1);
            });
        }
        return {
            openDelete: false,
            selectedRows: new Set(),
            selectedIndexes: [],
            rowsToDelete: [],
            rowData,
            filteredRowData,
        };
    }
    return {
        openDelete: false,
    };
}

export function download(docId) {
    let status = '';
    downloadDoc(docId)
        .then((response) => {
            status = response.status;
            return response.blob();
        })
        .then((data) => {
            if (status === 200) {
                saveAs(data, 'Document.pdf');
            } else {
                showErrorToast('No PDF to download');
            }
        });
}

export async function download_multiple(params, selectedID) {
    const { selectedRows, rowData } = params;
    const docid = [];
    const selected_rows = Array.from(selectedRows);
    if (selectedID === 'fromDocid') {
        rowData.forEach((data, index) => {
            if (selected_rows.includes(data.doc_id)) {
                docid.push(data.doc_id);
            }
        });
    } else {
        rowData.forEach((data, index) => {
            if (selected_rows.includes(data.id)) {
                docid.push(data.doc_id);
            }
        });
    }

    let multiple_id = '';
    docid.forEach((id) => {
        if (multiple_id === '') {
            multiple_id += `docId=${id}`;
        } else {
            multiple_id += `&docId=${id}`;
        }
    });
    let status = '';
    // const files = false;
    let status_200 = 0;
    const zip = new JSZip();
    const img = zip.folder('PDF');
    // images is the folder which will be zip
    let count = 0;
    let blob = '';

    await docid.forEach((doc_id, index) => {
        downloadDoc(doc_id)
            .then((response) => {
                status = response.status;
                return response.blob();
            })
            .then((data) => {
                count += 1;
                if (status === 200) {
                    blob = data;
                    status_200 += 1;
                    // const url = URL.createObjectURL(data);
                    img.file(`${doc_id}.pdf`, data, { base64: true });
                }
                if (docid.length === count && status_200 > 1) {
                    zip.generateAsync({ type: 'blob' }).then(function (
                        content
                    ) {
                        saveAs(content, 'Document.zip');
                    });
                }
                if (docid.length === count && status_200 === 1) {
                    saveAs(blob, 'Document.pdf');
                }
                if (docid.length === count && status_200 === 0) {
                    showErrorToast('No PDF to download');
                }
            });
    });
}

export const GetFieldData = (params, pageMenu = 'Inbox') => {
    const { configData } = params;
    let firstProcessId = null;
    let firstStatusId = null;
    let result = {
        tagFieldId: null,
        noteFieldId: null,
        branchFieldId: null,
        groupFieldId: null,
        attachmentTypeId: null,
        attachmentLocationId: null,
        newReferralId: null,
        noteTypeId: null,
        allStatusArray: [],
        noteTypes: [],
        firstProcessId,
        firstStatusId,
    };
    const allFieldID = {};
    if (
        stringEmptyOrUndefined(configData) &&
        stringEmptyOrUndefined(configData.fields)
    ) {
        configData.fields.forEach((data) => {
            if (data.name === 'Tags') {
                result = { ...result, tagFieldId: data.fieldId };
            }
            if (data.name === 'Note') {
                result = { ...result, noteFieldId: data.fieldId };
            }
            if (data.name === 'Branch') {
                result = { ...result, branchFieldId: data.fieldId };
            }
            if (data.name === 'Group') {
                result = { ...result, groupFieldId: data.fieldId };
            }
            if (data.name === 'Attachment Type') {
                result = { ...result, attachmentTypeId: data.fieldId };
            }
            if (data.name === 'Attachment Location') {
                result = { ...result, attachmentLocationId: data.fieldId };
            }
            if (data.name === 'New Referral') {
                result = { ...result, newReferralId: data.fieldId };
            }
            if (data.name === 'Owner') {
                result = { ...result, ownerFieldId: data.fieldId };
            }
            if (data.name === 'Service Line') {
                result = { ...result, serviceLineId: data.fieldId };
            }
            if (data.name === 'Team') {
                result = { ...result, teamId: data.fieldId };
            }
            const fieldName = formatNameWithSpaceToUnderscore(data.name);
            result = { ...result, [fieldName]: data.fieldId };
            allFieldID[data.fieldId] = data;
        });
    }
    const allStatusArray = [];
    if (
        stringEmptyOrUndefined(configData) &&
        stringEmptyOrUndefined(configData.menuItems)
    ) {
        configData.menuItems.forEach((data) => {
            if (data.menuName === pageMenu && data.processes.length > 0) {
                data.processes.map((value, index) => {
                    if (index === 0) {
                        firstProcessId = value.processId;
                    }
                    //firstProcessId = value.processId;

                    result = { ...result, firstProcessId };
                    if (value.statuses.length > 0) {
                        value.statuses
                            .sort((a, b) => a.statusId - b.statusId)
                            .forEach((val, index) => {
                                if (
                                    val !== null &&
                                    val !== undefined &&
                                    val.statusId !== null &&
                                    val.statusId !== undefined &&
                                    val.name !== null &&
                                    val.name !== undefined
                                ) {
                                    if (
                                        index === 0 &&
                                        value.processId == firstProcessId
                                    ) {
                                        firstStatusId = val.statusId;
                                        const firstStatusName = String(val.name)
                                            .replace(/\s/g, '-')
                                            .toLowerCase();
                                        result = {
                                            ...result,
                                            firstStatusId,
                                            firstStatusName,
                                        };
                                    }
                                    allStatusArray.push({
                                        id: val.statusId,
                                        name: val.name,
                                    });
                                }
                            });
                    }
                });
            }
        });
    }
    result = { ...result, allStatusArray, allFieldID };

    if (
        stringEmptyOrUndefined(configData) &&
        stringEmptyOrUndefined(configData.noteTypeId) &&
        configData.noteTypeId !== ''
    ) {
        result = { ...result, noteTypeId: configData.noteTypeId };
    }
    if (configData.noteTypes && configData.noteTypes !== '') {
        result = { ...result, noteTypes: configData.noteTypes };
    }

    return result;
};

export function sortMethod(
    data,
    sortColumn,
    sortDirection,
    dateFormatFields,
    columnDataType = ''
) {
    const isAscending = sortDirection === 'ASC';

    const compareValues = (a, b) => {
        if (a === '') {
            return isAscending ? -1 : 1; // Place empty aValue at the top or bottom
        }
        if (b === '') {
            return isAscending ? 1 : -1; // Place empty bValue at the top or bottom
        }

        if (columnDataType === 'Numeric9' || columnDataType === 'Numeric') {
            return isAscending
                ? parseFloat(a) - parseFloat(b)
                : parseFloat(b) - parseFloat(a);
        } else if (columnDataType === 'Date' || columnDataType === 'DateTime') {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return isAscending ? dateA - dateB : dateB - dateA;
        } else {
            const stringA = typeof a === 'number' ? a.toString() : a;
            const stringB = typeof b === 'number' ? b.toString() : b;
            return isAscending
                ? stringA.localeCompare(stringB)
                : stringB.localeCompare(stringA);
        }
    };

    let modifiedColumn;
    data.map((item) => {
        if (item.hasOwnProperty(`${sortColumn}_rawdata`)) {
            modifiedColumn = `${sortColumn}_rawdata`;
        } else {
            modifiedColumn = sortColumn;
        }
    });

    data = data.sort((a, b) => {
        const aValue = a[modifiedColumn];
        const bValue = b[modifiedColumn];
        return compareValues(aValue, bValue);
    });

    return data;
}
export function sortFields(
    sortColumn,
    sortDirection,
    dateFormatFields,
    columnDataType = ''
) {
    const sortOrder = sortColumn.split('&');

    const orderBy = [];
    const sortfn = (data) => {
        const result = [];
        sortOrder.forEach((val, i) => {
            if (dateFormatFields.indexOf(val) !== -1) {
                // const date = data[`${val}_rawdata`].split('/');
                // const day = date[1];
                // const month = date[0] - 1;
                // const year = date[2];
                let dateWithTime = new Date(data[`${val}_rawdata`]);
                dateWithTime = Date.parse(dateWithTime);
                result.push(dateWithTime);

                return result;
            }

            switch (val.trim()) {
                case 'date':
                    const date = data[val].split('/');
                    const day = date[1];
                    const month = date[0] - 1;
                    const year = date[2];
                    result.push(new Date(year, month, day).getTime());
                    // var dateWithTime = new Date(data[`${val}_rawdata`]).getTime();
                    //  result.push(dateWithTime);

                    break;
                // case 'lastlogin':
                //     const lastloginDate = data.props.title.split('/');
                //     break;
                case 'batch':
                    result.push(parseInt(data[val].toLowerCase(), 10));
                    break;
                case 'tags':
                    result.push(parseInt(data.tags_data.toLowerCase(), 10));
                    break;
                case 'owner':
                    result.push(data.owner_rawdata.toLowerCase());
                    break;
                case 'coordinator':
                    result.push(data.owner_rawdata.toLowerCase());
                    break;
                case 'lastnote':
                    result.push(data.lastnote_rawdata.toLowerCase());
                    break;
                case 'note':
                    result.push(data.lastnote_rawdata.toLowerCase());
                    break;
                case 'name':
                    if (data.name_rawdata !== undefined) {
                        result.push(data.name_rawdata.toLowerCase());
                    } else {
                        result.push(data[val].toLowerCase());
                    }
                    break;
                case 'documenttype':
                    if (data.documenttype_rawdata !== undefined) {
                        result.push(data.documenttype_rawdata.toLowerCase());
                    }

                    break;
                case 'entrydate':
                    break;
                case 'npi':
                    result.push(Number(data.npi));
                    // eslint-disable-next-line vars-on-top
                    // var temp = Number(data.npi);
                    // result.push(i)

                    break;
                case 'daysoutstanding':
                    result.push(Number(data.daysoutstanding));
                    break;
                default:
                    if (val.trim() !== '' && data[val] !== undefined) {
                        result.push(data[val].toLowerCase());
                    }
                    break;
            }
        });

        return result;
    };

    sortOrder.forEach(() => orderBy.push(sortDirection.toLowerCase()));
    return { orderBy, sortfn };
}

export function generateId(length) {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

export function RefreshCounts(processId, statusId, props) {
    // const redux_store = useSelector(state => state);

    // const count = [];
    const count = [];
    const oldCount = props.count;
    // var oldCount = props.count;
    getProcessCount(processId)
        .then((response) => {
            // status = response.status;
            return response.json();
        })
        .then((data) => {
            // count.push(data);
            /* oldCount.map((old_count) => {
                    if (old_count.process.processId !== data.process.processId) {
                        count.push(old_count)
                    }
                }) */
            count.push(data);
            oldCount.map((old_count) => {
                if (
                    old_count.process !== undefined &&
                    old_count.process.processId !== undefined &&
                    data.process !== undefined &&
                    data.process.processId !== undefined &&
                    old_count.process.processId !== data.process.processId
                ) {
                    count.push(old_count);
                }
                return old_count;
            });
            props.getCountData(count);
        });
    getCount(processId, statusId)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            localStorage.setItem('refresh_count', data.count);
            let countData = localStorage.getItem(`CountData${data.processId}`);
            if (countData !== null) {
                let totalCount = 0;
                countData = JSON.parse(countData);
                if (countData.statuses !== undefined) {
                    countData.statuses.map((arrayval) => {
                        if (
                            arrayval.processId === data.processId &&
                            arrayval.statusId === data.statusId
                        ) {
                            arrayval.count = data.count;
                        }
                        totalCount += arrayval.count;
                        return arrayval;
                    });
                }
                if (countData.process !== undefined) {
                    countData.process.count = totalCount;
                }
                localStorage.setItem(
                    `CountData${data.processId}`,
                    JSON.stringify(countData)
                );
            }
        });
    return count;
}

export function PatientSearch(searchResult) {
    const searchData = [];

    if (searchResult.dataTable !== undefined) {
        const { dataRows, headerColumns } = searchResult.dataTable;

        const header = [];
        headerColumns.forEach((data, index) => {
            const headerObj = {
                key: formatHeaderNameWithOutSpace(data.name),
                name: data.name,
                datatype: data.datatype,
                fieldId: data.fieldId,
            };
            header.push(headerObj);
        });
        dataRows.forEach((data, index) => {
            const obj = {};
            obj['id'] = data.id;
            data.values.forEach((row, i) => {
                if (header[i] !== undefined) {
                    obj[header[i].key] = row.trim();
                    switch (header[i].key) {
                        case 'dateofbirth':
                            obj['formatteddob'] = formatDate(row, 'll');
                            obj[header[i].key] = row.trim();
                            break;
                        case 'patientname':
                            var split = row.trim().split(',');
                            obj['firstname'] = split[1];
                            obj['lastname'] = split[0];
                            obj[header[i].key] = row.trim();
                            break;
                        default:
                            break;
                    }
                }
            });

            searchData.push(obj);
        });
    }

    return searchData;
}
export function manipulatDataFromHeader(headerColumns, dataRows) {
    const header = [];
    // const searchData = [];

    headerColumns.forEach((data, index) => {
        const headerObj = {
            key: formatNameWithSpaceToUnderscore(data.name),
            name: data.name,
            datatype: data.datatype,
            fieldId: data.fieldId,
        };
        header.push(headerObj);
    });

    const obj = {};
    obj['id'] = dataRows.id;
    dataRows.values.forEach((row, i) => {
        if (header[i] !== undefined) {
            obj[header[i].key] = row.trim();
            switch (header[i].key) {
                case 'date_of_birth':
                    obj['formatteddob'] = formatDate(row, 'll');
                    obj[header[i].key] = row.trim();
                    break;
                case 'patient_name':
                    var split = row.trim().split(',');
                    /* obj['first_name'] = split[1];
                            obj['last_name'] = split[0]; */

                    // kalpana changed
                    obj['first_name'] = split[1];
                    obj['last_name'] = split[0];

                    obj[header[i].key] = row.trim();
                    break;
                case 'patientid':
                    obj.patient_id = row.trim();
                    break;
                default:
                    break;
            }
        }
    });

    // searchData.push(obj);

    return obj;
}
export function PatientWithUnderScore(searchResult) {
    const searchData = [];

    if (searchResult.dataTable !== undefined) {
        const { dataRows, headerColumns } = searchResult.dataTable;

        const header = [];
        headerColumns.forEach((data, index) => {
            const headerObj = {
                key: formatNameWithSpaceToUnderscore(data.name),
                name: data.name,
                datatype: data.datatype,
                fieldId: data.fieldId,
            };
            header.push(headerObj);
        });
        dataRows.forEach((data, index) => {
            const obj = {};
            obj['id'] = data.id;
            if (data.isModifiable !== undefined) {
                obj['isModifiable'] = data.isModifiable;
            }
            data.values.forEach((row, i) => {
                if (header[i] !== undefined) {
                    obj[header[i].key] = row.trim();
                    switch (header[i].key) {
                        case 'date_of_birth':
                            obj['formatteddob'] = formatDate(row, 'll');
                            obj[header[i].key] = row.trim();
                            break;
                        case 'patient_name':
                            var split = row.trim().split(',');
                            /* obj['first_name'] = split[1];
                            obj['last_name'] = split[0]; */

                            // kalpana changed
                            obj['first_name'] = split[1];
                            obj['last_name'] = split[0];

                            obj[header[i].key] = row.trim();
                            break;
                        case 'patientid':
                            obj.patient_id = row.trim();
                            break;
                        default:
                            break;
                    }
                }
            });
            delete obj['patientid'];
            searchData.push(obj);
        });
    }

    return searchData;
}

export function OrderSearch(searchResult) {
    const searchData = [];

    if (searchResult.dataTable !== undefined) {
        const { dataRows, headerColumns } = searchResult.dataTable;

        const header = [];
        headerColumns.forEach((data, index) => {
            const headerObj = {
                key: formatHeaderNameWithOutSpace(data.name),
                name: data.name,
                datatype: data.datatype,
                fieldId: data.fieldId,
            };
            header.push(headerObj);
        });
        dataRows.forEach((data, index) => {
            const obj = {};
            obj['id'] = data.id;
            data.values.forEach((row, i) => {
                if (header[i] !== undefined) {
                    obj[header[i].key] = row.trim();
                    switch (header[i].key) {
                        case 'orderdate':
                            obj['formattedorderdate'] = formatDate(row, 'll');
                            obj[header[i].key] = row.trim();
                            break;
                        case 'patient':
                            var split = row.trim().split(',');
                            obj['firstname'] = split[1];
                            obj['lastname'] = split[0];
                            obj[header[i].key] = row.trim();
                            break;
                        case 'dob':
                            obj['dateofbirth'] = row.trim();
                            break;
                        default:
                            break;
                    }
                }
            });

            searchData.push(obj);
        });
    }

    return searchData;
}

export function OrderWithUnderScore(searchResult) {
    const searchData = [];

    if (searchResult.dataTable !== undefined) {
        const { dataRows, headerColumns } = searchResult.dataTable;

        const header = [];
        headerColumns.forEach((data, index) => {
            const headerObj = {
                key: formatNameWithSpaceToUnderscore(data.name),
                name: data.name,
                datatype: data.datatype,
                fieldId: data.fieldId,
            };
            header.push(headerObj);
        });
        dataRows.forEach((data, index) => {
            const obj = {};
            obj['id'] = data.id;
            data.values.forEach((row, i) => {
                if (header[i] !== undefined) {
                    obj[header[i].key] = row.trim();
                    switch (header[i].key) {
                        case 'order_date':
                            obj['formattedorderdate'] = formatDate(row, 'll');
                            obj[header[i].key] = row.trim();
                            break;
                        case 'patient':
                            var split = row.trim().split(',');
                            if (split.length > 1) {
                                obj['first_name'] = split[1].trim();
                                obj['last_name'] = split[0].trim();
                            }
                            obj[header[i].key] = row.trim();
                            break;
                        case 'dob':
                            obj['date_of_birth'] = row.trim();
                            break;
                        default:
                            break;
                    }
                }
            });

            searchData.push(obj);
        });
    }

    return searchData;
}
export function BranchIdSearch(result) {
    if (result.dataTable !== undefined) {
        const { dataRows, headerColumns } = result.dataTable;

        let branch_idIndex = '';
        let firstNonEmptyBranchID = '';
        headerColumns.forEach((data, index) => {
            if (data.name === 'Branch ID') {
                branch_idIndex = index;
            }
        });
        for (const row of dataRows) {
            const branchID = row.values[branch_idIndex];

            if (branchID !== '') {
                firstNonEmptyBranchID = branchID;
                break;
            }
        }

        return firstNonEmptyBranchID;
    }
}
export function EpisodeSearch(searchResult) {
    const searchData = [];

    if (searchResult.dataTable !== undefined) {
        const { dataRows, headerColumns } = searchResult.dataTable;

        const header = [];
        headerColumns.forEach((data, index) => {
            const headerObj = {
                key: formatNameWithSpaceToUnderscore(data.name),
                name: data.name,
                datatype: data.datatype,
                fieldId: data.fieldId,
            };
            header.push(headerObj);
        });
        dataRows.forEach((data, index) => {
            const obj = {};
            obj['id'] = data.id;
            data.values.forEach((row, i) => {
                if (header[i] !== undefined) {
                    obj[header[i].key] = row.trim();
                    switch (header[i].key) {
                        case 'dateofbirth':
                            obj['formatteddob'] = formatDate(row, 'll');
                            obj[header[i].key] = row.trim();
                            break;
                        default:
                            break;
                    }
                }
            });

            searchData.push(obj);
        });
    }

    return searchData;
}

export async function getDropDownDataFromApi(fieldID) {
    var configData = localStorage.getItem('configData');
    configData = JSON.parse(configData);
    let tagCondition = '';
    configData?.fields.map((data, index) => {
        if (data.fieldId === fieldID) {
            tagCondition = data.name;
        }
    });
    let ddl = '';
    if (stringEmptyOrUndefined(fieldID)) {
        if (tagCondition !== 'Tags') {
            await GetDropdownData(fieldID)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    ddl = data;

                    let newArray = [];
                    if (data.values) {
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                    }
                    ddl = {
                        ...ddl,
                        values: newArray,
                    };
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            const response = await getTags();
            if (response.ok) {
                const data = await response.json();
                let tagList = data.map((item) => item.name);
                const stringifiedObj = JSON.stringify(data);
                localStorage.setItem('tagList', stringifiedObj);
                ddl = {
                    fieldId: fieldID,
                    name: tagCondition,
                    values: tagList,
                };
            } else {
                console.error('An error has occurred');
            }
        }
    }
    return ddl;
}

export async function getPos(el) {
    const rect = el.getBoundingClientRect();
    return { x: rect.left, y: rect.top };
}

export const generatePatientId = () => {
    let d = new Date().getTime();
    let d2 =
        // eslint-disable-next-line valid-typeof
        (typeof performance !== undefined &&
            performance.now &&
            performance.now() * 1000) ||
        0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
};

export const faxCellFormatter = (fax_number) => {
    // var number=number1.toString();
    // const faxPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (fax_number !== undefined && fax_number !== '' && fax_number !== null) {
        const fax_number_tempo = fax_number.split('-');
        const fax_number_temp = fax_number_tempo.join('');

        const formatFax = fax_number_temp.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (formatFax !== null) {
            var faxNumberFind = `${formatFax[1]}-${formatFax[2]}-${formatFax[3]}`;
        }
        // eslint-disable-next-line block-scoped-var, no-unneeded-ternary
        return faxNumberFind ? faxNumberFind : fax_number;
    }
};

export const colorCodearray = () => {
    const color = [
        '#00B1BC',
        '#88cc00',
        '#F3E13B',
        '#ECB445',
        '#D87C3A',
        '#D84C3A',
    ];
    return color;
};

export const reportColorCodeArray = () => {
    const color = [
        '#0029BC',
        '#007DBC',
        '#00B1BC',
        '#55D36A',
        '#8DC63F',
        '#F3E13B',
        '#ECB445',
        '#D87C3A',
        '#D84C3A',
        '#D83A71',
        '#9F3481',
        '#8B36BF',
        '#0e0883',
        '#37a6de',
        '#126a70',
        '#a0f5ae',
        '#afca8a',
        '#efe697',
        '#fbd486',
        '#f5a367',
        '#fb7867',
        '#fe6299',
        '#fd32b3',
        '#bf5efa',
    ];
    return color;
};
export const reportColorCodeBigArray = async (count) => {
    const color = [
        '#0029BC',
        '#007DBC',
        '#00B1BC',
        '#55D36A',
        '#8DC63F',
        '#F3E13B',
        '#ECB445',
        '#D87C3A',
        '#D84C3A',
        '#D83A71',
        '#9F3481',
        '#8B36BF',
        '#0e0883',
        '#37a6de',
        '#126a70',
        '#a0f5ae',
        '#afca8a',
        '#efe697',
        '#fbd486',
        '#f5a367',
        '#fb7867',
        '#fe6299',
        '#fd32b3',
        '#bf5efa',
    ];
    /* const color = [
        '#0029bc',
        '#007dbc',
        '#00b1bc',
        '#55d36a',
        '#8dc63f',
        '#f3e13b',
        '#ecb445',
        '#d87c3a',
        '#d84c3a',
        '#d83971',
        '#9f3481',
        '#8b36bf',
        '#0e0883',
        '#37a6de',
        '#126a70',
        '#a0f5ae',
        '#afca8a',
        '#efe697',
        '#fbd486',
        '#f5a367',
        '#fb7867',
        '#fe6299',
        '#fd32b3',
        '#bf5efa',
        '#070358',
        '#236a8e',
        '#167f86',
        '#5fa86b',
        '#6a9232',
        '#c8ba3f',
        '#f8c55e',
        '#f79146',
        '#fa5d49',
        '#f94283',
        '#c9208c',
        '#ab2bf9',
        '#053fe5',
        '#00aaff',
        '#00fff6',
        '#00ff2a',
        '#93f210',
        '#ffe600',
        '#ffa900',
        '#ff6b00',
        '#ff1e00',
        '#ff90b7',
        '#fe5dd1',
        '#8d02e1',
        '#434166',
        '#7bb3cf',
        '#62898c',
        '#046814',
        '#809069',
        '#605705',
        '#9e6b04',
        '#cc5702',
        '#7f1002',
        '#8d3e5a',
        '#996b88',
        '#4f1d6e',
    ]; */

    let colorArray = [];
    var counts = 0;
    for (let index = 0; index < count; index++) {
        if (counts === color.length) {
            counts = 0;
        }
        await colorArray.push(color[counts]);
        counts += 1;
    }

    return colorArray;
    //    let newArray;
    //     if (count > color.length) {
    //         let loopData = Math.round(count / 24);
    //         if (loopData <= 1 ) {
    //             newArray = color.concat(color);
    //         } else {
    //
    //            /*  for (let i = 1;  i < loopData && i+24 <= loopData; i += 24) {
    //
    //                     let arrayOne = color.concat(color);
    //                     let arrayTwo = color.concat(color);
    //                     newArray = arrayOne.concat(arrayTwo);
    //             } */

    //             let arrayOne = color.concat(color);
    //             let arrayTwo = color.concat(color);
    //             newArray = arrayOne.concat(arrayTwo);
    //             if(count>100){

    //                 for (let i = 1;  i < 10 ; i++) {

    //                     newArray.push(newArray)
    //                 }
    //             }

    //         }
    //     }
    //
    //     return newArray;
};
export const reportPhysicianColorCode = async (count, page) => {
    let color;
    if (page % 2 !== 0) {
        color = [
            '#0029bc',
            '#007dbc',
            '#00b1bc',
            '#55d36a',
            '#8dc63f',
            '#f3e13b',
            '#ecb445',
            '#d87c3a',
            '#d84c3a',
            '#d83971',
            '#9f3481',
            '#8b36bf',
            '#0e0883',
            '#37a6de',
            '#126a70',
            '#a0f5ae',
            '#afca8a',
            '#efe697',
            '#fbd486',
            '#f5a367',
            '#fb7867',
            '#fe6299',
            '#fd32b3',
            '#bf5efa',
            '#070358',
            '#236a8e',
            '#167f86',
            '#5fa86b',
            '#6a9232',
            '#c8ba3f',
        ];
    } else {
        color = [
            '#f8c55e',
            '#f79146',
            '#fa5d49',
            '#f94283',
            '#c9208c',
            '#ab2bf9',
            '#053fe5',
            '#00aaff',
            '#00fff6',
            '#00ff2a',
            '#93f210',
            '#ffe600',
            '#ffa900',
            '#ff6b00',
            '#ff1e00',
            '#ff90b7',
            '#fe5dd1',
            '#8d02e1',
            '#434166',
            '#7bb3cf',
            '#62898c',
            '#046814',
            '#809069',
            '#605705',
            '#9e6b04',
            '#cc5702',
            '#7f1002',
            '#8d3e5a',
            '#996b88',
            '#4f1d6e',
        ];
    }

    let colorArray = [];
    var counts = 0;
    for (let index = 0; index < count; index++) {
        if (counts === color.length) {
            counts = 0;
        }
        await colorArray.push(color[counts]);
        counts += 1;
    }

    return colorArray;
};
export const getRandomColors = (a, n) => {
    const array = [];
    let i;

    for (i = 1; i <= a.length; i += n) {
        array.push(a[i - 1]);
    }
    return array;
};

export const loadHTMLData = (data) => {
    return { __html: data };
};

export const documentTypeMappings = (data) => {
    let result = {};
    data.map((item) => {
        result = {
            ...result,
            [item.documentTypeName]: item.detailViewName,
        };
    });
    return result;
};
export function sortByCol(arr, colIndex) {
    function sortFunction(a, b) {
        a = a[colIndex];
        b = b[colIndex];
        return a === b ? 0 : a < b ? -1 : 1;
    }
    arr.sort(sortFunction);
}
export function sortObjectByKeys(o) {
    return (
        Object.keys(o)
            .sort()
            // eslint-disable-next-line no-sequences
            .reduce((r, k) => ((r[k] = o[k]), r), {})
    );
}
export function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == '#') {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function updateAllactions(allActions, data) {
    let allActionsArray = allActions;
    for (const property in allActionsArray) {
        let newObject = {};
        newObject = allActionsArray[property];
        data.map((item) => {
            if (String(item) === String(property)) {
                newObject = {
                    ...newObject,
                    used: 'Yes',
                };
            }
        });
        allActionsArray[property] = newObject;
    }

    return allActionsArray;
}

export function neededConfiguration(statusId, menuName, configData) {
    let updateState = [];
    configData.statusIdsNeedingConfiguration.map((data) => {
        updateState.push(String(data));
    });

    let status = String(statusId);

    if (updateState.includes(String(status))) {
        if (statusId !== undefined) {
            configurationWithStatus(statusId)
                .then(async (response) => {
                    if (response.json !== undefined) return response.json();
                })
                .then(async (data) => {
                    if (data !== undefined) {
                        // await localStorage.setItem(
                        //     'configData',
                        //     JSON.stringify(data)
                        // );

                        let updateConfigData = data;
                        let newConfigData = configData;
                        let searchIds = [];
                        let headerColumns = [];
                        let icon;
                        let color;
                        let graphicalDisplayItems;
                        let noteTypes = [];
                        let actions = [];

                        updateConfigData.configuredStatuses.map((idx) => {
                            if (String(idx.statusId) === String(statusId)) {
                                searchIds = idx.searchIds;
                                headerColumns = idx.headerColumns;
                                icon = idx.icon;
                                color = idx.color;
                                graphicalDisplayItems =
                                    idx.graphicalDisplayItems;
                                noteTypes = idx.noteTypes;
                                actions = idx.actions;
                            }
                        });
                        newConfigData.menuItems.map((property) => {
                            if (property.menuName === menuName) {
                                let statuses = property.processes[0].statuses;
                                statuses.map((item) => {
                                    if (
                                        String(item.statusId) ===
                                        String(statusId)
                                    ) {
                                        item.searchIds = searchIds;
                                        item.headerColumns = headerColumns;
                                        item.icon = icon;
                                        item.color = color;
                                        item.graphicalDisplayItems =
                                            graphicalDisplayItems;
                                        item.noteTypes = noteTypes;
                                        item.actions = actions;
                                    }
                                });
                                return newConfigData;
                            }
                        });

                        localStorage.setItem(
                            'configData',
                            JSON.stringify(newConfigData)
                        );
                    }
                });
        }
    }
}
export function getOwnersToOutbox(dataArray) {
    const color_code = [
        '#ECB445',
        '#8B36BF',
        '#00B1BC',
        '#ffbe0b',
        '#fb5607',
        '#ff006e',
        '#8338ec',
        '#3a86ff',
        '#003049',
        '#d62828',
        '#f77f00',
        '#283618',
        '#bc6c25',
        '#1b4332',
        '#1a759f',
    ];

    const finalOwner = [];
    dataArray.forEach((item) => {
        const color = {};
        const bg_color = color_code[Math.floor(Math.random() * 15)];
        color.ownerId = item;
        color.ownerName = item;
        color.label = item;
        color.title = item;
        color.bg_color = bg_color;
        finalOwner.push(color);
    });

    return finalOwner;
}
export function multiSelectErrorMessage(failedDocIds) {
    let message;
    if (failedDocIds.length === 1) {
        message = `Doc Id  ${failedDocIds[0]} is  Locked`;
    } else {
        message = `Doc Ids `;
        failedDocIds.map(async (item, idx) => {
            if (
                idx !== failedDocIds.length - 1 &&
                idx !== failedDocIds.length - 2
            ) {
                message += `${item},`;
            } else if (idx === failedDocIds.length - 2) {
                message += `${item}`;
            } else {
                message += ` and ${item}`;
            }
        });
        message += ` are Locked`;
    }

    showErrorToast(message);
}

/**s
 * Validate Based on dynamic rules
 * @param {Object} form_data validation data
 * @param {Object} rules validation rules
 * @return {Promise<Map>} promise return
 */
export const checkRules = async (form_data, rules) => {
    return new Promise((resolve, reject) => {
        try {
            const validationError = new Map();
            var belowRules = rules;

            if (typeof rules === 'object') {
                belowRules = rules;
            }

            if (typeof rules == 'string') {
                belowRules = JSON.parse(rules);
            }

            belowRules = JSON.parse(
                JSON.stringify(belowRules).replace(
                    /Location Type/g,
                    'Attachment Location'
                )
            );

            for (const property in belowRules.ValidationRules) {
                const splitProperty = property
                    .replace('(', '')
                    .replace(')', '')
                    .split(',');
                if (
                    formatNameWithSpaceToUnderscore(splitProperty[0].trim()) in
                        form_data &&
                    form_data[
                        formatNameWithSpaceToUnderscore(splitProperty[0].trim())
                    ].toLowerCase() == splitProperty[1].trim(0).toLowerCase()
                ) {
                    const ValidationRulesPropertyLength =
                        belowRules.ValidationRules[property].length;
                    for (
                        let index = 0;
                        index < ValidationRulesPropertyLength;
                        index++
                    ) {
                        const allCheck = belowRules.ValidationRules[property][
                            index
                        ]?.attributerefs?.map((attributes, attIndex) => {
                            if (
                                formatNameWithSpaceToUnderscore(attributes) in
                                    form_data &&
                                form_data[
                                    formatNameWithSpaceToUnderscore(attributes)
                                ]
                            ) {
                                if (
                                    formatNameWithSpaceToUnderscore(
                                        attributes
                                    ) !=
                                    formatNameWithSpaceToUnderscore(
                                        belowRules.ValidationRules[property][
                                            index
                                        ].target.value
                                    )
                                ) {
                                    if (
                                        belowRules.ValidationRules[property][
                                            index
                                        ].validation.id == 'daterange'
                                    ) {
                                        if (
                                            formatNameWithSpaceToUnderscore(
                                                attributes
                                            ) ==
                                                formatNameWithSpaceToUnderscore(
                                                    belowRules.ValidationRules[
                                                        property
                                                    ][index].validation
                                                        .rangestart.value
                                                ) &&
                                            new Date(
                                                form_data[
                                                    formatNameWithSpaceToUnderscore(
                                                        attributes
                                                    )
                                                ]
                                            ) <
                                                (belowRules.ValidationRules[
                                                    property
                                                ][index].validation.rangeend
                                                    .id == 'macro'
                                                    ? new Date(
                                                          belowRules.ValidationRules[
                                                              property
                                                          ][
                                                              index
                                                          ].validation.rangeend.value
                                                      )
                                                    : new Date(
                                                          form_data[
                                                              formatNameWithSpaceToUnderscore(
                                                                  belowRules
                                                                      .ValidationRules[
                                                                      property
                                                                  ][index]
                                                                      .validation
                                                                      .rangeend
                                                                      .value
                                                              )
                                                          ]
                                                      ))
                                        ) {
                                            return true;
                                        } else if (
                                            formatNameWithSpaceToUnderscore(
                                                attributes
                                            ) ==
                                                formatNameWithSpaceToUnderscore(
                                                    belowRules.ValidationRules[
                                                        property
                                                    ][index].validation.rangeend
                                                        .value
                                                ) &&
                                            new Date(
                                                form_data[
                                                    formatNameWithSpaceToUnderscore(
                                                        attributes
                                                    )
                                                ]
                                            ) >
                                                (belowRules.ValidationRules[
                                                    property
                                                ][index].validation.rangestart
                                                    .id == 'macro'
                                                    ? new Date(
                                                          belowRules.ValidationRules[
                                                              property
                                                          ][
                                                              index
                                                          ].validation.rangestart.value
                                                      )
                                                    : new Date(
                                                          form_data[
                                                              formatNameWithSpaceToUnderscore(
                                                                  belowRules
                                                                      .ValidationRules[
                                                                      property
                                                                  ][index]
                                                                      .validation
                                                                      .rangestart
                                                                      .value
                                                              )
                                                          ]
                                                      ))
                                        ) {
                                            return true;
                                        } else {
                                            validationError.set(
                                                formatNameWithSpaceToUnderscore(
                                                    attributes
                                                ),
                                                validationErrorMessage.dateNotInRange.replace(
                                                    validationErrorMessage.attr,
                                                    attributes
                                                )
                                            );
                                            return false;
                                        }
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }
                            }

                            validationError.set(
                                formatNameWithSpaceToUnderscore(attributes),
                                validationErrorMessage.isRequired.replace(
                                    validationErrorMessage.attr,
                                    attributes
                                )
                            );
                            return false;
                        });
                        // if (!(allCheck.every((value) => value === true))) {
                        //     continue
                        // }

                        const errorString = ruleBasedValidation(
                            form_data,
                            belowRules.ValidationRules[property][index]
                        );
                        if (errorString) {
                            validationError.set(
                                formatNameWithSpaceToUnderscore(
                                    belowRules.ValidationRules[property][index]
                                        .target.value
                                ),
                                errorString
                            );
                        }
                    }
                }
            }
            if (validationError.size > 0) {
                showErrorToast(validationErrorMessage.validationError);
            }
            resolve(validationError);
        } catch (error) {
            reject(error);
        }
    });
};

const validationJsonVariables = {
    attribute: `attribute`,
};

/**
 * validation based on validation id
 * @param {String} validationObject
 * @returns {Boolean}
 */
export const ruleBasedValidation = (data, validationObject) => {
    switch (validationObject.validation.id) {
        case 'daterange':
            if (
                data[
                    formatNameWithSpaceToUnderscore(
                        validationObject.target.value
                    )
                ]
            ) {
                const startDateValue =
                    validationObject.validation.rangestart.id ===
                    validationJsonVariables.attribute
                        ? data[
                              formatNameWithSpaceToUnderscore(
                                  validationObject.validation.rangestart.value
                              )
                          ]
                        : validationObject.validation.rangestart.value;

                const endDateValue =
                    validationObject.validation.rangeend.id ===
                    validationJsonVariables.attribute
                        ? data[
                              formatNameWithSpaceToUnderscore(
                                  validationObject.validation.rangeend.value
                              )
                          ]
                        : validationObject.validation.rangeend.value;

                const targetDate =
                    data[
                        formatNameWithSpaceToUnderscore(
                            validationObject.target.value
                        )
                    ];

                const startDateStripped =
                    stripTimeAndConvertToUTC(startDateValue);
                const endDateStripped = stripTimeAndConvertToUTC(endDateValue);
                const targetDateStripped = stripTimeAndConvertToUTC(targetDate);

                if (
                    !isDateInRange(
                        targetDateStripped,
                        startDateStripped,
                        endDateStripped
                    )
                ) {
                    return validationErrorMessage.dateNotInRange;
                }
            } else {
                return validationErrorMessage.isRequired.replace(
                    validationErrorMessage.attr,
                    validationObject.target.value
                );
            }
            break;
        case 'matches':
            if (
                !(
                    data[
                        formatNameWithSpaceToUnderscore(
                            validationObject.target.value
                        )
                    ] ==
                    data[
                        formatNameWithSpaceToUnderscore(
                            validationObject.validation.matchvalue.value
                        )
                    ]
                )
            ) {
                return validationErrorMessage.matches.replace(
                    validationErrorMessage.attr,
                    validationObject.target.value
                );
            }

            break;
        case 'required':
            if (
                !data[
                    formatNameWithSpaceToUnderscore(
                        validationObject.target.value
                    )
                ]
            ) {
                return validationErrorMessage.isRequired.replace(
                    validationErrorMessage.attr,
                    validationObject.target.value
                );
            }
            break;
        default:
            if (
                !process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development'
            ) {
                alert('Check console, Missed Validation');
            }
            break;
    }
    return '';
};

export const validationErrorMessage = {
    attr: `#attrName#`,
    isRequired: `#attrName# is required`,
    dateNotInRange: `Invalid date`,
    matches: 'Invalid #attrName#',
    validationError: `Validation Error`,
};

const stripTimeAndConvertToUTC = (dateString) => {
    const date = new Date(dateString);
    return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
    );
};

const isDateInRange = (dateUTC, startDateUTC, endDateUTC) => {
    return dateUTC >= startDateUTC && dateUTC <= endDateUTC;
};

export const clearUnMatchAttachmentType = (form_data, rules) => {
    return new Promise((resolve, reject) => {
        var baseRules = rules;
        var returnFormData = form_data;
        if (!(typeof rules == 'object')) {
            baseRules = JSON.parse(rules);
        }

        for (const property in baseRules.ValidationRules) {
            const splitProperty = property
                .replace('(', '')
                .replace(')', '')
                .split(',');

            if (
                formatNameWithSpaceToUnderscore(splitProperty[0].trim()) in
                    form_data &&
                formatNameWithSpaceToUnderscore(splitProperty[0].trim()) ==
                    'attachment_type' &&
                form_data[
                    formatNameWithSpaceToUnderscore(splitProperty[0].trim())
                ] != splitProperty[1].trim(0)
            ) {
                const ValidationRulesPropertyLength =
                    baseRules.ValidationRules[property].length;
                for (
                    let index = 0;
                    index < ValidationRulesPropertyLength;
                    index++
                ) {
                    returnFormData = {
                        ...returnFormData,
                        ...{
                            [baseRules.ValidationRules[property][index].target
                                .value]: '',
                        },
                    };
                }
            }
        }

        resolve(returnFormData);
    });
};
export function encodeParameter(text) {
    return encodeURIComponent(text);
}
export const getAttachmentData = async (formData, configData) => {
    let recordsetId;
    const result = [];
    configData.configuredRecordsets.forEach((data) => {
        if (data.name === 'Attachment Data') {
            recordsetId = data.recordsetId;
        }
    });
    let filedId = [
        'Attachment Type',
        'Attachment Location',
        'DocCode',
        'DocTypeID',
        'AgencyID',
    ];
    let nameFiledId = {};
    configData.fields.map((data) => {
        filedId.map((item) => {
            if (data.name === item) {
                nameFiledId = {
                    ...nameFiledId,
                    [formatNameWithSpaceToUnderscore(data.name)]: data.fieldId,
                };
            }
        });
    });
    let searchText = '';
    if (formData.agencyid) {
        searchText += `fieldSearch=${nameFiledId.agencyid}=${formData.agencyid}&`;
    }
    if (formData.attachment_location) {
        let encodeLoc = encodeParameter(formData.attachment_location);
        searchText += `fieldSearch=${nameFiledId.attachment_location}=${encodeLoc}&`;
    }
    if (formData.attachment_type) {
        let encodeType = encodeParameter(formData.attachment_type);
        searchText += `fieldSearch=${nameFiledId.attachment_type}=${encodeType}`;
    }
    if (recordsetId) {
        const response = await recordSetSearch(recordsetId, searchText);
        var data = null;
        if (response.status === 200) {
            data = await response.json();
        } else {
            throw new Error('Error in recordSetSearch');
            return;
        }
        if (data.dataTable) {
            let { headerColumns, dataRows } = data.dataTable;
            let doccode_Index;
            let doctypeid_Index;
            headerColumns.map((item, key) => {
                if (item.fieldId === nameFiledId.doccode) {
                    doccode_Index = key;
                }
                if (item.fieldId === nameFiledId.doctypeid) {
                    doctypeid_Index = key;
                }
            });
            if (dataRows && dataRows.length > 0) {
                dataRows[0].values.map((data, key) => {
                    let param;
                    if (key === doccode_Index && data) {
                        param = {
                            fieldId: nameFiledId.doccode,
                            value: data !== 0 ? data : '',
                        };
                        result.push(param);
                    }
                    if (key === doctypeid_Index && data) {
                        param = {
                            fieldId: nameFiledId.doctypeid,
                            value: data !== 0 ? data : '',
                        };
                        result.push(param);
                    }
                });
            }
        }
    }
    return result;
};

export const fetchClassifierRecordsetData = async (recordSetName: string) => {
    try {
        const configData = getConfig();
        const configuredRecordsets =
            configData.length > 0 ? configData[0].configuredRecordsets : [];
        if (configuredRecordsets !== undefined) {
            const recordsetData = configuredRecordsets.find(
                (recordset: any) => recordset.name === recordSetName
            );

            if (
                recordsetData &&
                recordsetData.recordsetId &&
                recordsetData.name
            ) {
                const response = await relatedDoc(recordsetData.recordsetId);

                if (!response) {
                    throw new Error(
                        `No response received for: ${recordsetData.name}`
                    );
                }

                const data = await response.json();

                return { data, headerColumns: data.dataTable.headerColumns };
            } else {
                showErrorToast(`No recordset found for ${recordSetName}`);
            }
        }
    } catch (error) {
        console.error('Error in fetching or processing data:', error);
        showErrorToast('Error while processing');
        throw new Error('Error while processing');
    }
};
export function updateCustomSearchConfigDataInLocalStorage(
    newCustomSearchQueries,
    newCustomSearchNamedIdsNeedingConfiguration
) {
    const existingConfigData =
        JSON.parse(localStorage.getItem('configData')) || {};

    const existingCustomSearchQueries =
        existingConfigData.customSearchQueries || [];
    const existingCustomSearchNamedIdsNeedingConfiguration =
        existingConfigData.customSearchNamedIdsNeedingConfiguration || [];

    const mergedCustomSearchQueries = mergeArraysByKey(
        existingCustomSearchQueries,
        newCustomSearchQueries || [],
        'searchId'
    );

    const mergedCustomSearchNamedIdsNeedingConfiguration = mergeArraysByKey(
        existingCustomSearchNamedIdsNeedingConfiguration,
        newCustomSearchNamedIdsNeedingConfiguration || [],
        'id'
    );

    existingConfigData.customSearchQueries = mergedCustomSearchQueries;
    existingConfigData.customSearchNamedIdsNeedingConfiguration =
        mergedCustomSearchNamedIdsNeedingConfiguration;

    localStorage.setItem('configData', JSON.stringify(existingConfigData));
}

export function mergeArraysByKey(existingArray, newArray, key) {
    const mergedMap = {};

    existingArray.forEach((item) => {
        if (item && item[key] !== undefined) {
            mergedMap[item[key]] = item;
        }
    });

    newArray.forEach((item) => {
        if (item && item[key] !== undefined) {
            mergedMap[item[key]] = item;
        }
    });

    return Object.values(mergedMap);
}
