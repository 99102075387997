import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SignerPatientForm from './SignerPatientForm';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { OptionType } from '../../../eSign/models/interfaces';
import { getDropDownDataFromApi } from '../../../../components/commonfunctionTS';

interface SignerPatientDataProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: (values: any) => Promise<void>;
    selectedRow: any;
    fields: any[];
}

const SignerPatientDataModal: React.FC<SignerPatientDataProps> = ({
    showModal,
    setShowModal,
    onSubmit,
    selectedRow,
    fields,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [signerRole, setSignerRole] = useState<OptionType[]>([]);

    const formikRef = useRef<FormikProps<any>>(null);

    const initialValues = {
        signerFirstName: selectedRow?.signerfirstname ?? '',
        signerLastName: selectedRow?.signerlastname ?? '',
        signerEmail: selectedRow?.signeremail ?? '',
        signerRole: selectedRow?.signerrole ?? '',
        patientFirstName: selectedRow?.patientfirstname ?? '',
        patientLastName: selectedRow?.patientlastname ?? '',
        patientName: (selectedRow?.patientfirstname ?? '') + " " + (selectedRow?.patientlastname ?? ''),
        patientDOB: selectedRow?.dateofbirth ?? '',
    };
console.log('initia',initialValues)
    useEffect(() => {
        if (showModal) {
            const signerRoleFieldId = fields.find(
                (col: any) => col.name === 'Signer Role'
            )?.fieldId;
            if (signerRoleFieldId) {
                fetchDropDownData(signerRoleFieldId);
            }
        }
    }, []);

    const fetchDropDownData = async (fieldId: number) => {
        const data = await getDropDownDataFromApi(fieldId);
        if (data && data.values) {
            const signerRole: OptionType[] = data.values.map(
                (item: string) => ({
                    value: item ?? '',
                    label: item ?? '',
                })
            );
            setSignerRole(signerRole);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleSubmit = async (values: object) => {
        setIsLoading(true);
        try {
            await onSubmit(values);
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <div>
            <Modal
                className='import-popup'
                show={showModal}
                onHide={handleClose}
                animation={false}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header
                    closeButton
                    className='d-block justify-content-start'
                >
                    <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <i
                                className='fa-solid fa-file-contract'
                                aria-hidden='true'
                            />
                            <span>Signer and Patient Information</span>
                        </div>
                        <i
                            title='Close'
                            className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                            aria-hidden='true'
                            onClick={handleClose}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                    }}
                >
                    <Formik
                        innerRef={formikRef}
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {(formik) => (
                            <Form>
                                <SignerPatientForm
                                    formik={formik}
                                    signerRole={signerRole}
                                />
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer className='d-block delete_btn'>
                    <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                        <div className='d-flex'>
                            <Button
                                title='Cancel'
                                variant='secondary'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='d-flex'>
                            <Button
                                title='Submit'
                                type='submit'
                                variant='success'
                                onClick={() =>
                                    formikRef?.current?.handleSubmit()
                                }
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <span>Submit</span>
                                )}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SignerPatientDataModal;

const validationSchema = Yup.object({
    signerFirstName: Yup.string().required('Required'),
    signerLastName: Yup.string().required('Required'),
    signerEmail: Yup.string().email('Invalid email').required('Required'),
    patientFirstName: Yup.string().required('Required'),
    patientLastName: Yup.string().required('Required'),
    patientName: Yup.string().required('Required'),
    patientDOB: Yup.string().required('Required'),
});

