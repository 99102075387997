/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { recordSetSearch } from '../../../pages/api/api';
import {
    GetFieldData,
    PatientWithUnderScore,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    encodeParameter,
} from '../../../components/commonfunction.js';
import { inboxPage } from '../../_redux/actions/inboxActions';
import { getDDLlist } from '../../../pages/_redux/actions/configActions';
import { Nav } from 'react-bootstrap';

const _ = require('lodash');

export class ByPhysicianSignatureNPI extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            ...this.props.state,
            autoCompleteSpinner: true,
            patient_filter: { patientname: '' },
            patient_filter_display:
                this.props.patient_filter_display !== undefined
                    ? this.props.patient_filter_display
                    : '',
            open_patient_delete: false,
            disableBtn: this.props.disableBtn ? this.props.disableBtn : false,
            tempPatientFilter: '',
            searchByTypeOption: 'ordering_physician',
        };
    }

    debounceFunc = debounce(1000, (num) => {
        this.patientAPICall(num);
    });

    componentDidMount = async () => {
        if (this.textInput.current) {
            this.textInput.current.focus();
        }
        this.getTransitonDropDownData();
        this.configuredRecordsets();
        GetFieldData({ configData: this.props.configData });

        if (
            _.isEmpty(this.state.patient_filter) === false &&
            this.state.patient_filter.hasOwnProperty('patient_name')
        ) {
            this.setState({
                patient_filter_display: this.state.patient_filter.patient_name,
            });
        }

        if (
            _.isEmpty(this.state.patient_filter) === false &&
            this.state.patient_filter.hasOwnProperty('patientRecord') &&
            this.state.patient_filter.patientRecord &&
            !this.state.patient_filter.hasOwnProperty('order_number')
        ) {
            this.pickPatientFromTheList(this.state.patient_filter);
        }

        document.addEventListener('mousedown', this.closePatientDropdown);
    };

    componentDidUpdate = (prevProps) => {
        if (
            this.props.state.patient_filter.hasOwnProperty('patient_id') &&
            this.props.enableSingleSearch
        ) {
            this.updateState({
                enableSingleSearch: false,
            });
        }

        if (this.props.state !== prevProps.state) {
            if (
                this.state.patient_filter_display !==
                this.props.state.patient_filter_display
            )
                if (this.props.closeFunction) {
                    this.setState({
                        open_create_patient:
                            this.props.state.open_create_patient,
                        patient_filter: this.props.state.patient_filter,
                        view_patient: this.props.state.view_patient,
                        patientIsNegative: this.props.state.patientIsNegative,
                        tempPatientFilter: this.props.state.tempPatientFilter,
                        patient_filter_display:
                            this.props.state.patient_filter_display,
                    });
                    this.props.updateState({ closeFunction: false });
                    this.props.toggleRecentDocFun();
                }
        }
    };

    getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (!this.props.ddl.hasOwnProperty('branch')) {
            getDropDownDataFromApi(fieldData.branch).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }
        if (!this.props.ddl.hasOwnProperty('serviceline')) {
            getDropDownDataFromApi(fieldData.service_line).then((data) => {
                if (data.values) {
                    this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }
    }

    updateState = (state) => {
        this.props.updateState(state);
    };

    configuredRecordsets = async () => {
        let configuredRecordsets = [];
        let patientFieldIdArray;
        let modifyPatientFieldIdArray;
        let patientRecordsetId;
        let modifyPatientRecordsetId;
        let patientModifiableRecordsetId;
        let modifyPatientModifiableRecordsetId;
        let episodeFieldIdArray;
        let episodeRecordsetId;

        configuredRecordsets = this.props.configuredRecordsets;

        if (configuredRecordsets !== undefined) {
            configuredRecordsets.map((data) => {
                if (formatHeaderNameWithOutSpace(data.name) === 'patientdata') {
                    patientFieldIdArray = data.fields;
                    patientRecordsetId = data.recordsetId;
                    patientModifiableRecordsetId = data.modifiableRecordsetId;
                }
                if (
                    formatHeaderNameWithOutSpace(data.name) ===
                    'patientdatamodify'
                ) {
                    modifyPatientFieldIdArray = data.fields;
                    modifyPatientRecordsetId = data.recordsetId;
                    modifyPatientModifiableRecordsetId =
                        data.modifiableRecordsetId;
                }
                if (formatHeaderNameWithOutSpace(data.name) === 'episodedata') {
                    episodeFieldIdArray = data.fields;
                    episodeRecordsetId = data.recordsetId;
                }
                return data;
            });
        }

        this.setState({
            patientModifiableRecordsetId: patientModifiableRecordsetId,
            patientFieldIdArray: patientFieldIdArray,
            patientRecordsetId: patientRecordsetId,
            modifyPatientFieldIdArray: modifyPatientFieldIdArray,
            modifyPatientRecordsetId: modifyPatientRecordsetId,
            modifyPatientModifiableRecordsetId:
                modifyPatientModifiableRecordsetId,
            edit_details: false,
            episodeFieldIdArray: episodeFieldIdArray,
            episodeRecordsetId: episodeRecordsetId,
        });
    };

    closePatientDropdown = (event) => {
        if (event.target.id !== 'patient_inputbox') {
            const popElement =
                document.getElementsByClassName('patient_dropdown');
            let popEl;
            for (let i = 0; i < popElement.length; i++) {
                popEl = popElement[i];
                const isClickInside = popEl.contains(event.target);
                if (!isClickInside) {
                    this.setState({
                        open_patient_dropdown: false,
                        patient_filter: '',
                        order_filter: '',
                        patient_filter_display: '',
                    });
                    return;
                }
            }
        }
    };

    byPatientOnChangeHandler = async (e) => {
        this.setState({
            patient_filter_display: e.target.value,
        });
        this.debounceFunc(e.target.value);
    };

    patientAPICall = async (text) => {
        const currentValue = text.split('');

        if (currentValue.length <= 0) {
            this.setState({
                open_patient_dropdown: false,
                patient_filter: { patientname: text },
            });
            return true;
        }

        this.setState({
            open_patient_dropdown: false,
            patient_filter: { patientname: text },
        });

        let searchText = '';

        let fieldId = GetFieldData({
            configData: this.props.configData,
        });

        searchText = `fieldSearch=${
            fieldId[this.state.searchByTypeOption]
        }%3D${encodeParameter(text)}%2A`;

        this.setState({
            autoCompleteSpinner: true,
        });

        this.setState({
            open_patient_dropdown: true,
            patient_list: [],
        });

        this.fetchPatientsFromAPI(searchText);
    };

    fetchPatientsFromAPI = async (searchText) => {
        let status;
        let recordSearchId = this.props.physicianSignatureRecordSetId;

        if (!recordSearchId) {
            return null;
        }

        await recordSetSearch(recordSearchId, searchText)
            .then((response) => {
                if (response !== undefined && response.status !== undefined) {
                    status = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                if (status === 200) {
                    const searchResult = PatientWithUnderScore(data);
                    searchResult.reduce(function (r, a, c) {
                        r[a.patient_id] = r[a.patient_id] || [];
                        r[a.patient_id].push(a);
                        return r;
                    }, Object.create(null));

                    this.setState({
                        patient_list: searchResult,
                        autoCompleteSpinner: false,
                    });
                }
            });
    };

    PatientList = () => {
        if (this.state.patient_list.length > 0) {
            return this.state.patient_list.map((search, index) => {
                if (!search) return;

                let res = search.ordering_physician;

                const req = [this.state.patient_filter_display];

                if (req) {
                    res = res.replace(
                        new RegExp(`(${req.join('|')})`, 'i'),
                        (match) => `<b class='highlight'>${match}</b>`
                    );
                }

                const custom_name = `${res}`;

                return (
                    <React.Fragment key={index}>
                        <li onClick={() => this.pickPatientFromTheList(search)}>
                            <div className='patient_autocomplete'>
                                <div className='patient_no_name'>
                                    <span
                                        className='first_name'
                                        dangerouslySetInnerHTML={{
                                            __html: custom_name,
                                        }}
                                    />
                                </div>
                                <div className='filter-date text-right'>
                                    <div className='patient_status'>
                                        <span>NPI :</span>
                                        <span className='status'>
                                            {search.npi}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                );
            });
        } else {
            return <div style={{ textAlign: 'center' }}>No Records Found</div>;
        }
    };

    pickPatientFromTheList = async (search) => {
        this.props.setPhysicianSignatureNPI(search.npi);
        this.props.setPhysicianSignatureOrderingPhysician(
            search.ordering_physician
        );
        this.props.setPhysicianSignatureCity(search.city || '');
        this.props.setPhysicianSignatureFileName(search.file_name || '');
        this.props.setPhysicianSignatureState(search.state || '');

        let state = {
            patient_filter: search,
            tempPatientFilter: search,
            open_patient_dropdown: false,
            view_patient: false,
            selected_patient_id: search.patientid,
            open_create_patient: false,
            create_patient: false,
        };

        if (
            search.hasOwnProperty('physician_first_name') &&
            search.hasOwnProperty('physician_last_name')
        ) {
            state.patient_filter_display = `${search.physician_last_name}, ${search.physician_first_name}`;
        }

        this.setState({
            patient_filter: { patientname: state.patient_filter_display },
            patient_filter_display: state.patient_filter_display,
        });

        this.setState(state, () => {
            this.props.updateState(state);
        });

        this.setState({ view_patient: true }, () => {
            this.props.updateState({
                view_patient: true,
                toggleRecentDoc: true,
                togglePatient: true,
            });
        });
    };

    clearInputBox = () => {
        this.setState({
            patient_filter_display: '',
        });
        if (this.textInput.current) {
            this.textInput.current.focus();
        }
    };

    render() {
        return (
            <>
                <div className='detail_search mb-4'>
                    <label
                        className='d-block pl-5 mb-2'
                        style={{ fontWeight: 400, fontSize: '10px' }}
                    >
                        LOOKUP OPTIONS
                    </label>
                    <div className='mb-4 mt-2'>
                        <div className='tab_part'>
                            <div className='tabs'>
                                <Nav
                                    id='noanim-tab'
                                    defaultActiveKey={
                                        this.state.searchByTypeOption
                                    }
                                    variant='pills'
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            title='By Physician Name'
                                            key={0}
                                            eventKey='ordering_physician'
                                            disabled={this.props.isLocked}
                                            onClick={() => {
                                                this.setState({
                                                    searchByTypeOption:
                                                        'ordering_physician',
                                                });
                                            }}
                                        >
                                            By Physician Name
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            title='By NPI Number'
                                            key={1}
                                            eventKey='npi'
                                            disabled={this.props.isLocked}
                                            onClick={() => {
                                                this.setState({
                                                    searchByTypeOption: 'npi',
                                                });
                                            }}
                                        >
                                            By NPI Number
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <label
                        className='d-block pl-5 mb-2'
                        style={{ fontWeight: 400, fontSize: '10px' }}
                    >
                        LOOKUP
                    </label>
                    <div className='d-flex'>
                        <div className='filter'>
                            <input
                                className='form-control'
                                value={this.state.patient_filter_display}
                                placeholder={
                                    this.state.searchByTypeOption ===
                                    'physician_last_name'
                                        ? 'Type to search by Physician Name'
                                        : 'Type to search by NPI Number'
                                }
                                type='text'
                                onChange={this.byPatientOnChangeHandler.bind(
                                    this
                                )}
                                ref={this.textInput}
                                id='patient_inputbox'
                                disabled={this.props.isLocked}
                            />
                            {this.state.patient_filter_display &&
                            this.state.patient_filter_display.length > 0 ? (
                                <i
                                    className='fa-regular fa-xmark'
                                    onClick={this.clearInputBox}
                                />
                            ) : (
                                <i className='fa-regular fa-magnifying-glass' />
                            )}
                            {this.state.open_patient_dropdown ? (
                                <div
                                    className='no_create patient_dropdown'
                                    style={{
                                        height:
                                            this.state.patient_list.length > 0
                                                ? '248px'
                                                : '80px',
                                    }}
                                >
                                    <ul
                                        style={{
                                            listStyleType: 'none',
                                            overflowY: 'scroll',
                                            height:
                                                this.state.patient_list.length >
                                                0
                                                    ? '240px'
                                                    : '',
                                        }}
                                    >
                                        {this.state.autoCompleteSpinner ? (
                                            <div
                                                style={{ textAlign: 'center' }}
                                            >
                                                <br />
                                                <Spinner
                                                    animation='grow'
                                                    variant='primary'
                                                />
                                            </div>
                                        ) : (
                                            this.PatientList()
                                        )}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    inboxPage,
    getDDLlist: getDDLlist,
};
const mapStateToProps = (state) => {
    return {
        ddl: state.persistDDL.DDL,
        rulesJson: state.configReducerPersist.configData,
        selectedRowDetails: state.inbox.selectedRowDetails,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ByPhysicianSignatureNPI));
