/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Dropdown } from 'react-bootstrap';
import { Notes } from './Notes';
import {
    stringEmptyOrUndefined,
    GetFieldData,
    formatNameWithSpaceToUnderscore,
    showSuccessToast,
    formatPathName,
    getNotes,
} from '../../../components/commonfunction';
import { updateSearchState } from '../../_redux/actions/searchActions';
import { globalDocumentAction } from '../../api/api';
import { OwnerAutocomplete } from './OwnerAutocomplete';
import RelatedDocument from './RelatedDocument';
import DeliveryDetailModal from './DeliveryDetailModal';
import { showLoader, getListaction } from '../../_redux/actions/inboxActions';
import { outboxPage } from '../../_redux/actions/outboxActions';
import { ViewAllNotes } from './ViewAllNotes';
import OutboxActionsComponent from './OutboxActionsComponent';
import { AddEditTag } from '../../../components/commonAction/AddEditTag';
import LockBar from '../../../components/LockBar';

class OutboxSmallView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_add_notes: '',
            notes_details: 1,
            notes_list: [],
            printElement: undefined,
            toggleRecentDoc: false,
            detailBigView:
                this.props.detailView !== undefined
                    ? this.props.detailView
                    : false,
            selectedDoc: [],
            openNote: false,
            docIds: [],
            docSpinner: false,
            rowDetails: this.props.rowDetails,
            ownerlist: [],
            showOwnerDropDown: false,
            tagData: [],
            tagCount: 0,
            openTag: false,
            docId: undefined,
            more_information: false,
            printSelectedDocId: [],
            viewAllNotes: false,
            noteCount: 0,
            viewNotes: false,
            show_owner: false,
            show_tag: false,
            processId: null,
            statusId: null,
            statusArray: [],
            editId: '',
            detailPage:
                this.props.detailView !== undefined
                    ? this.props.detailView
                    : false,
            viewAllOpen: false,
            viewAllClose: true,
            viewNoteValue: [],
            resetCount: false,
            allActions: this.props.allActions,
            open_cover_details_section: false,
            open_delivery_detail: false,
            open_delivery_detail_section: false,
            isLocked:
                this.props.calingFrom !== 'deleted_document' &&
                this.props.callingFrom !== 'related_document' &&
                this.props.outbox.selectedRowDetails !== undefined &&
                this.props.outbox.selectedRowDetails.hasOwnProperty(
                    'miscPproperties'
                )
                    ? this.props.outbox.selectedRowDetails.miscPproperties
                          .isLocked
                    : this.props.calingFrom === 'deleted_document' &&
                      this.props.is_lockedSearch !== undefined
                    ? this.props.is_lockedSearch
                    : false,
            relatedDocRefresh: true,
            tagReferesh: true,
            ownerRefresh: true,
            ownerColor: JSON.parse(localStorage.getItem('owner_bg_color')),
            noteTypes: undefined,
        };
        this.handleOwnerRef = React.createRef();
        this.onInitialActions = this.onInitialActions.bind(this);
        this.handleOwnerName = this.handleOwnerName.bind(this);
        this.handleOwnerHide = this.handleOwnerHide.bind(this);
        this.handleOwnerNameReset = this.handleOwnerNameReset.bind(this);
        this.handleTagOpen = this.handleTagOpen.bind(this);
        this.handleTagClose = this.handleTagClose.bind(this);
    }
    handleOwnerHide() {
        this.setState({
            showOwnerDropDown: false,
        });
    }
    async handleOwnerNameReset(name) {
        if (name === '') {
            name = null;
        }

        await this.setState({
            ownerRefresh: false,
        });
        await this.props.outbox.stateData.rowData.forEach((data, index) => {
            if (data.doc_id === this.state.rowDetails.doc_id) {
                data.owner_rawdata = name;
            }
        });

        this.setState({
            ownerRefresh: true,
        });

        /* this.setState({
            rowDetails: {
                ...this.state.rowDetails, owner_rawdata: name
            },
            showOwnerDropDown: false,
        }); */
    }

    onInitialActions() {
        let tagData = [];
        let tagCount = 0;
        let doc_id = 0;
        let rowDetails = {};
        const processId = this.props.processId;
        const statusId = this.props.statusId;

        if (
            this.props.outbox.stateData.rowDetails !== undefined ||
            (this.props.search.deletedPage !== undefined &&
                this.props.search.deletedPage.rowDetails !== undefined)
        ) {
            let tags_data;

            if (this.props.outbox.stateData.rowDetails !== undefined) {
                rowDetails = this.props.outbox.stateData.rowDetails;
                doc_id = this.props.outbox.stateData.rowDetails.doc_id;

                if (
                    this.props.outbox.stateData !== undefined &&
                    this.props.outbox.stateData.rowData !== undefined
                ) {
                    this.props.outbox.stateData.rowData.forEach(
                        (data, index) => {
                            if (
                                data.doc_id ===
                                this.props.outbox.stateData.rowDetails.doc_id
                            ) {
                                tags_data = data.tags_data;
                            }
                        }
                    );
                } else {
                    //comment by geetha
                    //this.props.outbox.outboxpage.stateData.rowData.forEach(

                    this.props.outbox.stateData.rowData.forEach(
                        (data, index) => {
                            if (
                                data.doc_id ===
                                this.props.outbox.stateData.rowDetails.doc_id
                            ) {
                                tags_data = data.tags_data;
                            }
                        }
                    );
                }
            } else if (this.props.search.deletedPage.rowDetails !== undefined) {
                rowDetails = this.props.search.deletedPage.rowDetails;
                doc_id = this.props.search.deletedPage.rowDetails.doc_id;
                this.props.search.deletedPage.rowData.forEach((data, index) => {
                    if (data.doc_id === doc_id) {
                        tags_data = data.tags_data;
                    }
                });
            }

            if (
                tags_data !== undefined &&
                tags_data !== '' &&
                tags_data !== '|'
            ) {
                //tagData = tags_data.split('|');
                //tagCount = tagData.length;

                var lastChar = tags_data.substr(tags_data.length - 1);
                if (String(lastChar) === '|') {
                    tags_data = tags_data.slice(0, -1); //'abcde'
                }

                tagData = tags_data.split('|');

                tagCount = tagData.length;
            }
        }
        let ownerlist = [];
        if (this.props.ownerlist !== undefined) {
            ownerlist = this.props.ownerlist;
        }
        // let permission = userPermission({
        //     processId: this.props.processId,
        //     statusId: this.props.statusId,
        //     menu_name: this.props.currentMenuName,
        //     configData: this.props.configData,
        // });
        //permission = { ...permission, show_pdf: true, show_notes: true }; // default true
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ ownerFieldId: fieldDataObj.ownerFieldId });
        if (this.state.resetCount === false) {
            this.setState({ tagCount });
        }
        if (this.props.calingFrom === 'smallView') {
            if (
                this.props.outbox.selectedRowDetails !== undefined &&
                this.props.outbox.selectedRowDetails.hasOwnProperty(
                    'miscPproperties'
                )
            ) {
                this.setState({
                    isLocked:
                        this.props.outbox.selectedRowDetails.miscPproperties
                            .isLocked,
                });
            }
        }

        this.setState({
            rowDetails,
            ownerlist,
            tagData,
            docId: doc_id,
            processId,
            statusId,
            outboxActionsComponentRefresh: true,
        });
    }

    handleTagOpen() {
        this.setState({
            openTag: !this.state.openTag,
            showOwnerDropDown: false,
        });
    }

    resetCount = (count) => {
        this.setState({
            tagCount: count,
            resetCount: true,
        });
    };

    handleTagClose() {
        this.setState({
            openTag: false,
        });
    }

    formatName(name) {
        let name_array;
        let displayName;
        if (name !== '' && name !== undefined && name !== null) {
            name_array = name.split(' ');
            if (name_array.length > 1) {
                displayName = `${name_array[0]
                    .charAt(0)
                    .toUpperCase()}${name_array[1].charAt(0).toUpperCase()}`;
            } else {
                const username = name.substring(0, 3);

                if (username === 'WV-') {
                    // displayName = `${name_array[0].substring(3,2).toUpperCase()}`;
                    displayName = `${name[3].toUpperCase()}${name[4].toUpperCase()}`;
                    /* displayName = `${name_array[0]
                        .charAt(0)
                        .toUpperCase()}${name_array[0].charAt(1).toUpperCase()}`; */
                } else {
                    displayName = `${name_array[0]
                        .charAt(0)
                        .toUpperCase()}${name_array[0]
                        .charAt(1)
                        .toUpperCase()}`;
                }
            }
        }

        /* const nameArray = name.split(' ');
        const val = [];
        nameArray.forEach((v) => {
            if (nameArray.length === 1) {
                val.push(v.slice(0, 2).toUpperCase());
            } else {
                val.push(v.charAt(0).toUpperCase());
            }
        });
        const finalName = [];
        if (val.length > 0) {
            finalName.push(val[0]);
            finalName.push(val[1]);
        } */

        return displayName;
    }

    doCollapse = () => {
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        // const elements = document.querySelectorAll('.rdg-row');
        // for (let i = 0; i < elements.length; i++) {}
        this.setState({ row_transition: false });
        if (this.props.calingFrom !== 'deleted_document') {
            this.props.rowTransition();
        }
        if (this.props.calingFrom === 'deleted_document') {
            this.props.deletedPage({
                row_transition: false,
            });
            this.updateState({
                editDetailsOutbox: false,
            });
        }
        if (isMobile) {
            document.body.classList.remove('small-view-display');
            this.props.updateState({
                check: true,
            });
        }
        setTimeout(() => {
            const checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
        this.props.outboxPage({
            docOpen: false,
            // finalTags: [],
        });
    };

    doExpand = () => {
        this.setState({
            tagReferesh: false,
            resetCount: false,
        });
        document.body.classList.remove('doc-small-view');
        document.body.classList.add('doc-detail-view');
        const checkBox = document.getElementsByClassName('rdg-checkbox-input');
        if (checkBox !== null) {
            for (let i = 0; i < checkBox.length; i++) {
                checkBox[i].disabled = true;
            }
        }
        this.props.openBigView();
        this.setState({
            detailBigView: true,
            listingView: false,
        });
        setTimeout(() => {
            this.setState({
                tagReferesh: true,
            });
        }, 20);

        if (isMobile) {
            document.body.classList.remove('small-view-display');
            //if(this.props.mobileHide !== undefined){
            this.props.updateState({
                mobileHide: true,
                mobileTab: true,
            });
            // }
        }
    };

    edit_details = () => {
        this.props.editDetails();
        this.doExpand();
    };

    viewAllNotes = async () => {
        await this.setState({ viewAllNotes: !this.state.viewAllNotes });
    };

    componentDidMount() {
        this.onInitialActions();
        if (this.props.nextDocument === true) {
            this.props.resetNextDoc();
            this.doExpand();
        }
        this.setState({
            backURL: this.props.location.pathname,
        });
        const noteTypes = getNotes(
            this.props.configData,
            this.props.processId,
            this.props.statusId
        );

        this.setState({ noteTypes: noteTypes });
    }
    //    componentWillUnmount(){
    //     window.onbeforeunload = function () {

    //     }
    //    }

    async componentDidUpdate(prevProps, prevState) {
        if (String(prevProps.stateToken) !== String(this.props.stateToken)) {
            this.onInitialActions();
        }
        if (prevState.noteCount !== this.state.noteCount) {
            if (this.state.noteCount !== 0) {
                this.setState({
                    viewNotes: false,
                });
                setTimeout(() => {
                    this.setState({
                        viewNotes: true,
                    });
                }, 100);
            } else {
                this.setState({
                    viewNotes: false,
                });
            }
        }
        if (
            String(prevState.selectedDocId) !== String(this.props.selectedDocId)
        ) {
            await this.setState(
                {
                    open_cover_details_section: false,
                    open_delivery_detail: false,
                    open_delivery_detail_section: false,
                    open_notes_section: false,
                    relatedDocRefresh: false,
                    printSelectedDocId: [],
                    toggleRecentDoc: false,
                    selectedDoc: [],
                    selectedDocId: this.props.selectedDocId,
                    outboxActionsComponentRefresh: false,
                    noteCount:
                        this.props.outbox.selectedRowDetails.hasOwnProperty(
                            'miscPproperties'
                        )
                            ? this.props.outbox.selectedRowDetails
                                  .miscPproperties.noteCount
                            : '',
                },
                () => {
                    this.onInitialActions();
                }
            );
            await this.setState({
                relatedDocRefresh: true,
            });
        }
    }

    async download() {
        await this.props.download();
    }

    collapseBigView = () => {
        document.body.classList.add('doc-small-view');
        this.setState(
            {
                detailBigView: false,
                open_edit_details: false,
            },
            () => {
                this.props.closeBigView();
                if (isMobile) {
                    document.body.classList.remove('doc-detail-view');
                    this.props.updateState({
                        mobileHide: false,
                        mobileTab: false,
                    });
                }
            }
        );
        this.props.updateState({
            open_edit_details: false,
        });
        this.updateState({
            editDetailsOutbox: false,
        });
        this.props.outboxPage({
            docOpen: true,
        });
    };

    updateState = (state) => {
        this.props.updateState(state);
        this.setState({ ...state });
    };

    recoverDoc = async (type) => {
        this.props.deletedPage({ blurSpinner: true });
        var selectedDocId = [];
        var actionId = {};
        var messageText = '';
        if (type === 'single') {
            selectedDocId.push(this.props.selectedDocId);
        }
        if (
            this.props.globalActions !== undefined &&
            this.props.globalActions.length > 0
        ) {
            this.props.globalActions.map((actions) => {
                if (
                    formatNameWithSpaceToUnderscore(actions.name) === 'undelete'
                ) {
                    actionId = actions.id;
                    messageText = 'Recovered';
                } else if (
                    formatNameWithSpaceToUnderscore(actions.name) ===
                    'resume_tracking'
                ) {
                    actionId = actions.id;
                    messageText = 'Resumed';
                } else {
                    actionId = actions.id;
                    messageText = actions.name;
                }
            });
        }
        var params = {};
        params.actionId = actionId;
        params.fields = {
            docIds: selectedDocId,
            fields: [],
        };
        await globalDocumentAction(params)
            .then((response) => {
                this.props.deletedPage({
                    blurSpinner: false,
                    refreshTable: true,
                    row_transition: false,
                    deleteBigView: false,
                });
                this.props.updateSearchState({
                    deletedPage: {
                        ...this.props.deleted_page,
                        deleteBigView: false,
                        row_transition: false,
                        blurSpinner: false,
                    },
                });
                this.props.updateState({
                    deleteBigView: false,
                });
                if (response.status === 204 || response.status === 200) {
                    showSuccessToast(messageText + ' Successfully');
                    return true;
                }
                return response.json();
            })
            .then((data) => {});
    };

    handleOwnerName() {
        let name = '';
        let titleName = '';
        let bg_color = '#8B36BF';
        if (
            this.props.outbox.stateData !== undefined &&
            this.props.outbox.stateData.rowData !== undefined
        ) {
            this.props.outbox.stateData.rowData.forEach((data, index) => {
                if (
                    data.doc_id ===
                    this.props.outbox.stateData.rowDetails.doc_id
                ) {
                    name = data.owner_rawdata;
                    titleName = data.owner_rawdata;
                }
            });
        } else {
            //comment by geetha
            //this.props.outbox.outboxpage.stateData.rowData.forEach(

            this.props.outbox.stateData.rowData.forEach((data, index) => {
                if (
                    data.doc_id ===
                    this.props.outbox.stateData.rowDetails.doc_id
                ) {
                    name = data.owner_rawdata;
                    titleName = data.owner_rawdata;
                }
            });
        }

        // let ownerColor = [];
        // ownerColor = JSON.parse(localStorage.getItem('owner_bg_color'));

        let ownerColor = [];
        if (localStorage.getItem('owner_bg_color_outbox')) {
            ownerColor = JSON.parse(
                localStorage.getItem('owner_bg_color_outbox')
            );
        }

        //This is handled for If name is not in the list then we will Show it
        if (name === undefined || String(name).trim() === '') {
            let ownerId = '';
            const fieldDataObj = GetFieldData({
                configData: this.props.configData,
            });
            if (this.props.outbox.selectedRowDetails.hasOwnProperty('fields')) {
                this.props.outbox.selectedRowDetails.fields.map((data) => {
                    if (data.fieldId && data.fieldId === fieldDataObj.owner) {
                        ownerId = data.value;
                    }
                });
            }
            if (ownerColor !== null && Array.isArray(ownerColor)) {
                ownerColor.forEach((owner) => {
                    if (owner.ownerId === ownerId) {
                        name = owner.ownerName;
                    }
                });
            }
        }

        if (ownerColor !== null && Array.isArray(ownerColor)) {
            ownerColor.forEach((owner) => {
                if (owner.ownerName === name) {
                    bg_color = owner.bg_color;
                }
            });
        }

        /*  if (this.state.isLocked === true) {
            return (
                <>
                    <button
                        type='button'
                        id='owner-display-name'
                        className='no-user-icon owner-logo'
                        ref={this.handleOwnerRef}
                        style={{ cursor: 'not-allowed' }}
                    >
                        <i
                            className={
                                this.state.allActions.hasOwnProperty(
                                    'assign_to_user'
                                ) &&
                                this.state.allActions.assign_to_user.icon !==
                                    null
                                    ? this.state.allActions.assign_to_user.icon
                                    : 'fas fa-user-plus'
                            }
                            style={{
                                color:
                                    this.state.allActions.hasOwnProperty(
                                        'assign_to_user'
                                    ) &&
                                    this.state.allActions.assign_to_user
                                        .color !== null
                                        ? this.state.allActions.assign_to_user
                                              .color
                                        : '',
                            }}
                        />
                    </button>
                </>
            );
        } */

        if (name === undefined || String(name).trim() === '' || name === null) {
            return (
                <>
                    <button
                        type='button'
                        id='owner-display-name'
                        className='no-user-icon owner-logo'
                        ref={this.handleOwnerRef}
                        title={titleName}
                        disabled={
                            this.props.callingFrom === 'related_document' ||
                            this.state.isLocked
                        }
                        style={
                            this.props.callingFrom === 'related_document' ||
                            this.state.isLocked
                                ? { cursor: 'not-allowed' }
                                : {}
                        }
                        onClick={this.handleOwnerFocus.bind(this)}
                    >
                        <i
                            className={
                                this.state.allActions.hasOwnProperty(
                                    'assign_to_user'
                                ) &&
                                this.state.allActions.assign_to_user.icon !==
                                    null
                                    ? this.state.allActions.assign_to_user.icon
                                    : 'fas fa-user-plus'
                            }
                            style={{
                                color:
                                    this.state.allActions.hasOwnProperty(
                                        'assign_to_user'
                                    ) &&
                                    this.state.allActions.assign_to_user
                                        .color !== null
                                        ? this.state.allActions.assign_to_user
                                              .color
                                        : '',
                            }}
                        />
                    </button>
                </>
            );
        }
        name = this.formatName(name);

        return (
            <>
                <button
                    type='button'
                    id='owner-display-name'
                    style={{
                        backgroundColor: bg_color,
                        /* cursor:
                            this.props.callingFrom === 'related_document' || this.state.isLocked
                                ? 'not-allowed'
                                : 'allowed', */
                    }}
                    className='username'
                    ref={this.handleOwnerRef}
                    title={titleName}
                    onClick={
                        this.props.callingFrom !== 'related_document'
                            ? this.handleOwnerFocus.bind(this)
                            : {}
                    }
                    disabled={this.state.isLocked}
                >
                    {name}
                </button>
            </>
        );
    }

    handleUnlock = () => {
        this.setState({ isLocked: false });
        this.props.onUnlock();
    };

    handleOwnerFocus(event) {
        this.setState(
            {
                showOwnerDropDown: !this.state.showOwnerDropDown,
                openTag: false,
            },
            () => {
                if (
                    isMobile &&
                    document.getElementsByClassName('owner-autocomplete')
                ) {
                    const el =
                        document.getElementsByClassName(
                            'owner-autocomplete'
                        )[0];
                    el.style.left = '71.48438px';
                    el.style.top = '50px';
                }
            }
        );
    }
    render() {
        if (isMobile) {
            setTimeout(() => {
                if (
                    document.getElementById('left-aside') !== null &&
                    this.state.isLocked === false
                ) {
                    document
                        .getElementById('left-aside')
                        .classList.add('no-lock-section');
                } else if (
                    document.getElementById('left-aside') !== null &&
                    this.state.isLocked === true
                ) {
                    document
                        .getElementById('left-aside')
                        .classList.remove('no-lock-section');
                }
            });
        }
        // const getPrintElement = (ele) => {
        //     if (this.state.printElement === undefined) {
        //         // Avoid Multiple Rerendering
        //         this.setState({
        //             printElement: ele,
        //         });
        //     }
        // };
        const handleReset = () => {
            this.setState({
                openNote: false,
            });
        };
        const toggleSpinner = () => {
            this.setState({
                docSpinner: !this.state.docSpinner,
            });
            this.props.enableLoader(!this.state.docSpinner);
        };
        let position = { left: 0, top: 0 };

        const path_name = window.location.pathname;
        const pathname = formatPathName(path_name);

        if (
            this.handleOwnerRef.current !== null &&
            this.state.detailPage === false
        ) {
            const pos = this.handleOwnerRef.current.getBoundingClientRect();
            position = { left: pos.left - 150, top: pos.top - 50 };
        }
        if (
            this.handleOwnerRef.current !== null &&
            this.state.detailPage === true
        ) {
            const pos = this.handleOwnerRef.current.getBoundingClientRect();
            position = { left: pos.left, top: pos.top - 60 };
        }

        if (
            this.handleOwnerRef.current !== null &&
            (pathname === 'search-patients' || pathname === 'search-physicians')
        ) {
            const pos = this.handleOwnerRef.current.getBoundingClientRect();
            position = { left: pos.left - 150, top: pos.top - 180 };
        }
        const goToFullScreen = () => {
            this.doExpand();
            setTimeout(() => {
                if (document.getElementById('viewAllNotes') !== null) {
                    document.getElementById('viewAllNotes').scrollIntoView();
                }
            }, 20);
        };
        return (
            <>
                <div
                    className={`toast-container ${this.state.toast_type}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '25%',
                    }}
                />
                {/* Details Top menu Sections */}
                {this.state.isLocked === true ? (
                    <LockBar
                        docId={this.props.selectedDocId}
                        isCalledFromGrid={false}
                        onUnlock={this.handleUnlock}
                    />
                ) : null}
                {this.state.detailBigView === true ? (
                    <div
                        className={
                            this.state.isLocked === true
                                ? 'd-flex align-items-center justify-content-between mt-5 mb-5'
                                : 'd-flex align-items-center justify-content-between mb-5'
                        }
                    >
                        {this.props.detailView === undefined ? (
                            <div>
                                <div
                                    className='left-pdf-button'
                                    style={{ display: 'inline-block' }}
                                >
                                    <button
                                        type='button'
                                        title='Collapse'
                                        onClick={this.collapseBigView}
                                    >
                                        <i className='fas fa-arrow-to-right' />
                                        Collapse
                                    </button>
                                </div>
                                {this.props.location.pathname.includes(
                                    'search'
                                ) !== true && (
                                    <>
                                        {this.state.rowDetails !== undefined &&
                                        this.state.ownerRefresh &&
                                        this.state?.allActions?.hasOwnProperty(
                                            'assign_to_user'
                                        )
                                            ? this.handleOwnerName()
                                            : null}
                                        {this.state.showOwnerDropDown &&
                                        this.state?.allActions?.hasOwnProperty(
                                            'assign_to_user'
                                        ) ? (
                                            <div
                                                style={{
                                                    ...{
                                                        padding: '9px',
                                                        borderRadius: '10px',
                                                        border: '1px solid #00233A',
                                                        width: '230px',
                                                        background: '#fff',
                                                        position: 'absolute',
                                                        zIndex: 1,
                                                    },
                                                    ...position,
                                                }}
                                                className='owner-autocomplete'
                                            >
                                                <OwnerAutocomplete
                                                    ownerlist={
                                                        this.state.ownerlist
                                                    }
                                                    ownerValue={
                                                        this.state.rowDetails
                                                            .owner_rawdata
                                                    }
                                                    handleHide={
                                                        this.handleOwnerHide
                                                    }
                                                    docId={
                                                        this.props.selectedDocId
                                                    }
                                                    handleOwnerNameReset={
                                                        this
                                                            .handleOwnerNameReset
                                                    }
                                                    actionId={
                                                        this.state.allActions
                                                            .assign_to_user
                                                            .actionId
                                                    }
                                                    ownerFieldId={
                                                        this.state.ownerFieldId
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                )}

                                {this.props.location.pathname.includes(
                                    'search'
                                ) !== true && (
                                    <>
                                        {this.props.isLocked === true &&
                                        this.state?.allActions?.update_tag !==
                                            undefined ? (
                                            <span
                                                style={{
                                                    cursor: 'not-allowed',
                                                }}
                                                className={
                                                    this.state.tagCount > 0
                                                        ? 'details-tag contain-tag-value ml-3'
                                                        : 'details-tag ml-3'
                                                }
                                                id='details-tag'
                                                disabled
                                            >
                                                <span className='fatag active'>
                                                    <i
                                                        className={
                                                            this.state
                                                                .allActions !==
                                                                undefined &&
                                                            this.state.allActions.hasOwnProperty(
                                                                'update_tag'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .update_tag
                                                                .icon !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .update_tag
                                                                      .icon
                                                                : 'fas fa-tag aaaa'
                                                        }
                                                        style={{
                                                            color:
                                                                this.state
                                                                    .allActions !==
                                                                    undefined &&
                                                                this.state.allActions.hasOwnProperty(
                                                                    'update_tag'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .update_tag
                                                                    .color !==
                                                                    null
                                                                    ? this.state
                                                                          .allActions
                                                                          .update_tag
                                                                          .color
                                                                    : '',
                                                        }}
                                                    />
                                                </span>
                                                <span className='count'>
                                                    {this.state.tagData.length}{' '}
                                                </span>
                                            </span>
                                        ) : this.state.allActions !==
                                              undefined &&
                                          this.state.allActions.update_tag !==
                                              undefined ? (
                                            <Dropdown className='tag_dropdown d-inline-block'>
                                                <Dropdown.Toggle className='dropdown_btn'>
                                                    {this.props.detailView ===
                                                        undefined &&
                                                    this.state.tagReferesh ? (
                                                        <span
                                                            className={
                                                                this.state
                                                                    .tagCount >
                                                                0
                                                                    ? 'details-tag contain-tag-value'
                                                                    : 'details-tag'
                                                            }
                                                            title={this.state.tagData
                                                                .filter(
                                                                    (e) => e
                                                                )
                                                                .join(', ')}
                                                            id='details-tag'
                                                            onClick={() => {
                                                                this.handleTagOpen();
                                                            }}
                                                        >
                                                            <span
                                                                className={
                                                                    this.state.tagData.filter(
                                                                        (e) => e
                                                                    ).length > 0
                                                                        ? 'fatag active'
                                                                        : 'fatag'
                                                                }
                                                            >
                                                                <i
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .allActions !==
                                                                            undefined &&
                                                                        this.state.allActions.hasOwnProperty(
                                                                            'update_tag'
                                                                        ) &&
                                                                        this
                                                                            .state
                                                                            .allActions
                                                                            .update_tag
                                                                            .icon !==
                                                                            null
                                                                            ? this
                                                                                  .state
                                                                                  .allActions
                                                                                  .update_tag
                                                                                  .icon
                                                                            : 'fas fa-tag bbb'
                                                                    }
                                                                    style={{
                                                                        color:
                                                                            this
                                                                                .state
                                                                                .allActions !==
                                                                                undefined &&
                                                                            this.state.allActions.hasOwnProperty(
                                                                                'update_tag'
                                                                            ) &&
                                                                            this
                                                                                .state
                                                                                .allActions
                                                                                .update_tag
                                                                                .color !==
                                                                                null
                                                                                ? this
                                                                                      .state
                                                                                      .allActions
                                                                                      .update_tag
                                                                                      .color
                                                                                : '',
                                                                    }}
                                                                />
                                                            </span>
                                                            <span className='count'>
                                                                {this.state.tagData.filter(
                                                                    (e) => e
                                                                ).length > 0
                                                                    ? this.state.tagData.filter(
                                                                          (e) =>
                                                                              e
                                                                      ).length
                                                                    : null}
                                                            </span>
                                                        </span>
                                                    ) : null}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div>
                                                        <AddEditTag
                                                            selectedTag={
                                                                this.props
                                                                    .menuSelectedTags
                                                            }
                                                            page='outbox'
                                                            tagColorArray={
                                                                this.props
                                                                    .tagColorArray
                                                            }
                                                            selectedTagNameData={
                                                                this.state
                                                                    .tagData
                                                            }
                                                            docId={
                                                                this.props
                                                                    .selectedDocId
                                                            }
                                                            rowDetails={
                                                                this.props
                                                                    .outbox
                                                                    .stateData
                                                                    .rowDetails !==
                                                                undefined
                                                                    ? this.props
                                                                          .outbox
                                                                          .stateData
                                                                          .rowDetails
                                                                    : ''
                                                            }
                                                            resetCount={(c) =>
                                                                this.resetCount(
                                                                    c
                                                                )
                                                            }
                                                            updateTag={(
                                                                tag
                                                            ) => {
                                                                this.setState({
                                                                    tagData:
                                                                        tag,
                                                                });
                                                            }}
                                                            handleTagClose={
                                                                this
                                                                    .handleTagClose
                                                            }
                                                            refreshTag={
                                                                this.props
                                                                    .refreshTag
                                                            }
                                                            tagCount={
                                                                this.state
                                                                    .tagCount
                                                            }
                                                            updateState={(
                                                                state
                                                            ) => {
                                                                this.setState({
                                                                    ...state,
                                                                });
                                                                this.props.updateState(
                                                                    state
                                                                );
                                                            }}
                                                            dataGridReferesh={
                                                                this.props
                                                                    .dataGridReferesh
                                                            }
                                                        />
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : this.props.detailView ===
                                              undefined &&
                                          this.state.allActions !== undefined &&
                                          !this.state.allActions.hasOwnProperty(
                                              'update_tag'
                                          ) ? (
                                            <span
                                                className={
                                                    this.state.tagCount > 0
                                                        ? 'details-tag contain-tag-value'
                                                        : 'details-tag'
                                                }
                                                id='details-tag'
                                                style={{
                                                    cursor: 'not-allowed',
                                                }}
                                            >
                                                <span
                                                    className={
                                                        this.state.tagCount > 0
                                                            ? 'fatag active'
                                                            : 'fatag'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            this.state
                                                                .allActions !==
                                                                undefined &&
                                                            this.state.allActions.hasOwnProperty(
                                                                'update_tag'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .update_tag
                                                                .icon !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .update_tag
                                                                      .icon
                                                                : 'fas fa-tag'
                                                        }
                                                        style={{
                                                            color:
                                                                this.state
                                                                    .allActions !==
                                                                    undefined &&
                                                                this.state.allActions.hasOwnProperty(
                                                                    'update_tag'
                                                                ) &&
                                                                this.state
                                                                    .allActions
                                                                    .update_tag
                                                                    .color !==
                                                                    null
                                                                    ? this.state
                                                                          .allActions
                                                                          .update_tag
                                                                          .color
                                                                    : '',
                                                        }}
                                                    />
                                                </span>
                                                <span className='count'>
                                                    {this.state.tagData.length}{' '}
                                                </span>
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        ) : null}
                        {this.props.globalActions !== undefined ? (
                            <div className='right-function'>
                                <Button
                                    title='Resume Tracking'
                                    className='track_resume'
                                    onClick={() => {
                                        this.recoverDoc('single');
                                    }}
                                    disabled={this.state.isLocked}
                                >
                                    Resume Tracking
                                </Button>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {this.state.detailBigView === false ? (
                    <div>
                        <div
                            className={
                                this.state.isLocked === true
                                    ? 'pdf-detail d-flex flex-wrap align-items-stretch justify-content-between locked-screen'
                                    : 'pdf-detail d-flex flex-wrap align-items-stretch justify-content-between'
                            }
                        >
                            <div className='left-expand left-ex-btn'>
                                <div
                                    className='ml-0 left-pdf-button'
                                    onClick={this.doExpand}
                                >
                                    <span title='Expand'>
                                        <i className='fas fa-arrow-to-left' />
                                        Expand
                                    </span>
                                </div>
                                <div
                                    className='left-pdf-button'
                                    onClick={this.doCollapse}
                                >
                                    <span title='Collapse'>
                                        <i className='fas fa-arrow-to-right' />
                                        Collapse
                                    </span>
                                </div>
                            </div>

                            <div
                                className={
                                    this.state.isLocked === true &&
                                    this.props.calingFrom ===
                                        'deleted_document' &&
                                    String(
                                        this.props.location.pathname
                                    ).includes('outbound-documents') === true
                                        ? 'right-function action-locked outbound_search'
                                        : this.state.isLocked === true
                                        ? 'right-function action-locked'
                                        : this.props.calingFrom ===
                                              'deleted_document' &&
                                          String(
                                              this.props.location.pathname
                                          ).includes('outbound-documents') ===
                                              true
                                        ? 'right-function outbound_search'
                                        : 'right-function '
                                }
                                style={{ display: 'inline-flex' }}
                            >
                                {this.props.globalActions !== undefined ? (
                                    <Button
                                        title='Resume Tracking'
                                        className='track_resume'
                                        onClick={() => {
                                            this.recoverDoc('single');
                                        }}
                                        disabled={this.state.isLocked}
                                    >
                                        Resume Tracking
                                    </Button>
                                ) : null}
                                {this.state.outboxActionsComponentRefresh && (
                                    <OutboxActionsComponent
                                        pdfPrintRef={this.props.pdfPrintRef}
                                        {...this.state}
                                        allActions={
                                            this.state.allActions !== undefined
                                                ? this.state.allActions
                                                : this.props.allActions
                                        }
                                        calingFrom='smallView'
                                        isLocked={this.state.isLocked}
                                        open_edit_details={
                                            this.props.open_edit_details
                                        }
                                        open_delivery_detail_section={
                                            this.state
                                                .open_delivery_detail_section
                                        }
                                        open_notes_section={
                                            this.state.open_notes_section
                                        }
                                        selectedDocId={this.props.selectedDocId}
                                        pageCount={
                                            this.props.outbox.selectedRowDetails
                                                .miscPproperties !== undefined
                                                ? this.props.outbox
                                                      .selectedRowDetails
                                                      .miscPproperties.pageCount
                                                : 1
                                        }
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                            this.props.updateState(state);
                                        }}
                                        doExpand={() => {
                                            this.doExpand();
                                        }}
                                        openDetailView={() => {
                                            this.props.openDetailView();
                                        }}
                                        refreshTable={this.props.refreshTable}
                                        deleteDocument={this.props.calingFrom}
                                        delstatusId={this.props.delstatusId}
                                        delprocessId={this.props.delprocessId}
                                        deletedPage={this.props.deletedPage}
                                        openBigView={this.props.openBigView}
                                        editDetailsOutbox={
                                            this.state.editDetailsOutbox
                                        }
                                        fromSearch={this.props.fromSearch}
                                        globalActions={this.props.globalActions}
                                        rowTransition={() => {
                                            this.props.rowTransition();
                                        }}
                                        closeBigView={() => {
                                            this.props.closeBigView();
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        {/* DELIVERY Details */}
                        {this.state.open_delivery_detail_section ? (
                            <DeliveryDetailModal
                                calingFrom='smallView'
                                outboxrefreshTable={this.props.refreshTable}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                    this.props.updateState({ ...state });
                                }}
                                deleteDocument={this.props.calingFrom}
                                allActions={
                                    this.props.calingFrom === 'deleted_document'
                                        ? this.props.allActions
                                        : {}
                                }
                                delstatusId={this.props.delstatusId}
                                delprocessId={this.props.delprocessId}
                                selectedDocId={this.props.selectedDocId}
                                refreshForNextDocument={() => {
                                    this.props.refreshForNextDocument();
                                }}
                                rowTransition={() => {
                                    this.props.rowTransition();
                                }}
                                lockedStatus={() => {
                                    this.props.lockedStatus();
                                }}
                                closeBigView={() => {
                                    this.props.closeBigView();
                                }}
                            />
                        ) : null}

                        {/* Notes Section */}
                        {this.state.open_notes_section ? (
                            <div className='wrap_details wrap_note p-4'>
                                <div
                                    className={
                                        this.state.noteCount === 0
                                            ? 'd-flex details_part align-items-stretch justify-content-between'
                                            : 'd-flex mb-5 details_part align-items-stretch justify-content-between'
                                    }
                                >
                                    <div className='details_fun'>
                                        <span>
                                            <i
                                                title='Notes'
                                                className='fa-solid fa-sticky-note'
                                            >
                                                {' '}
                                            </i>
                                            Notes
                                        </span>
                                    </div>
                                </div>
                                {this.state.noteCount === 0 && (
                                    <div className='no_result norecordfound mt-0 mb-3'>
                                        <span>No Notes Found </span>
                                    </div>
                                )}
                                {this.state.viewNotes === true ? (
                                    <ViewAllNotes
                                        selectedDocId={this.props.selectedDocId}
                                    />
                                ) : null}
                                {this.state.noteTypes !== undefined &&
                                this.state.noteTypes.length !== 0 ? (
                                    <>
                                        <hr />
                                        <Notes
                                            openNote={
                                                this.state.open_notes_section
                                            }
                                            docId={this.props.selectedDocId}
                                            type='normal'
                                            updateState={() => {
                                                let countNotes =
                                                    this.state.noteCount + 1;
                                                this.setState({
                                                    noteCount: countNotes,
                                                });
                                            }}
                                            handleReset={handleReset}
                                            viewAllOpen
                                            allActions={
                                                this.props.calingFrom ===
                                                'deleted_document'
                                                    ? this.props.allActions
                                                    : {}
                                            }
                                            delstatusId={this.props.delstatusId}
                                            delprocessId={
                                                this.props.delprocessId
                                            }
                                            deleteDocument={
                                                this.props.calingFrom
                                            }
                                        />
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                        {this.state.relatedDocRefresh === true && (
                            <RelatedDocument
                                selectedDocId={this.props.selectedDocId}
                                isLocked={this.state.isLocked}
                                toggleSpinner={toggleSpinner}
                                backURL={this.props.location.pathname}
                                allActions={
                                    this.state.allActions !== undefined
                                        ? this.state.allActions
                                        : this.props.allActions
                                }
                            />
                        )}
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    showLoader: (canShow) => showLoader(canShow),
    getListaction: getListaction,
    updateSearchState,
    outboxPage,
};
const mapStateToProps = (state, ownProps) => {
    const getInboxLocally = (name) => {
        switch (name) {
            case 'inbox':
                // eslint-disable-next-line no-case-declarations
                let detailsData = localStorage.getItem('inbox_data');
                if (detailsData !== null) {
                    detailsData = JSON.parse(detailsData);
                } else {
                    detailsData = {};
                }
                return detailsData;
            default:
                return {};
        }
    };

    return {
        outbox:
            stringEmptyOrUndefined(state.outbox.stateData) &&
            Object.keys(state.outbox.stateData).length > 0
                ? state.outbox
                : getInboxLocally('outbox'),
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        stateToken: state.outbox.stateToken,
        search: state.search,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboxSmallView))
);
