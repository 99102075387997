import { formatHeaderNameWithOutSpace } from '../../../components/commonfunction';
export const mapClassifierApiData = (values: any, headerColumns: any[]) => {
    const apiData = [];

    for (const column of headerColumns) {
        let value;

        switch (column.name) {
            case 'Last Name Corrected':
                value = values.lastnamecorrected;
                break;
            case 'First Name Corrected':
                value = values.firstnamecorrected;
                break;
            case 'Last Name Detected':
                value = values.lastnamedetected;
                break;
            case 'First Name Detected':
                value = values.firstnamedetected;
                break;
            case 'Document Type Corrected':
                value = values.documenttypecorrected;
                break;
            case 'Document Type Detected':
                value = values.documenttypedetected;
                break;
            case 'Date of Birth Corrected':
                value = values.dateofbirthcorrected;
                break;
            case 'Date of Birth Detected':
                value = values.dateofbirthdetected;
                break;
            case 'State Corrected':
                value = values.statecorrected;
                break;
            case 'State Detected':
                value = values.statedetected;
                break;
            case 'City Corrected':
                value = values.citycorrected;
                break;
            case 'City Detected':
                value = values.citydetected;
                break;
            case 'Address Corrected':
                value = values.addresscorrected;
                break;
            case 'Address Detected':
                value = values.addressdetected;
                break;
            case 'Document Handle':
                value = values.documenthandle;
                break;
            case 'Service Line':
                value = values.serviceline;
                 break;
            case 'Referral Date':
                value = values.referraldate;
                break;
            case 'Classifier Feedback':
                    value = values.classificationFeedback;
                    break;
               
            default:
                continue;
        }
        apiData.push({ fieldId: column.fieldId, value });
    }

    return apiData;
};


export const mapApiDataToObjectModel = (apiData: any): any[] => {
    const objects: any[] = [];

    apiData?.dataTable?.dataRows?.forEach((row: any) => {
        const values = row.values;
        const object: any = {};

        apiData.dataTable.headerColumns.forEach((h: any) => {
            const propertyName = formatHeaderNameWithOutSpace(h.name);
            if (propertyName) {
                object.id = row.id;
                object[propertyName] =
                    values[
                    apiData.dataTable.headerColumns.findIndex(
                        (header: any) => header.name === h.name
                    )
                    ];
            }
        });

        objects.push({ ...object, id: row.id });
    });

    return objects;
};