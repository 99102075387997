import React, { useEffect , useState} from 'react';
import { Field , ErrorMessage} from 'formik';
import { ClassifierFormProps } from '../models/interfaces';
import DatePicker from 'react-date-picker';
import states from '../../../../states.json';
import { OptionType } from '../../eSign/models/interfaces';
const ClassifierForm: React.FC<ClassifierFormProps> = ({
    formik,
    documentTypes,
    serviceLines,
    feedbackTypes,
    setDocumentTypeCorrected,
}) => {
    const [classificationFeedback, setclassificationFeedback] = useState('');
    const handleClassficationType = (event: any) => { 
        console.log(event.target.value)
        const selectedValue = event.target.value;
        formik.setFieldValue('classificationFeedback', selectedValue); 
        console.log('formik.values',formik.values)
        console.log('formik.errors',formik.errors)
        setclassificationFeedback(selectedValue);
        if(selectedValue === feedbackTypes?.[0]?.value || selectedValue === feedbackTypes?.[2]?.value)
        {
            setDocumentTypeCorrected('Referral');
            formik.setFieldValue('documenttypecorrected', 'Referral');
        }   
    };
    const handleDocumentType = (event: any) => { 
        const selectedValue = event.target.value;
        setDocumentTypeCorrected(selectedValue);
        formik.handleChange(event);
    };

    return (
        <div>
            <div className='d-flex justify-content-between mb-2 pl-7 pr-7'>
                <div className='d-flex mb-3 align-items-stretch justify-content-between'>
                    <h3 className='m-0 title-color'>
                        <i
                            title='add'
                            className='fa-solid fa-microchip-ai'
                            style={{ color: '#007dbc', marginRight: '15px' }}
                        />
                        AI Referral Feedback
                    </h3>
                </div>
            </div>
            <hr />
            <div className='mb-5 pl-7 pr-7'>
                <p className='text-dark mb-10'>
                    On this page, you can add feedback for AI referrals.
                </p>

                {/* Classification Section */}
                <div>
                    <div className='row grid-row-group mb-5'>
                        {/* Classification Type (Spanning two columns) */}
                        <div className='col-md-8 col-xl-8 transition-select form-control-iconless'>
                            <label className='pl-5'>
                                Feedback Type
                                <span style={{ color: 'red' }}> *</span>
                                <i
                                    title={'Open'}
                                    className={'fa-regular fa-chevron-down'}
                                    aria-hidden='true'
                                />
                            </label>
                            <Field
                                        as='select'
                                        name='classificationFeedback' 
                                        className={`form-control ${
                                            formik.touched.classificationFeedback &&
                                            formik.errors.classificationFeedback
                                                ? 'is-invalid'
                                                : ''
                                        }`}
                                        onChange={handleClassficationType}
                                    >
                                        <option value=''>Select</option>
                                        {feedbackTypes?.map(
                                            (
                                                option: OptionType,
                                                index: number
                                            ) => (
                                                <option
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                    <ErrorMessage
                                    name='classificationFeedback'
                                    component='div'
                                    className='error m-0'
                            />
                        </div>

                        {/* Conditional Field 1: Referral Page Number */}
                        <div className='col-md-4 col-xl-4 transition-select form-control-iconless'>
                            {classificationFeedback === feedbackTypes?.[0].value && (
                                <div>
                                    <label className='pl-5'>
                                        Referral Page Number
                                    </label>
                                    <Field
                                        type='text'
                                        name='referralpageNumber'
                                        className={`form-control ${formik.touched.referralpageNumber}`}
                                    />
                                </div>
                            )}

                            {classificationFeedback ===
                                feedbackTypes?.[1]?.value && (
                                <div>
                                    <label className='pl-5'>
                                        Actual Document Type
                                        <i
                                            title='Open'
                                            className='fa-regular fa-chevron-down'
                                            aria-hidden='true'
                                        />
                                        <span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <Field
                                        as='select'
                                        name='documenttypecorrected'
                                        className={`form-control ${formik.touched.documenttypecorrected}`}
                                        onChange={handleDocumentType}
                                    >
                                        <option value=''>Select</option>
                                        {documentTypes?.map(
                                            (option: OptionType) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* --- Personal Information Section --- */}
                {(classificationFeedback ===
                    feedbackTypes?.[0]?.value || classificationFeedback === feedbackTypes?.[2].value ) && (
                    <div className='personal-information-section mt-5 p-4 border rounded shadow-sm'>
                        <h4
                            className='section-title  mb-4'
                            style={{ color: '#007dbc' }}
                        >
                            Extracted Patient Information
                        </h4>

                        {/* Personal Info Grid */}
                        <div className='row grid-row-group'>
                            {/* First Name Field */}
                            <div className='col-md-4 col-xl-4'>
                                <label className='pl-5'>FIRST NAME</label>
                                <Field
                                    type='text'
                                    name='firstnamecorrected'
                                    className={`form-control ${
                                        formik.touched.firstnamecorrected &&
                                        formik.errors.firstnamecorrected
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    data-toggle='tooltip'
                                    data-trigger='focus'
                                />
                            </div>

                            {/* Last Name Field */}
                            <div className='col-md-4 col-xl-4'>
                                <label className='pl-5'>LAST NAME</label>
                                <Field
                                    type='text'
                                    name='lastnamecorrected'
                                    className={`form-control ${
                                        formik.touched.lastnamecorrected &&
                                        formik.errors.lastnamecorrected
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    data-toggle='tooltip'
                                    data-trigger='focus'
                                    title='Enter the last name'
                                />
                            </div>
                            <div className='col-md-4 col-xl-4'>
                                <label className='pl-5'>Date of Birth</label>
                                <Field name='dateofbirthcorrected'>
                                    {({ field, form }: any) => (
                                        <DatePicker
                                            className='date-picker form-control position-relative d-flex'
                                            format='MM/dd/y'
                                            onChange={(date: Date) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    date
                                                );
                                            }}
                                            value={
                                                field.value
                                                    ? new Date(field.value)
                                                    : null
                                            }
                                            maxDate={new Date()}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>

                        {/* Address and City Grid */}
                        <div className='row grid-row-group'>
                            <div className='col-md-6 col-xl-6'>
                                <label className='pl-5'>Address</label>
                                <Field
                                    type='text'
                                    name='addresscorrected'
                                    className={`form-control ${
                                        formik.touched.addresscorrected &&
                                        formik.errors.addresscorrected
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    data-toggle='tooltip'
                                    data-trigger='focus'
                                    title='Enter the address'
                                />
                            </div>

                            <div className='col-md-2 col-xl-2'>
                                <label className='pl-5'>City</label>
                                <Field
                                    type='text'
                                    name='citycorrected'
                                    className={`form-control ${
                                        formik.touched.citycorrected &&
                                        formik.errors.citycorrected
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    data-toggle='tooltip'
                                    data-trigger='focus'
                                    title='Enter the city'
                                />
                            </div>
                            <div className='col-md-4 col-xl-4'>
                                <label className='pl-5'>State</label>
                               
                                <Field
                                    type='text'
                                    name='statecorrected'
                                    className={`form-control ${
                                        formik.touched.statecorrected &&
                                        formik.errors.statecorrected
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    data-toggle='tooltip'
                                    data-trigger='focus'
                                    title='Enter the address'
                                />
                            </div>
                        </div>
                        <div className='row grid-row-group'>
                            <div className='col-md-6 col-xl-6'>
                                <label className='pl-5'>Service Line</label>
                                <Field
                                    as='select'
                                    name='serviceline'
                                    className={`form-control ${formik.touched.serviceline}`}
                                >
                                    <option value=''>Select</option>
                                    {serviceLines?.map((option: OptionType) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div className='col-md-6 col-xl-6'>
                                <label className='pl-5'>Referral Date</label>
                                <Field name='referraldate'>
                                    {({ field, form }: any) => (
                                        <DatePicker
                                            className='date-picker form-control position-relative d-flex'
                                            format='MM/dd/y'
                                            onChange={(date: Date) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    date
                                                );
                                            }}
                                            value={
                                                field.value
                                                    ? new Date(field.value)
                                                    : null
                                            }
                                            maxDate={new Date()}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className='col-md-4 col-xl-4'></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClassifierForm;
