/* eslint-disable array-callback-return */
import React from 'react';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import OutboxActionsComponent from '../../../pages/outbox/components/OutboxActionsComponent';
import {
    showErrorToast,
    stringEmptyOrUndefined,
    userPermission,
    ownerCellFormatter,
    createTagElement,
    closeDeletePopUp,
    getDocumentsOwners,
    GetFieldData,
} from '../../commonfunction';
import {
    addDocumentOwners,
    outboxDocumentAction,
} from '../../../pages/api/api';
import {
    setSelectedRow,
    setOwner,
    updateList,
    getListaction,
    updateOutboxState,
    showLoader,
} from '../../../pages/_redux/actions/outboxActions';

import OwnerRowAction from '../../commonAction/OwnerRowAction';
import { getTagFromStorage } from '../../commonfunction';

import { AddTags } from '../../commonAction/RowSelTags';

var deletetag_id_local = [];

const _ = require('lodash');

class OutboxRowSelComponent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            urlChange: true,
            selectedRows: new Set(),
            ownerValue: null,
            open_replacetag: false, //this.props.stateData.open_replacetag,
            refresh_delete: true,
            menu_tags: [], // can be removed. can take it from storage
            close_tagmenu: false, //this.props.stateData.close_tagmenu,
            open_addtag: false, //this.props.stateData.open_addtag,
            open_deletetag: false, // this.props.stateData.open_deletetag,
            menu_selected_tagonly: this.props.stateData.menu_selected_tagonly,
            menu_selected_tag: this.props.stateData.menu_selected_tag,
            tagLoader: false,
            //  open_transition: false,
            //  menu_branch: null,
            //   menu_group: null,
            //  menu_inbox: null,
            //  rowsTansition: [],
            //  printSelectedDocId: [],
            printLoader: false,
            openDelete: false,
            restrictAllowEdit: false,
            rowsToDelete: [],
            rowsToRemove: [],
            show_owner: false,
            show_tag: false,
            show_branch: false,
            show_group: false,
            show_inbox: false,
            show_notes: true, // default true
            //   show_pdf: true,   // default true
            //  show_delete: false,
            //  show_print: false,
            //   show_download: false,
            processId: null,
            statusId: null,
            statusArray: [],
            branchActionId: null,
            groupActionId: null,
            docIdForNotes: [],
            notesVal: '',
            editURL: '/documents/',
            allActions: {},
            noteTypes: [],
            selectedBulkAction: 'Fax Now',
            openFaxNow: false,
            faxNumberRequired: false,
            resumeResending: false,
            pauseResending: true,
            ownerList: [],
            arrayOfHeader: [],
        };
    }

    componentDidMount() {
        // eslint-disable-next-line no-underscore-dangle

        const getDLL = async () => {
            const { menu_tags, newTagColorArray } = await getTagFromStorage(
                this.props.configData,
                this.props.ddl.tags
            );

            this.setState({
                menu_tags: menu_tags,
            });
        };

        getDLL();

        const docOwner = this.props.ddl.owner;
        const displayOwner = getDocumentsOwners(docOwner);
        this.setState({
            ownerList: displayOwner,
        });
        this._isMounted = true;
        //const owners = getDocumentsOwners(this.props.owners);
        /*   this.setState({
            owners:displayOwner ,
        });  */
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ allFieldIds: fieldDataObj });
        let arrayOfHeader = [];
        this.props.outbox.outbox?.dataTable?.headerColumns.map((data) => {
            arrayOfHeader.push(data.name);
        });
        this.setState({
            arrayOfHeader: arrayOfHeader,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const prevSelectedRows = Array.from(prevState.selectedRows);
        const propsSelectedRows = Array.from(this.props.selectedRows);
        let isNotContain = 0;
        propsSelectedRows.forEach((data) => {
            if (prevSelectedRows.indexOf(data) === -1) {
                isNotContain++;
            }
        });

        //  const owners = getDocumentsOwners(this.props.owners);
        if (isNotContain > 0 || prevSelectedRows.length === 0) {
            let permission = userPermission({
                processId: this.props.processId,
                statusId: this.props.statusId,
                menu_name: this.props.currentMenuName,
                configData: this.props.configData,
            });

            permission = { ...permission, show_pdf: true, show_notes: true }; // default true
            const propStateObj = this.props;
            const newState = { ...prevState, ...propStateObj };

            this.setState({
                ...newState,
                ...permission,
                // owners: owners,
            });
        }

        // UPDATE STATE EVENT
        if (prevProps.stateToken !== this.props.stateToken) {
            // stateToken Varaible  denotes that the COMPONENT STATE varaible was changed
            this.setState({
                //      ...this.props.stateData,
                selectedRows: this.props.selectedRows,
                menu_inbox: this.state.menu_inbox,
                ownerActionId: this.state.ownerActionId,
                ownerFieldId: this.state.ownerFieldId,
                // processId: this.state.processId,
                // owners: owners,
            });
        }
        if (
            prevState.ownerValue !== this.props.ownerValue &&
            this.props.ownerValue !== '' &&
            prevState.ownerValue !== ''
        ) {
            //hiddden by geetha for fixing showing next time on row selection
            // this.handleOwnerChange(this.props.ownerValue);
        }
    }

    /*
    handleOwnerChange = async (val) => {
        let ownData;
        let value;
        let owner_id;
        if (val === null) {
            ownData = '';
            value = '';
            owner_id = '';
        } else {
            ownData = val.split('#');
            value = ownData[0];
            owner_id = ownData[1];
        }

        //   await this.setState({ ownerValue: value });
        const rowsToUpdate = [];
        const newRowsToUpdate = [];
        const rowDocs = [];
        let docIds = [];
        // let owner_id = 1;
        let processId = 1;
        let statusId = 1;
        if (String(this.state.processId) !== '') {
            processId = this.state.processId;
        }
        if (String(this.state.statusId) !== '') {
            statusId = this.state.statusId;
        }

       
        this.state.selectedRows.forEach((rowIndex, i) => {
            rowsToUpdate.push(rowIndex);
            rowDocs.push(this.state.rowData[rowIndex]);
        });
        if (rowsToUpdate.length > 0) {
            this.props.setOwner('');
            this.props.setSelectedRow('');
        }
        await rowDocs.map((data) => {
            docIds.push(data.doc_id);
            return data;
        });

        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });

         if (
             this.props.outbox.selectedRow &&
             this.props.outbox.selectedRow !== ''
         ) {
             docIds = [];
             docIds.push(this.props.outbox.selectedRow);
             await this.state.rowData.map(async (data, index) => {
                 if (data.doc_id === this.props.outbox.selectedRow) {
                     await rowsToUpdate.push(index);
                 }
                 return data;
             });
             await this.props.setOwner('');
             await this.props.setSelectedRow('');
         }  //don't need this logic for multiple select 

        window.scroll({ top: 0, behavior: 'smooth' });
        const newStatus = {};
        newStatus.processId = processId;
        newStatus.statusId = statusId;
        const params = {};
        params.processId = processId;
        params.statusId = statusId;
        params.owner_id = owner_id;
        params.docIds = docIds;
        params.actionId = this.state.ownerActionId;
        params.fieldId = fieldDataObj.ownerFieldId;
        params.statusArr = newStatus;
        //  addDocumentOwners(processId, statusId, owner_id, docIds)
        let apiDocIds = [];
        let failedDocIds = [];

        // let apiRow
        await addDocumentOwners(params)
            .then(async (response) => {
                let result = await response.json();
                return result;
            })
            .then(async (data) => {
                let results = data.results;

                if (data.hadFailures === true) {
                    await results.map(async (item) => {
                        if (item.actionSucceeded === true) {
                            await apiDocIds.push(item.docId);
                        } else if (item.actionSucceeded === false) {
                            await failedDocIds.push(item.docId);
                        }
                    });
                } else {
                    await results.map(async (item) => {
                        await apiDocIds.push(item.docId);
                    });
                }
            })
            .catch((err) => { });

        await this.state.rowData.map(async (data, index) => {
            if (apiDocIds.includes(data.doc_id)) {
                await newRowsToUpdate.push(index);
            }
            return data;
        });

        if (this.props.outbox.outbox.dataTable !== undefined) {
            let ownerIndex = '';
            await this.props.outbox.outbox.dataTable.headerColumns.map(
                (data, index) => {
                    if (data.name.toLowerCase() === 'owner') {
                        ownerIndex = index;
                    }
                    return data;
                }
            );
            //  const tableData = this.props.outbox.outbox;
            const rawData = this.props.outbox.outbox.dataTable.dataRows.slice();
            await this.props.outbox.outbox.dataTable.dataRows.map(
                async (data, index) => {
                    if (apiDocIds.includes(data.id)) {
                        const updated = {};
                        updated[ownerIndex] = value;
                        await data.values.map((row, idx) => {
                            if (idx === ownerIndex) {
                                // eslint-disable-next-line dot-notation
                                const rowToUpdate = rawData[index]['values'];
                                const updatedRaw = update(rowToUpdate, {
                                    $merge: updated,
                                });
                                // eslint-disable-next-line dot-notation
                                rawData[index]['values'] = updatedRaw;
                            }
                            return row;
                        });
                    }
                    return data;
                }
            );
            //  await this.props.updateList('');
            //  tableData.dataTable.dataRows = rawData;
            // this.setState({ ownerValue: '', selectedRows: new Set() });

            //  await this.props.updateList(tableData);
        }

        const updated = {};
        updated.owner = ownerCellFormatter(value, '', true, 'outbox');
        const nameData =
            value.split('#')[0] !== undefined ? value.split('#')[0] : value;
        updated.owner_rawdata = nameData;
        const rowData = this.state.rowData.slice();
        await newRowsToUpdate.map(async (data) => {
            //   const rowToUpdate = rowData[data];
            //   const updatedRow = update(rowToUpdate, { $merge: updated });
            this.state.rowData[data].owner_rawdata = updated.owner_rawdata;
            this.state.rowData[data].owner = updated.owner;


            /*   const fields = sortFields(
                   this.state.sortColumn,
                   this.state.sortDirection,
                   this.state.dateFormatFields
               );
               const startIndex =
                   parseInt(this.state.currentPage, 10) *
                   parseInt(this.state.perPage, 10);
               const endIndex = startIndex + parseInt(this.state.perPage, 10);
               let paginatedRowData = _.orderBy(
                   rowData,
                   fields.sortfn,
                   fields.orderBy
               );
               paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex); 
               await this.props.updateState({
                   rowData: rowData,
                   filteredRowData: paginatedRowData,
                   selectedRows: new Set(),
                   //ownerValue: '',
                   // row_transition: false,
               }); 
            await this.setState(
                {
                    rowData: rowData,
                    //filteredRowData: paginatedRowData,
                    // selectedRows: new Set(),
                    //ownerValue: '',
                    //row_transition: false,
                },
                () => {

                    this.props.updateOutboxState({
                        // ...this.state,  // comment by geetha for memory optimization
                        rowData: rowData,
                        // filteredRowData: paginatedRowData,

                        close_tagmenu: true,
                        open_addtag: true,
                        open_replacetag: false,
                        open_deletetag: false,
                        //selectedRows: new Set(),
                        //ownerValue: '',
                        //row_transition: false,
                    });
                }
            );
            return { rowData };
        });

        let message;
        if (failedDocIds.length !== 0) {
            if (failedDocIds.length === 1) {
                message = `Doc Id  ${failedDocIds[0]} is  Locked`;
            } else {
                message = `Doc Ids `;
                await failedDocIds.map(async (item, idx) => {
                    if (
                        idx !== failedDocIds.length - 1 &&
                        idx !== failedDocIds.length - 2
                    ) {
                        message += `${item},`;
                    } else if (idx === failedDocIds.length - 2) {
                        message += `${item}`;
                    } else {
                        message += ` and ${item}`;
                    }
                });
                message += ` are Locked`;
            }

            showErrorToast(message);
        } else {
            //showSuccessToast('Owner changed successfully!')
        }
    };
    */

    getData = (values) => {
        this.setState(values);
    };

    add_tag = async (tag, colour) => {
        AddTags(tag, this.state, this.getData, this.props, 'outbox', 'add');
    };

    //can be removed
    /*
    replace_remove_tag_REMOVED = async (tag_index, value) => {
        let errorCount = 0;
        this.setState({ spinner: true, tag_action: true, tagLoader: true });
        if (tag_index !== '') {
            this.setState({ menu_selected_tagonly: [] });
            this.state.menu_selected_tag.splice(tag_index, 1);
            await this.setState(
                { menu_selected_tag: this.state.menu_selected_tag },
                () => {
                    this.state.menu_selected_tag.forEach((data) => {
                        this.state.menu_selected_tagonly.push(data.name);
                    });
                }
            );
            this.setState({ spinner: false });
            this.setState({ refresh_delete: true, delete_tag: true });
        } else if (value !== '') {
            const index_tagonly =
                this.state.menu_selected_tagonly.indexOf(value);
            this.state.menu_selected_tagonly.splice(index_tagonly, 1);
            this.state.menu_selected_tag.forEach((data, index_main) => {
                if (data.name === value) {
                    this.state.menu_selected_tag.splice(index_main, 1);
                    this.setState(
                        {
                            menu_selected_tagonly:
                                this.state.menu_selected_tagonly,
                        },
                        () => {
                            this.setState({ spinner: false });
                            this.setState({
                                refresh_delete: true,
                                delete_tag: true,
                            });
                        }
                    );
                }
            });
        }
        const docid = [];
        const tagIndex = [];
        const selected_rows = Array.from(this.state.selectedRows);
        await this.state.rowData.forEach((data, index) => {
            if (selected_rows.includes(index)) {
                docid.push(data.doc_id);
                tagIndex.push(index);
            }
        });

        const join = this.state.menu_selected_tagonly.join('|');
        const fieldData = {};
        const fieldDataObj = GetFieldData({
            configData: this.props.configData,
        });
        fieldData.fieldId = fieldDataObj.tagFieldId;
        fieldData.value = join;
        if (fieldData.value.charAt(0) === '|') {
            fieldData.value = fieldData.value.substring(1);
        }
        if (fieldData.value.charAt(fieldData.value.length - 1) === '|') {
            fieldData.value = fieldData.value.substring(
                0,
                fieldData.value.length - 1
            );
        }
        let status = '';
        const params = {};
        const fields = {};
        const successDocId = [];
        fields.docIds = docid;
        params.processId = this.props.processId;
        params.statusId = this.props.statusId;
        params.actionId = this.props.allActions.update_tag.actionId;
        params.fields = fields;
       
        await outboxDocumentAction(params)
            .then((response) => {
                return response.json();
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === true) {
                            await successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    let failedDocId = [];
                    await data.results.map(async (item, idx) => {
                        if (item.actionSucceeded === false) {
                            await failedDocId.push(item.docId);
                        } else {
                            await successDocId.push(item.docId);
                        }
                    });
                    let message;
                    if (failedDocId.length !== 0) {
                        if (failedDocId.length === 1) {
                            message = `Doc Id  ${failedDocId[0]} is  Locked`;
                        } else {
                            message = `Doc Ids `;
                            await failedDocId.map(async (item, idx) => {
                                if (
                                    idx !== failedDocId.length - 1 &&
                                    idx !== failedDocId.length - 2
                                ) {
                                    message += `${item}, `;
                                } else if (idx === failedDocId.length - 2) {
                                    message += `${item}`;
                                } else {
                                    message += ` and ${item} `;
                                }
                            });
                            message += `are Locked`;
                        }

                        showErrorToast(message);
                    }
                }
            });
        let tagNewIndex = [];
        if (successDocId.length !== 0) {
            await this.state.rowData.forEach((data, index) => {
                if (successDocId.includes(data.doc_id)) {
                    tagNewIndex.push(index);
                }
            });
        } else {
            this.setState(
                {
                    tagLoader: false,
                    selected_tag: [],
                },
                () => {
                    this.props.updateOutboxState({
                        // ...this.state, comment by geetha memory optimization
                        tagLoader: false,
                        selected_tag: [],
                        menu_selected_tag: this.state.menu_selected_tag

                    });
                }
            );
        }
        
        tagNewIndex.forEach((index) => {
            const { tagElement } = createTagElement(
                fieldData.value,
                this.props.configData,
                false,
                '',
                this.props.ddl.tags
            );
            let tagIndexPosition = '';
            this.props.outbox.outbox.dataTable.headerColumns.map(
                (data, index) => {
                    if (data.name.toLowerCase() === 'tags') {
                        tagIndexPosition = index;
                    }
                }
            );
            this.state.rowData[index].tags = tagElement;
            this.state.rowData[index].tags_data = fieldData.value;
            this.props.outbox.outbox.dataTable.dataRows[index].values[
                tagIndexPosition
            ] = fieldData.value;
            this.setState(
                {
                    rowData: this.state.rowData,
                    tagLoader: false,
                },
                () => {
                    this.props.updateOutboxState({
                        // ...this.state, comment by geetha for memory optimization
                        rowData: this.state.rowData,
                        spinner: false,
                        tagLoader: false,
                        menu_selected_tag: this.state.menu_selected_tag,
                        selectedRows: this.state.selectedRows,
                    });
                }
            );
        });
      
    };
 */
    // delete tags start
    delete_tag = async (tag) => {
        this.setState({ tagLoader: true });
        this.setState({ spinner: true });
        const final_tags = [];
        this.setState({ refresh_delete: true, delete_tag: true });

        const nextCounters = await Promise.all(
            deletetag_id_local.map((deletetags) => {
                const tag_data = deletetags.tags_data;
                if (tag_data.includes(tag)) {
                    let deleted = tag_data.replace(tag, '');
                    if (deleted.charAt(0) === '|') {
                        deleted = deleted.slice(1);
                    }
                    final_tags.push({
                        doc_id: deletetags.doc_id,
                        tags: deleted,
                        index: deletetags.index,
                    });
                    deletetags.tag_data = deleted;
                    return deletetags;
                } else {
                    final_tags.push({
                        doc_id: deletetags.doc_id,
                        tags: tag_data,
                        index: deletetags.index,
                    });

                    return deletetags;
                }
            })
        );

        deletetag_id_local = nextCounters;
        AddTags(
            tag,
            this.state,
            this.getData,
            this.props,
            'outbox',
            'delete',
            final_tags
        );
    };

    remove_tag = async (tag_index, tag) => {
        AddTags(tag, this.state, this.getData, this.props, 'outbox', 'remove');

        return true;
        /*
        const doc_id = [];

        let errorMsgDocId = [];
        await docid.forEach(async (data, index) => {
            const join = this.state.menu_selected_tagonly.join('|');
            const fieldData = {};
            fieldData.fieldId = fieldDataObj.tagFieldId;
            const old_tag = data.tags.split('|');
            old_tag.splice(value, 1);
            if (data.tags !== '') {
                fieldData.value = `${join}|${old_tag.join('|')}`;
            } else {
                fieldData.value = join;
            }
            fieldData.value = [...new Set(fieldData.value.split('|'))].join(
                '|'
            );
            if (fieldData.value.charAt(0) === '|') {
                fieldData.value = fieldData.value.substring(1);
            }
            if (fieldData.value.charAt(fieldData.value.length - 1) === '|') {
                fieldData.value = fieldData.value.substring(
                    0,
                    fieldData.value.length - 1
                );
            }
            let status = '';
            const tagIndex = data.index;

            fieldData.value += '|'; // kalpana added for testing

            let removeTag = fieldData.value.split('|');
            removeTag = removeTag.filter(function (item) {
                return item !== removeValue;
            });
            let finalRemoveTag = removeTag.join('|');
            const params = {};
            const fields = {};
            fields.docIds = [data.id];
            params.processId = this.props.processId;
            params.statusId = this.props.statusId;
            params.actionId = this.props.allActions.update_tag.actionId;
            params.fields = fields;
            fields.fields = [
                {
                    fieldId: fieldDataObj.tags,
                    value: finalRemoveTag,
                },
            ];
            await outboxDocumentAction(params)
                .then((response) => {
                    if (response.status === 400 || response.status === 401) {
                        showErrorToast('Something went wrong please try again');
                    }
                    return response.json();
                })
                .then(async (data) => {
                    if (data.hadFailures === false) {
                        await data.results.map(async (item, idx) => {
                            if (item.actionSucceeded === true) {
                                await successDocId.push(item.docId);
                            }
                        });
                    }
                    if (data.hadFailures === true) {
                        let failedDocId = [];
                        await data.results.map(async (item, idx) => {
                            if (item.actionSucceeded === false) {
                                await failedDocId.push(item.docId);
                            }
                        });
                        
                    }
                });
            if (errorMsgDocId.length !== 0 && docid.length - 1 === index) {
                let message;
                if (errorMsgDocId.length === 1) {
                    message = `Doc Id  ${errorMsgDocId[0]} is  Locked`;
                } else {
                    message = `Doc Ids `;
                    await errorMsgDocId.map(async (item, idx) => {
                        if (
                            idx !== errorMsgDocId.length - 1 &&
                            idx !== errorMsgDocId.length - 2
                        ) {
                            message += `${item}, `;
                        } else if (idx === errorMsgDocId.length - 2) {
                            message += `${item}`;
                        } else {
                            message += ` and ${item} `;
                        }
                    });
                    message += `are Locked`;
                }

                showErrorToast(message);
            }

            if (successDocId.length !== 0) {
                if (successDocId.includes(data.id)) {
                   
                    const { tagElement } = createTagElement(
                        finalRemoveTag,
                        this.props.configData,
                        false,
                        '',
                        this.props.ddl.tags
                    );
                    let tagIndexPosition = '';
                    this.props.outbox.outbox.dataTable.headerColumns.map(
                        (data, index) => {
                            if (data.name.toLowerCase() === 'tags') {
                                tagIndexPosition = index;
                            }
                        }
                    );
                    this.state.rowData[tagIndex].tags = tagElement;
                    this.state.rowData[tagIndex].tags_data = finalRemoveTag;
                    this.props.outbox.outbox.dataTable.dataRows[
                        tagIndex
                    ].values[tagIndexPosition] = finalRemoveTag;
                    this.props.updateOutboxState({
                        // ...this.state, comment by geetha for memory optimization
                        rowData: this.state.rowData,
                        spinner: false,
                        tagLoader: false,
                        selectedRows: this.state.selectedRows,
                    });
                   
                }
      
            } else {
                this.setState(
                    {
                        tagLoader: false,
                        selected_tag: [],
                    },
                    () => {
                        this.props.updateOutboxState({
                            // ...this.state, comment by geetha for memory optimization
                            tagLoader: false,
                            selected_tag: [],
                        });
                    }
                );
            }
        });
        this.setState({ tagLoader: false, spinner: false });
        */
    };

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        endIndex = endIndex < 10 ? 10 : endIndex; // if perPage is less than 10
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    handleOwnerFocus(event) {
        setTimeout(function () {
            if (document.getElementById('owner_bottom_input') !== null) {
                document.getElementById('owner_bottom_input').focus();
            }
        }, 100);
    }

    entering = (e) => {
        e.children[0].style.borderTopColor = 'rgb(0, 35, 58)';
        e.children[1].style.backgroundColor = 'rgb(0, 35, 58)';
    };

    back_tag = () => {
        this.setState({
            close_tagmenu: false,
            open_addtag: false,
            open_replacetag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
        });
        /* this.props.updateOutboxState({
            close_tagmenu: false,
            open_addtag: false,
            open_replacetag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
         }); */
    };

    open_addtag = () => {
        this.setState({
            close_tagmenu: true,
            open_addtag: true,
            open_replacetag: false,
            open_deletetag: false,
        });
        /*this.props.updateOutboxState({
            close_tagmenu: true,
            open_addtag: true,

        });*/
    };

    open_replacetag = () => {
        this.setState({
            close_tagmenu: true,
            open_addtag: false,
            open_replacetag: true,
            open_deletetag: false,
        });
        /* this.props.updateOutboxState({
             close_tagmenu: true,
             open_replacetag: true,
             
         }); */
    };

    open_deletetag = async () => {
        let delete_tags = '';
        const deletetag_id = [];
        const selected_rows = Array.from(this.state.selectedRows);
        this.state.rowData.forEach((data, index) => {
            if (selected_rows.includes(data.id)) {
                data.index = index;
                deletetag_id.push(data);
                if (delete_tags !== '') {
                    delete_tags += `|${data.tags_data}`;
                } else {
                    delete_tags += data.tags_data;
                }
            }
        });

        deletetag_id_local = deletetag_id;

        let delete_array = [];
        delete_array = delete_tags.split('|');
        const uniqueDelete = [...new Set(delete_array)];
        const deleteFinal = [];
        const tagList = this.state.menu_tags.map((item) => item.name);
        await uniqueDelete.map((tag, index) => {
            if (tagList.includes(tag)) {
                this.state.menu_tags.map((data, index) => {
                    if (tag === data.name) {
                        deleteFinal.push(data);
                    }
                });
            } else {
                if (tag) {
                    let ddl = {
                        tagId: 0,
                        name: tag,
                        color: '#7F96A5',
                    };
                    deleteFinal.push(ddl);
                }
            }
        });
        this.setState({ delete_tags: deleteFinal });
        this.setState({
            close_tagmenu: true,
            open_addtag: false,
            open_replacetag: false,
            refresh_delete: true,
        });
        this.setState({ open_deletetag: true });
    };

    //not used , can be removed
    closeDeletePopUp(param) {
        param = {
            ...param,
            rowData: this.state.rowData,
            filteredRowData: this.state.filteredRowData,
        };
        const data = closeDeletePopUp(param);
        const { currentPage, perPage } = this.props;
        this.setState(
            {
                ...this.state,
                ...data,
                currentPage,
                perPage,
            },
            () => {
                this.props.updateOutboxState({
                    // ...this.state, comment by geetha for memory optimization
                    ...data,
                    currentPage,
                    perPage,
                });
                if (param.removeRow === true) {
                    this.props.getListaction(
                        this.state.processId,
                        this.state.statusId
                    );
                }
            }
        );
    }

    async componentWillUnmount() {
        await this.setState({ open_notes: false });
        // eslint-disable-next-line no-underscore-dangle
        this._isMounted = false;
    }

    // replace starts
    replace_tag = async (tag, colour) => {
        AddTags(tag, this.state, this.getData, this.props, 'outbox', 'replace');
    };

    render() {
        const handleMultiSelectClose = () => {
            this.setState(
                {
                    close_tagmenu: false,
                    open_addtag: false,
                    open_replacetag: false,
                    open_deletetag: false,
                    menu_selected_tagonly: [],
                    menu_selected_tag: [],
                    selectedRows: new Set(),
                    printSelectedDocId: [],
                    open_notes: false,
                },
                () => {
                    this.props.updateOutboxState({
                        // ...this.state, comment by geetha for memory optimization
                        close_tagmenu: false,
                        open_addtag: false,
                        open_replacetag: false,
                        open_deletetag: false,
                        menu_selected_tagonly: [],
                        menu_selected_tag: [],
                        selectedRows: new Set(),
                        printSelectedDocId: [],
                        open_notes: false,
                    });
                }
            );
        };

        return (
            <>
                {Array.from(this.state.selectedRows).length > 0 ? (
                    <div
                        className='bottom-function d-flex align-items-center sticky'
                        id='inbox_bottom_menu'
                    >
                        <span className='bottombar-txt'>
                            {Array.from(this.state.selectedRows).length}{' '}
                            {Array.from(this.state.selectedRows).length > 1
                                ? 'files'
                                : 'file'}{' '}
                            selected
                        </span>
                        <div className='outbox-outer-action'>
                            {this.state.allActions.hasOwnProperty(
                                'assign_to_user'
                            ) &&
                            this.state.arrayOfHeader.length > 0 &&
                            this.state.arrayOfHeader.includes('Owner') ? (
                                <OwnerRowAction
                                    rowSelectFrom='outbox'
                                    allActions={this.state.allActions}
                                    ownerList={this.state.ownerList}
                                    updateState={(state) => {
                                        this.props.updateState({ ...state });
                                    }}
                                />
                            ) : null}

                            {this.state.allActions.hasOwnProperty(
                                'update_tag'
                            ) &&
                            this.state.arrayOfHeader.length > 0 &&
                            this.state.arrayOfHeader.includes('Tags') ? (
                                <div className='tag-symbol ml-5'>
                                    <Dropdown onToggle={this.onclose_tag}>
                                        <Dropdown.Toggle id='dropdown-basic'>
                                            <OverlayTrigger
                                                placement='bottom'
                                                onEntering={this.entering}
                                                overlay={
                                                    <Tooltip
                                                        id='tooltip'
                                                        style={{
                                                            backgroundColor:
                                                                'rgb(0, 35, 58)',
                                                            color: 'white',
                                                            marginBottom:
                                                                '25px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    'rgb(0, 35, 58)',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Tags
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <i
                                                    className={
                                                        this.state.allActions.hasOwnProperty(
                                                            'update_tag'
                                                        ) &&
                                                        this.state.allActions
                                                            .update_tag.icon !==
                                                            null
                                                            ? this.state
                                                                  .allActions
                                                                  .update_tag
                                                                  .icon
                                                            : 'fas fa-tag'
                                                    }
                                                    style={{
                                                        color:
                                                            this.state.allActions.hasOwnProperty(
                                                                'update_tag'
                                                            ) &&
                                                            this.state
                                                                .allActions
                                                                .update_tag
                                                                .color !== null
                                                                ? this.state
                                                                      .allActions
                                                                      .update_tag
                                                                      .color
                                                                : '',
                                                    }}
                                                    onClick={this.back_tag}
                                                />
                                            </OverlayTrigger>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                            className='main-tag'
                                            style={{
                                                border: '1px solid #00233A',
                                                overflow: 'hidden',
                                                borderTopLeftRadius: '0',
                                                borderTopRightRadius: '0',
                                            }}
                                        >
                                            {this.state.close_tagmenu ? null : (
                                                <div
                                                    className='category-tags'
                                                    style={{ padding: '9px' }}
                                                >
                                                    <p
                                                        key={0}
                                                        onClick={
                                                            this.open_addtag
                                                        }
                                                        title='Add Tags'
                                                    >
                                                        <i className='fas fa-plus' />{' '}
                                                        Add Tags
                                                    </p>
                                                    <p
                                                        key={1}
                                                        onClick={
                                                            this.open_replacetag
                                                        }
                                                        title='Replace Tags'
                                                    >
                                                        <i className='fas fa-sync-alt' />{' '}
                                                        Replace Tags
                                                    </p>
                                                    <p
                                                        key={2}
                                                        onClick={
                                                            this.open_deletetag
                                                        }
                                                        title='Delete Tags'
                                                    >
                                                        <i className='fas fa-trash-alt' />{' '}
                                                        Delete Tags
                                                    </p>
                                                </div>
                                            )}
                                            {/* open_addtag  starts */}
                                            {this.state.open_addtag ? (
                                                <div
                                                    className='main_tag tag_options'
                                                    id='addtag'
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {/* <i onClick={this.back_tag} className="fas fa-arrow-left"></i> */}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div className='menu-tag tag_options_bottom'>
                                                            {this.state.menu_tags.map(
                                                                (data, i) => (
                                                                    <React.Fragment
                                                                        key={i}
                                                                    >
                                                                        {/* eslint-disable-next-line react/button-has-type */}
                                                                        <button
                                                                            className={
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? 'delete_true'
                                                                                    : ''
                                                                            }
                                                                            onClick={() => {
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? this.remove_tag(
                                                                                          '',
                                                                                          data
                                                                                      )
                                                                                    : this.add_tag(
                                                                                          data
                                                                                      );
                                                                            }}
                                                                            style={{
                                                                                borderRadius:
                                                                                    '25px',
                                                                                backgroundColor:
                                                                                    data.color,
                                                                                color: 'white',
                                                                            }}
                                                                            title={
                                                                                data.name
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                            {this.state.menu_selected_tagonly.includes(
                                                                                data.name
                                                                            ) ? (
                                                                                <i
                                                                                    title='Close'
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            ) : null}
                                                                        </button>

                                                                        <br />
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div
                                                            className=' tag_options_up'
                                                            style={{
                                                                borderTop:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            {this.state
                                                                .tagLoader ? (
                                                                <div
                                                                    className='spinner-border text-primary'
                                                                    role='status'
                                                                >
                                                                    <span className='sr-only'>
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {this.state.menu_selected_tag.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                            color: 'white',
                                                                            borderRadius:
                                                                                '25px',
                                                                        }}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {data.name !==
                                                                        '' ? (
                                                                            <>
                                                                                {/* eslint-disable-next-line react/button-has-type */}
                                                                                <button
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            data.color,
                                                                                        borderRadius:
                                                                                            '25px',
                                                                                    }}
                                                                                    title={
                                                                                        data.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        data.name
                                                                                    }
                                                                                </button>
                                                                                <i
                                                                                    title='Close'
                                                                                    onClick={() => {
                                                                                        this.remove_tag(
                                                                                            '',
                                                                                            data
                                                                                        );
                                                                                    }}
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {/* open_addtag  ends */}
                                            {/* open_replacetag  starts */}
                                            {this.state.open_replacetag ? (
                                                <div
                                                    className='main_tag tag_options'
                                                    style={{
                                                        border: this.state
                                                            .refresh_delete
                                                            ? ''
                                                            : '',
                                                        width: '267px',
                                                        backgroundColor:
                                                            'white',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {/* <i onClick={this.back_tag} className="fas fa-arrow-left"></i> */}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div className='menu-tag tag_options_bottom'>
                                                            {this.state.menu_tags.map(
                                                                (data, i) => (
                                                                    <React.Fragment
                                                                        key={i}
                                                                    >
                                                                        {/* eslint-disable-next-line react/button-has-type */}
                                                                        <button
                                                                            className={
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? 'delete_true'
                                                                                    : ''
                                                                            }
                                                                            onClick={() =>
                                                                                this.state.menu_selected_tagonly.includes(
                                                                                    data.name
                                                                                )
                                                                                    ? this.remove_tag(
                                                                                          '',
                                                                                          data.name
                                                                                      )
                                                                                    : this.replace_tag(
                                                                                          data
                                                                                      )
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    '25px',
                                                                                backgroundColor:
                                                                                    data.color,
                                                                                color: 'white',
                                                                            }}
                                                                            title={
                                                                                data.name
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                            {this.state.menu_selected_tagonly.includes(
                                                                                data.name
                                                                            ) ? (
                                                                                <i
                                                                                    title='Close'
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            ) : null}
                                                                        </button>
                                                                        <br />
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}

                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div
                                                            className=' tag_options_up'
                                                            style={{
                                                                borderTop:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            {this.state
                                                                .tagLoader ? (
                                                                <div
                                                                    className='spinner-border text-primary'
                                                                    role='status'
                                                                >
                                                                    <span className='sr-only'>
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {this.state.menu_selected_tag.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                            color: 'white',
                                                                            borderRadius:
                                                                                '25px',
                                                                        }}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {data.name !==
                                                                        '' ? (
                                                                            <>
                                                                                {/* eslint-disable-next-line react/button-has-type */}
                                                                                <button
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            data.color,
                                                                                        borderRadius:
                                                                                            '25px',
                                                                                    }}
                                                                                    title={
                                                                                        data.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        data.name
                                                                                    }
                                                                                </button>
                                                                                <i
                                                                                    title='Close'
                                                                                    onClick={() => {
                                                                                        this.remove_tag(
                                                                                            '',
                                                                                            data
                                                                                        );
                                                                                    }}
                                                                                    className='fa-light fa-xmark'
                                                                                />
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {/* open_replacetag  ends */}
                                            {/* open_deletetag  starts */}
                                            {this.state.open_deletetag ? (
                                                <div
                                                    className='main_tag tag_options'
                                                    style={{
                                                        border: this.state
                                                            .refresh_delete
                                                            ? '1px solid black'
                                                            : '',
                                                        width: '267px',
                                                        backgroundColor:
                                                            'white',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {/* <i onClick={this.back_tag} className="fas fa-arrow-left"></i> */}
                                                    {this.state
                                                        .refresh_delete ? (
                                                        <div className='menu-tag tag_options_bottom'>
                                                            {this.state
                                                                .tagLoader ? (
                                                                <div
                                                                    className='spinner-border text-primary'
                                                                    role='status'
                                                                >
                                                                    <span className='sr-only'>
                                                                        Loading...
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {this.state
                                                                .delete_tags
                                                                .length ===
                                                            0 ? (
                                                                <div
                                                                    style={{
                                                                        textAlign:
                                                                            'center',
                                                                    }}
                                                                    className='noTags'
                                                                >
                                                                    No Tags
                                                                </div>
                                                            ) : null}
                                                            {this.state.delete_tags.map(
                                                                (data, i) => (
                                                                    <React.Fragment
                                                                        key={i}
                                                                    >
                                                                        {/* eslint-disable-next-line react/button-has-type */}
                                                                        <button
                                                                            className='delete_true'
                                                                            onClick={() =>
                                                                                this.delete_tag(
                                                                                    data.name
                                                                                )
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    '25px',
                                                                                backgroundColor:
                                                                                    data.color,
                                                                                color: 'white',
                                                                            }}
                                                                            title={
                                                                                data.name
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                            <i
                                                                                title='Close'
                                                                                className='fa-light fa-xmark'
                                                                            />
                                                                        </button>

                                                                        <br />
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {/* open_deletetag  ends */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            ) : null}

                            {/* bulk Action STARTS */}
                            <OutboxActionsComponent
                                allActions={this.state.allActions}
                                calingFrom='actionBar'
                                updateState={(state) => {
                                    this.setState({ ...state });
                                }}
                                selectedRows={this.state.selectedRows}
                                refreshTable={this.props.refreshTable}
                                refresh_whole={() => {
                                    this.props.refresh_whole();
                                }}
                                fromSearch={this.props.fromSearch}
                                processId={this.state.processId}
                                statusId={this.state.statusId}
                                query={this.props.query}
                                rowTransition={() => {
                                    this.props.rowTransition();
                                }}
                                lockedStatus={() => {
                                    this.props.lockedStatus();
                                }}
                            />
                            {/* bulk Action END */}

                            <div className='bottom-icons d-flex'>
                                <OverlayTrigger
                                    placement='top'
                                    onEntering={this.entering}
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            style={{
                                                backgroundColor:
                                                    'rgb(0, 35, 58)',
                                                color: 'white',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        'rgb(0, 35, 58)',
                                                    color: 'white',
                                                }}
                                            >
                                                Close
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div className='icon_fun outer-col no-bg'>
                                        <i
                                            className='fa-light fa-xmark'
                                            onClick={() =>
                                                handleMultiSelectClose()
                                            }
                                        />
                                    </div>
                                </OverlayTrigger>
                            </div>
                            {/* this.state.printSelectedDocId.length > 0 ? (
                            <PrintPdfDocument
                                docId={this.state.printSelectedDocId}
                                loader={setPrinterloader}
                            />
                        ) : null */}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = {
    setOwner,
    updateList,
    setSelectedRow: setSelectedRow,
    getListaction: getListaction,
    updateOutboxState,
    showLoader: (data) => showLoader(data),
};
const mapStateToProps = (state, ownProps) => {
    return {
        outbox: state.outbox,
        processId: state.outbox.processId,
        statusId: state.outbox.statusId,
        configData:
            stringEmptyOrUndefined(state.configReducerPersist) &&
            stringEmptyOrUndefined(state.configReducerPersist.configData)
                ? state.configReducerPersist.configData
                : {},
        owners: state.outbox.owners,
        stateData: stringEmptyOrUndefined(state.outbox)
            ? state.outbox.stateData
            : {},
        stateToken: state.outbox.stateToken,
        ddl: state.persistDDL.DDL,
    };
};
export default React.memo(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(OutboxRowSelComponent))
);
