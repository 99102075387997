/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import {
    GetDropdownData,
    addTransition,
    getLockDocuments,
    recordSetSearch,
    unlockDocument,
} from '../../../pages/api/api';
import { getActions } from '../../../../_metronic/_helpers';
import { Button, Modal } from 'react-bootstrap';
import {
    showSuccessToast,
    showErrorToast,
    stringEmptyOrUndefined,
    GetFieldData,
    BranchIdSearch,
    encodeParameter,
} from '../../../components/commonfunction';
import {
    showLoader,
    setRowData,
    reRenderInbox,
    getCountData,
} from './../../../pages/_redux/actions/inboxActions';
import { getProcessCount } from '../../_redux/authCrud';
export class TransitionComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menu_branch:
                this.props.editDetailPage.hasOwnProperty('selectedRowDetail') &&
                this.props.editDetailPage.selectedRowDetail.branch !==
                    undefined &&
                this.props.editDetailPage.selectedRowDetail.branch.value !==
                    undefined
                    ? this.props.editDetailPage.selectedRowDetail.branch.value
                    : '',
            menu_inbox:
                this.props.menuInbox !== undefined ? this.props.menuInbox : '',
            menu_group:
                this.props.menuGroup !== undefined ? this.props.menuGroup : '',
            menuBranch: '',
            menuGroup: '',
            menuInbox: '',
            edit_transition:
                this.props.editTransition !== undefined
                    ? this.props.editTransition
                    : false,
            selectedRows: [],
            branches: [],
            groups: [],
            selectedDocId: null,
            rowData: [],
            processId: null,
            statusId: null,
            branchActionId: null,
            groupActionId: null,
            accessBranch: false,
            menu_name: null,
            tempBranch: this.props.menuBranch,
            disabledTransBtn: false,
        };
        this.closeTransition = this.closeTransition.bind(this);
        this.getTransitonDropDownData =
            this.getTransitonDropDownData.bind(this);
    }
    componentWillUnmount() {
        if (this.props.subCalingFrom !== 'global_actions') {
            this.props.showLoader(false);
        }
        this._isMounted = false;
    }
    componentDidMount() {
        // this.props.showLoader(true);
        this._isMounted = true;
        this.setState({
            selectedRows: stringEmptyOrUndefined(this.props.selectedRows)
                ? this.props.selectedRows
                : [],
            // menu_branch: stringEmptyOrUndefined(this.props.menu_branch)
            //   ? this.props.menu_branch
            // : null,
            menu_inbox: stringEmptyOrUndefined(this.props.menu_inbox)
                ? this.props.menu_inbox
                : null,
            menu_group: stringEmptyOrUndefined(this.props.menu_group)
                ? this.props.menu_group
                : null,
            selectedDocId: stringEmptyOrUndefined(this.props.selectedDocId)
                ? this.props.selectedDocId
                : null,
            rowData: stringEmptyOrUndefined(this.props.rowData)
                ? this.props.rowData
                : [],
            processId: stringEmptyOrUndefined(this.props.processId)
                ? this.props.processId
                : null,
            statusId: stringEmptyOrUndefined(this.props.statusId)
                ? this.props.statusId
                : null,
            branchActionId: stringEmptyOrUndefined(this.props.branchActionId)
                ? this.props.branchActionId
                : null,
            groupActionId: stringEmptyOrUndefined(this.props.groupActionId)
                ? this.props.groupActionId
                : null,
            //updateFaxActionId:stringEmptyOrUndefined(this)
            menu_name: stringEmptyOrUndefined(this.props.menu_name)
                ? this.props.menu_name
                : null,
            tempBranch: this.props.menuBranch,
        });
        //this.getTransitonDropDownData();

        // Check the item availability in the branch and set aside the item if there is only one.
        if (this.props.ddl.branch) {
            this.setBranch({
                target: {
                    value: this.props.ddl.branch[0],
                },
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        let docId1 = [];
        let docId2 = [];
        let notSameData = 0;
        if (prevState.selectedRows !== undefined) {
            prevState.selectedRows.forEach((data, index) => {
                docId1.push(data.doc_id);
            });
        }
        if (this.props.selectedRows !== undefined) {
            this.props.selectedRows.forEach((data, index) => {
                docId2.push(data.doc_id);
                if (docId1.indexOf(data.doc_id) === -1) {
                    notSameData++;
                }
            });
        }
        if (notSameData > 0) {
            this.setState(
                {
                    selectedRows: stringEmptyOrUndefined(
                        this.props.selectedRows
                    )
                        ? this.props.selectedRows
                        : [],
                    menu_inbox: stringEmptyOrUndefined(this.props.menuInbox)
                        ? this.props.menuInbox
                        : null,
                    menu_group: stringEmptyOrUndefined(this.props.menuGroup)
                        ? this.props.menuGroup
                        : null,
                    selectedDocId: stringEmptyOrUndefined(
                        this.props.selectedDocId
                    )
                        ? this.props.selectedDocId
                        : null,
                    rowData: stringEmptyOrUndefined(this.props.rowData)
                        ? this.props.rowData
                        : [],
                    processId: stringEmptyOrUndefined(this.props.processId)
                        ? this.props.processId
                        : null,
                    statusId: stringEmptyOrUndefined(this.props.statusId)
                        ? this.props.statusId
                        : null,
                    branchActionId: stringEmptyOrUndefined(
                        this.props.branchActionId
                    )
                        ? this.props.branchActionId
                        : null,
                    groupActionId: stringEmptyOrUndefined(
                        this.props.groupActionId
                    )
                        ? this.props.groupActionId
                        : null,
                    menu_name: stringEmptyOrUndefined(this.props.menu_name)
                        ? this.props.menu_name
                        : null,
                },
                () => {
                    // this.getTransitonDropDownData();
                    let currentMenuName = this.props.currentMenuName;
                    if (
                        currentMenuName !== undefined &&
                        currentMenuName !== null
                    ) {
                        currentMenuName =
                            currentMenuName.charAt(0).toUpperCase() +
                            currentMenuName.slice(1, currentMenuName.length);
                        this.setState({
                            menu_inbox: currentMenuName,
                        });
                    }
                }
            );
        } else if (
            stringEmptyOrUndefined(this.props.selectedDocId) &&
            this.props.selectedDocId !== prevState.selectedDocId
        ) {
            this.setState(
                {
                    selectedRows: stringEmptyOrUndefined(
                        this.props.selectedRows
                    )
                        ? this.props.selectedRows
                        : [],
                    menu_branch: stringEmptyOrUndefined(this.props.menuBranch)
                        ? this.props.menuBranch
                        : '',
                    menu_inbox: stringEmptyOrUndefined(this.props.menuInbox)
                        ? this.props.menuInbox
                        : '',
                    menu_group: stringEmptyOrUndefined(this.props.menuGroup)
                        ? this.props.menuGroup
                        : '',
                    selectedDocId: stringEmptyOrUndefined(
                        this.props.selectedDocId
                    )
                        ? this.props.selectedDocId
                        : null,
                    rowData: stringEmptyOrUndefined(this.props.rowData)
                        ? this.props.rowData
                        : [],
                    processId: stringEmptyOrUndefined(this.props.processId)
                        ? this.props.processId
                        : null,
                    statusId: stringEmptyOrUndefined(this.props.statusId)
                        ? this.props.statusId
                        : null,
                    branchActionId: stringEmptyOrUndefined(
                        this.props.branchActionId
                    )
                        ? this.props.branchActionId
                        : null,
                    groupActionId: stringEmptyOrUndefined(
                        this.props.groupActionId
                    )
                        ? this.props.groupActionId
                        : null,
                    menu_name: stringEmptyOrUndefined(this.props.menu_name)
                        ? this.props.menu_name
                        : null,
                },
                () => {
                    // this.getTransitonDropDownData();
                    let currentMenuName = this.props.currentMenuName;
                    if (
                        currentMenuName !== undefined &&
                        currentMenuName !== null
                    ) {
                        currentMenuName =
                            currentMenuName.charAt(0).toUpperCase() +
                            currentMenuName.slice(1, currentMenuName.length);
                        this.setState({
                            menu_inbox: currentMenuName,
                            accessBranch: false,
                        });
                    }
                }
            );
        }
    }
    getTransitonDropDownData() {
        let fieldData = GetFieldData({ configData: this.props.configData });
        if (
            stringEmptyOrUndefined(fieldData) &&
            stringEmptyOrUndefined(fieldData.branchFieldId) &&
            !this.props.ddl.hasOwnProperty('branch')
        ) {
            GetDropdownData(fieldData.branchFieldId)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.values && data.values.length > 0) {
                        let newArray = [];
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                        this.setState({ branches: newArray });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.setState({ branches: this.props.ddl.branch });
        }
        if (
            stringEmptyOrUndefined(fieldData) &&
            stringEmptyOrUndefined(fieldData.groupFieldId) &&
            !this.props.ddl.hasOwnProperty('group')
        ) {
            GetDropdownData(fieldData.groupFieldId)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.values && data.values.length > 0) {
                        let newArray = [];
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                        this.setState({ groups: newArray });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.setState({ groups: this.props.ddl.group });
        }
    }
    setGroup = (event) => {
        this.setState({
            menu_group: event.target.value,
            menuGroup: event.target.value,
        });
    };

    setBranch = (event) => {
        this.setState({
            menu_branch: event.target.value,
            menuBranch: event.target.value,
            accessBranch: true,
        });
    };
    setInbox = (event) => {
        this.setState({
            menu_inbox: event.target.value,
            menuInbox: event.target.value,
        });
    };
    closeTransition(params = {}) {
        this.props.closeTransition(params);
    }
    getBranchId(value) {
        let branchFiledId = '';
        this.props.configData.fields.forEach((data) => {
            if (data.name === 'Branch') {
                branchFiledId = data.fieldId;
            }
        });

        const searchText = `fieldSearch=${branchFiledId}=${encodeParameter(
            value
        )}`;
        let recordsetId = '';

        this.props.configData.configuredRecordsets?.forEach((data) => {
            if (data.name === 'Branch Data') {
                recordsetId = data.recordsetId;
            }
        });

        return recordSetSearch(recordsetId, searchText)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Error in recordSetSearch');
            })
            .then((data) => {
                return BranchIdSearch(data);
            });
    }
    numberOfDocInList = async (docId) => {
        let count = [];
        var oldCount = this.props.count;
        getProcessCount(this.state.processId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                count.push(data);
                if (this.props.subCalingFrom !== 'global_actions') {
                    oldCount.forEach((old_count) => {
                        if (
                            old_count.process.processId !==
                            data.process.processId
                        ) {
                            count.push(old_count);
                        }
                    });

                    this.props.getCountData(count);
                }
            });
        let params = {
            reload: true,
            DocId: docId,
        };
        this.closeTransition(params);
    };

    documentMove = async (docId, inboxActionId, finalData, newStatusArray) => {
        try {
            const docMoved = await addTransition(
                docId,
                inboxActionId,
                this.state.processId,
                this.state.statusId,
                finalData,
                newStatusArray
            );

            let successDoc = !docMoved.hadFailures;
            let messageShow = [];
            let failDocID = [];

            if (docMoved.hadFailures) {
                docMoved.results.forEach((idx) => {
                    if (!idx.actionSucceeded) {
                        messageShow.push(idx.message);
                        if (!failDocID.includes(idx.docId)) {
                            failDocID.push(idx.docId);
                        }
                    }
                });
            }

            for (const data of messageShow) {
                if (
                    data.toLowerCase().includes('lock') &&
                    this.state.menuInbox
                ) {
                    successDoc = true;
                } else {
                    successDoc = false;
                }
            }

            if (successDoc) {
                this.numberOfDocInList(docId);
            } else {
                if (failDocID.length) {
                    this.multiSelectErrorMessage(failDocID, messageShow[0]);
                } else {
                    showErrorToast('Error in Transition');
                }
                this.setState({
                    disabledTransBtn: false,
                });
                this.props.showLoader(false);
            }
        } catch (error) {
            console.error('Error during document move:', error);
            this.setState({
                disabledTransBtn: false,
            });
            this.props.showLoader(false);
            showErrorToast('Error in Transition');
        }
    };

    multiSelectErrorMessage = (failedDocIds, messageError) => {
        let message = '[';
        if (failedDocIds.length === 1) {
            message += `${failedDocIds[0]} ]`;
        } else {
            failedDocIds.map(async (item, idx) => {
                if (
                    idx !== failedDocIds.length - 1 &&
                    idx !== failedDocIds.length - 2
                ) {
                    message += `${item},`;
                } else if (idx === failedDocIds.length - 2) {
                    message += `${item}`;
                } else {
                    message += ` and ${item}`;
                }
            });
            message += `] `;
        }

        showErrorToast(`${messageError} ${message}`);
    };

    saveTransition = async () => {
        let actions;
        this.setState({
            disabledTransBtn: true,
        });
        if (
            this.props.calingFrom === undefined ||
            this.props.calingFrom !== 'deleted_document'
        ) {
            actions = getActions(
                this.state.menu_name,
                this.state.processId,
                this.state.statusId
            );
        } else {
            actions = getActions(
                'Search',
                this.props.processId,
                this.props.statusId
            );
        }

        let fieldData = GetFieldData({ configData: this.props.configData });

        const branch_field_id = fieldData.branchFieldId;
        const group_field_id = fieldData.groupFieldId;
        let branch_action_id = this.state.branchActionId;

        let group_action_id = this.state.groupActionId;
        const processId = this.state.processId
            ? this.state.processId
            : this.props.processId;
        let tagId = [];
        let docId = [];
        if (this.state.selectedRows.length > 0) {
            this.state.selectedRows.forEach((data) => {
                tagId.push(data.tag_id);
                docId.push(data.doc_id);
            });
        } else if (this.state.selectedDocId !== undefined) {
            docId.push(this.state.selectedDocId);
        }
        if (this.state.rowData.length > 0) {
            let rowData = this.state.rowData.map((rowdata, i) => {
                if (docId.indexOf(rowdata.doc_id) !== -1) {
                    rowdata.branch = this.state.menuBranch;
                    rowdata.assignedgroup = this.state.menuGroup;
                }
                return rowdata;
            });
            this.props.setRowData(rowData);
            this.props.reRenderInbox(true);
        }
        let fieldsData = [];
        let branchData = [];
        let groupData = [];
        let inboxData = [];
        let actionIdData = [];
        let newStatusArray = [];
        let inboxActionId;
        if (stringEmptyOrUndefined(this.state.menuBranch)) {
            let fields = {};
            fields.fieldId = branch_field_id;
            fields.value = this.state.menuBranch;
            fieldsData.push(fields);
            branchData.push(fields);

            let branchID;
            try {
                branchID = await this.getBranchId(this.state.menuBranch);
            } catch (error) {
                console.error('Error:', error);
            }

            fieldsData.push({
                fieldId: fieldData.branch_id,
                value: branchID,
            });
            //comment out the duplicate branch addition because not sure about the purpose of it
            // branchData.push({
            //     fieldId: fieldData.branch_id,
            //     value: branchID,
            // });

            if (this.state.branchActionId !== null) {
                actionIdData.push(branch_action_id);
            }

            newStatusArray.push({});
        }

        if (stringEmptyOrUndefined(this.state.menuGroup)) {
            let fields = {};
            fields.fieldId = group_field_id;
            fields.value = this.state.menuGroup;
            fieldsData.push(fields);
            groupData.push(fields);
            if (this.state.groupActionId !== null) {
                actionIdData.push(group_action_id);
            }

            newStatusArray.push({});
        }

        if (stringEmptyOrUndefined(this.state.menuInbox)) {
            let newStatus = {};
            newStatus.processId = processId;
            newStatus.statusId = this.state.menuInbox;
            let inboxAction = '';
            if (this.props.statusArray && this.props.statusArray.length > 0) {
                this.props.statusArray.forEach((data) => {
                    if (String(data.id) === String(this.state.menuInbox)) {
                        inboxAction = data.name;
                    }
                });
            } else {
                if (
                    this.props.userPermission &&
                    this.props.userPermission.statusArray
                ) {
                    this.props.userPermission.statusArray.forEach((data) => {
                        if (String(data.id) === String(this.state.menuInbox)) {
                            inboxAction = data.name;
                        }
                    });
                }
            }

            if (inboxAction !== '') {
                inboxActionId = actions.indexOf(inboxAction);

                fieldsData.push('');
                inboxData.push('');
                newStatusArray.push(newStatus);
                //actionIdData.push(inboxActionId);
            }

            if (
                this.props.calingFrom !== undefined &&
                (this.props.calingFrom === 'deleted_document' ||
                    this.props.calingFrom === 'search')
            ) {
                if (
                    this.state.menuGroup !== '' &&
                    (this.state.groupActionId === null ||
                        this.state.groupActionId === undefined)
                ) {
                    let inboxActionId = actions.indexOf('Reassign to Group');
                    group_action_id = actions.indexOf('Reassign to Group');

                    actionIdData.push(inboxActionId);
                }
                if (
                    this.state.menuBranch !== '' &&
                    (this.state.branchActionId === null ||
                        this.state.branchActionId === undefined)
                ) {
                    let inboxActionId = actions.indexOf('Change Branch');
                    actionIdData.push(inboxActionId);
                    branch_action_id = actions.indexOf('Change Branch');
                }
            }
        }
        const lockedDocIds = await this.getLockedDocIds();
        let docIdsToProceed = docId.filter((id) => !lockedDocIds.includes(id));
        const selectedDoc = this.state.selectedDocId;
        let lockedIdsInRequest = docId.filter((id) =>
            lockedDocIds.includes(id)
        );
        if (selectedDoc) {
            docIdsToProceed.push(selectedDoc);
            lockedIdsInRequest = lockedIdsInRequest.filter(
                (id) => id !== selectedDoc
            );
        }
        //unlocking selected docs to update the keywords
        for (const docId of docIdsToProceed) {
            await unlockDocument(docId);
        }
        if (fieldsData.length > 0 && actionIdData.length > 0) {
            if (this.props.subCalingFrom !== 'global_actions') {
                this.props.showLoader(true);
            }
            let finalData = [];
            const values = await actionIdData.reduce(
                async (previousPromise, actionId, index) => {
                    await previousPromise;
                    if (actionId === branch_action_id) {
                        finalData = branchData;
                    } else if (actionId === group_action_id) {
                        finalData = groupData;
                    }
                    return await addTransition(
                        docIdsToProceed,
                        actionId,
                        this.state.processId,
                        this.state.statusId,
                        finalData,
                        newStatusArray[index]
                    );
                },
                Object.create(null)
            );
            let Error = new Set();
            let data;
            if (values && Array.isArray(values.results)) {
                data = values.results.filter((valueData) => {
                    if (valueData.status === 400) {
                        if (this.props.subCalingFrom !== 'global_actions') {
                            this.setState({
                                disabledTransBtn: false,
                            });
                            this.props.showLoader(false);
                        }
                        Error.add('ERR:Some Docs Locked');
                    }
                    return true;
                });
            }
            if (inboxActionId !== undefined) {
                await this.documentMove(
                    docIdsToProceed,
                    inboxActionId,
                    [],
                    newStatusArray[actionIdData.length] // new status array will contain 3 length , action data conatain 2 length that y calling length-2
                );
            } else {
                if (data) {
                    let successDoc = false;
                    let messageShow = [];
                    let failDocID = [];
                    data.map((item, key) => {
                        if (item.hadFailures === false) {
                            successDoc = true;
                        } else {
                            item.results.map((idx) => {
                                if (idx.actionSucceeded === false) {
                                    messageShow.push(idx.message);
                                    if (
                                        failDocID.includes(idx.docId) === false
                                    ) {
                                        failDocID.push(idx.docId);
                                    }
                                }
                            });
                        }
                    });

                    messageShow.map(async (data, key) => {
                        if (
                            String(data).toLowerCase().includes('lock') ===
                                true &&
                            this.state.menuInbox !== '' &&
                            this.state.menuInbox !== null &&
                            this.state.menuInbox !== undefined
                        ) {
                            successDoc = true;
                        } else {
                            successDoc = false;
                        }
                    });

                    if (successDoc === true) {
                        if (Array.from(Error).length > 0) {
                            showErrorToast(Array.from(Error)[0]);
                            let params = {
                                reload: false,
                                DocId: docIdsToProceed,
                            };
                            this.closeTransition(params);
                        } else {
                            this.numberOfDocInList(docIdsToProceed);
                        }
                    } else {
                        if (failDocID.length !== 0) {
                            this.multiSelectErrorMessage(
                                failDocID,
                                messageShow[0]
                            );
                        } else {
                            showErrorToast('Error in Transition');
                        }
                        this.setState({
                            disabledTransBtn: false,
                        });
                        this.props.showLoader(false);
                    }
                }
            }
        } else if (actionIdData.length === 0 && inboxActionId !== undefined) {
            await this.documentMove(
                docIdsToProceed,
                inboxActionId,
                [],
                newStatusArray[0]
            );
        } else {
            let toastAlert = document.getElementsByClassName(
                'Toastify__toast-container'
            );

            if (Array.from(toastAlert).length === 0) {
                showErrorToast('Choose any one of the field');
            }
            this.setState({
                disabledTransBtn: false,
            });
        }
        if (lockedIdsInRequest.length) {
            showErrorToast(
                `Documents [${lockedIdsInRequest.join(
                    ', '
                )}] are locked and can't be transitioned`
            );
        }
        if (docIdsToProceed.length) {
            showSuccessToast('Transition added successfully');
        }
    };

    edit_transition = () => {
        this.setState({
            edit_transition: true,
        });
    };

    async getLockedDocIds() {
        try {
            let docIds = [];
            const response = await getLockDocuments();
            if (response.length) {
                response.forEach((item) => {
                    docIds.push(item.itemNum);
                });
                return docIds;
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        let currentMenuName = this.props.currentMenuName;
        if (currentMenuName !== undefined && currentMenuName !== null) {
            currentMenuName =
                currentMenuName.charAt(0).toUpperCase() +
                currentMenuName.slice(1, currentMenuName.length);
        }
        if (this.props.detailPage) {
            // Only For Detail Page
            return (
                <>
                    <div className='d-flex transition_part footer_part align-items-stretch justify-content-between mb-5 pb-2'>
                        <div className='transition_fun'>
                            <span>
                                <i
                                    title='Transition'
                                    className='fa-regular fa-arrow-right-arrow-left'
                                >
                                    {' '}
                                </i>
                                Transition
                            </span>
                        </div>
                    </div>
                    <div className='form-group row transition'>
                        <div className='col-lg-12 col-xl-4 transition-select'>
                            <label className='pl-5'>BRANCH </label>
                            <p className='pl-5'>
                                {this.state.tempBranch !== '' &&
                                this.state.tempBranch !== null
                                    ? this.state.tempBranch // here first only tempBrach there ,but if only tempbranch then data is got clear and get null in RD so added menuBranch 18/12/2023
                                    : this.props.menuBranch !== '' &&
                                      this.props.menuBranch !== null
                                    ? this.props.menuBranch
                                    : '-'}
                            </p>
                        </div>
                        <div className='col-lg-12 col-xl-4  transition-select'>
                            <label className='pl-5'>GROUP</label>
                            <p className='pl-5'>
                                {this.props.menuGroup !== '' &&
                                this.props.menuGroup !== null
                                    ? this.props.menuGroup
                                    : '-'}
                            </p>
                        </div>
                        <div className='col-lg-12 col-xl-4  transition-select'>
                            <label className='pl-5'>INBOX</label>
                            <p className='pl-5'>
                                {this.props.menuInbox !== '' &&
                                this.props.menuInbox !== null
                                    ? this.props.menuInbox
                                    : '-'}
                            </p>
                        </div>
                    </div>
                </>
            );
        } //Detail Page End
        if (this.props.content === 'normal') {
            //For Normal Display
            return (
                <>
                    <div className='d-flex transition_part footer_part align-items-stretch justify-content-between mb-5 pb-2'>
                        <div className='transition_fun'>
                            <span>
                                <i
                                    title='Transition'
                                    className='fa-regular fa-arrow-right-arrow-left'
                                >
                                    {' '}
                                </i>
                                Transition
                            </span>
                        </div>
                        {this.state.edit_transition ? (
                            <div className='cancel_save'>
                                <button
                                    className='cancel_btn'
                                    title='Cancel'
                                    onClick={() => {
                                        this.setState({
                                            edit_transition: false,
                                            accessBranch: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className='save_btn'
                                    title='Save Changes'
                                    onClick={() => this.saveTransition()}
                                >
                                    Save Changes
                                </button>
                            </div>
                        ) : (
                            <div className='edit_location'>
                                {(this.props.showBranch ||
                                    this.props.showGroup ||
                                    (this.props.userPermission !== undefined &&
                                        (this.props.userPermission
                                            .show_branch === true ||
                                            this.props.userPermission
                                                .show_group === true))) &&
                                this.props?.userPermission?.processName !==
                                    'Export' ? (
                                    <Button
                                        variant='primary'
                                        title='Edit Location'
                                        disabled={
                                            this.props.isLocked === true
                                                ? true
                                                : false
                                        }
                                        onClick={this.edit_transition}
                                    >
                                        Edit Location
                                    </Button>
                                ) : null}
                            </div>
                        )}
                    </div>
                    {this.state.edit_transition ? (
                        <div className='form-group row transition'>
                            {this.props.showBranch ||
                            (this.props.userPermission !== undefined &&
                                this.props.userPermission.show_branch) ? (
                                <div className='col-lg-12 col-xl-4 transition-select'>
                                    <label className='pl-5'>BRANCH</label>
                                    <>
                                        <select
                                            className='form-control'
                                            name='billing_card_exp_month'
                                            value={
                                                this.state.accessBranch
                                                    ? this.state.menu_branch
                                                    : this.props.editDetailPage.hasOwnProperty(
                                                          'selectedRowDetail'
                                                      ) &&
                                                      this.props.editDetailPage
                                                          .selectedRowDetail
                                                          .branch !== undefined
                                                    ? this.props.editDetailPage
                                                          .selectedRowDetail
                                                          .branch.value
                                                    : this.props.calingFrom ===
                                                          'deleted_document' &&
                                                      this.props.menuBranch !==
                                                          ''
                                                    ? this.props.menuBranch
                                                    : this.state.menu_branch
                                            }
                                            onChange={this.setBranch}
                                            disabled={
                                                /* this.props.ddl.branch.length ==
                                                    1 || */
                                                this.state.edit_transition
                                                    ? null
                                                    : true
                                            }
                                        >
                                            <option value=''>
                                                Select Branch
                                            </option>

                                            {this.props.ddl !== undefined &&
                                            this.props.ddl.branch !==
                                                undefined &&
                                            this.props.ddl.branch.length > 0
                                                ? this.props.ddl.branch.map(
                                                      (data, index) => (
                                                          <option
                                                              key={index}
                                                              value={data}
                                                              style={{
                                                                  display:
                                                                      data !==
                                                                      ''
                                                                          ? 'block'
                                                                          : 'none',
                                                              }}
                                                          >
                                                              {data}
                                                          </option>
                                                      )
                                                  )
                                                : null}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        ></i>
                                    </>
                                </div>
                            ) : null}
                            {this.props.showGroup ||
                            (this.props.userPermission !== undefined &&
                                this.props.userPermission.show_group) ? (
                                <div className='col-lg-12 col-xl-4  transition-select'>
                                    <label className='pl-5'>GROUP</label>
                                    <>
                                        <select
                                            className='form-control'
                                            name='billing_card_exp_year'
                                            value={this.state.menu_group}
                                            onChange={this.setGroup}
                                            disabled={
                                                this.state.edit_transition
                                                    ? null
                                                    : true
                                            }
                                        >
                                            <option value=''>
                                                Select Group
                                            </option>
                                            {this.props.ddl !== undefined &&
                                            this.props.ddl.group &&
                                            this.props.ddl.group.length > 0
                                                ? this.props.ddl.group.map(
                                                      (data, index) => (
                                                          <option
                                                              key={index}
                                                              value={data}
                                                          >
                                                              {data}
                                                          </option>
                                                      )
                                                  )
                                                : null}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        ></i>
                                    </>
                                </div>
                            ) : null}
                            {this.props.showInbox ||
                            (this.props.userPermission !== undefined &&
                                this.props.userPermission.show_inbox) ? (
                                <div className='col-lg-12 col-xl-4  transition-select'>
                                    <label className='pl-5'>INBOX</label>
                                    <>
                                        <select
                                            className='form-control'
                                            name='billing_card_exp_year'
                                            value={this.state.menu_inbox}
                                            onChange={this.setInbox}
                                            disabled={
                                                this.state.edit_transition
                                                    ? null
                                                    : true
                                            }
                                        >
                                            <option value=''>Select</option>
                                            {this.props.statusArray &&
                                            this.props.statusArray.length > 0
                                                ? this.props.statusArray.map(
                                                      (data, index) => (
                                                          <option
                                                              key={index}
                                                              value={data.id}
                                                          >
                                                              {data.name}
                                                          </option>
                                                      )
                                                  )
                                                : this.props.userPermission.statusArray.map(
                                                      (data, index) => (
                                                          <option
                                                              key={index}
                                                              value={data.id}
                                                          >
                                                              {data.name}
                                                          </option>
                                                      )
                                                  )}
                                        </select>
                                        <i
                                            className='fa-light fa-chevron-down'
                                            style={{ pointerEvents: 'none' }}
                                        ></i>
                                    </>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div className='form-group row transition'>
                            {
                                /* this.props.showBranch ||
                            (this.props.userPermission !== undefined &&
                                this.props.userPermission.show_branch) */ true ? (
                                    <div className='col-lg-12 col-xl-4 transition-select'>
                                        <label className='pl-5'>BRANCH </label>
                                        <p className='pl-5'>
                                            {this.props.editDetailPage.hasOwnProperty(
                                                'selectedRowDetail'
                                            ) &&
                                            this.props.editDetailPage
                                                .selectedRowDetail.branch !==
                                                undefined
                                                ? this.props.editDetailPage
                                                      .selectedRowDetail.branch
                                                      .value
                                                : this.props.calingFrom ===
                                                      'deleted_document' &&
                                                  this.props.menuBranch !== ''
                                                ? this.props.menuBranch
                                                : '-'}
                                        </p>
                                    </div>
                                ) : null
                            }

                            {
                                /* this.props.showGroup ||
                            (this.props.userPermission !== undefined &&
                                this.props.userPermission.show_group) */ true ? (
                                    <div className='col-lg-12 col-xl-4  transition-select'>
                                        <label className='pl-5'>GROUP</label>
                                        <p className='pl-5'>
                                            {this.props.menuGroup !== ''
                                                ? this.props.menuGroup
                                                : '-'}
                                        </p>
                                    </div>
                                ) : null
                            }

                            {this.props.showInbox ||
                            (this.props.userPermission !== undefined &&
                                this.props.userPermission.show_inbox) ? (
                                <div className='col-lg-12 col-xl-4  transition-select'>
                                    <label className='pl-5'>INBOX</label>
                                    <p className='pl-5'>
                                        {/* {currentMenuName !== ''
                                            ? currentMenuName
                                            : ''} */}
                                        {this.props.menuInbox !== ''
                                            ? this.props.menuInbox
                                            : '-'}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    )}
                </>
            );
        }

        return (
            <>
                <Modal
                    show={this.props.openTransition}
                    onHide={this.closeTransition}
                    className='transition-popup'
                    animation={false}
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                    size='sm'
                >
                    <Modal.Header
                        closeButton
                        className='d-block justify-content-start'
                    >
                        <Modal.Title className='d-flex align-items-stretch justify-content-between'>
                            <div className=''>
                                <i className='fa-regular fa-arrow-right-arrow-left mr-3'></i>
                                Transition
                            </div>
                            <i
                                title='Close'
                                className='fa-light fa-xmark d-flex justify-content-end align-items-center'
                                aria-hidden='true'
                                onClick={this.closeTransition}
                            ></i>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-group row transition'>
                            {this.props.showBranch ? (
                                <div className='col-lg-12 col-xl-4 transition-select'>
                                    <label className='pl-5'>BRANCH</label>
                                    {this.state.edit_transition ? (
                                        <>
                                            <select
                                                className='form-control'
                                                name='billing_card_exp_month'
                                                value={this.state.menu_branch}
                                                onChange={this.setBranch}
                                                disabled={
                                                    /* this.props.ddl.branch
                                                        .length == 1 || */
                                                    this.state.edit_transition
                                                        ? null
                                                        : true
                                                }
                                            >
                                                <option value=''>
                                                    Select Branch
                                                </option>

                                                {this.props.ddl.branch &&
                                                this.props.ddl.branch.length > 0
                                                    ? this.props.ddl.branch.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data}
                                                                  style={{
                                                                      display:
                                                                          data !==
                                                                          ''
                                                                              ? 'block'
                                                                              : 'none',
                                                                  }}
                                                              >
                                                                  {data}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            ></i>
                                        </>
                                    ) : (
                                        <p className='pl-5'>
                                            {this.props.menuBranch !== ''
                                                ? this.props.menuBranch
                                                : ''}
                                        </p>
                                    )}
                                </div>
                            ) : null}

                            {this.props.showGroup ? (
                                <div className='col-lg-12 col-xl-4  transition-select'>
                                    <label className='pl-5'>GROUP</label>
                                    {this.state.edit_transition ? (
                                        <>
                                            <select
                                                className='form-control'
                                                name='billing_card_exp_year'
                                                value={this.props.menu_group}
                                                onChange={this.setGroup}
                                                disabled={
                                                    this.state.edit_transition
                                                        ? null
                                                        : true
                                                }
                                            >
                                                <option value=''>
                                                    Select Group
                                                </option>
                                                {this.props.ddl.group &&
                                                this.props.ddl.group.length > 0
                                                    ? this.props.ddl.group.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={data}
                                                              >
                                                                  {data}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            ></i>
                                        </>
                                    ) : (
                                        <p className='pl-5'>
                                            {this.props.menuGroup !== ''
                                                ? this.props.menuGroup
                                                : ''}
                                        </p>
                                    )}
                                </div>
                            ) : null}

                            {this.props.showInbox ? (
                                <div className='col-lg-12 col-xl-4  transition-select'>
                                    <label className='pl-5'>INBOX</label>
                                    {this.state.edit_transition ? (
                                        <>
                                            <select
                                                className='form-control'
                                                name='billing_card_exp_year'
                                                value={this.props.menu_inbox}
                                                onChange={this.setInbox}
                                                disabled={
                                                    this.state.edit_transition
                                                        ? null
                                                        : true
                                                }
                                            >
                                                <option value=''>Select</option>
                                                {this.props.statusArray &&
                                                this.props.statusArray.length >
                                                    0
                                                    ? this.props.statusArray.map(
                                                          (data, index) => (
                                                              <option
                                                                  key={index}
                                                                  value={
                                                                      data.id
                                                                  }
                                                              >
                                                                  {data.name}
                                                              </option>
                                                          )
                                                      )
                                                    : null}
                                            </select>
                                            <i
                                                className='fa-light fa-chevron-down'
                                                style={{
                                                    pointerEvents: 'none',
                                                }}
                                            ></i>
                                        </>
                                    ) : (
                                        <p className='pl-5'>
                                            {currentMenuName !== ''
                                                ? currentMenuName
                                                : ''}
                                        </p>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-block border-0 transition-footer'>
                        <div className='d-flex footer-btn align-items-stretch justify-content-between'>
                            <div className='d-flex'>
                                <Button
                                    variant='secondary'
                                    onClick={this.closeTransition}
                                    title='Cancel'
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='d-flex'>
                                <Button
                                    variant='primary'
                                    className='success'
                                    onClick={() => this.saveTransition()}
                                    disabled={
                                        (this.state.menu_branch === '' &&
                                            this.state.menu_group === '' &&
                                            this.state.menu_inbox === '') ||
                                        this.state.disabledTransBtn === true
                                    }
                                    title='Save Changes'
                                >
                                    {this.state.disabledTransBtn !== true ? (
                                        'Save Changes'
                                    ) : (
                                        <span
                                            className='spinner-border spinner-border-sm'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    showLoader: (canShow) => showLoader(canShow),
    setRowData: (data) => setRowData(data),
    reRenderInbox: (canrender) => reRenderInbox(canrender),
    getCountData: getCountData,
};
const mapStateToProps = (state, ownProps) => {
    return {
        rowData: state.inbox.rowData,
        count: state.persist.count,
        configuredRecordsets: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        currentMenuName: state.persist.currentMenuName,
        selectedRowDetails: state.inbox.selectedRowDetails,
        editDetailPage: state.inbox.editDetailPage,
        ddl: state.persistDDL.DDL,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransitionComponent);
