/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { isMobile } from 'react-device-detect';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import DataGrid from 'react-data-grid';
import DatePicker from 'react-date-picker';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Tile } from '../../pages/reports/Tiles';
// import Donut from '../../pages/reports/Donut';
import Radial from '../../pages/reports/Radial';
import StackedBar from '../../pages/reports/StackedBar';
/* import { Radial } from '../../pages/reports/Radial'; */
import * as moment from 'moment-timezone';
import DocumentDetailViewComponent from '../../pages/outbox/components/DocumentDetailViewComponent';
import InboxDocumentDetailViewComponent from '../../pages/inbox/components/DocumentDetailViewComponent';
import * as XLSX from 'xlsx';
import { saveAs } from 'save-as';

import {
    getReport,
    getDocumentMetaWithDocIdCustom,
    getDocumentMetaWithDocId,
    lockDocument,
    configurationCustomeSearchId,
    configurationRecordSetId,
    configurationSqlId,
} from '../../pages/api/api';
import {
    formatHeaderNameWithOutSpace,
    formatNameWithSpaceToUnderscore,
    GetFieldData,
    NoRecordFound,
    getPageFromPath,
    formatPathName,
    handleClickOutside,
    handleColumnsReorder,
    sortFields,
    sortMethod,
    // customizedWidth,
    ObjectPropertyToLowerCase,
    ObjectPropertyToUpperCase,
    headerDataFormating,
    formatDate,
    showErrorToast,
    dateTimeDisplayFormatter,
    dateCellFormatter,
    // stringEmptyOrUndefined,
    createTagElement,
    // ownerCellFormatter,
    userPermission,
    faxCellFormatter,
    documentTypeMappings,
    typeCellFormatter,
    noteCellFormatter,
    updateCustomSearchConfigDataInLocalStorage,
} from '../commonfunction';
import {
    inboxPage,
    getSelectedRowDetails,
} from '../../pages/_redux/actions/inboxActions';
import {
    getConfigData,
    getCustomSearchConfig,
    getRecordSetConfig,
} from '../../pages/_redux/actions/configActions';
import ToggleGroupFormatter from './ToggleGroupFormatter';
import Donut from '../../pages/reports/Donut';
import TreeMap from '../../pages/reports/TreeMap';
import DropDownEditor from '../owner/DropDownEditor';

import { RowRenderer } from './RowRenderer';
import { SpinnerBlur } from '../Spinner';

import { SelectColumn } from './Column';
import DeleteRowSelection from './deleteDocument/DeleteRowSelection';
import MultipleEditPage from '../../pages/MultipleEditPage';
import { Notes } from '../notes/Notes';
import { Button } from 'react-bootstrap';
import {
    deletedPage,
    selectedRowDetail,
} from '../../pages/_redux/actions/searchActions';
import SearchFilter from '../searchFilter/SearchFilter';
const _ = require('lodash');

class ReportDocumentsDataGrid extends React.Component {
    _isMounted = false;
    //static contextType = ThemeContext;
    constructor(props) {
        super(props);
        this.state = {
            toggleRecentDoc: false,
            selectedDoc: [],
            docIds: [],
            header: [],
            rowData: [],
            selectedRows: new Set(),
            selectedDocIds: [],
            currentPage: 0,
            perPage: 10,
            filterTxt: '',
            refreshDataGrid: undefined,
            filteredData: [],
            physicianFieldIdArray: [],
            physicianRecordsetId: '',
            modifyPhysicianRecordsetId: '',
            patientId: this.props.patientId,
            groupBy: [],
            groupByData: {},
            row_transition: false,
            defaultPopOpenState: {},
            expandedGroupIds: new Set(),
            dateFormatFields: [],
            modifiedColumnsNames: new Set(),
            rowClickClass: [],
            customGroupedData: [],
            defaultOpen: true,
            groupLoader: true,
            openedRowId: [],
            open_notes_modal: false,
            docIdForNotes: [],
            graphicalType: 'Tiles',
            graphicalField: '',
            show_tag: true,
            refreshTile: true,
            to_date: '',
            from_date: '',
            customDateError: false,
            showCustomDateRange: false,
            propertyTiles: ' All Data',
            // hideSmallView: false,
            previousTiles: '',
            colourSub: '#0f0f12',
            bgColourSub: '#F2F4F5',
            daterange: '',
            rowTrans: false,
            visibleDoc: false,
            chartfilteredData: [],
            chartFilterTxt: '',
            stackFilter: '',
            selectedRadialChart: '',
            chartName: '',
            chartLoader: true,
            loopingRefresh: false,
            isRecordset: false,
            noGraph: false,
            noDataFound: false,
            noDataOnClick: false,
            hideChart: false,
            graphicalFiledEmpty: false,
            queryUpdate: false,
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.createGroupedData = this.createGroupedData.bind(this);
        this.removeAllGrouping = this.removeAllGrouping.bind(this);
        this.resetPopOverData = this.resetPopOverData.bind(this);
        this.createGroupByRows = this.createGroupByRows.bind(this);
        this.radialChartOnClick = this.radialChartOnClick.bind(this);
        this.sortRows = this.sortRows.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            if (
                nextProps.location.pathname.includes('detail') &&
                this.props.location.pathname.includes('editmultiple')
            ) {
                this.setState({
                    visibleDoc: true,
                });
            }
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        let pathName = this.props.location.pathname;
        let pathId = await getPageFromPath(pathName);
        this.setState({ pathId: pathId });

        this.props.deletedPage({
            currentPage_Report: 0,
            perPage_Report: 10,
            stackFilter: '',
        });

        if (
            this.props.location.pathname.includes('detail') === true &&
            this.state.visibleDoc === false
        ) {
            this.setState({
                visibleDoc: true,
            });
            let docid = window.atob(
                String(this.props.location.state.selectedDocID)
            );
            //let rowdetails={},
            let rowdetails = { id: docid };

            this.onRowClick(rowdetails);
        } else {
            this.props.deletedPage({
                filterTxt_report: '',
            });
            await this.setState({
                tileGridRefresh: true,
                currentPath: this.props.location.pathname,
                filterTxt: '',
                tileFilter: false,
                propertyTiles: 'All Data',
                colourSub: '#0f0f12',
                bgColourSub: '#F2F4F5',
                to_date: '',
                from_date: '',
                daterange: '',
                customDateError: false,
                rowTrans: false,
                visibleDoc: false,
                currentPage: 0,
                perPage: 10,
                viewDetail: '',
                queryUpdate: false,
            });
            await this.configurationCall();

            setTimeout(() => {
                this.setState({
                    tileGridRefresh: false,
                });
            }, 10);
        }

        GetFieldData({
            configData: this.props.configData,
        });
        this.setState({ row_transition: false });
        this.OutboundStatusFumction(this.state.allRows);

        // this.getReportDocumentsData();
    }
    async componentWillUnmount() {
        await this.setState({ row_transition: false });
        this._isMounted = false;
    }
    async configurationCustomeSearchId(id) {
        let customSearchArray = [];
        await this.props.configData.customSearchNamedIdsNeedingConfiguration.map(
            (item) => {
                customSearchArray.push(item.id);
            }
        );
        if (customSearchArray.includes(id)) {
            await configurationCustomeSearchId(id)
                .then(async (data) => {
                    const customSearches = data.customSearchQueries;
                    const customSearchNamedIdsNeedingConfiguration =
                        data.customSearchNamedIdsNeedingConfiguration;

                    updateCustomSearchConfigDataInLocalStorage(
                        customSearches,
                        customSearchNamedIdsNeedingConfiguration
                    );

                    if (this.props.getCustomSearchConfig) {
                        await this.props.getCustomSearchConfig(
                            customSearches,
                            customSearchNamedIdsNeedingConfiguration
                        );
                    }
                })
                .catch((error) => {
                    // Handle the encountered error
                    console.error(error);
                });
        }
    }
    async configurationCustomSqlId(sqlqueryid) {
        const config = localStorage.getItem('configData');
        if (this.props.getConfigData !== undefined) {
            await this.props.getConfigData(JSON.parse(config));
            // this.props.getConfigData()
        }
    }
    async configurationRecordSetId(id) {
        let recordSetArray = [];
        await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
            (item) => {
                recordSetArray.push(item.id);
            }
        );
        if (recordSetArray.includes(id)) {
            await configurationRecordSetId(id)
                .then(async (data) => {
                    const configuredRecordsets = data.configuredRecordsets;
                    const recordsetNamedIdsNeedingConfiguration =
                        data.recordsetNamedIdsNeedingConfiguration;

                    const existingConfigData =
                        JSON.parse(localStorage.getItem('configData')) || {};
                    existingConfigData.configuredRecordsets =
                        configuredRecordsets;
                    existingConfigData.recordsetNamedIdsNeedingConfiguration =
                        data.recordsetNamedIdsNeedingConfiguration;

                    localStorage.setItem(
                        'configData',
                        JSON.stringify(existingConfigData)
                    );

                    if (this.props.getRecordSetConfig) {
                        await this.props.getRecordSetConfig(
                            configuredRecordsets,
                            recordsetNamedIdsNeedingConfiguration
                        );
                    }
                })
                .catch((error) => {
                    // Handle any errors
                    console.error(error);
                });
        }
    }

    async componentDidUpdate(prevState, prevProps) {
        let pathName = this.props.location.pathname;
        let pathId = getPageFromPath(pathName);

        if (
            pathName.includes('detail') === false &&
            this.state.visibleDoc === true
        ) {
            this.setState({
                visibleDoc: false,
            });
        }
        if (String(prevState.filterTxt) !== String(this.props.filterTxt)) {
            this.setState(
                {
                    filterTxt: this.props.filterTxt,
                },
                () => {
                    this.initialConfigExecAfterState();
                }
            );
        }

        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);
        if (
            this.state.totalCount === 0 &&
            this.state.propertyTiles !== 'All Data'
        ) {
            this.setState({
                propertyTiles: 'All Data',
                forCountZero: true,
            });
            //this.filterDataGrid(this.props.deleted_page.filterTxt_report);
        }

        if (this.state.from_date === '' || this.state.to_date === '') {
            if (this.props.reports && this.props.reports.length !== 0) {
                this.props.reports.map((data) => {
                    if (String(data.reportId) === String(pathname)) {
                        if (
                            data.customSearchQueryId !== null ||
                            data.recordsetId !== null ||
                            data.sql_query_id != null
                        ) {
                            let fromDate = localStorage.getItem(
                                `filter-options-${pathname}-from_date`
                            );

                            let toDate = localStorage.getItem(
                                `filter-options-${pathname}-to_date`
                            );
                            // let fromDate = localStorage.getItem(
                            //     `filter-options-report-from_date`
                            // );

                            // let toDate = localStorage.getItem(
                            //     `filter-options-report-to_date`
                            // );

                            if (
                                fromDate !== '' &&
                                toDate !== '' &&
                                fromDate !== null &&
                                toDate !== null &&
                                fromDate !== undefined &&
                                toDate !== undefined
                            ) {
                                // fromDate = JSON.parse(fromDate);
                                // toDate = JSON.parse(toDate);
                                this.setState({
                                    from_date: new Date(fromDate),
                                    to_date: new Date(toDate),
                                });
                            }
                            // if (dateRange !== '' && dateRange !== undefined) {
                            //     this.setState({
                            //         dateRange: dateRange,
                            //     });
                            // }
                        }
                    }
                });
            }
        }

        let pageTitle = this.props.location.pathname.split('/');

        if (
            this.state.currentPath !== this.props.location.pathname &&
            String(this.state.currentPath).includes('detail') !== true
        ) {
            await this.setState({
                currentPath: this.props.location.pathname,
            });

            if (
                String(this.props.location.pathname).includes('detail') !== true
            ) {
                let recordsetId;
                let customSearchQueryId;
                let customsqlId;
                await this.props.reports.map(async (data) => {
                    if (String(data.reportId) === String(pathId)) {
                        if (data.recordsetId !== null) {
                            recordsetId = data.recordsetId;
                        }
                        if (data.customSearchQueryId !== null) {
                            customSearchQueryId = data.customSearchQueryId;
                        }

                        if (data.sql_query_id !== null) {
                            customsqlId = data.customSqlQueryId;
                        }
                    }
                });
                await this.configurationRecordSetId(recordsetId);
                await this.configurationCustomeSearchId(customSearchQueryId);
                if (customsqlId != null)
                    await this.configurationCustomSqlId(customsqlId);
                await this.setState({
                    tileGridRefresh: true,
                    currentPath: this.props.location.pathname,
                    filterTxt: '',
                    tileFilter: false,
                    propertyTiles: 'All Data',
                    colourSub: '#0f0f12',
                    bgColourSub: '#F2F4F5',
                    to_date: '',
                    from_date: '',
                    daterange: '',
                    customDateError: false,
                    rowTrans: false,
                    visibleDoc: false,
                    // currentPage: 0,
                    // perPage: 10,
                    viewDetail: '',
                    selectedRadialChart: '',
                    chartLoader: false,
                    chartName: '',
                    chartFilterTxt: '',
                    stackFilter: '',
                    chartfilteredData: undefined,
                    queryUpdate: false,
                });

                this.props.deletedPage({
                    filterTxt_report: undefined,
                    chartName: '',
                    chartFilterTxt: '',
                    stackFilter: '',
                    propertyTiles: 'All Data',
                });
                await this.removeAllGrouping();

                if (!this.daterange) {
                    this.setReportDefaultDateRange(
                        this.props.location.pathname
                    );
                }

                await this.getReportDocumentsData();

                setTimeout(() => {
                    this.setState({
                        tileGridRefresh: false,
                        chartLoader: true,
                    });
                }, 1000);
            } else if (
                String(this.state.currentPath).includes('editmultiple') === true
            ) {
                await this.setState({
                    currentPath: this.props.location.pathname,
                });
                this.setState({
                    visibleDoc: true,
                });
                let docid = window.atob(
                    String(this.props.location.state.selectedDocID)
                );
                //let rowdetails={},
                let rowdetails = { id: docid };

                this.onRowClick(rowdetails);
                //this.componentDidMount();
            } else {
                await this.setState({
                    currentPath: this.props.location.pathname,
                });
            }
        }
        if (
            this.state.currentPath !== this.props.location.pathname &&
            String(this.state.currentPath).includes('detail') === true &&
            String(this.state.currentPath).includes(pageTitle[3]) === true
        ) {
            let nextPage = this.props.deleted_page.currentPage_Report;
            let perPageIndex = this.props.deleted_page.perPage_Report;
            let dateRange = localStorage.getItem(
                `filter-options-${pathname}-dateRange`
            );
            let fromDate = localStorage.getItem(
                `filter-options-${pathname}-from_date`
            );

            let toDate = localStorage.getItem(
                `filter-options-${pathname}-to_date`
            );
            // let dateRange = localStorage.getItem(
            //     `filter-options-report-dateRange`
            // );
            // let fromDate = localStorage.getItem(
            //     `filter-options-report-from_date`
            // );

            // let toDate = localStorage.getItem(`filter-options-report-to_date`);
            await this.setState({
                refreshDataGrid: true,
                chartLoader: false,
                currentPath: this.props.location.pathname,
                filterTxt: this.props.deleted_page.filterTxt_report,
                tileFilter: true,
                propertyTiles:
                    this.props.deleted_page.propertyTiles !== undefined
                        ? this.props.deleted_page.propertyTiles
                        : 'All Data',

                colourSub:
                    prevProps.colourSub !== undefined
                        ? prevProps.colourSub
                        : '#0f0f12',
                bgColourSub:
                    prevProps.bgColourSub !== undefined
                        ? prevProps.bgColourSub
                        : '#F2F4F5',
                to_date: dateRange === 'custom' ? toDate : '',
                from_date: dateRange === 'custom' ? fromDate : '',
                daterange: dateRange !== '' ? dateRange : '',
                customDateError: false,
                rowTrans: false,
                visibleDoc: false,
                chartName:
                    this.props.deleted_page.chartName !== undefined
                        ? this.props.deleted_page.chartName
                        : '',
                chartFilterTxt:
                    this.props.deleted_page.chartFilterTxt !== undefined
                        ? this.props.deleted_page.chartFilterTxt
                        : '',
                // selectedRadialChart: need add like propertTile

                currentPage:
                    this.props.deleted_page.currentPage_Report !== undefined
                        ? this.props.deleted_page.currentPage_Report
                        : 0,
                perPage:
                    this.props.deleted_page.perPage_Report !== undefined
                        ? this.props.deleted_page.perPage_Report
                        : 10,
                stackFilter:
                    this.props.deleted_page.stackFilter !== undefined
                        ? this.props.deleted_page.stackFilter
                        : '',
                queryUpdate: false,
            });
            //await this.getReportDocumentsData();

            if (
                this.props.deleted_page.groupData_report !== '' &&
                this.props.deleted_page.groupData_report !== undefined &&
                this.props.deleted_page.groupData_report !== null
            ) {
                await this.createGroupedData(
                    this.props.deleted_page.groupData_report
                );
            } else if (
                this.props.deleted_page.filterTxt_report !== '' &&
                this.props.deleted_page.filterTxt_report !== undefined &&
                this.props.deleted_page.filterTxt_report !== null &&
                this.state.chartFilterTxt === '' &&
                this.state.stackFilter === '' &&
                (this.props.deleted_page.propertyTiles === undefined ||
                    this.props.deleted_page.propertyTiles === 'All Data')
            ) {
                await this.filterDataGrid(
                    this.props.deleted_page.filterTxt_report
                );
            } else if (
                this.props.deleted_page.filterTxt_report !== '' &&
                this.props.deleted_page.filterTxt_report !== undefined &&
                this.props.deleted_page.filterTxt_report !== null &&
                this.props.deleted_page.chartFilterTxt !== undefined &&
                this.props.deleted_page.chartFilterTxt !== null &&
                this.props.deleted_page.chartFilterTxt !== '' &&
                this.props.deleted_page.stackFilter
            ) {
                await this.filterDataGrid(
                    this.props.deleted_page.filterTxt_report
                );
                await this.radialChartOnClick(
                    this.props.deleted_page.chartFilterTxt,
                    this.props.deleted_page.chartName
                );
            } else if (
                this.props.deleted_page.propertyTiles !== '' &&
                this.props.deleted_page.propertyTiles !== undefined &&
                this.props.deleted_page.propertyTiles !== null &&
                String(this.props.location.pathname).includes(
                    'stacked-bar-chart-test'
                ) !== true
            ) {
                this.filterDataGrid(this.props.deleted_page.filterTxt_report);
            } else if (
                this.props.deleted_page.chartFilterTxt !== '' &&
                this.props.deleted_page.chartFilterTxt !== undefined &&
                this.props.deleted_page.chartFilterTxt !== null &&
                (this.props.deleted_page.stackFilter === '' ||
                    this.props.deleted_page.stackFilter === undefined)
            ) {
                this.radialChartOnClick(
                    this.props.deleted_page.chartFilterTxt,
                    this.props.deleted_page.chartName
                );
            } else if (
                this.props.deleted_page.chartFilterTxt !== '' &&
                this.props.deleted_page.chartFilterTxt !== undefined &&
                this.props.deleted_page.chartFilterTxt !== null &&
                this.props.deleted_page.stackFilter
            ) {
                // this.radialChartOnClick(
                //     this.props.deleted_page.chartFilterTxt,
                //     'Stack'
                // );

                this.radialChartOnClick(
                    this.props.deleted_page.chartFilterTxt,
                    'Stack'
                );

                //    this.radialChartOnClick(
                //             this.props.deleted_page.chartFilterTxt,
                //             'Stack'
                //    )
            } else if (
                this.props.deleted_page.stackFilter &&
                (this.props.deleted_page.chartFilterTxt === '' ||
                    this.props.deleted_page.chartFilterTxt === undefined ||
                    this.props.deleted_page.chartFilterTxt === null)
            ) {
                //this.radialChartOnClick('', 'Stack')
            } else {
                await this.filterDataGrid(
                    this.props.deleted_page.filterTxt_report
                );
                //await this.getReportDocumentsData();
            }

            // if (this.props.deleted_page.perPage_Report > 10) {
            this.handleChangeRowsPerPage({
                target: { value: perPageIndex },
            });
            //}
            // if (this.props.deleted_page.currentPage_Report > 0) {
            this.nextPage('', nextPage);

            // }

            setTimeout(() => {
                this.setState({
                    chartLoader: true,
                });
            }, 1000);
        }
        if (
            this.state.currentPath !== this.props.location.pathname &&
            String(this.state.currentPath).includes('detail') === true &&
            String(this.state.currentPath).includes(pageTitle[3]) === false
        ) {
            if (
                String(this.props.location.pathname).includes(
                    'editmultiple'
                ) === true
            ) {
                await this.setState({
                    currentPath: this.props.location.pathname,
                });
            } else {
                await this.setState({
                    tileGridRefresh: true,
                    currentPath: this.props.location.pathname,
                    filterTxt: '',
                    tileFilter: false,
                    propertyTiles: 'All Data',
                    colourSub: '#0f0f12',
                    bgColourSub: '#F2F4F5',
                    to_date: '',
                    from_date: '',
                    daterange: '',
                    customDateError: false,
                    rowTrans: false,
                    visibleDoc: false,
                    currentPage: 0,
                    perPage: 10,
                    viewDetail: '',
                    chartLoader: false,
                    chartName: '',
                    chartFilterTxt: '',
                    stackFilter: '',
                    chartfilteredData: undefined,
                    queryUpdate: false,
                });
                this.props.deletedPage({
                    filterTxt_report: undefined,
                });
                await this.getReportDocumentsData();
                await this.props.deletedPage({
                    filterTxt_report: undefined,
                    chartName: '',
                    chartFilterTxt: '',
                    stackFilter: '',
                    propertyTiles: 'All Data',
                });

                setTimeout(() => {
                    this.setState({
                        tileGridRefresh: false,
                        chartLoader: true,
                    });
                }, 1000);
            }
        }
        if (
            JSON.stringify(prevState.filter_options) !==
            JSON.stringify(this.props.filter_options)
        ) {
            this.setState(
                {
                    //filteredRowData: paginatedRowData,
                    currentPage: 0,
                    perPage: this.state.perPage,
                    totalCount: this.state.rowData.length,
                },
                () => {
                    this.resetPopOverData();
                }
            );
        }

        if (prevState.selectedDocId !== this.props.selectedDocId) {
            this.setState({
                toggleRecentDoc: false,
                selectedDoc: [],
                openPhysician: false,
                docIds: [],
            });
        }

        if (this.props.deleted_page.refreshTable === true) {
            this.refreshTable();

            this.doCollapse();
        }

        if (this.state.formName === 'Tiles') {
            this.setState({
                formName: 'No Tiles',
                refreshDataGrid: true,
                onlyGridloader: false,
            });
        }
    }
    initialConfigExecAfterState(
        params = {
            searchMode: false,
        }
    ) {
        let paginatedRowData = this.state.rowData;

        if (
            this.state.rowData !== undefined &&
            this.state.rowData.length !== 0
        ) {
            if (
                this.state.filterTxt !== '' &&
                this.state.filterTxt !== undefined
            ) {
                paginatedRowData = this.filterDataGrid(
                    this.state.filterTxt,
                    params.searchMode
                );

                this.setState({
                    allFilteredRowData: paginatedRowData,
                    totalCount: paginatedRowData.length,
                    selectedRows: new Set(),
                });
                this.props.inboxPage({
                    allFilteredRowData: paginatedRowData,
                });
            } else {
                if (this.state.groupBy.length === 0) {
                    const startIndex = 0;
                    const endIndex =
                        startIndex + parseInt(this.state.perPage, 10);

                    /* const fields = sortFields(
                        this.state.sortColumn,
                        this.state.sortDirection,
                        this.state.dateFormatFields
                    );
                    paginatedRowData = _.orderBy(
                        paginatedRowData,
                        fields.sortfn,
                        fields.orderBy
                    ); // Sorting */
                    if (this.state.sortColumn) {
                        let columnDataType;
                        this.state.header.map((item, index) => {
                            if (item.key === this.state.sortColumn) {
                                columnDataType = item.datatype;
                            }
                        });

                        paginatedRowData = sortMethod(
                            paginatedRowData,
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields,
                            columnDataType
                        );
                    }

                    paginatedRowData = _.slice(
                        paginatedRowData,
                        startIndex,
                        endIndex
                    ); // Pagination
                }

                this.props.deletedPage({
                    currentPage_Report: this.state.currentPage,
                    perPage_Report: this.state.perPage,
                });

                this.setState(
                    {
                        filteredRowData: paginatedRowData,
                        currentPage: 0,
                        perPage: this.state.perPage,
                        totalCount: this.state.rowData.length,
                    },
                    () => {
                        this.resetPopOverData();
                    }
                );
            }
        }
    }
    stringCase = (data) => {
        data = data.toLowerCase();
        data = data.charAt(0).toUpperCase() + data.slice(1);
        return data;
    };
    configurationCall = async () => {
        let pathname = this.props.location.pathname;
        let id = await getPageFromPath(pathname);

        let reports = this.props.configData.reports;
        let customeSearchArray =
            this.props.configData.customSearchNamedIdsNeedingConfiguration;
        let recordsetArray =
            this.props.configData.recordsetNamedIdsNeedingConfiguration;

        await reports.map(async (data) => {
            if (Number(data.reportId) === Number(id)) {
                if (data.customSearchQueryId !== null) {
                    customeSearchArray.map(async (item) => {
                        if (item.id === data.customSearchQueryId) {
                            await configurationCustomeSearchId(
                                data.customSearchQueryId
                            )
                                .then(async (data) => {
                                    const customSearches =
                                        data.customSearchQueries;
                                    const customSearchNamedIdsNeedingConfiguration =
                                        data.customSearchNamedIdsNeedingConfiguration;

                                    updateCustomSearchConfigDataInLocalStorage(
                                        customSearches,
                                        customSearchNamedIdsNeedingConfiguration
                                    );

                                    if (this.props.getCustomSearchConfig) {
                                        await this.props.getCustomSearchConfig(
                                            customSearches,
                                            customSearchNamedIdsNeedingConfiguration
                                        );
                                    }
                                    await this.getReportDocumentsData();
                                })
                                .catch((error) => {
                                    // Handle any errors
                                    console.error(error);
                                });
                        }
                    });
                } else {
                    recordsetArray.map(async (item) => {
                        if (item.id === data.recordsetId) {
                            await configurationRecordSetId(data.recordsetId)
                                .then(async (data) => {
                                    const configuredRecordsets =
                                        data.configuredRecordsets;
                                    const recordsetNamedIdsNeedingConfiguration =
                                        data.recordsetNamedIdsNeedingConfiguration;

                                    const existingConfigData =
                                        JSON.parse(
                                            localStorage.getItem('configData')
                                        ) || {};
                                    existingConfigData.configuredRecordsets =
                                        configuredRecordsets;
                                    existingConfigData.recordsetNamedIdsNeedingConfiguration =
                                        data.recordsetNamedIdsNeedingConfiguration;

                                    localStorage.setItem(
                                        'configData',
                                        JSON.stringify(existingConfigData)
                                    );

                                    if (this.props.getRecordSetConfig) {
                                        await this.props.getRecordSetConfig(
                                            configuredRecordsets,
                                            recordsetNamedIdsNeedingConfiguration
                                        );
                                    }
                                    await this.getReportDocumentsData();
                                })
                                .catch((error) => {
                                    // Handle the encountered error
                                    console.error(error);
                                });
                        }
                    });
                }
            }
        });
    };
    getReportDocumentsData = async () => {
        await this.setState({
            loader: true,
            loopingRefresh: true,
            filteredRowData: [],
        });
        // console.log('getReportDocumentsData getReportDocumentsData')
        await this.resetDataGrid();
        let fieldId = GetFieldData({
            configData: this.props.configData,
        });

        let pathname = this.props.location.pathname;

        pathname = await getPageFromPath(pathname);

        var customreports = [];
        customreports = this.props.reports;

        var query = '';
        // eslint-disable-next-line vars-on-top, no-var
        var i = 0;
        // eslint-disable-next-line vars-on-top, no-var
        var concat;
        let customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;
        let reportList = this.props.configData.reports;

        reportList.map((param) => {
            if (String(param.reportId) === String(pathname)) {
                this.setState({
                    pageTitle: param.name,
                });
            }
        });
        let dateSearchFieldId = null;
        await customreports.map(async (data) => {
            if (pathname === '' || pathname === 'reports')
                pathname = data.reportId; //hardcoded here

            await this.setState({ pathId: pathname });

            if (String(data.reportId) === String(pathname)) {
                let reportId = data.reportId;

                await this.props.configuredRecordsets.map(async (item) => {
                    if (String(reportId) === String(pathname)) {
                        if (
                            String(data.recordsetId) ===
                            String(item.recordsetId)
                        ) {
                            dateSearchFieldId = item.dateSearchFieldId;
                        }
                    }
                });
                if (data.customSearchQueryId !== null) {
                    query = 'customSearch/' + data.customSearchQueryId;
                    await this.setState({
                        isRecordset: false,
                        isCustomSearchQueryId: true,
                        isCustomSql: false,
                    });
                } else if (data.recordsetId !== null) {
                    await this.setState({
                        isRecordset: true,
                        isCustomSearchQueryId: false,
                        isCustomSql: false,
                    });
                    query = 'recordset/' + data.recordsetId;
                } else if (data.customSqlQueryId !== null) {
                    await this.setState({
                        isRecordset: false,
                        isCustomSearchQueryId: false,
                        isCustomSql: true,
                    });
                    query = 'customsql/' + data.customSqlQueryId;
                }
                let dateRange = localStorage.getItem(
                    `filter-options-${pathname}-dateRange`
                );
                // let dateRange = localStorage.getItem(
                //     `filter-options-report-dateRange`
                // );
                if (
                    dateRange !== '' &&
                    dateRange !== undefined &&
                    dateRange !== null
                ) {
                    await this.setState({ daterange: dateRange });
                }

                if (
                    this.state.daterange !== '' &&
                    this.state.daterange !== undefined &&
                    this.state.daterange !== null
                ) {
                    dateRange = this.state.daterange;
                }
                if (
                    dateRange !== '' &&
                    dateRange !== undefined &&
                    dateRange !== null
                ) {
                    localStorage.setItem(
                        `filter-options-${pathname}-dateRange`,
                        dateRange
                    );
                    // localStorage.setItem(
                    //     `filter-options-report-dateRange`,
                    //     dateRange
                    // );
                    if (dateRange !== 'custom') {
                        var startdate = moment()
                            .subtract(dateRange, 'days')
                            .format('YYYY-MM-DD');
                        i++;
                        query +=
                            this.state.isCustomSearchQueryId === true ||
                            this.state.isCustomSql === true
                                ? '?beginDate=' + startdate
                                : '?fieldSearch=' +
                                  dateSearchFieldId +
                                  '%3E%3D' +
                                  startdate;
                    }
                }

                if (
                    this.state.daterange === 'custom' &&
                    this.state.from_date !== '' &&
                    this.state.to_date !== '' &&
                    this.state.customDateError === false
                ) {
                    await this.setState({
                        loader: false,
                    });
                    var startDate = moment(this.state.from_date).format(
                        'YYYY-MM-DD'
                    );
                    let endDate = moment(this.state.to_date).add(1, 'd');
                    endDate = endDate.format('YYYY-MM-DD');
                    i++;
                    query +=
                        this.state.isCustomSearchQueryId === true ||
                        this.state.isCustomSql === true
                            ? '?beginDate=' + startDate + '&endDate=' + endDate
                            : '?fieldSearch=' +
                              dateSearchFieldId +
                              '%3E%3D' +
                              startDate +
                              '&fieldSearch=' +
                              dateSearchFieldId +
                              '	%3C%3D' +
                              endDate;
                    await this.setState({
                        loader: true,
                    });
                }

                if (i === 0) concat = '?';
                // eslint-disable-next-line block-scoped-var
                else concat = '&';

                //commented by kalpana on 1412 since it is meaningless in some reports
                //if(this.state.daterange !== '' && this.state.daterange !== null && this.state.daterange !== undefined){

                await customSearchQueries.map(async (searchdata) => {
                    if (searchdata.searchId === data.customSearchQueryId) {
                        if (
                            searchdata.inputSearchFieldIdsAndValues.length > 0
                        ) {
                            await searchdata.inputSearchFieldIdsAndValues.map(
                                (inputSearch) => {
                                    if (
                                        inputSearch.fieldValue !== null &&
                                        inputSearch.frontEndSupplies
                                    ) {
                                        // eslint-disable-next-line block-scoped-var

                                        query += `${concat}fieldSearch=${inputSearch.fieldId}%3D${inputSearch.fieldValue}`;
                                    }
                                }
                            );
                        }
                    }
                });

                //Looping chart new begins
                let graphicalTypeArray = [];
                let graphicalDisplayItems = data.graphicalDisplayItems;

                await graphicalDisplayItems.map(async (data) => {
                    await graphicalTypeArray.push(data.type);
                });
                await this.setState({
                    graphicalTypeArray: graphicalTypeArray,
                });
                let callingApi = 1;
                if (graphicalTypeArray && graphicalTypeArray.length > 0) {
                    for (const property in graphicalTypeArray) {
                        await graphicalDisplayItems.map(async (data) => {
                            if (graphicalTypeArray[property] === data.type) {
                                if (data.groupByFields.length > 1) {
                                    let objectName = data.type;
                                    objectName = objectName.replace(/-/g, '');
                                    let graphicalFieldId1 =
                                        data.groupByFields[0];
                                    let graphicalFieldId2 =
                                        data.groupByFields[1];
                                    let graphicalField =
                                        fieldId.allFieldID[graphicalFieldId1]
                                            .name;
                                    let graphicalField2 =
                                        fieldId.allFieldID[graphicalFieldId2]
                                            .name;

                                    await this.setState({
                                        [objectName]: {
                                            graphicalField:
                                                formatHeaderNameWithOutSpace(
                                                    graphicalField
                                                ),
                                            grpahicalFieldNameFormat:
                                                graphicalField,
                                            graphicalField2:
                                                formatHeaderNameWithOutSpace(
                                                    graphicalField2
                                                ),
                                            grpahicalFieldNameFormat2:
                                                graphicalField2,
                                        },
                                    });

                                    if (
                                        graphicalField === '' ||
                                        graphicalField === undefined ||
                                        graphicalField === null
                                    ) {
                                        await this.setState({
                                            graphicalFiledEmpty: true,
                                        });
                                    } else if (
                                        graphicalField2 === '' ||
                                        graphicalField2 === undefined ||
                                        graphicalField2 === null
                                    ) {
                                        await this.setState({
                                            graphicalFiledEmpty: true,
                                        });
                                    } else {
                                        await this.setState({
                                            graphicalFiledEmpty: false,
                                        });
                                    }
                                } else {
                                    let objectName = data.type;
                                    objectName = objectName.replace(/-/g, '');
                                    let graphicalFieldId =
                                        data.groupByFields[0];
                                    let graphicalField =
                                        fieldId.allFieldID[graphicalFieldId]
                                            .name;
                                    if (
                                        graphicalField === '' ||
                                        graphicalField === undefined ||
                                        graphicalField === null
                                    ) {
                                        await this.setState({
                                            graphicalFiledEmpty: true,
                                        });
                                    } else {
                                        await this.setState({
                                            [objectName]: {
                                                graphicalField:
                                                    formatHeaderNameWithOutSpace(
                                                        graphicalField
                                                    ),
                                                grpahicalFieldNameFormat:
                                                    graphicalField,
                                            },
                                        });
                                        await this.setState({
                                            graphicalFiledEmpty: false,
                                        });
                                    }
                                }
                            }
                        });
                        // await this.setState({
                        //     queryUpdate: true
                        // })
                        if (callingApi === 1) {
                            await this.callingApi(dateSearchFieldId, query);
                            callingApi = 2;
                        }
                    }
                } else {
                    // console.log('else')
                    // console.log('query',query)
                    await this.callingApi(dateSearchFieldId, query);
                }
            } else {
                //if you enable this loader will not work
                // this.setState({
                //     loader: false,
                // });
            }
            //}
        });

        //if( this.state.queryUpdate === true){

        this.setState({
            loopingRefresh: false,
        });
    };
    async callingApi(dateSearchFieldId, query) {
        // console.log('calling api', this.state)
        if (
            (dateSearchFieldId === undefined ||
                dateSearchFieldId === null ||
                dateSearchFieldId === '' ||
                this.state.graphicalFiledEmpty === true) &&
            this.state.isCustomSearchQueryId === false &&
            this.state.isCustomSql === false &&
            isNaN(this.state.pathId) === false &&
            this.state.daterange !== '' &&
            this.state.daterange !== null &&
            this.state.daterange !== undefined
        ) {
            showErrorToast(
                'Reports is not configured. Please contact the administrator'
            );
            setTimeout(() => {
                this.setState({
                    loader: false,
                });
            }, 100);
            this.setState({
                filteredData: [],
                relDocLoading: false,
                rowData: [],
                //allRows: filteredData,
                totalCount: 0,
                currentPage: 0,
                perPage: 10,
                refreshDataGrid: false,
                hideChart: true,
            });
            this.props.deletedPage({
                currentPage_Report: 0,
                perPage_Report: 10,
            });
            return false;
        } else {
            let status = '';
            if (
                query !== '' &&
                query !== undefined &&
                this.state.daterange !== '' &&
                this.state.daterange !== null &&
                this.state.daterange !== undefined
            ) {
                await getReport(query)
                    .then((response) => {
                        if (response !== undefined) {
                            status = response.status;
                            if (
                                status === 400 ||
                                status === 404 ||
                                status === 403
                            ) {
                                showErrorToast('Error while processing report');
                                this.setState({
                                    noGraph: true,
                                });
                                setTimeout(() => {
                                    this.setState({
                                        loader: false,
                                    });
                                }, 100);
                                this.setState({
                                    filteredData: [],
                                    relDocLoading: false,
                                    rowData: [],
                                    //allRows: filteredData,
                                    totalCount: 0,
                                    currentPage: 0,
                                    perPage: 10,
                                    refreshDataGrid: false,
                                    hideChart: true,
                                });
                                this.props.deletedPage({
                                    currentPage_Report: 0,
                                    perPage_Report: 10,
                                });
                                if (response.json !== undefined)
                                    return response.json();
                            } else {
                                if (response.json !== undefined)
                                    return response.json();
                            }
                        }
                    })
                    .then(async (data) => {
                        if (
                            data !== undefined &&
                            Object.keys(data).length !== 0
                        ) {
                            this.setState({
                                hideChart: false,
                            });
                            if (
                                data.hasOwnProperty('dataTable') &&
                                data.dataTable !== ''
                            ) {
                                let dataRows = data.dataTable.dataRows;

                                if (dataRows.length === 0) {
                                    this.setState({
                                        noGraph: true,
                                        hideChart: true,
                                    });
                                }
                                await this.setState({
                                    //totalCount: length,
                                    refreshTab: false,
                                    // loader: false,
                                    headerColumns: data.dataTable.headerColumns,
                                });
                                setTimeout(() => {
                                    this.setState({
                                        refreshTab: true,
                                    });
                                }, 10);

                                await this.getReportDocData(data.dataTable);
                            }
                        } else {
                            this.setState({
                                loader: false,
                                refreshDataGrid: false,
                                hideChart: true,
                            });
                        }
                    });
            }
        }
        //}
    }
    resetDataGrid = () => {
        this.setState({
            selectedRows: new Set(),
        });
    };

    handleClick = async () => {
        await this.setState({ openPopover: true });
    };

    /*actionMenu = (id) => {
        // const id = this.state.openPopover ? 'simple-popover-' : undefined;
        return (
            <div className='d-flex action_column align-items-center rel_action_menu'>
                <Button
                    variant='primary'
                    className='success'
                    onClick={() => {
                        this.props.history.push({
                            pathname: '/search/patients/?ID=' + id,
                        });
                    }}
                    title='View Patient'
                >
                    View Patient
                </Button>
            </div>
        );
    };*/

    getReportDocData = async (data) => {
        await this.setState({
            relDocLoading: true,
            refreshDataGrid: false,
        });
        let headername = [];
        var rows = [];
        const lowerCase = [];
        let uniqueData = {};
        let header = [];
        let dateFormatFields = [];
        //header.push(SelectColumn)
        if (data !== undefined) {
            let { dataRows, headerColumns } = data;

            headerColumns.map((item) => {
                headername.push(formatHeaderNameWithOutSpace(item.name));
            });

            //return false
            // var actionArray = {
            //     name: 'Action',
            //     fieldId: '',
            //     datatype: 'AlphaNumeric',
            // };

            var currentWidth = 0;
            if (document.getElementById('custom_listing')) {
                currentWidth = document
                    .getElementById('custom_listing')
                    .getBoundingClientRect().width;
            }

            // await headerColumns.push(actionArray);//commented to remove action column

            var otherColumnWidth = (headerColumns.length - 1) * 150;

            var actionMenuWidth = 150;
            if (currentWidth !== 0) {
                actionMenuWidth = currentWidth - otherColumnWidth;
            }
            const uniqueTagName = new Set();
            var headerNameOriginal = [];
            var headerNameFormatted = [];
            //await headerColumns.push(actionArray);
            // For duplicate header below line handled
            const headerColumnsData = [];
            const headerColumnsData2 = [];
            let nameunique = '';
            let nameunique2 = '';

            let headerColumnsName = [];
            headerColumns.forEach((data, index) => {
                headerColumnsName.push(data.name);
            });

            const counts = {};
            headerColumnsName.forEach(function (x) {
                counts[x] = (counts[x] || 0) + 1;
            });

            for (const property in counts) {
                if (counts[property] > 1) {
                    let totalCount = counts[property];
                    let i = 1;
                    let initiateValue = ' I';
                    if (i <= totalCount) {
                        // eslint-disable-next-line no-loop-func
                        headerColumns.forEach((data, index) => {
                            if (data.name === property) {
                                if (i === 1) {
                                    nameunique2 = data.name;
                                } else {
                                    nameunique2 = data.name + initiateValue;
                                }
                                const headerArray = {
                                    name: nameunique2,
                                    fieldId: data.fieldId,
                                    datatype: data.datatype,
                                };
                                i++;
                                initiateValue = initiateValue + 'I';
                                headerColumnsData2.push(headerArray);
                            }
                        });
                    }
                }
            }

            headerColumns.forEach((data, index) => {
                if (counts[data.name] === 1) {
                    nameunique = data.name;
                } else if (counts[data.name] > 1) {
                    headerColumnsData2.forEach((property, index) => {
                        if (property.fieldId === data.fieldId) {
                            nameunique = property.name;
                        }
                    });
                }
                const headerArray = {
                    name: nameunique,
                    fieldId: data.fieldId,
                    datatype: data.datatype,
                };

                headerColumnsData.push(headerArray);
            });
            headerColumns = headerColumnsData;

            // duplicate header end

            await headerColumns.forEach((data, index) => {
                let headerName = formatHeaderNameWithOutSpace(data.name);
                headerNameOriginal.push(data.name);
                headerNameFormatted.push(headerName);

                uniqueData[headerName] = new Set();
                if (data.datatype === 'Date' || data.datatype === 'DateTime') {
                    dateFormatFields = [...dateFormatFields, headerName];
                }
                let headerObj = {
                    key: formatHeaderNameWithOutSpace(data.name),
                    name: data.name,
                    datatype: data.datatype,
                    colname: data.datatype,
                    fieldId: data.fieldId,
                    sortable: true,
                    draggable: true,
                    resizable: true,
                    className: data.name,
                    dummyname: data.name.trim().toUpperCase(),
                    //width: 123,
                    // colSpan(args) {
                    //
                    //     return args.type === 'ROW' && args.row.branch === "BBB" ? 3 : undefined;
                    // }
                };

                switch (data.name.trim().toUpperCase()) {
                    case 'TAGS':
                        headerObj = {
                            ...headerObj,
                            ...{
                                // editor: TagAction, //Comment Due to issue
                                width: 60,
                                editorOptions: {
                                    createPortal: true,
                                },
                            },
                        };
                        break;
                    case 'TYPE':
                        headerObj = {
                            ...headerObj,
                            ...{
                                sortable: false,
                            },
                        };
                        break;
                    case 'ACTION':
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: actionMenuWidth - 20,
                            },
                        };
                        break;
                    default:
                        headerObj = {
                            ...headerObj,
                            ...{
                                width: 150,
                                editable: false,
                            },
                        };
                        break;
                }
                header.push(headerObj);
            });
            //worked for new display name by kalpana - start
            let displayNameObj = {
                key: 'displayname',
                name: 'Display Name',
                colname: 'AlphaNumeric',
                sortable: true,
                draggable: true,
                resizable: true,
                width: 150,
                //  frozen: false,
                className: 'Display Name',
            };
            header.push(displayNameObj);
            uniqueData['displayname'] = new Set();
            //worked for new display name by kalpana - end

            this.setState({
                headerNameOriginal: headerNameOriginal,
                headerNameFormatted: headerNameFormatted,
            });

            this.setState({ totalColumn: header });
            //header = [SelectColumn].concat(header); //commented to remove header checkbox
            var g = 0;
            var uniqueRow = [];

            await dataRows.forEach((data, index) => {
                g = g + 1;
                const lowercaseObj = {};

                const obj = {};
                obj['index'] = index;
                obj['id'] = data.id;
                obj.doc_id = data.id;
                lowercaseObj['id'] = data.id;
                var array = data.values;
                //array.splice(0, 0, '');// commented to remove row data for checkbox
                array.push(''); //addinf extra column for any actions
                array.forEach((row, i) => {
                    if (header[i] !== undefined) {
                        obj[header[i].key] = row.trim();
                        lowercaseObj[header[i].key] =
                            row.trim().toLowerCase() + header[i].key;
                        // return fals

                        switch (header[i].key) {
                            case 'tags':
                                const tagData = createTagElement(
                                    row,
                                    this.props.configData,
                                    this.state.show_tag,
                                    window.location.pathname,
                                    this.props.ddl.tags
                                );
                                obj[header[i].key] = tagData.tagElement;
                                obj.tags_data = row;
                                row.split('|').forEach((val) =>
                                    uniqueTagName.add(val.trim())
                                );
                                obj.editable = true;
                                const tagRowArray = row
                                    .split('|')
                                    .filter((v) => v.trim());
                                tagRowArray.forEach((v) =>
                                    uniqueTagName.add(v)
                                );
                                break;
                            case 'faxnumber':
                                obj[header[i].key] = row;
                                obj[header[i].key] = faxCellFormatter(row);
                                obj.faxnumber_rawdata = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            case 'documenttype':
                                obj[header[i].key] = typeCellFormatter(row);
                                obj.documenttype_rawdata = row;
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;

                            //     case 'owner':
                            //         /* obj.owner_rawdata = row;
                            //         obj[header[i].key] = ownerCellFormatter(
                            //             row,
                            //             obj.doc_id
                            //         );
                            //         obj.editable = true;
                            //         break;/*/
                            //
                            //         /*var ownerValue = '';
                            //             var ownerId = [];

                            //             if (this.props.inbox.owners !== '') {
                            //                 this.props.inbox.owners.map(
                            //                     (ownerData) => {
                            //                         ownerId.push(ownerData.ownerId);
                            //                         if (ownerData.ownerId === row) {
                            //                             ownerValue =
                            //                                 ownerData.ownerName;
                            //                         }
                            //                         return ownerData;
                            //                         // return 'AAAAA';
                            //                     }
                            //                 );
                            //             }

                            //             if (ownerId.includes(row)) {
                            //                 this.props.inbox.owners.map(
                            //                     (ownerData) => {
                            //                         if (ownerData.ownerId === row) {
                            //                             ownerValue =
                            //                                 ownerData.ownerName;
                            //                         }
                            //                         return ownerData;
                            //                         //return 'BBBBB';
                            //                     }
                            //                 );
                            //             } else {
                            //                 ownerValue = row;
                            //                 // ownerValue = 'kalpama';
                            //             }

                            //             const nameData =
                            //                 ownerValue.split('#')[0] !== undefined
                            //                     ? ownerValue.split('#')[0]
                            //                     : ownerValue; // issue fixes

                            // 			let tempOwner = ownerCellFormatter(
                            //                 ownerValue,
                            //                 obj.doc_id,
                            //                 this.props.show_owner
                            //             );

                            //

                            // 			obj[header[i].key] = tempOwner;
                            // 			obj.owner_rawdata = nameData;

                            //             obj.editable = true;
                            //             row = ownerValue;
                            // 			*/

                            //         let tempOwnerData = ownerCellFormatter(
                            //             row,
                            //             obj.doc_id
                            //         );
                            //         obj[header[i].key] = tempOwnerData;
                            //         obj.owner_rawdata = row;
                            //         this.state.modifiedColumnsNames.add(
                            //             header[i].key
                            //         );
                            //         break;
                            //     case 'lastnote':
                            //         obj[header[i].key] = row;
                            //         obj[header[i].key] = noteCellFormatter(row);
                            //         obj.lastnote_rawdata = row.trim();
                            //         this.state.modifiedColumnsNames.add(
                            //             header[i].key
                            //         );
                            //         break;
                            case 'comments':
                                obj[header[i].key] = row;
                                obj[header[i].key] = noteCellFormatter(
                                    row,
                                    'normal'
                                );
                                obj.comments_rawdata = row.trim();
                                this.state.modifiedColumnsNames.add(
                                    header[i].key
                                );
                                break;
                            default:
                                break;
                        }
                        switch (header[i].colname) {
                            case 'Date':
                                obj[`${header[i].key}_rawdata`] = formatDate(
                                    row,
                                    'MM/DD/YYYY hh:mm:ss A'
                                );
                                obj[`${header[i].key}_onlyDate`] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );

                                // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                obj[header[i].key] = formatDate(
                                    row,
                                    'MM/DD/YYYY'
                                );
                                if (header[i].key === 'date&batch') {
                                    obj[header[i].key] = dateCellFormatter(row);
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(
                                        formatDate(row, 'MM/DD/YYYY')
                                    );
                                }
                                break;
                            case 'DateTime':
                                // pls note we r reciving UTC date time('2023-06-29T09:58:42Z') so convert to local date time
                                let dateVal = '';
                                const date = new Date(row);
                                const offset = date.getTimezoneOffset();
                                const localDate = new Date(
                                    date.getTime() - offset * 60 * 1000
                                );
                                dateVal = formatDate(
                                    localDate,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                obj[`${header[i].key}_rawdata`] = formatDate(
                                    row,
                                    'MM/DD/YYYY HH:mm:ss'
                                );
                                obj[`${header[i].key}_onlyDate`] = formatDate(
                                    row,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                // if field "Date" : date, alone if it is "DateTime" 24 hours format should show - reffer workitem=8989
                                obj[header[i].key] = formatDate(
                                    row,
                                    'MM/DD/YYYY HH:mm:ss'
                                );

                                if (header[i].key === 'date&batch') {
                                    obj[header[i].key] = dateCellFormatter(row);
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(
                                        //dateTimeDisplayFormatter(row)
                                        formatDate(row, 'MM/DD/YYYY HH:mm:ss')
                                    );
                                }
                                break;
                            //     /*case 'AlphaNumeric':
                            //         if (
                            //             header[i].key !== 'owner' &&
                            //             header[i].key !== 'tags'
                            //         ) {
                            //             obj[header[i].key] = row;
                            //             obj[header[i].key] = noteCellFormatter(
                            //                 row,
                            //                 'normal'
                            //             );
                            //             obj.lastnote_rawdata = row.trim();

                            //         }
                            //         if (row !== undefined) {
                            //             uniqueData[header[i].key].add(row);
                            //         }
                            //         break;*/

                            default:
                                if (header[i].key === 'owner') {
                                    row =
                                        row.split('#')[0] !== undefined
                                            ? row.split('#')[0]
                                            : row; // issue fixes
                                }
                                if (row !== undefined) {
                                    uniqueData[header[i].key].add(row);
                                }
                                break;
                        }
                    }
                });

                lowerCase.push(lowercaseObj);
                if (!uniqueRow.includes(data.id)) {
                    uniqueRow.push(data.id);
                    rows.push(obj);
                }
            });

            //added recently to avoid duplicates
            /*if (rows.length !== 0) {
                if (
                    this.props.tab_name === 'tab_all_documents' ||
                    this.props.tab_name === 'tab_inbox'
                ) {
                    var myEle = document.getElementById('documents_count');
                    if (myEle && rows.length !== 0) {
                        document.getElementById('documents_count').innerHTML =
                            rows.length;
                        document.getElementById(
                            'documents_count'
                        ).style.display = 'inherit';
                    }
                }
                if (
                    this.props.tab_name === 'tab_tracking' ||
                    this.props.tab_name === 'tab_outbox'
                ) {
                    var myEle = document.getElementById('tracking_count');
                    if (myEle && rows.length !== 0) {
                       
                        document.getElementById('tracking_count').innerHTML =
                            rows.length;
                        document.getElementById(
                            'tracking_count'
                        ).style.display = 'inherit';
                    }
                }
            }*/

            this.setState({
                totalCount: rows.length,
            });
        }

        rows = rows.filter((value) => {
            return value.id !== this.props.selectedDocId;
        });

        if (isMobile && window.screen.width <= 960) {
            header = header.filter(
                (data) => data.key !== 'select-row' && data.name.trim() !== ''
            );
            let documentTitle;
            let docIndex;
            if (headername.includes('documentname') === true) {
                documentTitle = header.filter(
                    (data) => data.key === 'documentname'
                );
                docIndex = header.findIndex(
                    (data) => data.key === 'documentname'
                );
            } else if (headername.includes('name') === true) {
                documentTitle = header.filter((data) => data.key === 'name');
                docIndex = header.findIndex((data) => data.key === 'name');
            } else if (headername.includes('ordernumber') === true) {
                documentTitle = header.filter(
                    (data) => data.key === 'ordernumber'
                );
                docIndex = header.findIndex(
                    (data) => data.key === 'ordernumber'
                );
            } else if (
                headername.includes('documentname') !== true &&
                headername.includes('ordernumber') !== true &&
                headername.includes('name') !== true &&
                headername.includes('documenthandle') === true
            ) {
                documentTitle = header.filter(
                    (data) => data.key === 'documenthandle'
                );
                docIndex = header.findIndex(
                    (data) => data.key === 'documenthandle'
                );
            }

            header.splice(docIndex, 1);
            const ArrowFormatter = {
                key: 'toggle',
                name: '',
                colname: '',
                width: 10,
                className: 'arrow-mobile',
                formatter: ({ row }) => {
                    const docid = row.id;
                    const openedRowId = this.state.openedRowId;
                    const toggle = () => {
                        if (openedRowId[docid] === undefined) {
                            openedRowId[docid] = true;
                            this.setState({ openedRowId: openedRowId });
                        } else {
                            openedRowId[docid] = !openedRowId[docid];
                            this.setState({ openedRowId: openedRowId });
                        }
                    };
                    const icon =
                        openedRowId[docid] === true
                            ? 'fa fa-chevron-up'
                            : 'fa fa-chevron-right';
                    return (
                        <div
                            onClick={() => toggle()}
                            className='toggle-it d-flex align-items-center'
                        >
                            {' '}
                            <i className={`${icon}`} aria-hidden='true' />{' '}
                        </div>
                    );
                },
            };
            documentTitle.push(ArrowFormatter);
            header = documentTitle.concat(header); // Adding Document Name at the beginning
        } else {
            header = header.filter(
                (data) =>
                    data.key !== 'select-row' &&
                    data.name.trim() !== '' &&
                    data.name.trim() !== 'Document Name' &&
                    data.name.trim() !== 'Display Name'
            );
        }
        header =
            this.state.isCustomSearchQueryId === true
                ? [SelectColumn].concat(header)
                : header;

        await this.setState(
            {
                uniqueData: uniqueData,
                relDocLoading: false,
                header: header,
                rowData: rows,
                filteredRowData: rows,
                allRows: rows,
                orginAllRows: rows,
                copyAllRows: rows,
                filteredData: rows,
                allRowsLoweCase: lowerCase,
                dateFormatFields,
                refreshDataGrid: true,
            },
            () => {
                let paginatedRowData = this.state.rowData;
                //let paginatedRowData = rows;
                paginatedRowData = _.slice(paginatedRowData, 0, 10);
                this.setState({
                    refreshDataGrid: true,
                    rowData: paginatedRowData,
                    filteredRowData: paginatedRowData,
                    filteredData: rows,
                });
            }
        );
        setTimeout(() => {
            this.setState({
                loader: false,
            });
        }, 100);
    };

    openPhysician = (docId) => {
        this.setState({
            openPhysician: true,
            docIds: [docId],
        });
    };
    updateState = (state) => {
        this.props.updateState(state);
    };
    onRowClick = async (rowDetails, rowHeader, stateData) => {
        console.log(`this.state.headerColumns`, this.state.headerColumns);
        const headerColumns = this.state.headerColumns;

        if (
            !headerColumns.some((column) => column.name === 'Document Handle')
        ) {
            console.log('Document Handle not found. Exiting early.');
            return; // Early return
        }

        var customSearchQueries = [];
        customSearchQueries =
            this.props.configData.customSearchNamedIdsNeedingConfiguration;

        if (customSearchQueries !== undefined) {
            await customSearchQueries.map(async (data) => {
                if (data.name === 'By Batch') {
                    await this.configurationCustomeSearchId(data.id);
                }
                if (data.name === 'By Order') {
                    await this.configurationCustomeSearchId(data.id);
                }
            });
        }
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (data.name === 'Order Data') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Patient Data Modify') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Order Data Modify') {
                        await this.configurationRecordSetId(data.id);
                    }

                    if (data.name === 'Branch Data') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Episode Visit Data') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Physicians By Location') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Locations By Physician Standard') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Payor Sources') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Order Data') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Order Data Modify') {
                        await this.configurationRecordSetId(data.id);
                    }
                    if (data.name === 'Episode Data') {
                        await this.configurationRecordSetId(data.id);
                    }
                }
            );
        }
        if (this.state.isRecordset) {
            return false;
        }
        /*  await this.setState({
            hideSmallView: true,
        }); */
        await this.props.deletedPage({
            hideSmallView: true,
            reportRedirect: this.props.location.pathname,
        });
        // if (String(rowHeader.key) === 'action') {
        //     this.setState(
        //         {
        //             selectedDocIds: rowDetails.id,
        //         },
        //         async () => {
        //             let actionButton = document.querySelectorAll(
        //                 `.settingsmenu${this.state.selectedDocIds}`
        //             );
        //             let elementPosition =
        //                 actionButton[0].getBoundingClientRect(); // Get PopOver Position
        //             var position = {
        //                 top: parseInt(elementPosition.top),
        //                 left: parseInt(elementPosition.x),
        //             };
        //             await this.setState({ popOverPosition: position });
        //         }
        //     );
        // }
        // //
        // if (this.props.callingFrom === 'related_document') {
        //     return true;
        // }
        // // lockDocument(this.props.selectedDocId)
        // // .then((data) => {})
        // // .catch((err) => {});

        // if (
        //     String(rowHeader.key) === 'documenttype' ||
        //     String(rowHeader.key) === 'branch' ||
        //     String(rowHeader.key) === 'documentname'
        // ) {
        const docid = Math.abs(rowDetails.id);
        await this.setState(
            {
                selectedDocId: Math.abs(rowDetails.id),
                visibleDoc: false,
            },
            async () => {
                //this.props.inboxPage({ related_doc_detail: true });

                // if (this.props.myParent === 'bigview') {
                //     this.props.inboxPage({ related_doc_from: 'bigview' });
                // }
                let processIdd = 3;
                let statusIdd = 20;

                const response = getDocumentMetaWithDocId({ docid });

                await response
                    .then((data) => {
                        if (data.hasOwnProperty('statuses')) {
                            let documentTypeArray = documentTypeMappings(
                                this.props.documentTypeDetailViewMappings
                            );

                            let selectedDocumentType =
                                data.miscPproperties.documentType;
                            for (let property in documentTypeArray) {
                                if (property === selectedDocumentType) {
                                    this.setState({
                                        viewDetail:
                                            formatHeaderNameWithOutSpace(
                                                documentTypeArray[property]
                                            ),
                                        currentBigView:
                                            formatHeaderNameWithOutSpace(
                                                documentTypeArray[property]
                                            ),
                                    });
                                }
                            }
                            if (data.hasOwnProperty('statuses')) {
                                if (data.statuses.length > 0) {
                                    if (this.state.viewDetail === '') {
                                        if (data.statuses[0].processId === 1) {
                                            this.props.deletedPage({
                                                currentBigView: 'inbox',
                                            });
                                            this.setState({
                                                currentBigView: 'inbox',
                                            });
                                        }
                                        if (data.statuses[0].processId === 3) {
                                            this.props.deletedPage({
                                                currentBigView: 'outbox',
                                            });
                                            this.setState({
                                                currentBigView: 'outbox',
                                            });
                                        } else {
                                            this.props.deletedPage({
                                                currentBigView: 'inbox',
                                            });
                                            this.setState({
                                                currentBigView: 'inbox',
                                            });
                                        }
                                    } else {
                                        this.props.deletedPage({
                                            currentBigView:
                                                this.state.viewDetail,
                                        });
                                        this.setState({
                                            currentBigView:
                                                this.state.viewDetail,
                                        });
                                    }

                                    const permission = userPermission({
                                        processId: data.statuses[0].processId,
                                        statusId: data.statuses[0].statusId,
                                        menu_name: 'Search',
                                        configData: this.props.configData,
                                    });

                                    this.props.deletedPage({
                                        allActions: permission.allActions,
                                        permission: permission,
                                        processId: data.statuses[0].processId,
                                        statusId: data.statuses[0].statusId,
                                    });
                                } else {
                                    this.props.deletedPage({
                                        allActions: undefined,
                                        permission: undefined,
                                        processId: undefined,
                                        statusId: undefined,
                                    });
                                }
                            }
                            if (data.statuses.length > 0) {
                                processIdd = data.statuses[0].processId;
                                statusIdd = data.statuses[0].statusId;
                                this.props.deletedPage({
                                    processId: data.statuses[0].processId,
                                    statusId: data.statuses[0].statusId,
                                });
                            }
                            var currentPath =
                                window.location.pathname.split('/');

                            this.props.history.push({
                                pathname:
                                    '/' +
                                    currentPath[1] +
                                    '/' +
                                    currentPath[2] +
                                    '/' +
                                    currentPath[3] +
                                    '/detail',
                                // '/detail/' +
                                // window.btoa(String(this.state.selectedDocIds)),
                                search:
                                    '?detail=' +
                                    window.btoa(String(rowDetails.id)),
                                state: {
                                    selectedDocID: window.btoa(
                                        String(rowDetails.id)
                                    ),
                                    processId: processIdd,
                                    statusId: statusIdd,
                                },
                            });
                            this.setState({
                                visibleDoc: true,
                                //onlyGridloader: true,
                                rowTrans: true,
                            });
                            document.body.classList.add('aside-minimize');
                        } else {
                            /* this.setState({
                            hideSmallView: false,
                        }); */
                            this.props.deletedPage({
                                hideSmallView: false,
                            });
                            showErrorToast(
                                'An error occurred while processing your request'
                            );
                        }
                    })
                    .catch((err) => {
                        console.log('err', err);
                    });
            }
        );
    };

    getRowData(startIndex, endIndex, data = []) {
        const gridData = data;
        if (data.length > 0) {
            return _.slice(gridData, startIndex, endIndex);
        }
        return [];
    }

    formDate = async (dateval) => {
        await this.setState({
            from_date: dateval,
        });
        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);

        localStorage.setItem(
            `filter-options-${pathname}-from_date`,
            formatDate(this.state.from_date, 'YYYY-MM-DD')
        );

        if (
            this.state.to_date !== '' &&
            this.state.to_date !== null &&
            this.state.to_date !== undefined
        ) {
            await this.removeAllGrouping();
            await this.setState({
                //loader: true,
                //daterange: event.target.value,
                propertyTiles: 'All Data',
                selectedRadialChart: 'All Data',
                filterTxt: '',
                colourSub: '#0f0f12',
                bgColourSub: '#F2F4F5',
            });
            await this.setState({
                //displayAnimation: true,
                //refresh: false,
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                filteredRowData: [],
                filteredData: this.state.allRows,
                propertyTiles: 'All Data',
                tileFilter: false,
                colourSub: '#0f0f12',
                bgColourSub: '#F2F4F5',

                customDateError: false,

                loader: true,
                selectedRadialChart: '',
                chartFilterTxt: '',
                stackFilter: '',
                chartName: '',
            });

            await this.getReportDocumentsData();
        }
        // localStorage.setItem(
        //     `filter-options-report-from_date`,
        //     formatDate(this.state.from_date, 'YYYY-MM-DD')
        // );
    };
    toDate = async (dateval) => {
        await this.setState({
            to_date: dateval,
            // tileGridRefresh: true,
        });
        if (this.state.from_date === '') {
            this.setState({
                customDateError: true,
                //tileGridRefresh: false,
            });
        } else {
            let A = moment(this.state.from_date);
            let B = moment(this.state.to_date);
            let diff_days = B.diff(A, 'days');

            if (diff_days < 0) {
                this.setState({
                    customDateError: true,
                });
            } else {
                let pathname = this.props.location.pathname;
                pathname = getPageFromPath(pathname);
                /* await this.setState({
                    customDateError: false,
                    propertyTiles: 'All Data',
                    filterTxt: '',
                    colourSub: '#0f0f12',
                    bgColourSub: '#F2F4F5',
                }); */
                localStorage.setItem(
                    `filter-options-${pathname}-to_date`,
                    formatDate(this.state.to_date, 'YYYY-MM-DD')
                );
                localStorage.setItem(
                    `filter-options-${pathname}-from_date`,
                    formatDate(this.state.from_date, 'YYYY-MM-DD')
                );
                // localStorage.setItem(
                //     `filter-options-report-to_date`,
                //     formatDate(this.state.to_date, 'YYYY-MM-DD')
                // );
                // localStorage.setItem(
                //     `filter-optionss=-report-from_date`,
                //     formatDate(this.state.from_date, 'YYYY-MM-DD')
                // );
                await this.removeAllGrouping();
                await this.setState({
                    //loader: true,
                    //daterange: event.target.value,
                    propertyTiles: 'All Data',
                    selectedRadialChart: 'All Data',
                    filterTxt: '',
                    colourSub: '#0f0f12',
                    bgColourSub: '#F2F4F5',
                });
                await this.setState({
                    //displayAnimation: true,
                    //refresh: false,
                    filterTxt: '',
                    perPage: 10,
                    currentPage: 0,
                    totalCount: 0,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filteredRowData: [],
                    filteredData: this.state.allRows,
                    propertyTiles: 'All Data',
                    tileFilter: false,
                    colourSub: '#0f0f12',
                    bgColourSub: '#F2F4F5',

                    customDateError: false,

                    loader: true,
                    selectedRadialChart: '',
                    chartFilterTxt: '',
                    stackFilter: '',
                    chartName: '',
                });
                await this.getReportDocumentsData();
            }
        }
        // setTimeout(() => {
        //     this.setState({
        //         tileGridRefresh: false,
        //     });
        // }, 1000);
    };

    async handleDateRangeOnChange(state, event) {
        const value = event.target.value;

        // if (value !== 'custom') {
        //     this.setState({
        //         tileGridRefresh: true,
        //     });
        // }
        await this.setState({
            //loader: true,
            daterange: event.target.value,
            propertyTiles: 'All Data',
            selectedRadialChart: 'All Data',
            filterTxt: '',
            colourSub: '#0f0f12',
            bgColourSub: '#F2F4F5',
        });
        await this.setState({
            //displayAnimation: true,
            //refresh: false,
            filterTxt: '',
            perPage: 10,
            currentPage: 0,
            totalCount: 0,
            sortDirection: 'NONE',
            sortColumn: '',
            filteredRowData: [],
            filteredData: this.state.allRows,
            propertyTiles: 'All Data',
            tileFilter: false,
            colourSub: '#0f0f12',
            bgColourSub: '#F2F4F5',

            customDateError: false,

            loader: true,
            selectedRadialChart: '',
            chartFilterTxt: '',
            stackFilter: '',
            chartName: '',
        });

        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);

        localStorage.setItem(`filter-options-${pathname}-dateRange`, value);
        // localStorage.setItem(`filter-options-report-dateRange`, value);

        if (value === 'custom') {
            this.setState({
                showCustomDateRange: true,
                to_date: undefined,
                from_date: undefined,
            });
            await localStorage.removeItem(
                `filter-options-${pathname}-from_date`
            );
            await localStorage.removeItem(`filter-options-${pathname}-to_date`);
        } else {
            this.setState({
                showCustomDateRange: false,
                //to_date: '',
                //from_date:'',
            });

            //await localStorage.removeItem(`filter-options-${pathname}-from_date`);
            //await localStorage.removeItem(`filter-options-${pathname}-to_date`);
        }
        if (value !== 'custom') {
            await this.removeAllGrouping();
            this.getReportDocumentsData();
        }

        // setTimeout(() => {
        //     this.setState({
        //         tileGridRefresh: false,
        //     });
        // }, 1000);
    }
    // this function is used for stack bar
    async chartFilter(searchText = '') {
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        const isValid = (str) => {
            //return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText) === false) {
            // Blocking Special Character
            return [];
        }
        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        await this.setState({ chartFilterTxt: inputData });

        const filteredData = _.filter(this.state.filteredData, function (data) {
            // filter Operation
            let passed = false;
            for (const property in data) {
                if (
                    property === 'doc_id' ||
                    property === 'tag_id' ||
                    property === 'id'
                ) {
                    continue;
                } // Excluding few Property from filter
                passed =
                    String(data[this.state.stackedbarchart.graphicalField2])
                        .toLowerCase()
                        .match(inputData) != null &&
                    String(data[this.state.stackedbarchart.graphicalField2])
                        .toLowerCase()
                        .match(inputData).length > 0;
                if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });

        // let state = {};
        // if (searchText.trim() === '') {
        //     state = {
        //         sortColumn: '',
        //         sortDirection: 'NONE',
        //     };
        // }

        // // Condition Specifically handled for Grouping + filter
        // let paginatedRowData =
        //     this.state.groupBy.length > 0 && inputData.trim() !== ''
        //         ? filteredData
        //         : this.getRowData(0, this.state.perPage, filteredData);
        // paginatedRowData =
        //     this.state.groupBy.length > 0 && inputData.trim() === ''
        //         ? this.state.allRows
        //         : paginatedRowData;
        // End

        // if (preventState === false) {
        //     state = { ...state, currentPage: 0 };
        // }
        this.setState({
            chartfilteredData: filteredData,
        });
        // await this.setState(
        //     Object.assign(
        //         {
        //             filteredData: filteredData,
        //             // chartFilterTxt: searchText,
        //             filteredRowData: paginatedRowData,
        //             totalCount: filteredData.length,
        //             isFilterRowEmpty: filteredData.length === 0,
        //         },
        //         state
        //     ),
        //     () => {
        //         this.resetPopOverData(filteredData);
        //     }
        // );
        // added for redux storing data used to come back from detail view
        this.props.deletedPage({
            filterTxt_chart: searchText,
        });
        // return filteredData;
    }
    async radialChartOnClick(searchText = '', chartName = '') {
        if (this.state.groupBy.length > 0) {
            await this.removeAllGrouping();
        }

        if (this.state.noDataFound === true) {
            await this.setState({
                chartFilterTxt: searchText,
                chartName: chartName,
            });
            await this.props.deletedPage({
                onClickchartName: chartName,
                onClickchartFilterTxt: searchText,
            });

            await this.setState({
                noDataOnClick: true,
            });
        } else {
            await this.setState({
                chartName: chartName,
            });

            if (
                chartName === 'Radial' ||
                chartName === 'Donut' ||
                chartName === 'Treemap'
            ) {
                await this.setState({
                    stackFilter: undefined,
                });
            }

            searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
            let isValid;
            //if(searchText !== '(Blank)'){
            isValid = (str) => {
                //return !/[~`!#$@%\^&*+=\-\[\]\\';,{}|\\"<>\?]/g.test(str);
                return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
            };
            if (isValid(searchText) === false) {
                // Blocking Special Character
                return [];
            }
            //}

            // End
            let graphicalField;
            if (chartName === 'Radial') {
                graphicalField = this.state.radialbarchart.graphicalField;
            } else if (chartName === 'Donut') {
                graphicalField = this.state.simpledonut.graphicalField;
            } else if (chartName === 'Stack') {
                graphicalField = this.state.stackedbarchart.graphicalField;
            } else if (chartName === 'Treemap') {
                graphicalField = this.state.treemap.graphicalField;
            } /*else{
                graphicalField=this.state.radialbarchart.graphicalField
            } */
            let inputData = searchText;
            inputData = inputData.toLowerCase();
            inputData = inputData.trim();
            // if(searchText === '(Blank)'){
            //     inputData = 'blank'
            // }

            await this.props.deletedPage({
                chartName: chartName,
                chartFilterTxt: searchText,
            });
            await this.setState({ selectedRadialChart: inputData });

            let allData;
            if (
                this.state.filterTxt !== '' &&
                this.state.filterTxt !== undefined &&
                this.state.chartFilterTxt === ''
            ) {
                allData = this.state.filteredData;
            } else if (
                this.state.filterTxt !== '' &&
                this.state.filterTxt !== undefined &&
                this.state.chartFilterTxt !== ''
            ) {
                allData = this.state.filterAllRows;
            } else if (
                this.state.chartName === 'Stack' &&
                ((this.state.stackFilter !== undefined &&
                    this.state.stackFilter !== '') ||
                    (this.props.deleted_page.stackFilter !== undefined &&
                        this.props.deleted_page.stackFilter !== ''))
            ) {
                allData = this.state.chartfilteredData;
            } else {
                allData = this.state.allRows;
            }

            const filteredData = _.filter(allData, function (data) {
                // filter Operation
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        continue;
                    } // Excluding few Property from filter

                    if (inputData === 'blank') {
                        passed = String(data[graphicalField]).length == 0;
                    } else {
                        passed =
                            String(data[graphicalField])
                                .toLowerCase()
                                .match(inputData) != null &&
                            String(data[graphicalField])
                                .toLowerCase()
                                .match(inputData).length > 0;
                    }
                    // }else{
                    //     passed= data[graphicalField] === '' ? data[graphicalField]: null
                    // }

                    if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                }
                return passed;
            });

            let state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }

            // Condition Specifically handled for Grouping + filter
            let paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() !== ''
                    ? filteredData
                    : this.getRowData(0, this.state.perPage, filteredData);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? allData
                    : paginatedRowData;
            // End

            // if (preventState === false) {
            //     state = { ...state, currentPage: 0 };
            // }
            if (this.state.filterTxt !== '') {
                // this.props.deletedPage({
                //     currentPage_Report : 0,
                //     perPage_Report : 10,
                // });
                await this.setState(
                    Object.assign(
                        {
                            filteredDataOriginal: filteredData,
                            //currentPage: 0,
                            filteredData: filteredData,
                            chartFilterTxt: searchText,
                            filteredRowData: paginatedRowData,
                            totalCount: filteredData.length,
                            isFilterRowEmpty: filteredData.length === 0,
                            perPage: 10,
                            currentPage: 0,
                        },
                        state
                    ),
                    () => {
                        this.resetPopOverData(filteredData);
                    }
                );
            } else {
                // this.props.deletedPage({
                //     currentPage_Report : 0,
                //     perPage_Report : 10,
                // });
                await this.setState(
                    Object.assign(
                        {
                            perPage: 10,
                            currentPage: 0,
                            filteredData: filteredData,
                            chartFilterTxt: searchText,
                            filteredRowData: paginatedRowData,
                            totalCount: filteredData.length,
                            isFilterRowEmpty: filteredData.length === 0,
                        },
                        state
                    ),
                    () => {
                        this.resetPopOverData(filteredData);
                    }
                );
            }

            // this.props.deletedPage({
            //     filterTxt_chart: searchText,
            // });
            return filteredData;
        }
    }
    async filterDataGrid(searchText = '', preventState = false) {
        await this.setState({
            selectedRadialChart: '',
            chartFilterTxt: '',
            chartName: '',
            stackFilter: '',
        });
        await this.props.deletedPage({
            chartFilterTxt: '',
            stackFilter: '',
        });
        // Fixes for ReEx issue
        searchText = String(searchText).replaceAll(/\\/g, ''); // Remove backward slash from string
        const isValid = (str) => {
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText) === false) {
            // Blocking Special Character
            return [];
        }
        // End
        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        if (this.state.tileFilter !== true) {
            const filteredData = _.filter(this.state.allRows, function (data) {
                // filter Operation
                let passed = false;
                for (const property in data) {
                    if (
                        property === 'doc_id' ||
                        property === 'tag_id' ||
                        property === 'id'
                    ) {
                        continue;
                    } // Excluding few Property from filter
                    passed =
                        String(data[property]).toLowerCase().match(inputData) !=
                            null &&
                        String(data[property]).toLowerCase().match(inputData)
                            .length > 0;
                    if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                }
                return passed;
            });
            let state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }

            // Condition Specifically handled for Grouping + filter
            let paginatedRowData =
                (this.state.groupBy.length > 0 && inputData.trim() !== '') ||
                preventState
                    ? filteredData
                    : this.getRowData(0, this.state.perPage, filteredData);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? filteredData
                    : paginatedRowData;
            // End

            if (preventState === false) {
                state = { ...state, currentPage: 0 };
            }
            if (filteredData.length === 0) {
                this.setState({
                    hideChart: true,
                });
            } else {
                this.setState({
                    hideChart: false,
                });
            }
            await this.setState(
                Object.assign(
                    {
                        filteredData: filteredData,
                        filterAllRows: filteredData,
                        filterTxt: searchText,
                        filteredRowData: paginatedRowData,
                        totalCount: filteredData.length,
                        isFilterRowEmpty: filteredData.length === 0,
                    },
                    state
                ),
                () => {
                    this.resetPopOverData(filteredData);
                }
            );
            this.props.deletedPage({
                filterTxt_report: searchText,
            });
            return filteredData;
        } else {
            this.props.deletedPage({
                filterTxt_report: searchText,
            });

            const filteredData = _.filter(
                this.state.copyAllRows,
                function (data) {
                    // filter Operation
                    let passed = false;
                    for (const property in data) {
                        if (
                            property === 'doc_id' ||
                            property === 'tag_id' ||
                            property === 'id'
                        ) {
                            continue;
                        } // Excluding few Property from filter
                        passed =
                            String(data[property])
                                .toLowerCase()
                                .match(inputData) != null &&
                            String(data[property])
                                .toLowerCase()
                                .match(inputData).length > 0;
                        if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                    }
                    return passed;
                }
            );
            let state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }

            // Condition Specifically handled for Grouping + filter
            let paginatedRowData =
                (this.state.groupBy.length > 0 && inputData.trim() !== '') ||
                preventState
                    ? filteredData
                    : this.getRowData(0, this.state.perPage, filteredData);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? this.state.allRows
                    : paginatedRowData;
            // End
            let AnotherpaginatedRowData = paginatedRowData;
            if (preventState === false) {
                state = { ...state, currentPage: 0 };
            }
            await this.setState(
                Object.assign(
                    {
                        filteredData: filteredData,
                        filterAllRows: filteredData,
                        filterTxt: searchText,
                        //filteredRowData: paginatedRowData,
                        // totalCount: filteredData.length,
                        //isFilterRowEmpty: filteredData.length === 0,
                    },
                    state
                ),
                () => {
                    //this.resetPopOverData(filteredData);
                }
            );

            // if(this.state.filterTxt === '' && ){
            //     this.setState({

            //     })
            // }
            const filteredDataTile = _.filter(
                this.state.allRows,
                function (data) {
                    // filter Operation
                    let passed = false;

                    for (const property in data) {
                        if (
                            property === 'doc_id' ||
                            property === 'tag_id' ||
                            property === 'id'
                        ) {
                            continue;
                        } // Excluding few Property from filter
                        passed =
                            String(data[property])
                                .toLowerCase()
                                .match(inputData) != null &&
                            String(data[property])
                                .toLowerCase()
                                .match(inputData).length > 0;
                        if (passed) break; // if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
                    }
                    return passed;
                }
            );
            state = {};
            if (searchText.trim() === '') {
                state = {
                    sortColumn: '',
                    sortDirection: 'NONE',
                };
            }

            // Condition Specifically handled for Grouping + filter
            paginatedRowData =
                (this.state.groupBy.length > 0 && inputData.trim() !== '') ||
                preventState
                    ? filteredDataTile
                    : this.getRowData(0, this.state.perPage, filteredDataTile);
            paginatedRowData =
                this.state.groupBy.length > 0 && inputData.trim() === ''
                    ? this.state.allRows
                    : paginatedRowData;
            // End

            if (preventState === false) {
                state = { ...state, currentPage: 0 };
            }
            if (
                filteredDataTile.length !== 0 &&
                this.state.propertyTiles !== 'All Data'
            ) {
                await this.setState(
                    Object.assign(
                        {
                            //filteredData: filteredData,
                            //filterTxt: searchText,
                            filteredRowData: paginatedRowData,
                            totalCount: filteredDataTile.length,
                            isFilterRowEmpty: filteredDataTile.length === 0,
                        },
                        state
                    ),
                    () => {
                        this.resetPopOverData(filteredDataTile);
                    }
                );
                return filteredDataTile;
            } else {
                this.setState({
                    propertyTiles: 'All Data',
                });
                await this.setState(
                    Object.assign(
                        {
                            //filteredData: filteredData,
                            //filterTxt: searchText,
                            filteredRowData: AnotherpaginatedRowData,
                            totalCount: filteredData.length,
                            isFilterRowEmpty: filteredData.length === 0,
                        },
                        state
                    ),
                    () => {
                        this.resetPopOverData(filteredData);
                    }
                );
                return filteredData;
            }
        }
    }
    async refreshTable() {
        // Removide By S1008, ( maintain data range )
        // await this.setState({
        //     daterange: '',
        // });

        document.body.classList.remove('doc-detail-view');
        document.body.classList.remove('aside-minimize');
        await this.removeAllGrouping();

        //await this.getReportDocumentsData();
        const resetIt = async () => {
            //this.componentDidMount();
            await this.setState({
                displayAnimation: true,
                refresh: false,
                filterTxt: '',
                perPage: 10,
                currentPage: 0,
                totalCount: 0,
                sortDirection: 'NONE',
                sortColumn: '',
                filteredRowData: [],
                filteredData: this.state.allRows,
                propertyTiles: 'All Data',
                tileFilter: false,
                colourSub: '#0f0f12',
                bgColourSub: '#F2F4F5',
                // Removide By S1008, ( maintain data range )
                // to_date: '',
                // from_date: '',
                // daterange: '',
                customDateError: false,
                loader: true,
                selectedRadialChart: '',
                chartFilterTxt: '',
                stackFilter: '',
                chartName: '',
                NoGraph: false,
                hideChart: false,
            });
            setTimeout(() => {
                this.setState({
                    displayAnimation: false,
                });
            }, 1000);
        };
        let pathname = this.props.location.pathname;
        pathname = getPageFromPath(pathname);
        // Removide By S1008, ( maintain data range )
        // await localStorage.removeItem(`filter-options-${pathname}-dateRange`);
        // await localStorage.removeItem(`filter-options-${pathname}-from_date`);
        // await localStorage.removeItem(`filter-options-${pathname}-to_date`);

        // await localStorage.removeItem(`filter-options-report-dateRange`);
        // await localStorage.removeItem(`filter-options-report-from_date`);
        // await localStorage.removeItem(`filter-options-report-to_date`);
        await this.setState(
            {
                refresh: true,
                loader: false,
            },
            () => {
                setTimeout(() => {
                    resetIt();
                }, 100);
            }
        );
        await this.props.deletedPage({
            filterTxt_report: undefined,
            chartName: '',
            chartFilterTxt: '',
            stackFilter: '',
            currentPage_Report: 0,
            perPage_Report: 10,
            stackFilter: '',
            refreshTableDataGrid: true,
        });
        await this.getReportDocumentsData();
    }

    nextPage(event, newPage) {
        let startIndex = parseInt(newPage) * parseInt(this.state.perPage);
        let endIndex = startIndex + parseInt(this.state.perPage);
        var paginatedRowData;
        if (
            this.state.filterTxt !== '' ||
            this.state.stackFilter !== '' ||
            (this.state.selectedRadialChart !== '' &&
                this.state.graphicalTypeArray.includes('tile') === false)
        ) {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }
        if (
            this.state.propertyTiles !== 'All Data' &&
            this.state.graphicalTypeArray.includes('tile')
        ) {
            paginatedRowData = this.state.allRows;
        }
        // paginatedRowData = this.state.allRows;
        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            currentPage: newPage,
            filteredRowData: paginatedRowData,
        });
        this.props.deletedPage({
            currentPage_Report: newPage,
        });
    }
    handleChangeRowsPerPage(event) {
        let startIndex = 0;
        let endIndex = parseInt(event.target.value);
        let paginatedRowData;
        if (this.state.filterTxt !== '' || this.state.stackFilter !== '') {
            paginatedRowData = this.state.filteredData;
        } else {
            paginatedRowData = this.state.allRows;
        }

        paginatedRowData = _.slice(paginatedRowData, startIndex, endIndex);
        this.setState({
            rowData: paginatedRowData,
            filteredRowData: paginatedRowData,
            perPage: endIndex,
            currentPage: 0,
        });
        this.props.deletedPage({
            currentPage_Report: 0,
            perPage_Report: endIndex,
        });
    }

    handleSearch = (searchText) => {
        this.setState({
            refreshDataGrid: false,
            relDocLoading: true,
        });
        searchText = String(searchText).replaceAll(/\\/g, ''); //Remove backward slash from string

        let inputData = searchText;
        inputData = inputData.toLowerCase();
        inputData = inputData.trim();
        let filteredData = _.filter(this.state.allRows, function (data) {
            // filter Operation
            let passed = false;
            for (let property in data) {
                if (property === 'id') {
                    continue;
                } //Excluding few Property from filter
                passed =
                    String(data[property]).toLowerCase().match(inputData) !=
                        null &&
                    String(data[property]).toLowerCase().match(inputData)
                        .length > 0;
                if (passed) break; //if ATLEAST one PROPERTY satisfy the search condition ,BREAK and MOVE to next Object
            }
            return passed;
        });
        var length = filteredData.length;

        var paginatedRowData = _.slice(filteredData, 0, 10);
        this.setState({
            filteredData: filteredData,
            relDocLoading: false,
            rowData: paginatedRowData,
            //allRows: filteredData,
            totalCount: length,
            currentPage: 0,
            perPage: 10,
            refreshDataGrid: true,
        });
        this.props.deletedPage({
            currentPage_Report: 0,
            perPage_Report: 10,
        });
    };

    draggableColumns() {
        const headerRenderer = (props) => {
            return (
                <DraggableHeaderRenderer
                    {...props}
                    {...this.state}
                    row_transition={this.props.deleted_page.row_transition}
                    setGrouping={this.createGroupedData}
                    onColumnsReorder={handleColumnsReorder.bind(this)}
                    handleClickOutside={handleClickOutside}
                    sortFields={(sortColumn, sortDirection) =>
                        this.sortRows(sortColumn, sortDirection)
                    }
                />
            );
        };
        let header = this.state.header;
        let pathname = this.props.location.pathname;
        pathname = formatPathName(pathname);
        if (header.length > 0 && !isMobile) {
            // HEADER REORDER AND WIDTH SETTING  FROM LOCALSTORAGE
            let headerReorder = localStorage.getItem(
                `header-reorder-${pathname}`
            );
            let headerWidth = localStorage.getItem(`header-width-${pathname}`);
            if (headerReorder !== null && headerReorder !== '') {
                headerReorder = JSON.parse(headerReorder);
                header = headerDataFormating({ headerReorder, header });
            }
            if (headerWidth !== null && headerWidth !== '') {
                headerWidth = JSON.parse(headerWidth);
                header = headerDataFormating({ headerWidth, header });
            }
        } //END
        if (header.length === 0) {
            header = this.state.header;
        }
        header = header.filter((data) => {
            data.rowGroup = false;
            if (data.groupFormatter !== undefined) {
                delete data.groupFormatter; // issue fixes for grouping
            }
            return data;
        });
        return header.map((c) => {
            if (this.state.groupBy.length > 0) {
                c.rowGroup = this.state.groupBy.indexOf(c.key) !== -1;
                if (c.rowGroup) {
                    c.width = c.width > 200 ? c.width : 200;
                    c.groupFormatter = ToggleGroupFormatter.bind(this);
                }
            }
            if (c.key === 'owner') {
                c.editor = DropDownEditor.bind(this);
                c.editorOptions = { createPortal: true, editOnClick: true };
                c.editable = true;
            }
            if (c.key === 'select-row') {
                c.selectedCount = Array.from(this.state.selectedRows).length;
            }
            c.row_transition = this.state.row_transition;
            if (
                c.key === 'id' ||
                c.key === 'select-row' ||
                this.state.tableDesign === 'simple'
            )
                return c;
            return { ...c, headerRenderer: headerRenderer };
        });
    }

    createGroupedData(data, type = '', mode = false) {
        this.setState({ customGroupedData: [] });
        let groupByData = this.state.groupByData;

        let { expandedGroupIds } = this.state; //Grouping Expansion Id
        for (const property in groupByData) {
            groupByData[property] = groupByData[property].map((data) =>
                data.trim().toLowerCase()
            );
        }
        const str_pad = (num) => {
            const str1 = '';
            return str1.padStart(num, '_');
        };
        if (String(type) === 'ALL') {
            //SELECT ALL option in POPOVER
            groupByData[data.title] = [];
            let collection = data.value;
            collection = collection.map((v) => v.trim().toLowerCase());
            groupByData[data.title] = mode ? collection : [];

            let groupBy = [];
            let openState = this.state.defaultPopOpenState;
            for (let names in openState) {
                openState[names] = false;
            }
            for (const props in groupByData) {
                if (groupByData[props].length > 0) {
                    groupBy.push(props);
                }
            }
            let groupingValueData = Object.values(groupByData);
            groupingValueData = groupingValueData.filter((v) => v.length > 0);
            let expandedArray = Array.from(expandedGroupIds);
            let lastIndexData = groupingValueData[groupingValueData.length - 1];
            let str = str_pad(2);
            if (lastIndexData !== undefined) {
                if (expandedArray.length > 0) {
                    if (
                        lastIndexData !== undefined &&
                        lastIndexData.length > 0
                    ) {
                        expandedArray.forEach((v1) => {
                            lastIndexData.forEach((v2) => {
                                v2 =
                                    v2.charAt(0).toUpperCase() +
                                    v2.slice(1, v2.length);
                                expandedGroupIds.add(v2);
                                let parentIndexCount = v1.split('__').length;
                                if (parentIndexCount <= groupBy.length) {
                                    expandedGroupIds.add(`${v1}${str}${v2}`);
                                }
                            });
                        });
                    }
                } else {
                    lastIndexData.forEach((v2) => {
                        v2 =
                            v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                        if (!expandedGroupIds.has(v2)) {
                            expandedGroupIds.add(v2);
                        }
                    });
                }
            }
            //let rowData = this.state.rowData;

            let rowData = this.state.allRows;
            if (!mode && !(groupBy.length > 0)) {
                // rowData = _.slice(rowData, 0, this.state.perPage);
                rowData = this.state.allRows;
            }
            if (this.state.filterTxt !== '' || this.state.stackFilter !== '') {
                rowData = this.state.filteredData;
            }
            this.setState({
                groupBy: groupBy,
                groupByData: groupByData,
                defaultOpen: groupBy.length > 0,
                groupLoader: groupBy.length > 0,
                filteredRowData: rowData,
                // filteredRowData: [],
                selectedRows: new Set(),
                tempExpandedGroupIds:
                    groupBy.length > 0 ? expandedGroupIds : new Set(),
                defaultPopOpenState: openState,
                sortColumn: '',
                sortDirection: 'NONE',
                reInitiateGrid: true,
            });
            if (mode === false) {
                this.resetPopOverData(rowData);
            }
            return;
        } //End SELECT ALL
        if (
            data.title !== undefined &&
            this.state.groupByData[data.title] === undefined
        ) {
            //Check , value already exist, if not,  assign it to Array
            groupByData[data.title] = [];
        }
        let val = data.value.trim().toLowerCase();
        if (
            String(groupByData[data.title].indexOf(val)) === String(-1) &&
            mode
        ) {
            // mode = true, add value to array
            groupByData[data.title].push(val);
        } else if (!mode) {
            // mode = false , Remove value
            let index = groupByData[data.title].indexOf(val);
            groupByData[data.title].splice(index, 1);
        }
        let groupBy = [];
        let openState = this.state.defaultPopOpenState; // FOR POPOVER OPEN or CLOSE
        for (let names in openState) {
            openState[names] = false; //CLOSING ALL POPOVER
        }
        openState[data.title] = true; //OPENING CURRENT POPOVER
        let groupedValueData = [];
        for (const props in groupByData) {
            if (groupByData[props].length > 0) {
                groupBy.push(props);
                groupByData[props].forEach((d) => {
                    groupedValueData.push(d);
                });
            }
        }
        let groupingValueData = Object.values(groupByData);
        groupingValueData = groupingValueData.filter((v) => v.length > 0);
        let expandedArray = Array.from(expandedGroupIds);
        let lastIndexData = groupingValueData[groupingValueData.length - 1];
        let str = str_pad(2);
        if (lastIndexData !== undefined) {
            // Generate Grouping Expansion Id
            if (expandedArray.length > 0) {
                if (lastIndexData !== undefined && lastIndexData.length > 0) {
                    expandedArray.forEach((v1) => {
                        lastIndexData.forEach((v2) => {
                            v2 =
                                v2.charAt(0).toUpperCase() +
                                v2.slice(1, v2.length);
                            expandedGroupIds.add(v2);
                            let parentIndexCount = v1.split('__').length;
                            if (parentIndexCount <= groupBy.length) {
                                expandedGroupIds.add(`${v1}${str}${v2}`);
                            }
                        });
                    });
                }
            } else {
                lastIndexData.forEach((v2) => {
                    v2 = v2.charAt(0).toUpperCase() + v2.slice(1, v2.length);
                    if (!expandedGroupIds.has(v2)) {
                        expandedGroupIds.add(v2);
                    }
                });
            }
        } //End
        //let rowData = this.state.rowData;
        let rowData = this.state.allRows;
        if (String(groupBy.length) === String(0)) {
            rowData = _.slice(rowData, 0, this.state.perPage); //Paginating the data
        }

        if (String(this.state.filterTxt) !== '') {
            //rowData = this.state.allFilteredRowData;

            rowData = this.state.filteredData;
        }
        if (this.state.stackFilter !== '') {
            //rowData = this.state.allFilteredRowData;

            rowData = this.state.filteredData;
        }
        // if (!mode && !(groupBy.length > 0)) {
        //     rowData = _.slice(rowData, 0, this.state.perPage);
        // }

        //rowData = this.performGroupingFilter(groupedValueData)

        this.setState({
            groupBy: groupBy, //REQUIRED STATE FOR GROUPING
            groupByData: groupByData,
            defaultOpen: groupBy.length > 0,
            groupLoader: groupBy.length > 0,
            filteredRowData: rowData,
            // filteredRowData: [],
            selectedRows: new Set(),
            //expandedGroupIds:  (groupBy.length > 0) ? expandedGroupIds : new Set(),
            tempExpandedGroupIds:
                groupBy.length > 0 ? expandedGroupIds : new Set(),
            defaultPopOpenState: openState,
            sortColumn: '',
            sortDirection: 'NONE',
            reInitiateGrid: true,
            // uniqueData:""
            // refreshDataGrid :true
        });

        //Abu added @19-01-2022 {
        if (mode === false) {
            this.resetPopOverData(rowData);
        }
        //}
    }

    async sortRows(sortColumn, sortDirection) {
        let columnDataType;
        this.state.header.map((item, index) => {
            if (item.key === sortColumn) {
                columnDataType = item.datatype;
            }
        });

        if (sortDirection !== 'NONE') {
            let paginatedRowData = this.state.allRows;

            if (
                this.state.filterTxt.trim() !== '' ||
                this.state.selectedRadialChart !== '' ||
                this.state.stackFilter !== ''
            ) {
                paginatedRowData = this.state.filteredData;
            }

            paginatedRowData = await sortMethod(
                paginatedRowData,
                sortColumn,
                sortDirection,
                this.state.dateFormatFields,
                columnDataType
            );

            let rowData = this.getRowData(
                0,
                this.state.perPage,
                paginatedRowData
            );

            let openState = this.state.defaultPopOpenState;
            for (let names in openState) {
                openState[names] = false;
            }

            let newState = {
                filteredRowData:
                    this.state.groupBy.length > 0 ? paginatedRowData : rowData,
                currentPage: 0,
                sortDirection: sortDirection,
                sortColumn: sortColumn,
                defaultPopOpenState: openState,
            };

            if (
                ((this.state.propertyTiles === 'All Data' &&
                    this.state.graphicalTypeArray.includes('tile')) ||
                    (this.state.selectedRadialChart === '' &&
                        this.state.graphicalTypeArray.includes('tile') ===
                            false)) &&
                this.state.stackFilter === ''
            ) {
                newState.filteredData = paginatedRowData;
                newState.allRows = paginatedRowData;
            } else {
                if (this.state.graphicalTypeArray.includes('tile')) {
                    newState.allRows = paginatedRowData;
                } else {
                    newState.filteredData = paginatedRowData;
                }
            }
            this.setState(newState);

            this.props.deletedPage({
                currentPage_Report: 0,
                perPage_Report: this.state.perPage,
            });
        }
    }

    // this function duplicate of sortRows ( Changes : {paginatedRowData: filteredRowData, allRows removed form setState } )
    // Pls dont use below funtion is not work for sorting (Pls change next page )
    /* headerSort(sortColumn, sortDirection) {      
        if (sortDirection !== 'NONE') {
            
            //let paginatedRowData = this.state.rowData;
            let paginatedRowData = this.state.allRows;

            if (this.state.filterTxt.trim() !== '') {
                paginatedRowData = this.state.filteredData;
                
            }
          
            let fields = sortFields(
                sortColumn,
                sortDirection,
                this.state.dateFormatFields
            );
            paginatedRowData = _.orderBy(
                paginatedRowData,
                fields.sortfn,
                fields.orderBy
            );

            let rowData = this.getRowData(
                0,
                this.state.perPage,
                paginatedRowData
            );

            let openState = this.state.defaultPopOpenState;
            for (let names in openState) {
                openState[names] = false;
            }

            this.setState({
                filteredRowData:
                    this.state.groupBy.length > 0 ? paginatedRowData : rowData,
                // filteredRowData:
                // allRows: paginatedRowData,
                currentPage: 0,
                sortDirection: sortDirection,
                sortColumn: sortColumn,
                defaultPopOpenState: openState,
            });
            this.props.deletedPage({
                currentPage_Report: 0,
                perPage_Report: this.state.perPage,
            });
        }
    } */

    //Common Function used to regenerate Popover Data while filtering
    resetPopOverData(filteredData = []) {
        let uniqueData = {};
        let uniqueTagNameValues = new Set();
        let makeData = this.state.allRows;

        //due to this condition it changing the bar color
        if (this.state.filterTxt !== '') {
            makeData = this.state.filterAllRows;
        }
        if (
            String(this.props.location.pathname).includes(
                'stacked-bar-chart-test'
            ) === true &&
            this.state.stackFilter !== ''
        ) {
            makeData = this.state.filterAllRows;
        }
        if (
            (this.state.stackFilter !== undefined &&
                this.state.stackFilter !== '') ||
            (this.props.deleted_page.stackFilter !== undefined &&
                this.props.deleted_page.stackFilter !== '')
        ) {
            makeData = this.state.chartfilteredData;
        }
        if (filteredData.length !== 0) {
            makeData = filteredData;
        }

        let modifiedColumnsNames = Array.from(this.state.modifiedColumnsNames);
        //  makeData=Object.values(this.state.customGroupedData)

        if (makeData !== undefined) {
            makeData.forEach((data, i) => {
                for (let property in data) {
                    if (uniqueData[property] === undefined) {
                        uniqueData[property] = new Set();
                    }
                    if (this.state.dateFormatFields.indexOf(property) !== -1) {
                        if (
                            property === 'date&batch' &&
                            data[property] !== undefined
                        ) {
                            uniqueData[property].add(data[property]);
                        } else if (data[`${property}_rawdata`] !== undefined) {
                            this.state.header?.map((item, idx) => {
                                if (item.datatype === 'DateTime') {
                                    if (
                                        formatHeaderNameWithOutSpace(
                                            item.name
                                        ) === property
                                    ) {
                                        uniqueData[property].add(
                                            formatDate(
                                                data[`${property}_rawdata`],
                                                'MM/DD/YYYY HH:mm:ss'
                                            )
                                        );
                                    }
                                } else {
                                    uniqueData[property].add(
                                        formatDate(
                                            data[`${property}_rawdata`],
                                            'MM/DD/YYYY'
                                        )
                                    );
                                }
                            });
                        }
                    } else if (
                        property === 'tags' &&
                        data[`${property}_data`] !== undefined
                    ) {
                        uniqueTagNameValues.add(data[`${property}_data`]);
                    } else if (
                        modifiedColumnsNames.indexOf(property) !== -1 &&
                        data[`${property}_rawdata`] !== undefined
                    ) {
                        uniqueData[property].add(data[`${property}_rawdata`]);
                    } else if (data[property] !== undefined) {
                        uniqueData[property].add(data[property]);
                    }
                }
            });
        }

        this.setState({
            uniqueData,
            uniqueTagNameValues,
        });
    }

    OutboundStatusFumction(passingRow = []) {
        let outboudData = {};
        let uniqueTagNameValues = new Set();
        let makeData = passingRow;
        //due to this condition it changing the bar color
        // if (this.state.filterTxt !== '' || this.state.chartFilterTxt !== '') {
        //     makeData = this.state.filterAllRows;
        // }
        // if (
        //     this.state.stackFilter !== undefined &&
        //     this.state.stackFilter !== ''
        // ) {
        //     makeData = this.state.chartfilteredData;
        // }

        let modifiedColumnsNames = Array.from(this.state.modifiedColumnsNames);
        //  makeData=Object.values(this.state.customGroupedData)
        if (makeData !== undefined) {
            makeData.forEach((data, i) => {
                for (let property in data) {
                    if (outboudData[property] === undefined) {
                        outboudData[property] = new Set();
                    }
                    if (this.state.dateFormatFields.indexOf(property) !== -1) {
                        if (
                            property === 'date&batch' &&
                            data[property] !== undefined
                        ) {
                            outboudData[property].add(data[property]);
                        } else if (data[`${property}_rawdata`] !== undefined) {
                            outboudData[property].add(
                                formatDate(
                                    data[`${property}_rawdata`],
                                    'MM/DD/YYYY'
                                )
                            );
                        } else if (
                            data[`${property}_dateTimedata`] !== undefined
                        ) {
                            outboudData[property].add(data[property]);
                        }
                    } else if (
                        property === 'tags' &&
                        data[`${property}_data`] !== undefined
                    ) {
                        uniqueTagNameValues.add(data[`${property}_data`]);
                    } else if (
                        modifiedColumnsNames.indexOf(property) !== -1 &&
                        data[`${property}_rawdata`] !== undefined
                    ) {
                        outboudData[property].add(data[`${property}_rawdata`]);
                    } else if (data[property] !== undefined) {
                        outboudData[property].add(data[property]);
                    }
                }
            });
        }

        this.setState({
            outboudData,
        });
    }

    createGroupByRows(groupedData, indexProp) {
        // this.props.deletedPage({
        //     groupData_report: data,
        // });

        for (const [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            if (String(property) === String(indexProp)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.groupByData[property] =
                    this.state.sortDirection === 'ASC'
                        ? rowValue.sort()
                        : rowValue.sort().reverse(); //Performing Sorting
            }
        }
        //End

        let customGroupedData = [];
        let groupResult = {};
        let dateFieldColumn = '';
        let modifiedColumnsNames = Array.from(this.state.modifiedColumnsNames);

        //Check If it is Date Field And Group It
        let emptyDateBatch = [];

        if (this.state.dateFormatFields.indexOf(indexProp) !== -1) {
            let index = this.state.dateFormatFields.indexOf(indexProp);
            dateFieldColumn = this.state.dateFormatFields[index];
            if (dateFieldColumn === 'date&batch') {
                customGroupedData = _.groupBy(groupedData, 'date_batch');
            } else {
                // customGroupedData = _.groupBy(
                //     groupedData,
                //     `${dateFieldColumn}_rawdata`
                // );
                customGroupedData = _.groupBy(
                    groupedData,
                    `${dateFieldColumn}_onlyDate`
                );
            }
        } else if (modifiedColumnsNames.indexOf(indexProp) !== -1) {
            customGroupedData = _.groupBy(groupedData, `${indexProp}_rawdata`);
        } else {
            customGroupedData = _.groupBy(groupedData, `${indexProp}`);
        }

        customGroupedData = ObjectPropertyToLowerCase(customGroupedData);
        for (let [property, rowValue] of Object.entries(
            this.state.groupByData
        )) {
            let index = this.state.groupBy.indexOf(property);
            if (index !== -1 && rowValue.length === 0) {
                this.state.groupBy.splice(index, 1);
            }
            if (String(property) === String(indexProp)) {
                rowValue.forEach((data, i) => {
                    let dateData = data.trim();

                    if (customGroupedData[dateData] !== undefined) {
                        let fields = sortFields(
                            this.state.sortColumn,
                            this.state.sortDirection,
                            this.state.dateFormatFields
                        );
                        customGroupedData[dateData] = _.orderBy(
                            customGroupedData[dateData],
                            fields.sortfn,
                            fields.orderBy
                        );
                        if (this.state.sortColumn) {
                            let columnDataType;
                            this.state.header.map((item, index) => {
                                if (item.key === this.state.sortColumn) {
                                    columnDataType = item.datatype;
                                }
                            });

                            customGroupedData[dateData] = sortMethod(
                                customGroupedData[dateData],
                                this.state.sortColumn,
                                this.state.sortDirection,
                                this.state.dateFormatFields,
                                columnDataType
                            );
                        }
                        groupResult[data] = customGroupedData[dateData];
                    } else {
                        emptyDateBatch.push(dateData);
                    }
                });
            }
        }
        //  this.state.defaultOpen = false;
        //this.state.isFilterRowEmpty = Object.keys(groupResult).length === 0;
        var empty = {};
        if (emptyDateBatch.length > 0) {
            emptyDateBatch.forEach((name) => {
                empty[name] = [];
            });
        }
        this.render();
        var result = { ...empty, ...groupResult };

        result = ObjectPropertyToUpperCase(
            result,
            this.state.sortDirection,
            this.state.groupByData
        );
        //this.setState({filteredRowData:result})
        // added by abu {

        if (this.state.customGroupedData.length === 0) {
            this.setState({ customGroupedData: result }, () => {
                var temp = Object.values(this.state.customGroupedData);
                var tempArray = [];
                temp.map((data) => {
                    data.map((array) => {
                        tempArray.push(array);
                    });
                });
                //this.setState({childUniqueData:tempArray})
                this.makeChildUniqueData(tempArray);
                //this.resetPopOverData(tempArray);
            });
        }
        // }
        return result;
    }

    makeChildUniqueData = (filteredData = []) => {
        let childUniqueData = {};
        let childTagNameValues = new Set();
        let makeData = this.state.rowData;
        makeData = filteredData;
        let modifiedColumnsNames = Array.from(this.state.modifiedColumnsNames);
        makeData.forEach((data, i) => {
            for (let property in data) {
                if (childUniqueData[property] === undefined) {
                    childUniqueData[property] = new Set();
                }

                if (this.state.dateFormatFields.indexOf(property) !== -1) {
                    if (
                        property === 'date&batch' &&
                        data[property] !== undefined
                    ) {
                        childUniqueData[property].add(data[property]);
                    } else if (data[`${property}_rawdata`] !== undefined) {
                        childUniqueData[property].add(
                            formatDate(
                                data[`${property}_rawdata`],
                                'MM/DD/YYYY'
                            )
                        );
                    }
                } else if (
                    property === 'tags' &&
                    data[`${property}_data`] !== undefined
                ) {
                    childTagNameValues.add(data[`${property}_data`]);
                } else if (
                    modifiedColumnsNames.indexOf(property) !== -1 &&
                    data[`${property}_rawdata`] !== undefined
                ) {
                    childUniqueData[property].add(data[`${property}_rawdata`]);
                } else if (data[property] !== undefined) {
                    childUniqueData[property].add(data[property]);
                }
            }
        });

        this.setState({
            childUniqueData,
            childTagNameValues,
        });
    };

    async removeAllGrouping() {
        this.setState({ refreshDataGrid: false });
        let paginatedRowData = this.state.allRows;
        if (this.state.filterTxt !== '') {
            paginatedRowData = await this.filterDataGrid(this.state.filterTxt);
        }

        let startIndex =
            parseInt(this.state.currentPage) * parseInt(this.state.perPage);
        let endIndex = startIndex + parseInt(this.state.perPage);
        paginatedRowData = await this.getRowData(
            startIndex,
            endIndex,
            paginatedRowData
        );
        await this.setState({
            groupByData: {},
            groupBy: [],
            filteredRowData: paginatedRowData,
            groupingData: {},
            tempExpandedGroupIds: new Set(),
            expandedGroupIds: new Set(),
            rowClickClass: this.state.row_transition
                ? this.state.rowClickClass
                : [],
            reInitiateGrid: true,
            refreshDataGrid: true,
        });
        if (this.state.stackFilter.trim() !== '') {
            await this.radialChartOnClick(
                this.state.stackFilter,
                this.state.chartName
            );
        }
    }

    rowTransition = async () => {
        this.setState({
            row_transition: false,
            detailBigView: false,
        });
    };

    open_notes_modal = () => {
        const selectedRows = Array.from(this.state.selectedRows);
        const extractedNumber = Array.from(selectedRows)[0];
        const rowsToNotes = this.state.rowData.filter((data) => {
            return extractedNumber !== -1 && extractedNumber === data.index;
        });
        const docIdForNotes = [];
        rowsToNotes.forEach((data) => {
            docIdForNotes.push(data.doc_id);
        });
        this.setState(
            {
                open_notes_modal: true,
                docIdForNotes,
            },
            () => {}
        );
    };

    doCollapse = () => {
        //used for resume tracking and recover
        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        var elements = document.querySelectorAll('.rdg-row');
        this.setState({
            row_transition: false,
            detailBigView: false,
        });
        this.rowTransition();
        //this.props.updateState({ row_transition: false,  });
        if (isMobile && window.screen.width <= 960) {
            document.body.classList.remove('small-view-display');
        }
        setTimeout(() => {
            let checkBox =
                document.getElementsByClassName('rdg-checkbox-input');
            if (checkBox !== null) {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].disabled = false;
                    checkBox[i].removeAttribute('disabled');
                }
            }
        }, 20);
    };

    removeAttribute = (
        data,
        propName,
        propName2,
        propName3,
        propName4,
        propName5,
        propName6
    ) => {
        data.map((value) => {
            var key;
            for (key in value) {
                if (
                    key.includes(propName) ||
                    key.includes(propName2) ||
                    key.includes(propName3) ||
                    key.includes(propName4) ||
                    key.includes(propName5) ||
                    key.includes(propName6)
                ) {
                    delete value[key];
                }
            }
        });
        return data;
    };

    exportToXlsx = (stateRowData) => {
        var allData;

        if (
            this.state.propertyTiles !== 'All Data' &&
            this.state.filterTxt !== '' &&
            this.state.selectedRadialChart === ''
        ) {
            allData = this.state.allRows;
        } else if (
            this.state.filterTxt !== '' &&
            this.state.propertyTiles === 'All Data' &&
            this.state.selectedRadialChart === ''
        ) {
            allData = this.state.filteredData;
        } else if (this.state.selectedRadialChart !== '') {
            //selectedRadialChart this condition added for radial chart selected grid
            allData = this.state.filteredData;
        } else {
            allData = this.state.allRows;
        }

        /*var allData1 = allData.filter(
                (data) =>
                    data.key !== 'documentdate_rawdata' &&
                    data.key !== 'documentdate_onlyDate' &&
                    data.key !== 'dateofbirth_rawdata' &&
                    data.key !== 'dateofbirth_onlyDate' 
            );*/

        var allData1 = this.removeAttribute(
            allData,
            '_rawdata',
            '_onlyDate',
            '_value',

            //'id', // dont include this since it will remove id from original data which cause issue in document id undefined
            'documentname',
            'editable'
        );
        let entireData = [];
        allData1.map((item) => {
            let newObject = {};
            for (const property in item) {
                if (property === 'doc_id' || property === 'id') {
                    newObject = {
                        ...newObject,
                        [property]: Math.abs(item[property]),
                    };
                } else {
                    newObject = {
                        ...newObject,
                        [property]: item[property],
                    };
                }
            }
            entireData.push(newObject);
        });
        var textarea = JSON.stringify(entireData);

        var find = this.state.headerNameFormatted;
        var replace = this.state.headerNameOriginal;

        find.push('doc_id');
        replace.push('Doc Id');

        let text = find.reduce((acc, item, i) => {
            const regex = new RegExp(item, 'g');
            return acc.replace(regex, replace[i]);
        }, textarea);
        var allData2 = JSON.parse(text);

        const ws = XLSX.utils.json_to_sheet(allData2);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        saveAs(data, this.state.pageTitle + '.xlsx');
    };
    tilechart() {
        return (
            <div key='tileChart_1' className='col-lg-12'>
                <Tile
                    pageTitle={this.state.pageTitle}
                    forCountZero={this.state.forCountZero}
                    rows={this.state.filteredRowData}
                    filteredRowData={this.state.filteredRowData}
                    allFilteredRowData={this.state.allFilteredRowData}
                    allRows={this.state.allRows}
                    rowData={this.state.rowData}
                    updateState={(state) => {
                        this.setState({
                            ...state,
                        });
                    }}
                    filteredData={this.state.filteredData}
                    graphicalType='tile'
                    graphicalField={
                        this.state.tile !== undefined &&
                        this.state.tile.graphicalField !== undefined
                            ? this.state.tile.graphicalField
                            : null
                    }
                    grpahicalFieldNameFormat={
                        this.state.tile !== undefined &&
                        this.state.tile.grpahicalFieldNameFormat !== undefined
                            ? this.state.tile.grpahicalFieldNameFormat
                            : null
                    }
                    resetPopOverData={(data) => this.resetPopOverData(data)}
                    headerColumns={this.state.headerColumns}
                    perPage={this.state.perPage}
                    propertyTiles={this.state.propertyTiles}
                    totalCount={this.state.totalCount}
                    filterTxt={this.state.filterTxt}
                    removeAllGrouping={() => this.removeAllGrouping()}
                    noDataFound={this.state.noDataFound}
                    noDataOnClick={this.state.noDataOnClick}
                />
            </div>
        );
    }
    donutChart() {
        return (
            <div
                key='donutChart_2'
                className={
                    this.state.graphicalTypeArray !== undefined &&
                    this.state.graphicalTypeArray.length > 1 &&
                    this.state.graphicalTypeArray.includes(
                        'stacked-bar-chart'
                    ) === true
                        ? 'col-lg-5'
                        : this.state.graphicalTypeArray !== undefined &&
                          this.state.graphicalTypeArray.length > 1 &&
                          this.state.graphicalTypeArray.includes(
                              'stacked-bar-chart'
                          ) === true
                        ? 'col-lg-6'
                        : 'col-lg-12'
                }
            >
                <Donut
                    chartFilterTxt={this.state.chartFilterTxt}
                    stackFilter={this.state.stackFilter}
                    state={this.state}
                    pageTitle={this.state.pageTitle}
                    forCountZero={this.state.forCountZero}
                    rows={this.state.filteredRowData}
                    filteredRowData={this.state.filteredRowData}
                    allFilteredRowData={this.state.allFilteredRowData}
                    allRows={this.state.allRows}
                    rowData={this.state.rowData}
                    updateState={(state) => {
                        this.setState({
                            ...state,
                        });
                    }}
                    filteredData={this.state.filteredData}
                    graphicalType='simple-donut'
                    graphicalField={
                        this.state.simpledonut !== undefined &&
                        this.state.simpledonut.graphicalField !== undefined
                            ? this.state.simpledonut.graphicalField
                            : null
                    }
                    grpahicalFieldNameFormat={
                        this.state.simpledonut !== undefined &&
                        this.state.simpledonut.grpahicalFieldNameFormat !==
                            undefined
                            ? this.state.simpledonut.grpahicalFieldNameFormat
                            : null
                    }
                    resetPopOverData={(data) => this.resetPopOverData(data)}
                    headerColumns={this.state.headerColumns}
                    perPage={this.state.perPage}
                    propertyTiles={this.state.propertyTiles}
                    totalCount={this.state.totalCount}
                    filterTxt={this.state.filterTxt}
                    removeAllGrouping={() => this.removeAllGrouping()}
                    radialChartOnClick={(data, chartName) =>
                        this.radialChartOnClick(data, chartName)
                    }
                    selectedRadialChart={this.state.selectedRadialChart}
                    // outboundstatus={
                    //     this.state.outboudData !== undefined &&
                    //     this.state.uniqueData.outboundstatus !== undefined
                    //         ? this.state.uniqueData.outboundstatus
                    //         : {}
                    // }

                    chartName={this.state.chartName}
                    filterDataGrid={(data) => this.filterDataGrid(data)}
                    filterAllRows={this.state.filterAllRows}
                    noDataFound={this.state.noDataFound}
                    noDataOnClick={this.state.noDataOnClick}
                />
            </div>
        );
    }
    radialChart() {
        return (
            <div
                key='radialChart_3'
                className={
                    this.state.graphicalTypeArray !== undefined &&
                    this.state.graphicalTypeArray.length > 1 &&
                    this.state.graphicalTypeArray.includes(
                        'stacked-bar-chart'
                    ) === true
                        ? 'col-lg-5'
                        : this.state.graphicalTypeArray !== undefined &&
                          this.state.graphicalTypeArray.length > 1 &&
                          this.state.graphicalTypeArray.includes(
                              'stacked-bar-chart'
                          ) !== true
                        ? 'col-lg-6'
                        : 'col-lg-12'
                }
            >
                <Radial
                    chartFilterTxt={this.state.chartFilterTxt}
                    stackFilter={this.state.stackFilter}
                    state={this.state}
                    pageTitle={this.state.pageTitle}
                    forCountZero={this.state.forCountZero}
                    rows={this.state.filteredRowData}
                    filteredRowData={this.state.filteredRowData}
                    allFilteredRowData={this.state.allFilteredRowData}
                    allRows={this.state.allRows}
                    rowData={this.state.rowData}
                    updateState={(state) => {
                        this.setState({
                            ...state,
                        });
                    }}
                    filteredData={this.state.filteredData}
                    graphicalType='radial-bar-chart'
                    graphicalField={
                        this.state.radialbarchart !== undefined &&
                        this.state.radialbarchart.graphicalField !== undefined
                            ? this.state.radialbarchart.graphicalField
                            : null
                    }
                    grpahicalFieldNameFormat={
                        this.state.radialbarchart !== undefined &&
                        this.state.radialbarchart.grpahicalFieldNameFormat !==
                            undefined
                            ? this.state.radialbarchart.grpahicalFieldNameFormat
                            : null
                    }
                    resetPopOverData={(data) => this.resetPopOverData(data)}
                    headerColumns={this.state.headerColumns}
                    perPage={this.state.perPage}
                    propertyTiles={this.state.propertyTiles}
                    totalCount={this.state.totalCount}
                    filterTxt={this.state.filterTxt}
                    removeAllGrouping={() => this.removeAllGrouping()}
                    radialChartOnClick={(data, chartName) =>
                        this.radialChartOnClick(data, chartName)
                    }
                    selectedRadialChart={this.state.selectedRadialChart}
                    // outboundstatus={
                    //     this.state.outboudData !== undefined &&
                    //     this.state.uniqueData.outboundstatus !== undefined
                    //         ? this.state.uniqueData.outboundstatus
                    //         : {}
                    // }
                    chartName={this.state.chartName}
                    filterDataGrid={(data) => this.filterDataGrid(data)}
                    filterAllRows={this.state.filterAllRows}
                    noDataFound={this.state.noDataFound}
                    noDataOnClick={this.state.noDataOnClick}
                />
            </div>
        );
    }
    stackChart() {
        return (
            <div
                key='stackChart_4'
                className={
                    this.state.graphicalTypeArray !== undefined &&
                    this.state.graphicalTypeArray.length > 1
                        ? 'col-lg-7'
                        : 'col-lg-12'
                }
            >
                <StackedBar
                    filterAllRows={this.state.filterAllRows}
                    pageTitle={this.state.pageTitle}
                    forCountZero={this.state.forCountZero}
                    rows={this.state.filteredRowData}
                    filteredRowData={this.state.filteredRowData}
                    allFilteredRowData={this.state.allFilteredRowData}
                    allRows={this.state.allRows}
                    rowData={this.state.rowData}
                    updateState={(state) => {
                        this.setState({
                            ...state,
                        });
                    }}
                    filteredData={this.state.filteredData}
                    graphicalType='stacked-bar-chart'
                    graphicalField={
                        this.state.stackedbarchart !== undefined &&
                        this.state.stackedbarchart.graphicalField2 !== undefined
                            ? this.state.stackedbarchart.graphicalField2
                            : null
                    }
                    barseriesData={
                        this.state.stackedbarchart !== undefined &&
                        this.state.stackedbarchart.graphicalField !== undefined
                            ? this.state.stackedbarchart.graphicalField
                            : null
                    }
                    grpahicalFieldNameFormat={
                        this.state.stackedbarchart !== undefined &&
                        this.state.stackedbarchart.grpahicalFieldNameFormat !==
                            undefined
                            ? this.state.stackedbarchart
                                  .grpahicalFieldNameFormat
                            : null
                    }
                    grpahicalFieldNameFormat2={
                        this.state.stackedbarchart !== undefined &&
                        this.state.stackedbarchart.grpahicalFieldNameFormat2 !==
                            undefined
                            ? this.state.stackedbarchart
                                  .grpahicalFieldNameFormat2
                            : null
                    }
                    resetPopOverData={(data) => this.resetPopOverData(data)}
                    OutboundStatusFumction={(data) =>
                        this.OutboundStatusFumction(data)
                    }
                    chartFilter={(data) => {
                        this.chartFilter(data);
                    }}
                    headerColumns={this.state.headerColumns}
                    perPage={this.state.perPage}
                    propertyTiles={this.state.propertyTiles}
                    totalCount={this.state.totalCount}
                    filterTxt={this.state.filterTxt}
                    removeAllGrouping={() => this.removeAllGrouping()}
                    outboundstatus={
                        this.state.outboudData !== undefined &&
                        this.state.outboudData.outboundstatus !== undefined
                            ? this.state.outboudData.outboundstatus
                            : []
                    }
                    outboudData={
                        this.state.outboudData !== undefined
                            ? this.state.outboudData
                            : []
                    }
                    chartfilteredData={this.state.chartfilteredData}
                    chartFilterTxt={this.state.chartFilterTxt}
                    stackFilter={this.state.stackFilter}
                    radialChartOnClick={(data, chartName) =>
                        this.radialChartOnClick(data, chartName)
                    }
                    selectedRadialChart={this.state.selectedRadialChart}
                    chartName={this.state.chartName}
                    filterDataGrid={(data) => this.filterDataGrid(data)}
                    refreshTable={() => this.refreshTable()}
                    noDataFound={this.state.noDataFound}
                    noDataOnClick={this.state.noDataOnClick}
                />
            </div>
        );
    }
    treeChart() {
        return (
            <div
                key='treeChart_5'
                className={
                    this.state.graphicalTypeArray !== undefined &&
                    this.state.graphicalTypeArray.length > 1 &&
                    this.state.graphicalTypeArray.includes(
                        'stacked-bar-chart'
                    ) === true
                        ? 'col-lg-5'
                        : this.state.graphicalTypeArray !== undefined &&
                          this.state.graphicalTypeArray.length > 1 &&
                          this.state.graphicalTypeArray.includes(
                              'stacked-bar-chart'
                          ) !== true
                        ? 'col-lg-6'
                        : 'col-lg-12'
                }
            >
                <TreeMap
                    rowData={this.state.rowData}
                    graphicalField={
                        this.state.treemap !== undefined &&
                        this.state.treemap.graphicalField !== undefined
                            ? this.state.treemap.graphicalField
                            : null
                    }
                    grpahicalFieldNameFormat={
                        this.state.treemap !== undefined &&
                        this.state.treemap.grpahicalFieldNameFormat !==
                            undefined
                            ? this.state.treemap.grpahicalFieldNameFormat
                            : null
                    }
                    updateState={(state) => {
                        this.setState({
                            ...state,
                        });
                    }}
                    reportParams='Pending Documents'
                    filteredData={this.state.filteredData}
                    filterAllRows={this.state.filterAllRows}
                    allRows={this.state.allRows}
                    radialChartOnClick={(data, chartName) =>
                        this.radialChartOnClick(data, chartName)
                    }
                    graphicalTypeArray={this.state.graphicalTypeArray}
                    noDataFound={this.state.noDataFound}
                    noDataOnClick={this.state.noDataOnClick}
                    resetPopOverData={(data) => this.resetPopOverData(data)}
                    filterDataGrid={(data) => this.filterDataGrid(data)}
                    removeAllGrouping={() => this.removeAllGrouping()}
                />
            </div>
        );
    }
    dynamicChartLooping() {
        const loopData = [];
        for (const property in this.state.graphicalTypeArray) {
            if (this.state.graphicalTypeArray[property] === 'tile') {
                loopData.push(this.tilechart());
            }
            if (
                this.state.graphicalTypeArray[property] === 'stacked-bar-chart'
            ) {
                loopData.push(this.stackChart());
            }
            if (
                this.state.graphicalTypeArray[property] === 'radial-bar-chart'
            ) {
                loopData.push(this.radialChart());
            }
            if (this.state.graphicalTypeArray[property] === 'tree-map') {
                loopData.push(this.treeChart());
            }
            if (this.state.graphicalTypeArray[property] === 'simple-donut') {
                //loopData.push(this.treeChart());
                loopData.push(this.donutChart());
            }
        }
        return <> {loopData}</>;
    }

    render() {
        const dynamicChartLooping = this.dynamicChartLooping();
        // console.log('log', this.state)
        var temp = Array.from(this.state.selectedRows);
        temp.push(this.state.onRowClickId);
        /* 
        let stateRowData = [];
        let filter = [];
        if (this.state.filteredRowData !== undefined) {
            filter = _.slice(this.state.filteredRowData, 0, this.state.perPage);
            stateRowData = filter;
        }

    */
        //if we use above condition then it would not work for grouping

        let stateRowData = [];
        if (this.state.filteredData !== undefined) {
            stateRowData = this.state.filteredRowData;
        }

        let reBuildGrid = {};
        if (this.state.reInitiateGrid) {
            // Need to Set this value to TRUE , if we face ROW COLLAPSE or DATAGRID Design issue
            reBuildGrid = { key: Math.random() };
        }
        let responsiveGrid = {};
        let toggleRowElement = (params) => {
            let { row } = params;
            let docid = row.id;
            let openedRowId = this.state.openedRowId;
            if (openedRowId[docid] === undefined) {
                openedRowId[docid] = true;
                this.setState({ openedRowId: openedRowId });
            } else {
                openedRowId[docid] = !openedRowId[docid];
                this.setState({ openedRowId: openedRowId });
            }
        };
        //isMobile
        if (isMobile && window.screen.width <= 960) {
            responsiveGrid = {
                rowRenderer: (props) => {
                    return (
                        <RowRenderer
                            {...props}
                            fullHeaderData={this.state.header}
                            openedRowId={this.state.openedRowId}
                            modifiedColumnsNames={Array.from(
                                this.state.modifiedColumnsNames
                            )}
                            dateFormatFields={Array.from(
                                this.state.dateFormatFields
                            )}
                            toggleRowElement={toggleRowElement}
                            fromPage={
                                this.state.isCustomSearchQueryId === true
                                    ? 'custom_listing'
                                    : 'reportset_listing'
                            }
                        />
                    );
                },
                rowHeight: (args) => {
                    const { type, row } = args;

                    let heightVal = this.state.header.length * 35;
                    if (
                        this.state.header.length >= 15 &&
                        this.state.header.length <= 19
                    ) {
                        heightVal = this.state.header.length * 33;
                    } else if (
                        this.state.header.length < 15 &&
                        this.state.header.length >= 12
                    )
                        heightVal = this.state.header.length * 35;
                    else if (
                        this.state.header.length < 12 &&
                        this.state.header.length >= 10
                    )
                        heightVal = this.state.header.length * 40;
                    else if (
                        this.state.header.length < 10 &&
                        this.state.header.length > 4
                    )
                        heightVal = (this.state.header.length - 1) * 45;

                    if (type === 'GROUP') {
                        return 70;
                    }

                    if (
                        this.state.openedRowId[row.id] !== undefined &&
                        this.state.openedRowId[row.id] === true
                    ) {
                        return heightVal;
                    }
                    return 60;
                },
                headerRowHeight: 0,
            };
        }

        var defaultHeight = window.innerHeight - 280;
        // Determine the display value if graphicalTypeArray has items
        const displayValue =
            this.state.graphicalTypeArray != null &&
            this.state.graphicalTypeArray.length > 0
                ? this.state.selectedRadialChart === ''
                    ? this.state.propertyTiles
                    : this.state.selectedRadialChart
                : null; // or any fallback value you prefer
        return (
            <>
                <div
                    className={
                        isNaN(this.state.pathId) === true
                            ? 'right-first-child form_empty'
                            : 'right-first-child'
                    }
                >
                    {isNaN(this.state.pathId) === true &&
                    this.props.location.pathname.includes('detail') !== true &&
                    this.props.location.pathname.includes('editmultiple') !==
                        true ? (
                        <div className='right-aside p-5 form_empty h-range'>
                            <div className='no_form'>
                                <i className='fa-light fa-file-chart-column' />
                                <span>
                                    Select a report on the left to get started
                                </span>
                            </div>
                        </div>
                    ) : null}

                    {(this.state.loader || this.state.refreshDataGrid === '') &&
                    this.state.daterange !== '' &&
                    this.state.daterange !== null &&
                    this.state.daterange !== undefined &&
                    this.state.daterange !== 'custom' &&
                    isNaN(this.state.pathId) === false ? (
                        <>
                            <SpinnerBlur show={this.state.loader} />
                        </>
                    ) : (this.state.loader ||
                          this.state.refreshDataGrid === '') &&
                      this.state.daterange === 'custom' &&
                      this.state.from_date !== '' &&
                      this.state.from_date !== null &&
                      this.state.from_date !== undefined &&
                      this.state.to_date !== '' &&
                      this.state.to_date !== null &&
                      this.state.to_date !== undefined &&
                      isNaN(this.state.pathId) === false ? (
                        <>
                            <SpinnerBlur show={this.state.loader} />
                        </>
                    ) : null}
                    {this.state.rowTrans === false &&
                        isNaN(this.state.pathId) === false && (
                            <div
                                className='hide-small-view'
                                style={{
                                    display:
                                        this.props.deleted_page
                                            .hideSmallView === false
                                            ? 'block'
                                            : 'none',
                                }}
                            >
                                <div
                                    className='right-aside py-5'
                                    id='right-aside'
                                >
                                    <div className='listing-grid grid-physicians'>
                                        <div className='d-flex justify-content-between mb-5 pl-7 pr-7 report_header'>
                                            <div className='d-flex align-items-center'>
                                                <h3 className='m-0 title-color'>
                                                    {this.state.pageTitle}
                                                </h3>
                                            </div>
                                            <div
                                                className={
                                                    this.state.daterange ===
                                                    'custom'
                                                        ? 'd-flex filter_part rpt-filter orp-rpt custom-rpt'
                                                        : 'd-flex filter_part rpt-filter orp-rpt'
                                                }
                                            >
                                                {this.state.daterange !== '' &&
                                                this.state.daterange !== null &&
                                                this.state.daterange !==
                                                    undefined ? (
                                                    <>
                                                        <div className='export'>
                                                            <Button
                                                                title='Export Xls'
                                                                variant='primary'
                                                                className='mr-3'
                                                                onClick={() => {
                                                                    this.exportToXlsx(
                                                                        stateRowData
                                                                    );
                                                                }}
                                                                disabled={
                                                                    (this.state
                                                                        .allRows !==
                                                                        undefined &&
                                                                        this
                                                                            .state
                                                                            .allRows
                                                                            .length ===
                                                                            0) ||
                                                                    (this.state
                                                                        .filterTxt !==
                                                                        '' &&
                                                                        this
                                                                            .state
                                                                            .filteredData
                                                                            .length ===
                                                                            0) ||
                                                                    (this.state
                                                                        .daterange ===
                                                                        'custom' &&
                                                                        (this
                                                                            .state
                                                                            .from_date ===
                                                                            '' ||
                                                                            this
                                                                                .state
                                                                                .to_date ===
                                                                                '' ||
                                                                            this
                                                                                .state
                                                                                .from_date ===
                                                                                undefined ||
                                                                            this
                                                                                .state
                                                                                .to_date ===
                                                                                undefined))
                                                                }
                                                            >
                                                                <i className='fa-solid fa-file-export'></i>
                                                                Export
                                                            </Button>
                                                        </div>
                                                        {/* <div className='filter'>
                                                            <input
                                                                type='text'
                                                                placeholder=''
                                                                value={
                                                                    this.state
                                                                        .filterTxt
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    this.filterDataGrid(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                style={{
                                                                    paddingRight:
                                                                        '34px',
                                                                }}
                                                            />
                                                            <i className='fa-regular fa-magnifying-glass' />
                                                        </div> */}
                                                        <SearchFilter
                                                            style={{
                                                                paddingRight:
                                                                    '34px',
                                                            }}
                                                            value={
                                                                this.state
                                                                    .filterTxt
                                                            }
                                                            callBack={(
                                                                value
                                                            ) => {
                                                                this.filterDataGrid(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                        <div className='reload-outer'>
                                                            <span
                                                                className='reload'
                                                                onClick={() =>
                                                                    this.refreshTable()
                                                                }
                                                            >
                                                                <i
                                                                    title='Refresh'
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .displayAnimation
                                                                            ? 'fas fa-sync-alt refreshing'
                                                                            : 'fas fa-sync-alt'
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : null}
                                                <div className='filter_select'>
                                                    <select
                                                        id='select_daterange'
                                                        className={
                                                            'form-control'
                                                        }
                                                        onChange={(event) => {
                                                            this.handleDateRangeOnChange(
                                                                'daterange',
                                                                event
                                                            );
                                                        }}
                                                        value={
                                                            this.state.daterange
                                                        }
                                                    >
                                                        <option value=''>
                                                            Select Date Range
                                                        </option>
                                                        <option
                                                            key={'last_1'}
                                                            value={'1'}
                                                        >
                                                            Last Day
                                                        </option>
                                                        <option
                                                            key={'last_7'}
                                                            value={'7'}
                                                        >
                                                            Last 7 Days
                                                        </option>
                                                        <option
                                                            key={'last_14'}
                                                            value={'14'}
                                                        >
                                                            Last 14 Days
                                                        </option>

                                                        <option
                                                            key={'last_30'}
                                                            value={'30'}
                                                        >
                                                            Last 30 Days
                                                        </option>
                                                        <option
                                                            key={'last_365'}
                                                            value={'365'}
                                                        >
                                                            Last 12 Months
                                                        </option>
                                                        <option
                                                            key={'custom'}
                                                            value={'custom'}
                                                        >
                                                            Custom
                                                        </option>
                                                    </select>
                                                    <i
                                                        className='fa-light fa-chevron-down'
                                                        style={{
                                                            pointerEvents:
                                                                'none',
                                                        }}
                                                    ></i>
                                                </div>
                                                {this.state.daterange ===
                                                'custom' ? (
                                                    <div className=' custom-pos'>
                                                        <div className='date_picker'>
                                                            <div
                                                                className={
                                                                    this.state
                                                                        .customDateError
                                                                        ? 'position-relative form-control  error-field'
                                                                        : 'position-relative form-control'
                                                                }
                                                            >
                                                                <DatePicker
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        this.formDate(
                                                                            date
                                                                        );
                                                                    }}
                                                                    value={
                                                                        typeof this
                                                                            .state
                                                                            .from_date ===
                                                                            'string' &&
                                                                        this
                                                                            .state
                                                                            .from_date !==
                                                                            ''
                                                                            ? new Date(
                                                                                  this.state.from_date
                                                                              )
                                                                            : this
                                                                                  .state
                                                                                  .from_date
                                                                    }
                                                                    placeholder='From Date'
                                                                    className='date-picker'
                                                                    format='MM/dd/y'
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    this.state
                                                                        .customDateError
                                                                        ? 'position-relative form-control  error-field'
                                                                        : 'position-relative form-control'
                                                                }
                                                            >
                                                                <DatePicker
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        this.toDate(
                                                                            date
                                                                        );
                                                                    }}
                                                                    value={
                                                                        typeof this
                                                                            .state
                                                                            .to_date ===
                                                                            'string' &&
                                                                        this
                                                                            .state
                                                                            .to_date !==
                                                                            ''
                                                                            ? new Date(
                                                                                  this.state.to_date
                                                                              )
                                                                            : this
                                                                                  .state
                                                                                  .to_date
                                                                    }
                                                                    placeholder='To Date'
                                                                    className='date-picker'
                                                                    format='MM/dd/y'
                                                                />
                                                            </div>
                                                        </div>
                                                        {this.state
                                                            .customDateError ? (
                                                            <p
                                                                className='error m-0'
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                Please check
                                                                From and to
                                                                Date.
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {this.state.tileGridRefresh === true ||
                                        this.state.loader ? (
                                            <SpinnerBlur />
                                        ) : (
                                            <div
                                                className={
                                                    this.state
                                                        .isCustomSearchQueryId ===
                                                    true
                                                        ? ''
                                                        : 'no-first-col'
                                                }
                                                id='custom_listing'
                                            >
                                                {this.state.refreshDataGrid ===
                                                    false &&
                                                this.state.loader === false ? (
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            marginTop: '30px',
                                                        }}
                                                    >
                                                        No Documents Found
                                                    </div>
                                                ) : null}

                                                {this.state.daterange ===
                                                    'custom' &&
                                                !(
                                                    this.state.from_date &&
                                                    this.state.to_date
                                                ) ? null : this.state
                                                      .refreshDataGrid &&
                                                  this.state.loopingRefresh ===
                                                      false ? (
                                                    <>
                                                        {this.state.loader ===
                                                            false &&
                                                        this.state.hideChart !==
                                                            true ? (
                                                            <div className='row-grid'>
                                                                {
                                                                    dynamicChartLooping
                                                                }
                                                            </div>
                                                        ) : null}

                                                        {this.state.loader ===
                                                            false &&
                                                            displayValue !==
                                                                null && (
                                                                <div className='SubTitle-Report'>
                                                                    <h4 className='m-0 title-color'>
                                                                        {this.stringCase(
                                                                            displayValue
                                                                        )}
                                                                        <span
                                                                            className='count'
                                                                            style={{
                                                                                background:
                                                                                    this
                                                                                        .state
                                                                                        .bgColourSub,
                                                                                color: this
                                                                                    .state
                                                                                    .colourSub,
                                                                            }}
                                                                        >
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .totalCount
                                                                            }
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                            )}
                                                        {this.state.groupBy
                                                            .length > 0 ? (
                                                            <div
                                                                className='remove-grouping-all'
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <div className='count_no d-none'>
                                                                    <span>
                                                                        12
                                                                    </span>
                                                                </div>
                                                                <i
                                                                    className='fa fa-list-alt'
                                                                    aria-hidden='true'
                                                                />
                                                                <span
                                                                    title='Remove Grouping'
                                                                    onClick={() =>
                                                                        this.removeAllGrouping()
                                                                    }
                                                                >
                                                                    Remove
                                                                    Grouping{' '}
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                        {this.props.deleted_page
                                                            .hideSmallView ===
                                                        false ? (
                                                            <DndProvider
                                                                backend={
                                                                    HTML5Backend
                                                                }
                                                            >
                                                                <DataGrid
                                                                    // {...reBuildGrid}
                                                                    columns={this.draggableColumns()}
                                                                    // columns={this.state.header}
                                                                    rows={
                                                                        stateRowData
                                                                    }
                                                                    rowsCount={
                                                                        stateRowData.length
                                                                    }
                                                                    selectedRows={
                                                                        this
                                                                            .state
                                                                            .selectedRows
                                                                    }
                                                                    onSelectedRowsChange={(
                                                                        rows
                                                                    ) => {
                                                                        let selectedRows =
                                                                            rows;
                                                                        this.setState(
                                                                            {
                                                                                selectedRows:
                                                                                    selectedRows,
                                                                                // selectedDocIds: selectedRows
                                                                            },
                                                                            () => {
                                                                                this.props.deletedPage(
                                                                                    {
                                                                                        ...this
                                                                                            .state,
                                                                                    }
                                                                                );
                                                                            }
                                                                        );
                                                                    }}
                                                                    groupBy={
                                                                        this
                                                                            .state
                                                                            .groupBy
                                                                    }
                                                                    expandedGroupIds={
                                                                        this
                                                                            .state
                                                                            .tempExpandedGroupIds
                                                                    }
                                                                    onExpandedGroupIdsChange={(
                                                                        groupId
                                                                    ) => {
                                                                        this.setState(
                                                                            {
                                                                                expandedGroupIds:
                                                                                    groupId,
                                                                                tempExpandedGroupIds:
                                                                                    groupId,
                                                                            }
                                                                        );
                                                                    }}
                                                                    //headerRowHeight={0}
                                                                    rowHeight={
                                                                        52
                                                                    }
                                                                    //summaryRowHeight={20}
                                                                    rowKeyGetter={(
                                                                        row
                                                                    ) => {
                                                                        return row.index;
                                                                    }}
                                                                    onRowClick={(
                                                                        rowDetails,
                                                                        rowHeader
                                                                    ) => {
                                                                        this.onRowClick(
                                                                            rowDetails,
                                                                            rowHeader,
                                                                            this
                                                                                .state
                                                                        );
                                                                    }}
                                                                    emptyRowsRenderer={NoRecordFound.bind(
                                                                        this,
                                                                        this
                                                                            .state
                                                                            .relDocLoading
                                                                    )}
                                                                    style={{
                                                                        height: defaultHeight,
                                                                        borderRight:
                                                                            '50px',
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .isCustomSearchQueryId ===
                                                                        true
                                                                            ? 'fill-grid'
                                                                            : 'fill-grid no-pointer-report'
                                                                    }
                                                                    rowClass={(
                                                                        row
                                                                    ) => {
                                                                        if (
                                                                            this.state.rowClickClass.indexOf(
                                                                                row.id
                                                                            ) !==
                                                                            -1
                                                                        ) {
                                                                            return 'row-selected-once';
                                                                        }
                                                                    }}
                                                                    defaultColumnOptions={{
                                                                        sortable: true,
                                                                        resizable: true,
                                                                        frozen: false,
                                                                    }}
                                                                    rowGrouper={(
                                                                        groupedData,
                                                                        indexProp
                                                                    ) =>
                                                                        this.createGroupByRows(
                                                                            groupedData,
                                                                            indexProp
                                                                        )
                                                                    }
                                                                    // compile error on 20/08/22
                                                                    // onColumnResize={(
                                                                    //     idx,
                                                                    //     width
                                                                    // ) => {
                                                                    //     idx,
                                                                    //         customizedWidth(
                                                                    //             width,
                                                                    //             this
                                                                    //                 .state
                                                                    //                 .header,
                                                                    //             this
                                                                    //                 .props
                                                                    //                 .location
                                                                    //                 .pathname
                                                                    //         );
                                                                    // }}
                                                                    headerRowHeight={
                                                                        40
                                                                    }
                                                                    {...responsiveGrid}
                                                                />
                                                            </DndProvider>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                            </div>
                                        )}
                                        {this.state.daterange === 'custom' &&
                                        !(
                                            this.state.from_date &&
                                            this.state.to_date
                                        ) ? null : this.state.refreshDataGrid &&
                                          this.state.loader !== true &&
                                          this.state.groupBy.length === 0 &&
                                          this.state.rowData.length > 0 &&
                                          this.props.paginationRefresh ? (
                                            <div>
                                                <TablePagination
                                                    component='div'
                                                    count={
                                                        this.state.totalCount
                                                    }
                                                    page={parseInt(
                                                        this.state.currentPage
                                                    )}
                                                    rowsPerPage={parseInt(
                                                        this.state.perPage
                                                    )}
                                                    onRowsPerPageChange={(
                                                        event
                                                    ) =>
                                                        this.handleChangeRowsPerPage(
                                                            event
                                                        )
                                                    }
                                                    onPageChange={this.nextPage.bind(
                                                        this
                                                    )}
                                                />
                                            </div>
                                        ) : null}
                                    </div>

                                    {Array.from(this.state.selectedRows)
                                        .length > 0 ? (
                                        <DeleteRowSelection
                                            state={{ ...this.state }}
                                            refreshTable={this.getTableData}
                                            updateState={(state) => {
                                                this.setState({ ...state });
                                            }}
                                            selectedRows={
                                                this.state.selectedRows
                                            }
                                            openNotesModal={() => {
                                                this.open_notes_modal();
                                            }}
                                        />
                                    ) : null}
                                </div>
                                {this.state.open_notes_modal ? (
                                    <Notes
                                        openNote={this.state.open_notes_modal}
                                        docId={this.state.docIdForNotes}
                                        handleReset={() => {
                                            this.setState({
                                                open_notes_modal: false,
                                            });
                                        }}
                                        updateState={() => {
                                            let count = this.state.noteCount;
                                            this.setState({
                                                noteCount: count++,
                                            });
                                        }}
                                        viewAllOpen={false}
                                        // refreshTable={() => {
                                        //     this.refreshTable();
                                        // }}
                                    />
                                ) : null}
                            </div>
                        )}
                    {/*now only outbox data cuming in future inbox data came need to add condition currentBigView === inbox or outbox*/}
                    {this.state.visibleDoc === true &&
                    this.state.currentBigView === 'outbox' &&
                    this.props.location.pathname.includes('detail') === true ? (
                        <DocumentDetailViewComponent
                            calingFrom='report'
                            loader={this.state.loader}
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                            removeAllGrouping={this.removeAllGrouping}
                        />
                    ) : null}
                    {this.state.currentBigView === 'inbox' &&
                    this.props.location.pathname.includes('detail') === true ? (
                        <InboxDocumentDetailViewComponent
                            calingFrom='report'
                            loader={this.state.loader}
                            updateState={(state) => {
                                this.setState({ ...state });
                            }}
                            removeAllGrouping={this.removeAllGrouping}
                            permission={this.props.deletedPage.userPermission}
                            processId={this.props.deletedPage.processId}
                            statusId={this.props.deletedPage.statusId}
                        />
                    ) : null}
                    {this.props.outbox.outboxpage.multiple_edit_page ? (
                        <MultipleEditPage
                            refreshTable={this.refreshTable}
                            calingFrom='Report'
                        />
                    ) : null}
                </div>
                {isNaN(this.state.pathId) === false &&
                this.props.location.pathname.includes('detail') !== true &&
                (this.state.daterange === '' ||
                    this.state.daterange === null ||
                    this.state.daterange === undefined) ? (
                    <div className='right-aside p-5 form_empty h-date-range'>
                        <div className='no_form '>
                            <span>
                                Please choose the date range for the report you
                                want to generate
                            </span>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }

    setReportDefaultDateRange(path) {
        const pathMap = {
            'orders-in-progress-report': '365',
            'orders-aging-report': '365',
            'stacked-bar-chart-test': '365',
            'returned-order-followup-productivity': '30',
            'incomplete-referrals': '7',
            'completed-referrals': '7',
            'completed-attachments': '7',
            'pending-documents-treemap-test': '7',
            'unsigned-order-followup-tasks': '1',
            'inbound-fax-processing': '1',
            'inbound-fax-processing-signed-orders': '1',
        };

        for (let key in pathMap) {
            if (path.includes(key)) {
                this.setState({ daterange: pathMap[key] });
                break;
            }
        }
    }
}

const mapDispatchToProps = {
    inboxPage,
    getSelectedRowDetails: getSelectedRowDetails,
    deletedPage,
    selectedRowDetail,
    getConfigData: getConfigData,
    getRecordSetConfig: getRecordSetConfig,
    getCustomSearchConfig: getCustomSearchConfig,
};
const mapStateToProps = (state, ownProps) => {
    return {
        reports: state.configReducerPersist.configData.reports,
        configData: state.configReducerPersist.configData,
        configuredRecordsets:
            state.configReducerPersist.configData.configuredRecordsets,
        customSearchQueries:
            state.configReducerPersist.configData.customSearchQueries,
        deleted_page: state.search.deletedPage,
        inbox: state.inbox,
        outbox: state.outbox,
        ddl: state.persistDDL.DDL,
        documentTypeDetailViewMappings:
            state.configReducerPersist.configData
                .documentTypeDetailViewMappings,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ReportDocumentsDataGrid));
