/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getConfigurationData,
    GetDropdownData,
    getPageTitle,
    getCount,
    getDocumentMetaWithDocId,
    lockDocument,
    GetStore,
    configurationRecordSetId,
    configurationCustomeSearchId,
    getLockDocuments,
} from '../api/api';
import {
    Spinner,
    SpinnerTwo,
    SpinnerBlur,
    SpinnerThree,
} from './components/Spinner';
import DocumentDetail from './components/DocumentDetail';
import DetailBigView from './components/DetailBigView';
import {
    setSelectedRow,
    getRulesJson,
    getCountData,
    showLoader,
    reRenderInbox,
    hideBigView,
    getOwners,
    setProcessId,
    setStatusId,
    setCallingPage,
    setDocOwner,
    setMenuName,
    setPageData,
    getListaction,
    updateList,
    updateInboxState,
    clearInboxState,
    setRowData,
    getSelectedRowDetails,
    inboxPage,
    editDetailPage,
} from '../_redux/actions/inboxActions';
import { getOwnerForAuth, logOut } from '../_redux/actions/chkauthActions';
import {
    getDDLlist,
    getConfigData,
    getCustomSearchConfig,
    getRecordSetConfig,
} from '../_redux/actions/configActions';
import {
    getDocumentIdFromArray,
    removeCheckboxClass,
    rowHeightDataGrid,
    handleClickOutside,
    download,
    download_multiple,
    userPermission,
    stringEmptyOrUndefined,
    GetFieldData,
    RefreshCounts,
    formatHeaderNameWithOutSpace,
    getDropDownDataFromApi,
    formatNameWithSpaceToUnderscore,
    showErrorToast,
} from '../../components/commonfunction';
import DataGridComponent from '../../components/datagrid/DataGrid';
import { getProcessCount } from '../_redux/authCrud';
import DeleteModal from './components/DeleteModal';
import {
    InboxState,
    getDefaultState,
    GridState,
} from '../../components/variable';
import EditWorkPage from '../EditWorkPage';
import DocumentDetailViewComponent from './components/DocumentDetailViewComponent';
import MultipleEditPage from '../MultipleEditPage';
import PdfPreview from './components/PdfPreview';
import { isMobile } from 'react-device-detect';
import SearchFilter from '../../components/searchFilter/SearchFilter';
import LockBar from '../../components/LockBar';

let userdetails = localStorage.getItem('user_details');
userdetails = JSON.parse(userdetails);
let dispname = '';
let uname = [];
let username = '';
if (userdetails) {
    username = userdetails.userDisplayName;
    uname = username.split(' ');
    if (uname.length > 1) {
        dispname = `${uname[0].charAt(0).toUpperCase()}${uname[1]
            .charAt(0)
            .toUpperCase()}`;
    } else {
        dispname = `${uname[0].charAt(0).toUpperCase()}${uname[0]
            .charAt(1)
            .toUpperCase()}`;
    }
}

const statusArray = [];

export const ThemeContext = React.createContext({ name: 'Abu' });

class InboxPage extends React.Component {
    constructor(props) {
        super(props);
        const defaultQueryParams = {
            pageNumber: 1,
            pageSize: 25,
        };
        this.pdfPrintRef = React.createRef();
        this.state = {
            ...InboxState,
            nextDocument: false,
            single_edit: false,
            perPage: 25,
            currentPage: 0,
            pdf_preview: true,
            tagDropDownData: [],
            detailBigViewExpand: false,
            isPartialEditModeEnabled: false,
            isEditModeCanceledFromDocumentDetail: false,
            isTriggerCollapseFromDetailBigView: false,
            expandWasPressedEvent: false,
            pathName: '',
            queryData: { ...defaultQueryParams, ...props.query },
            edit_details: false,
            menu_selected_tag: [],
            isInEditingMode: false,
            isEditModeButtonActive: true,
            selectedDocId: null,
            // 10067: New Physician Signature State Fields
            physicianSignature: {
                physicianSignatureCity: '',
                physicianSignatureFileName: '',
                physicianSignatureNPI: '',
                physicianSignatureOrderingPhysician: '',
                physicianSignatureState: '',
                physicianSignatureSignedDate: '',
            },
            isDetailsLoading: false,
            lockedDocIds: [],
            lockBarVisible: false,
            isExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded: false,
            openClassifierSection: false,
        };
        this.refreshTable = this.refreshTable.bind(this);
        this.setOpenClassifierSection =
            this.setOpenClassifierSection.bind(this);
        this.getTransitonDropDownData =
            this.getTransitonDropDownData.bind(this);
    }

    // 10067: New Physician Signature Setter Fields
    setPhysicianSignatureCity = (newValue) => {
        this.setState((prevState) => ({
            physicianSignature: {
                ...prevState.physicianSignature,
                physicianSignatureCity: newValue,
            },
        }));
    };

    setPhysicianSignatureFileName = (newValue) => {
        this.setState((prevState) => ({
            physicianSignature: {
                ...prevState.physicianSignature,
                physicianSignatureFileName: newValue,
            },
        }));
    };

    setPhysicianSignatureNPI = (newValue) => {
        this.setState((prevState) => ({
            physicianSignature: {
                ...prevState.physicianSignature,
                physicianSignatureNPI: newValue,
            },
        }));
    };

    setPhysicianSignatureOrderingPhysician = (newValue) => {
        this.setState((prevState) => ({
            physicianSignature: {
                ...prevState.physicianSignature,
                physicianSignatureOrderingPhysician: newValue,
            },
        }));
    };

    setPhysicianSignatureState = (newValue) => {
        this.setState((prevState) => ({
            physicianSignature: {
                ...prevState.physicianSignature,
                physicianSignatureState: newValue,
            },
        }));
    };

    setPhysicianSignatureSignedDate = (newValue) => {
        this.setState((prevState) => ({
            physicianSignature: {
                ...prevState.physicianSignature,
                physicianSignatureSignedDate: newValue,
            },
        }));
    };

    async componentDidMount() {
        this.getTransitonDropDownData();

        const fieldDataTemp = GetFieldData({
            configData: this.props.configData,
        });
        GetStore(fieldDataTemp);

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');

        let userDetail = localStorage.getItem('user_details');

        if (userDetail !== null) {
            userDetail = JSON.parse(localStorage.getItem('user_details'));
            await this.props.inboxPage({ userDetail: userDetail });
        }
        let processId = '';
        let statusId = '';
        let menu_name = '';

        const fieldData = GetFieldData({
            configData: this.props.configData,
        });
        GetStore(fieldData);
        if (this.props.match.params.menuName !== undefined) {
            menu_name = this.props.match.params.menuName;
        }
        if (this.props.match.params.processId !== undefined) {
            processId = this.props.match.params.processId;
        }
        if (this.props.match.params.statusId !== undefined) {
            statusId = this.props.match.params.statusId;
        }
        if (String(processId).trim() === '' && String(statusId) === '') {
            if (this.props.processId !== undefined)
                processId = this.props.processId;
            else processId = fieldData.firstProcessId;

            if (this.props.statusId !== undefined)
                statusId = this.props.statusId;
            else statusId = fieldData.firstStatusId;

            const curr_menu = `menu_p_${processId}_s_${statusId}`;
            const element = document.getElementById(curr_menu);
            if (element !== null) {
                element.classList.add('root-active');
            }
        }

        this.setState({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
        });

        let itemsPerPage = 25;
        if (localStorage.getItem('retainShowAllChecked') === 'true') {
            const countResponse = await getCount(processId, statusId).then(
                (response) => {
                    return response.json();
                }
            );

            itemsPerPage = countResponse.count > 0 ? countResponse.count : 25;
        }

        if (window.innerHeight < 825) {
            await this.setState({ perPage: 10 });
        } else {
            await this.setState({
                perPage: itemsPerPage,
                queryData: { ...this.state.queryData, pageSize: itemsPerPage },
            });
        }

        // Set Row data in redux
        await this.props.setMenuName(menu_name);
        await this.props.clearInboxState(getDefaultState());
        if (String(processId).includes('=') !== true) {
            await this.props.getOwners(processId, statusId);
        }

        let calingFrom = 'inbox-outbox';
        if (
            this.props.calingFrom !== undefined &&
            this.props.calingFrom === 'search'
        ) {
            calingFrom = 'search';
        }
        this.setState({
            calingFrom: calingFrom,
        });
        const { queryData, currentPage, perPage, filterTxt } = this.state;
        queryData.pageNumber = currentPage + 1;
        queryData.pageSize = perPage;
        queryData.fullTextSearchString = filterTxt;

        const customFilters = localStorage.getItem(
            `customFilters_${processId}_${statusId}`
        );
        if (customFilters) {
            queryData.filters = customFilters;
        }

        const sortingsObject = localStorage.getItem(
            `sorting_${processId}_${statusId}`
        );
        if (sortingsObject) {
            const storedSortings = JSON.parse(sortingsObject);

            queryData.sortColumn = storedSortings?.sortColumn ?? '';
            queryData.sortOrder = storedSortings?.sortDirection ?? '';
        }

        if (processId !== undefined && statusId !== undefined) {
            await this.props.getListaction(
                processId,
                statusId,
                calingFrom,
                queryData
            );
        }

        await this.props.setProcessId(processId);
        await this.props.setStatusId(statusId);
        await this.props.setCallingPage(calingFrom);

        let permissionAllactions = userPermission({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
            configData: this.props.configData,
        });
        this.setState({
            userPermission: permissionAllactions,
        });
        if (String(processId).trim() === '' && String(statusId) === '') {
            let permission = userPermission({
                processId: processId,
                statusId: statusId,
                menu_name: menu_name,
                configData: this.props.configData,
            });
            permission = {
                ...permission,
                show_pdf: true,
                show_notes: true,
            };
            this.props.inboxPage({
                permission: permission,
            });

            this.setState({
                ...permission,
            });
        }

        const titlePage = getPageTitle({ processId, statusId });

        this.setState({ pageTitle: titlePage });

        document.addEventListener('mousedown', handleClickOutside);
        const asideOn = document.getElementById('kt_aside_mobile_toggle');
        if (asideOn !== null) {
            asideOn.addEventListener('click', () => {
                const ktAside = document.getElementById('kt_aside');
                if (ktAside !== null) {
                    ktAside.classList.add('aside-on');
                }
            });
        }
        this.props.inboxPage({
            related_doc_detail: false,
            multiple_edit_page: false,
            single_edit: false,
        });
        var currentPathName = window.location.pathname.split('/');

        if (currentPathName.length > 2) {
            if (currentPathName[2] === 'edit') {
                this.setState({ single_edit: true });
            }
            if (currentPathName[2] === 'detail') {
                this.props.inboxPage({ related_doc_detail: true });
            }
            if (currentPathName[2] === 'editmultiple') {
                this.props.inboxPage({ multiple_edit_page: true });
            }
            if (currentPathName[2] === 'undefined') {
                this.props.logOut();
            }
        }
        await this.getLockedDocIds();
    }

    configurationRecordSet(id) {
        configurationRecordSetId(id)
            .then(async (data) => {
                const configuredRecordsets = data.configuredRecordsets;
                const recordsetNamedIdsNeedingConfiguration =
                    data.recordsetNamedIdsNeedingConfiguration;
                if (this.props.getRecordSetConfig) {
                    await this.props.getRecordSetConfig(
                        configuredRecordsets,
                        recordsetNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    configurationCustomeSearchId = (id) => {
        configurationCustomeSearchId(id)
            .then(async (data) => {
                const customSearches = data.customSearchQueries;
                const customSearchNamedIdsNeedingConfiguration =
                    data.customSearchNamedIdsNeedingConfiguration;

                if (this.props.getCustomSearchConfig) {
                    await this.props.getCustomSearchConfig(
                        customSearches,
                        customSearchNamedIdsNeedingConfiguration
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.location.pathname === '/inbox' &&
            this.props.location.pathname !== this.state.pathName
        ) {
            this.setState({
                single_edit: false,
                pathName: this.props.location.pathname,
                row_transition: false,
                detailBigView: false,
            });
            this.props.inboxPage({
                related_doc_detail: false,
                multiple_edit_page: false,
                single_edit: false,
            });
            document.body.classList.remove('aside-minimize');
            document.body.classList.remove('doc-small-view');
        }
        if (this.props.location.pathname !== this.state.pathName) {
            this.setState({
                pathName: this.props.location.pathname,
            });
        }
        document.addEventListener('mousedown', handleClickOutside);
        let processId = '';
        let statusId = '';
        let menu_name = '';
        const fieldData = GetFieldData({ configData: this.props.configData });
        if (this.props.match.params.menuName !== undefined) {
            menu_name = this.props.match.params.menuName;
        }
        if (this.props.match.params.processId !== undefined) {
            processId = this.props.match.params.processId;
        }
        if (this.props.match.params.statusId !== undefined) {
            statusId = this.props.match.params.statusId;
        }

        if (String(processId).trim() === '' && String(statusId).trim() === '') {
            if (this.props.processId !== undefined) {
                processId = this.props.processId;
            } else {
                processId = fieldData.firstProcessId;
            }

            if (this.props.statusId !== undefined) {
                statusId = this.props.statusId;
            } else {
                statusId = fieldData.firstStatusId;
            }

            const curr_menu = `menu_p_${processId}_s_${statusId}`;

            if (window.location.pathname === '/inbox') {
                await this.props.history.push({
                    pathname: `/inbox/${fieldData.firstStatusName}/${processId}/${statusId}`,
                });
            }

            const element = document.getElementById(curr_menu);
            if (element !== null) {
                element.classList.add('root-active');
            }
        }

        const filterKey = `searchFilter_${processId}_${statusId}`;
        const savedFilter = localStorage.getItem(filterKey) ?? '';

        if (savedFilter !== this.state.filterTxt) {
            this.setState({ filterTxt: savedFilter });
        }

        if (String(prevProps.stateToken) !== String(this.props.stateToken)) {
            const requiredProperty = {};
            for (const property in GridState) {
                if (this.props.stateData[property] !== undefined) {
                    requiredProperty[property] = this.props.stateData[property];
                }
            }

            this.setState({
                ...requiredProperty,
            });
        }

        let permission = userPermission({
            processId: processId,
            statusId: statusId,
            menu_name: menu_name,
            configData: this.props.configData,
        });

        permission = {
            show_tag: permission.show_tag,
            show_owner: permission.show_owner,
            show_pdf: true,
            show_notes: true,
        };

        if (permission.show_tag !== this.state.show_tag) {
            this.setState(
                {
                    ...permission,
                },
                () => {}
            );
        }
        if (permission.show_owner !== this.state.show_owner) {
            this.setState(
                {
                    ...permission,
                },
                () => {}
            );
        }

        if (
            this.props.loading === false &&
            prevState.displayAnimation === true
        ) {
            this.setState({ displayAnimation: false });
        }

        if (
            this.props.match.params.statusId !== undefined &&
            this.state.statusId !== undefined &&
            String(this.props.match.params.statusId) !==
                String(this.state.statusId) &&
            this.props.related_doc_detail !== true &&
            this.props.multiple_edit_page !== true &&
            String(this.props.location.pathname).includes('editmultiple') !==
                true
        ) {
            let permission = userPermission({
                processId: processId,
                statusId: statusId,
                menu_name: menu_name,
                configData: this.props.configData,
            });

            await this.setState({
                processId: processId,
                statusId: statusId,
                show_owner: false,
                show_tag: false,
                show_branch: false,
                show_group: false,
                show_inbox: false,
                show_notes: true,
                show_pdf: true,
                show_delete: false,
                show_print: true,
                show_download: true,
                NewPermission: permission,
            });

            if (
                String(processId).trim() !== '' &&
                String(statusId).trim() !== ''
            ) {
                permission = {
                    ...permission,
                    show_pdf: true,
                    show_notes: true,
                };

                let itemsPerPage = 25;
                if (
                    localStorage.getItem('retainShowAllChecked') === 'true' &&
                    processId &&
                    statusId
                ) {
                    const countResponse = await getCount(
                        processId,
                        statusId
                    ).then((response) => {
                        return response.json();
                    });
                    itemsPerPage =
                        countResponse.count === 0 ? 25 : countResponse.count;
                }

                await this.setState(
                    {
                        ...InboxState,
                        ...permission,
                        row_transition: false,
                        selectedRows: new Set(),
                        filterTxt: localStorage.getItem(filterKey) ?? '',
                        perPage: itemsPerPage,
                        queryData: {
                            ...this.state.queryData,
                            pageSize: itemsPerPage,
                        },
                    },
                    () => {
                        this.props.updateList('');
                        this.props.setMenuName(menu_name);
                        this.props.clearInboxState({
                            groupBy: [],
                            groupByData: {},
                            filterTxt: localStorage.getItem(filterKey) ?? '',
                            perPage: itemsPerPage,
                            currentPage: 0,
                            totalCount: 0,
                            sortDirection: 'NONE',
                            sortColumn: '',
                            selectedRows: new Set(),
                            rowData: [],
                            filteredRowData: [],
                            header: [],
                            expandedRow: new Set(),
                            expandedGroupIds: new Set(),
                            tempExpandedGroupIds: new Set(),
                            uniqueData: {},
                            row_transition: false,
                            rowClickClass: [],
                        });
                        if (String(processId).includes('=') !== true) {
                            this.props.getOwners(processId, statusId);

                            const customFilters = localStorage.getItem(
                                `customFilters_${processId}_${statusId}`
                            );
                            const queryData = { ...this.state.queryData };
                            if (customFilters) {
                                queryData.filters = customFilters;
                            }

                            const sortingsObject = localStorage.getItem(
                                `sorting_${processId}_${statusId}`
                            );
                            if (sortingsObject) {
                                const storedSortings =
                                    JSON.parse(sortingsObject);

                                queryData.sortColumn =
                                    storedSortings?.sortColumn ?? '';
                                queryData.sortOrder =
                                    storedSortings?.sortDirection ?? '';
                            }

                            this.props.getListaction(
                                processId,
                                statusId,
                                this.state.calingFrom,
                                queryData
                            );
                            RefreshCounts(processId, statusId, this.props);
                        }

                        this.props.setProcessId(processId);
                        this.props.setStatusId(statusId);
                        this.props.setCallingPage(this.state.calingFrom);

                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                    }
                );
            } else {
                this.setState({
                    show_owner: true,
                    show_tag: true,
                    show_branch: true,
                    show_group: true,
                    show_inbox: true,
                    show_notes: true,
                    show_pdf: true,
                    show_delete: true,
                    show_print: true,
                    show_download: true,
                    processId: processId,
                    statusId: statusId,
                });
            }

            const titlePage = getPageTitle({ processId, statusId });
            this.setState({ pageTitle: titlePage, menu_name: menu_name });
        }
    }

    setOpenClassifierSection = (isOpenState) => {
        this.setState({ openClassifierSection: isOpenState });
    };

    getLockedDocIds = async () => {
        try {
            let docIds = [];
            const response = await getLockDocuments();
            if (response.length) {
                response.forEach((item) => {
                    docIds.push(item.itemNum);
                });
                this.setState({ lockedDocIds: docIds });
            }
        } catch (e) {
            console.error(e);
        }
    };

    setLockBarVisibility = (isVisible) => {
        this.setState({ lockBarVisible: isVisible });
    };

    async getTransitonDropDownData() {
        const fieldData = GetFieldData({ configData: this.props.configData });

        GetStore(fieldData);

        if (stringEmptyOrUndefined(fieldData.tags)) {
            await getDropDownDataFromApi(fieldData.tags).then(async (data) => {
                if (data.values) {
                    await this.props.getDDLlist({
                        [formatHeaderNameWithOutSpace(data.name)]: data.values,
                    });
                }
            });
        }

        if (
            stringEmptyOrUndefined(fieldData) &&
            stringEmptyOrUndefined(fieldData.branchFieldId) &&
            !this.props.ddl.hasOwnProperty('branch')
        ) {
            await GetDropdownData(fieldData.branchFieldId)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.values && data.values.length > 0) {
                        let newArray = [];
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                        this.setState({ branches: newArray });

                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]: newArray,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        if (
            stringEmptyOrUndefined(fieldData) &&
            stringEmptyOrUndefined(fieldData.groupFieldId) &&
            !this.props.ddl.hasOwnProperty('group')
        ) {
            await GetDropdownData(fieldData.groupFieldId)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.values && data.values.length > 0) {
                        let newArray = [];
                        data.values.map((item) => {
                            if (
                                newArray.includes(item) !== true &&
                                item !== '' &&
                                item !== null &&
                                item !== undefined
                            ) {
                                newArray.push(item);
                            }
                        });
                        this.setState({ groups: newArray });
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]: newArray,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        if (stringEmptyOrUndefined(fieldData)) {
            if (!this.props.ddl.hasOwnProperty('attachmenttype')) {
                await getDropDownDataFromApi(fieldData.attachmentTypeId).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('attachmentlocation')) {
                await getDropDownDataFromApi(
                    fieldData.attachmentLocationId
                ).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                    }
                });
            }

            if (!this.props.ddl.hasOwnProperty('newreferral')) {
                await getDropDownDataFromApi(fieldData.newReferralId).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('serviceline')) {
                await getDropDownDataFromApi(fieldData.serviceLineId).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('payorsource')) {
                await getDropDownDataFromApi(fieldData.payor_source).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('caretype')) {
                await getDropDownDataFromApi(fieldData.care_type).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }

            if (!this.props.ddl.hasOwnProperty('episodestatus')) {
                await getDropDownDataFromApi(fieldData.episode_status).then(
                    (data) => {
                        if (data.values) {
                            this.props.getDDLlist({
                                [formatHeaderNameWithOutSpace(data.name)]:
                                    data.values,
                            });
                        }
                    }
                );
            }
            if (!this.props.ddl.hasOwnProperty('tags')) {
                await getDropDownDataFromApi(fieldData.tags).then((data) => {
                    if (data.values) {
                        this.props.getDDLlist({
                            [formatHeaderNameWithOutSpace(data.name)]:
                                data.values,
                        });
                        this.setState({
                            tagDropDownData: data.values,
                        });
                    }
                });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', handleClickOutside);

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        this.props.inboxPage({
            related_doc_detail: false,
            multiple_edit_page: false,
            single_edit: false,
        });
    }

    async configurationApiCall() {
        var customSearchQueries = [];
        customSearchQueries = this.props.customSearchQueries;

        await customSearchQueries.map(async (data) => {
            if (data.name === 'By Batch' || data.name === 'By Order') {
                await this.configurationCustomeSearchId(data.id);
                this.setState({ byBatchSearchId: data.id });
            }
        });
        if (
            this.props.configData.recordsetNamedIdsNeedingConfiguration !==
            undefined
        ) {
            await this.props.configData.recordsetNamedIdsNeedingConfiguration.map(
                async (data) => {
                    if (
                        data.name === 'Payor Sources' ||
                        data.name === 'Order Data' ||
                        data.name === 'Patient Data Modify' ||
                        data.name === 'Order Data Modify' ||
                        data.name === 'Episode Data' ||
                        data.name === 'Branch Data' ||
                        data.name === 'Episode Visit Data' ||
                        data.name === 'Physicians By Location' ||
                        data.name === 'Locations By Physician Standard' ||
                        data.name === 'Attachment Data'
                    ) {
                        await this.configurationRecordSet(data.id);
                    }
                }
            );
        }
    }

    expandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded = () => {
        this.setState({
            isExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded: true,
        });
    };

    cancelExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded = () => {
        this.setState({
            isExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded: false,
        });
    };

    onRowClick = async (rowDetails, rowHeader, stateData) => {
        // Remove previous edit type from redux
        this.props.inboxPage({
            editDetailsSwitch: {
                docId: '',
                editType: '',
            },
        });
        const docid = rowDetails.doc_id;
        document.body.classList.add('doc-small-view');
        this.setLockBarVisibility(false);
        if (String(rowHeader.key) === 'tags') {
            let tagIndex;
            await stateData.filteredRowData.map((rows, index) => {
                if (String(rows.doc_id) === String(docid)) {
                    tagIndex = index;
                }
                return rows;
            });

            // If edit details is not there, keep the hidden, overwise keep them shown when changing docs
            if (!this.state.edit_details) {
                this.setState({
                    edit_details: false,
                });
            } else {
                this.setState({
                    edit_details: true,
                });
            }

            this.setState(
                {
                    rowData: stateData.rowData,
                    selectedTagDocId: docid,
                    selected_tag: rowDetails.tags_data,
                    tagIndex: tagIndex,
                    selectedColumn: rowHeader.key,
                    showData: true,
                    reInitiateGrid: false,
                },
                () => {
                    this.props.updateInboxState({
                        tempExpandedGroupIds: stateData.tempExpandedGroupIds,
                        customGroupedData: stateData.customGroupedData,
                        expandedGroupIds: stateData.expandedGroupIds,
                        groupByData: stateData.groupByData,
                        groupByIndex: stateData.groupByIndex,
                        groupBy: stateData.groupBy,
                        sortDirection: stateData.sortDirection,
                        sortColumn: stateData.sortColumn,
                        statictics: stateData.statictics,
                        row_transition: stateData.row_transition,
                        modifiedColumnsNames: stateData.modifiedColumnsNames,
                        header: stateData.header,
                        currentStateMenuName: stateData.currentStateMenuName,
                        dateFormatFields: stateData.dateFormatFields,
                        totalColumn: stateData.totalColumn,
                        totalCount: stateData.totalCount,
                        tableDesign: stateData.tableDesign,
                        uniqueData: stateData.uniqueData,
                        filteredRowData: stateData.filteredRowData,
                        rowData: stateData.rowData,
                        perPage: stateData.perPage,
                        currentPage: stateData.currentPage,
                        allActions: stateData.allActions,
                        filterTxt: stateData.filterTxt,
                        selectedColumn: rowHeader.key,
                        selectedTagDocId: docid,
                        selected_tag: rowDetails.tags_data,
                        tagIndex: tagIndex,
                        showData: true,
                        reInitiateGrid: false,
                    });
                }
            );
            this.setState({
                tagRefresh: true,
            });
            return true;
        }

        if (String(rowHeader.key) === 'owner') {
            const docid = rowDetails.doc_id;
            this.setState(
                {
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                    edit_details: false,
                },
                async () => {
                    this.handleMultiSelectClose();

                    this.setState(
                        {
                            selectedRows: new Set(),
                        },
                        () => {
                            this.props.updateInboxState({
                                ...stateData,
                                tempExpandedGroupIds:
                                    stateData.tempExpandedGroupIds,
                                customGroupedData: stateData.customGroupedData,
                                expandedGroupIds: stateData.expandedGroupIds,
                                groupByData: stateData.groupByData,
                                groupByIndex: stateData.groupByIndex,
                                groupBy: stateData.groupBy,
                                sortDirection: stateData.sortDirection,
                                sortColumn: stateData.sortColumn,
                                row_transition: stateData.row_transition,
                                modifiedColumnsNames:
                                    stateData.modifiedColumnsNames,
                                header: stateData.header,
                                currentStateMenuName:
                                    stateData.currentStateMenuName,
                                dateFormatFields: stateData.dateFormatFields,
                                totalColumn: stateData.totalColumn,
                                totalCount: stateData.totalCount,
                                tableDesign: stateData.tableDesign,
                                uniqueData: stateData.uniqueData,
                                filteredRowData: stateData.filteredRowData,
                                rowData: stateData.rowData,
                                perPage: stateData.perPage,
                                currentPage: stateData.currentPage,
                                allActions: stateData.allActions,
                                filterTxt: stateData.filterTxt,
                                menu_branch: this.state.menu_branch,
                                menu_group: this.state.menu_group,
                                is_locked_by: this.state.is_locked_by,
                                noteCount: this.state.noteCount,
                                pageCount: this.state.pageCount,
                                showData: this.state.showData,
                                selectedColumn: this.state.selectedColumn,
                                selectedRows: new Set(),
                            });
                        }
                    );
                }
            );

            if (
                this.props.inbox.selectedRow === undefined ||
                String(this.props.inbox.selectedRow) !== String(docid)
            ) {
                await this.props.setSelectedRow(docid);
            }
            return true;
        }

        if (String(rowHeader.key) === 'select-row') {
            await this.configurationApiCall();
            this.setState({
                showData: false,
                selectedColumn: rowHeader.key,
                reInitiateGrid: false,
                edit_details: false,
            });
            return true;
        } else {
            this.handleMultiSelectClose();
            await this.configurationApiCall();
            const docid = rowDetails.doc_id;

            this.setState(
                {
                    onRowClickId: rowDetails.doc_id,
                    rowClickClass: [rowDetails.id],
                    menu_branch: '',
                    menu_group: '',
                    menu_inbox: '',
                    rowDetails: rowDetails,
                    reInitiateGrid: false,
                    showData: true,
                    selectedColumn: rowHeader.key,
                    // 10098: Change edit details less in unexpected places
                    // edit_details: true,
                },
                async () => {
                    // let key;
                    const fieldData = GetFieldData({
                        configData: this.props.configData,
                    });
                    //   await this.handleMultiSelectClose();

                    document.body.classList.add('aside-minimize');
                    const response = getDocumentMetaWithDocId({ docid });

                    const configData = getConfigurationData();
                    let singleStatus = true;
                    this.setState({ isDetailsLoading: true });
                    await response
                        .then(async (data) => {
                            if (!data.miscPproperties.rulesJson) {
                                console.error('No rules JSON to parse!');
                            } else {
                                data.miscPproperties.rulesJson = JSON.parse(
                                    data.miscPproperties.rulesJson
                                );
                            }

                            if (data.statuses.length > 1) {
                                showErrorToast(
                                    `Error in processing Document ${data.miscPproperties.docId} . Please contact WorldView Customer Support for further assistance.`
                                );
                                this.setState({
                                    row_transition: false,
                                });
                                document.body.classList.remove(
                                    'aside-minimize'
                                );
                                singleStatus = false;
                                return false;
                            } else {
                                await this.props.getSelectedRowDetails(data);
                                this.setState({
                                    selectedDetailsOfRow: data,
                                });
                                //
                                let formatted_rowDetail = '';
                                if (data.hasOwnProperty('fields')) {
                                    formatted_rowDetail = data.fields;
                                }

                                const allFields = this.props.configData.fields;
                                const finalRowDetail = {};
                                if (formatted_rowDetail.length > 0) {
                                    formatted_rowDetail.map((rowdetail) => {
                                        allFields.map((allfields) => {
                                            const temp = { ...rowdetail };
                                            if (
                                                rowdetail.fieldId ===
                                                allfields.fieldId
                                            ) {
                                                temp.fieldName = allfields.name;
                                                const removeSpace =
                                                    formatNameWithSpaceToUnderscore(
                                                        allfields.name
                                                    );
                                                finalRowDetail[removeSpace] =
                                                    temp;
                                            }
                                        });
                                    });
                                }

                                this.props.editDetailPage({
                                    selectedRowDetail: finalRowDetail,
                                });
                                await this.checkLock(docid);
                                if (this.state.is_locked !== undefined) {
                                    setTimeout(() => {
                                        if (this.state.is_locked === true) {
                                            if (
                                                document.getElementById(
                                                    'left-aside'
                                                ) !== null
                                            ) {
                                                document
                                                    .getElementById(
                                                        'left-aside'
                                                    )
                                                    .classList.remove(
                                                        'no-lock-section'
                                                    );
                                            }
                                        } else if (
                                            document.getElementById(
                                                'left-aside'
                                            ) !== null
                                        ) {
                                            document
                                                .getElementById('left-aside')
                                                .classList.add(
                                                    'no-lock-section'
                                                );
                                        }
                                    }, 10);
                                }
                                if (
                                    data.miscPproperties.lockedBy !== undefined
                                ) {
                                    this.setState({
                                        is_locked_by:
                                            data.miscPproperties.lockedBy,
                                    });
                                }
                                if (
                                    data.miscPproperties.noteCount !== undefined
                                ) {
                                    this.setState({
                                        noteCount:
                                            data.miscPproperties.noteCount,
                                    });
                                }
                                if (
                                    data.miscPproperties.pageCount !== undefined
                                ) {
                                    this.setState({
                                        pageCount:
                                            data.miscPproperties.pageCount,
                                    });
                                }
                                const foundFields = [];

                                if (data.fields !== undefined) {
                                    data.fields.forEach((dataValue) => {
                                        const fields = dataValue.fieldId;
                                        if (
                                            String(fields) ===
                                            String(fieldData.branchFieldId)
                                        ) {
                                            this.setState({
                                                menu_branch: dataValue.value,
                                            });
                                        }
                                        if (
                                            String(fields) ===
                                            String(fieldData.groupFieldId)
                                        ) {
                                            this.setState({
                                                menu_group: dataValue.value,
                                            });
                                        }

                                        if (
                                            String(fields) ===
                                            String(fieldData.workflow_status)
                                        ) {
                                            this.setState({
                                                menu_inbox: dataValue.value,
                                            });
                                        }
                                        for (const property in configData) {
                                            if (
                                                String(
                                                    configData[property].fieldId
                                                ) === String(fields)
                                            ) {
                                                foundFields.push(
                                                    configData[property]
                                                );
                                            }
                                        }
                                    });
                                }
                            }
                        })
                        .finally(() => {
                            this.setState({ isDetailsLoading: false });
                        });
                    if (singleStatus) {
                        this.setState(
                            {
                                row_transition: true,
                                selectedDocId: docid,
                                selectedRows: new Set(),
                            },
                            () => {
                                this.props.updateInboxState({
                                    row_transition: true,
                                    rowClickClass: this.state.rowClickClass,
                                    tempExpandedGroupIds:
                                        stateData.tempExpandedGroupIds,
                                    customGroupedData:
                                        stateData.customGroupedData,
                                    expandedGroupIds:
                                        stateData.expandedGroupIds,
                                    groupByData: stateData.groupByData,
                                    groupByIndex: stateData.groupByIndex,
                                    groupBy: stateData.groupBy,
                                    sortDirection: stateData.sortDirection,
                                    sortColumn: stateData.sortColumn,
                                    statictics: stateData.statictics,
                                    refreshDataGrid: stateData.refreshDataGrid,
                                    modifiedColumnsNames:
                                        stateData.modifiedColumnsNames,
                                    header: stateData.header,
                                    currentStateMenuName:
                                        stateData.currentStateMenuName,
                                    dateFormatFields:
                                        stateData.dateFormatFields,
                                    totalColumn: stateData.totalColumn,
                                    totalCount: stateData.totalCount,
                                    tableDesign: stateData.tableDesign,
                                    uniqueData: stateData.uniqueData,
                                    filteredRowData: stateData.filteredRowData,
                                    rowData: stateData.rowData,
                                    perPage: stateData.perPage,
                                    currentPage: stateData.currentPage,
                                    allActions: stateData.allActions,
                                    filterTxt: stateData.filterTxt,
                                    selectedDocId: docid,
                                    menu_branch: this.state.menu_branch,
                                    menu_group: this.state.menu_group,
                                    is_locked_by: this.state.is_locked_by,
                                    noteCount: this.state.noteCount,
                                    pageCount: this.state.pageCount,
                                    onRowClickId: this.state.onRowClickId,
                                    rowDetails: rowDetails,
                                    reInitiateGrid: this.state.reInitiateGrid,
                                    showData: this.state.showData,
                                    selectedColumn: this.state.selectedColumn,
                                    selectedRows: new Set(),
                                });
                            }
                        );

                        if (this.state.is_locked !== true) {
                            await lockDocument(docid);
                        }
                    }
                }
            );
        }

        const elem = document.getElementById('last-pos');
        if (elem !== null) {
            elem.parentElement.removeChild(elem);
        }

        // 10098: When we click on next document in the row, refresh the details
        if (this.state.isPartialEditModeEnabled) {
            if (this.state.isInEditingMode) {
                this.refreshEditDetails();
            } else {
                // 10098: TODO: Find a way to update the document
                this.causeRefreshOfViewDetailsInformation();
            }
        }
    };

    causeRefreshOfViewDetailsInformation = () => {
        this.refreshEditDetails();
        this.cancelEditing();
    };

    refreshEditDetails = () => {
        this.setState({
            edit_details: false,
        });
        this.setState({
            edit_details: true,
        });
    };

    async refreshTable() {
        const processId = this.state.processId;
        const statusId = this.state.statusId;
        await this.props.updateList('');
        const filterKey = `searchFilter_${processId}_${statusId}`;
        const savedFilter = localStorage.getItem(filterKey) ?? '';
        if (this.state.isPartialEditModeEnabled && this.state.edit_details) {
            this.turnPartialEditModeOn();
        }

        this.setState(
            {
                rowData: [],
                filteredRowData: [],
                currentPage: 0,
                perPage: 25,
                sortDirection: 'NONE',
                sortColumn: '',
                filterTxt: savedFilter,
                groupBy: [],
                rowClickClass: [],
                groupByData: {},
                tempExpandedGroupIds: new Set(),
                expandedGroupIds: new Set(),
                selectedRows: new Set(),
                displayAnimation: true,
                showLoader: true,
                row_transition: false,
                openedRowId: {},
            },
            () => {
                document.body.classList.remove('aside-minimize');
                this.props.clearInboxState({
                    rowData: [],
                    filteredRowData: [],
                    currentPage: 0,
                    perPage: 25,
                    sortDirection: 'NONE',
                    sortColumn: '',
                    filterTxt: savedFilter,
                    groupBy: [],
                    rowClickClass: [],
                    groupByData: {},
                    tempExpandedGroupIds: new Set(),
                    expandedGroupIds: new Set(),
                    selectedRows: new Set(),
                    displayAnimation: true,
                    showLoader: true,
                    row_transition: false,
                    openedRowId: {},
                });
                this.props.getListaction(
                    processId,
                    statusId,
                    this.state.calingFrom,
                    this.state.queryData
                );
            }
        );
        const count = [];
        const oldCount = this.props.count;
        getProcessCount(processId)
            .then((response) => {
                // status = response.status;
                return response.json();
            })
            .then(async (data) => {
                count.push(data);
                await oldCount.map((oldCountMap) => {
                    if (
                        oldCountMap.process !== undefined &&
                        oldCountMap.process.processId !== undefined &&
                        data.process !== undefined &&
                        data.process.processId !== undefined &&
                        oldCountMap.process.processId !== data.process.processId
                    ) {
                        count.push(oldCountMap);
                    }
                    return oldCountMap;
                });
                this.props.getCountData(count);
            });
        await getCount(processId, statusId)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('refresh_count', data.count);
                let countData = localStorage.getItem(
                    `CountData${data.processId}`
                );
                if (countData !== null) {
                    let totalCount = 0;
                    countData = JSON.parse(countData);

                    if (countData.statuses !== undefined) {
                        countData.statuses.map((arrayval) => {
                            if (
                                arrayval.processId === data.processId &&
                                arrayval.statusId === data.statusId
                            ) {
                                arrayval.count = data.count;
                            }
                            totalCount += arrayval.count;
                            return arrayval;
                        });
                    }
                    if (countData.process !== undefined) {
                        countData.process.count = totalCount;
                    }
                    localStorage.setItem(
                        `CountData${data.processId}`,
                        JSON.stringify(countData)
                    );
                }
            });
        await this.getLockedDocIds();
        await this.props.history.push(window.location.pathname);
    }

    openDeletePopUp() {
        const selectedRows = Array.from(this.state.selectedRows);
        let rowsToDelete = this.state.rowData.filter((data) => {
            return selectedRows.indexOf(data.id) !== -1;
        });

        if (rowsToDelete.length === 0 && this.state.rowClickClass.length > 0) {
            rowsToDelete = this.state.rowData.filter((data) => {
                return this.state.onRowClickId === data.doc_id;
            });
        }
        if (rowsToDelete.length === 0) {
            rowsToDelete = this.state.rowsToDelete;
        }
        this.setState({
            openDelete: true,
            rowsToDelete: rowsToDelete,
            deleteComments: [],
        });
    }

    closeDeletePopUp(param) {
        const { removeRow, rowsToDelete } = param;
        if (removeRow !== undefined && removeRow === true) {
            if (removeRow && rowsToDelete.length > 0) {
                const docIds = [];
                rowsToDelete.forEach((data) => {
                    docIds.push(data.doc_id);
                });
                const rowData = this.state.rowData.filter((data) => {
                    return !(docIds.indexOf(data.doc_id) !== -1);
                });
                const filteredRowData = this.state.filteredRowData.filter(
                    (data) => {
                        return !(docIds.indexOf(data.doc_id) !== -1);
                    }
                );
                this.setState(
                    {
                        openDelete: false,
                        selectedRows: new Set(),
                        selectedIndexes: [],
                        rowsToDelete: [],
                        rowData: rowData,
                        filteredRowData: filteredRowData,
                        row_transition: false,
                    },
                    () => {
                        this.props.updateInboxState(this.state);
                        this.props.getListaction(
                            this.state.processId,
                            this.state.statusId,
                            this.state.calingFrom,
                            this.state.queryData
                        );

                        document.body.classList.remove('aside-minimize');
                        document.body.classList.remove('doc-small-view');
                    }
                );
            }
            return;
        }
        this.setState({
            openDelete: false,
        });
    }

    setBranch = (event) => {
        this.setState({ menu_branch: event.target.value });
    };

    set_group = (event) => {
        this.setState({ menu_group: event.target.value });
    };

    set_inbox = (event) => {
        this.setState({ menu_inbox: event.target.value });
    };

    download_multiple = async () => {
        this.setState({ blur_spinner: true }, () => {
            download_multiple({
                selectedRows: this.state.selectedRows,
                rowData: this.state.rowData,
            });
            this.setState({ blur_spinner: false });
        });
    };

    enableLoader(value) {
        this.setState({ blur_spinner: value });
    }

    async restrictFilterDataGrid(searchText = '') {
        const isValid = (str) => {
            // eslint-disable-next-line no-useless-escape
            return !/[~`!#()$%\^&*+=\\[\]\\';{}|\\"<>\?]/g.test(str);
        };
        if (isValid(searchText.trim()) === false) {
            return [];
        } else {
            const { processId, statusId } = this.state;
            if (!!processId && !!statusId) {
                const filterKey = `searchFilter_${processId}_${statusId}`;
                const { queryData, currentPage, perPage } = this.state;
                queryData.pageNumber = currentPage + 1;
                queryData.pageSize = perPage;
                queryData.fullTextSearchString = searchText;

                this.props.getListaction(
                    processId,
                    statusId,
                    this.state.calingFrom,
                    queryData
                );

                localStorage.setItem(filterKey, searchText);
                this.setState({ filterTxt: searchText });
            }
        }
    }

    rowTransition = async () => {
        this.setState(
            {
                row_transition: false,
                detailBigView: false,
            },
            () => {
                //if we update it takes old row state variable.
                this.props.updateInboxState({
                    // ...this.state,
                    row_transition: false,
                    detailBigView: false,
                });
            }
        );
    };

    handleMultiSelectClose = () => {
        removeCheckboxClass();
        this.setState({
            selectedRows: new Set(),
        });
        this.setState({
            close_tagmenu: false,
            open_addtag: false,
            open_replacetag: false,
            open_deletetag: false,
            menu_selected_tagonly: [],
            menu_selected_tag: [],
        });
    };

    openBigView = () => {
        this.setState({
            detailBigView: true,
        });
    };

    expandWasPressedTrigger = () => {
        this.setState({
            expandWasPressedEvent: true,
        });
    };

    cancelExpandWasPressedEvent = () => {
        this.setState({
            expandWasPressedEvent: false,
        });
    };

    closeBigView = () => {
        this.setState({ detailBigView: false });
    };

    openEditDetails = () => {
        this.setState({ edit_details: true });
    };

    closeEditDetails = () => {
        this.setState({ edit_details: false });
    };

    turnPartialEditModeOff = () => {
        this.setState({
            isPartialEditModeEnabled: false,
        });
    };

    turnPartialEditModeOn = () => {
        this.setState({
            isPartialEditModeEnabled: true,
        });
    };

    turnEditModeButtonActive = () => {
        this.setState({
            isEditModeButtonActive: true,
        });
    };

    turnEditModeButtonInactive = () => {
        this.setState({
            isEditModeButtonActive: false,
        });
    };

    mimickCancelClick = () => {
        this.setState({
            isEditModeCanceledFromDocumentDetail: true,
        });
    };

    cancelMimickCancelClick = () => {
        this.setState({
            isEditModeCanceledFromDocumentDetail: false,
        });
    };

    refreshTag = async (smallView) => {
        this.setState({ tagLoader: true });
        this.setState({ refresh_table: false });
        this.setState({ refresh_table: true });
    };

    triggerCollapseFromDetailBigView = () => {
        this.setState({ isTriggerCollapseFromDetailBigView: true });
    };

    terminateCollapseFromDetailBigViewRequest = () => {
        this.setState({ isTriggerCollapseFromDetailBigView: false });
    };

    onSelectedRowsChange(rows, gridStateData) {
        const selectedRowData = getDocumentIdFromArray(
            gridStateData.filteredRowData,
            Array.from(rows)
        );
        const pushEditId = [];
        selectedRowData.forEach((v, i) => {
            pushEditId.push(v.doc_id);
        });
        const selectedRows = rows;
        this.setState(
            {
                selectedRows: selectedRows,
                printSelectedDocId: [],
                close_tagmenu: false,
                open_addtag: false,
                open_replacetag: false,
                open_deletetag: false,
                menu_selected_tagonly: [],
                menu_selected_tag: [],
                detailBigView: false,
                editId: pushEditId,
            },

            () => {
                this.props.updateInboxState({
                    tempExpandedGroupIds: gridStateData.tempExpandedGroupIds,
                    customGroupedData: gridStateData.customGroupedData,
                    expandedGroupIds: gridStateData.expandedGroupIds,
                    groupByData: gridStateData.groupByData,
                    groupByIndex: gridStateData.groupByIndex,
                    groupBy: gridStateData.groupBy,
                    sortDirection: gridStateData.sortDirection,
                    sortColumn: gridStateData.sortColumn,
                    statictics: gridStateData.statictics,
                    refreshDataGrid: gridStateData.refreshDataGrid,
                    reInitiateGrid: gridStateData.reInitiateGrid,
                    modifiedColumnsNames: gridStateData.modifiedColumnsNames,
                    header: gridStateData.header,
                    currentStateMenuName: gridStateData.currentStateMenuName,
                    dateFormatFields: gridStateData.dateFormatFields,
                    totalColumn: gridStateData.totalColumn,
                    totalCount: gridStateData.totalCount,
                    tableDesign: gridStateData.tableDesign,
                    uniqueData: gridStateData.uniqueData,
                    filteredRowData: gridStateData.filteredRowData,
                    perPage: gridStateData.perPage,
                    currentPage: gridStateData.currentPage,
                    filterTxt: gridStateData.filterTxt,
                    filteredData: gridStateData.filteredRowData,
                    rowData: gridStateData.rowData,
                    allActions: gridStateData.allActions,
                    selectedRows: selectedRows,
                    detailBigView: false,
                    editId: pushEditId,
                });
            }
        );
    }

    resetBigView = () => {
        this.rowTransition();

        document.body.classList.remove('aside-minimize');
        document.body.classList.remove('doc-small-view');
        this.refreshTable();
    };

    refreshForNextDocument = (docid) => {
        this.setState({ row_transition: false, detailBigView: false }, () => {
            this.setState({
                nextDocument: true,
                selectedDocId: docid,
                row_transition: true,
                detailBigView: true,
                onRowClickId: docid,
            });
        });
    };

    startEditing = () => {
        this.setState({
            isInEditingMode: true,
        });
    };

    cancelEditing = () => {
        this.setState({
            isInEditingMode: false,
        });
    };

    // 10067:
    getIsPhysicianSignatureDocument = () => {
        const status =
            (this.state.pageTitle === 'Physician Signature Main' ||
                this.state.pageTitle === 'Physician Signature Exception') &&
            (this.state.menu_name === 'physician-signature-main' ||
                this.state.menu_name === 'physician-signature-exception');
        return status;
    };

    // TEMP
    // putLocks = () => {
    //     this.setState({
    //         is_locked: true,
    //     });
    // }
    checkLock = async (docid) => {
        await this.getLockedDocIds();
        const isLocked = this.state.lockedDocIds.includes(docid);
        this.setIsLocked(isLocked);
    };

    setIsLocked = (value) => {
        this.setState({ is_locked: value });
    };

    unlockDocument = async () => {
        await this.getLockedDocIds();
        var updatedList = this.state.lockedDocIds.filter(
            (docId) => docId !== this.state.selectedDocId
        );
        this.setState({
            is_locked: false,
            lockedDocIds: updatedList,
        });
    };

    showUpNextPhysicianSignatureDocument = async () => {
        let nextIndex = null; // Use null to clearly differentiate no index found
        let nextDocId = '';
        let filteredData = this.props.inbox.stateData.rowData;
        const rowLen = filteredData.length;
        console.log('filteredData', filteredData);

        // Loop to find the current document and determine the next index
        filteredData.forEach((tableData, i) => {
            if (tableData.doc_id === this.state.selectedDocId) {
                if (rowLen === i + 1) {
                    // Check if the current document is the last in the array
                    nextIndex = null; // No next document
                } else {
                    nextIndex = i + 1; // Set the next document's index
                }
            }
        });

        console.log('Next index:', nextIndex); // Debugging output

        // Check if there is a valid next index
        if (nextIndex !== null) {
            nextDocId = filteredData[nextIndex]?.doc_id;
            console.log('Next Doc ID:', nextDocId); // Debugging output

            // Fetch document meta if there is a next document ID
            if (nextDocId) {
                try {
                    await getDocumentMetaWithDocId({
                        docid: nextDocId,
                    });

                    this.refreshForNextDocument(nextDocId);
                    this.expandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded();
                } catch (error) {
                    console.error('Error fetching document meta:', error);
                }
            }
        } else {
            console.log('No next document available.');
        }
    };

    render() {
        return (
            <>
                <ThemeContext.Provider value={{ name: 'inbox' }}>
                    <div
                        className={
                            this.state.detailBigView &&
                            this.state.is_locked === false
                                ? ''
                                : this.state.is_locked &&
                                  this.state.detailBigView
                                ? ''
                                : this.state.is_locked
                                ? 'scrol-outer'
                                : 'scrol-outer bigViewClosed'
                        }
                        style={
                            this.state.single_edit === true ||
                            this.props.related_doc_detail === true ||
                            this.props.multiple_edit_page === true
                                ? { display: 'none' }
                                : {}
                        }
                    >
                        <div
                            id='inbox3-overall'
                            className={`toast-container ${this.state.toast_type}`}
                        />
                        {this.state.detailBigView === false ? (
                            <div
                                id='right-aside'
                                className='right-aside py-5'
                                style={
                                    isMobile
                                        ? { height: window.innerHeight - 112 }
                                        : { height: window.innerHeight - 158 }
                                }
                            >
                                <div className=''>
                                    <div className='d-flex justify-content-between mb-5 pl-7 pr-7'>
                                        <div
                                            className='d-flex align-items-center'
                                            style={{ float: 'left' }}
                                        >
                                            <h3 className='m-0 title-color'>
                                                {this.props.calingFrom !==
                                                    undefined &&
                                                this.props.calingFrom ===
                                                    'search'
                                                    ? this.props.pageTitle
                                                    : this.state.pageTitle}
                                            </h3>
                                        </div>
                                        <div
                                            className='d-flex filter_part'
                                            style={{ maxWidth: 500 }}
                                        >
                                            <div
                                                style={{ float: 'left' }}
                                                className='filter normal_view_filter'
                                            >
                                                <SearchFilter
                                                    inputProps={{
                                                        maxLength: 50,
                                                        disabled:
                                                            this.state
                                                                .row_transition,

                                                        style: this.state
                                                            .row_transition
                                                            ? {
                                                                  cursor: 'not-allowed',
                                                              }
                                                            : {
                                                                  paddingRight:
                                                                      '34px',
                                                              },
                                                    }}
                                                    disabled={
                                                        this.state
                                                            .row_transition
                                                    }
                                                    value={this.state.filterTxt}
                                                    callBack={(text) =>
                                                        this.restrictFilterDataGrid(
                                                            text
                                                        )
                                                    }
                                                />
                                            </div>
                                            <span
                                                className='reload'
                                                onClick={() =>
                                                    this.refreshTable()
                                                }
                                            >
                                                {' '}
                                                <i
                                                    title='Refresh'
                                                    className={
                                                        this.state
                                                            .displayAnimation
                                                            ? 'fas fa-sync-alt refreshing'
                                                            : 'fas fa-sync-alt'
                                                    }
                                                />{' '}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mobile_filter pl-7 pr-7 mb-3'>
                                        <div className='filter'>
                                            <input
                                                type='text'
                                                placeholder=''
                                                value={this.state.filterTxt}
                                                onChange={(event) =>
                                                    this.setState({
                                                        filterTxt:
                                                            event.target.value,
                                                    })
                                                }
                                                maxLength={20}
                                                disabled={
                                                    this.state.row_transition
                                                }
                                                style={
                                                    this.state.row_transition
                                                        ? {
                                                              cursor: 'not-allowed',
                                                          }
                                                        : {
                                                              paddingRight:
                                                                  '34px',
                                                          }
                                                }
                                            />
                                            <i className='icon-md fab fa-sistrix' />
                                        </div>
                                    </div>
                                    {this.state.printLoader ? (
                                        <div className='grouping-loader'>
                                            {' '}
                                            <Spinner
                                                show={this.state.printLoader}
                                            />{' '}
                                        </div>
                                    ) : null}
                                    <DataGridComponent
                                        detailBigViewExpandObj={{
                                            data: this.state
                                                .detailBigViewExpand,
                                            setData: (value) => {
                                                this.setState({
                                                    detailBigViewExpand: value,
                                                });
                                            },
                                        }}
                                        {...this.state}
                                        rowData={this.state.rowData}
                                        owenerRow={this.state.owenerRow}
                                        userPermission={
                                            this.state.userPermission
                                        }
                                        pdfPrintRef={this.pdfPrintRef}
                                        filterTxt={this.state.filterTxt}
                                        tableDesign={this.state.tableDesign}
                                        currentPage={this.state.currentPage}
                                        perPage={this.state.perPage}
                                        handleMultiSelectClose={this.handleMultiSelectClose.bind(
                                            this
                                        )}
                                        rowHeightfn={rowHeightDataGrid}
                                        onRowClick={this.onRowClick.bind(this)}
                                        onSelectedRowsChange={this.onSelectedRowsChange.bind(
                                            this
                                        )}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                        }}
                                        calingFrom={this.props.calingFrom}
                                        refreshTable={this.refreshTable}
                                        allActions={
                                            this.state.NewPermission !==
                                                undefined &&
                                            this.state.NewPermission
                                                .allActions !== undefined
                                                ? this.state.NewPermission
                                                      .allActions
                                                : this.state.userPermission !==
                                                      undefined &&
                                                  this.state.userPermission
                                                      .allActions !== undefined
                                                ? this.state.userPermission
                                                      .allActions
                                                : undefined
                                        }
                                        tagRefresh={this.state.tagRefresh}
                                        tagPopup={this.state.tagPopup}
                                        tagDropDownData={
                                            this.state.tagDropDownData
                                        }
                                        selected_tag={this.state.selected_tag}
                                        selectedTagDocId={
                                            this.state.selectedTagDocId
                                        }
                                        lockedDocIds={this.state.lockedDocIds}
                                        lockBarVisible={
                                            this.state.lockBarVisible
                                        }
                                        setLockBarVisibility={
                                            this.setLockBarVisibility
                                        }
                                        count={this.props.count}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {this.state.openDelete ? (
                            <DeleteModal
                                openDelete={this.state.openDelete}
                                rowsToDelete={this.state.rowsToDelete}
                                closeDeletePopUp={this.closeDeletePopUp.bind(
                                    this
                                )}
                                processId={this.state.processId}
                                statusId={this.state.statusId}
                                deleteActionId={this.state.deleteActionId}
                                branchData={this.state.branches}
                                refreshTable={this.resetBigView}
                                calingFrom='inbox'
                                selectedRow={this.state.selectedRows}
                            />
                        ) : null}
                        {this.state.row_transition === true ? (
                            <div
                                id='left-aside'
                                className={
                                    this.state.detailBigView &&
                                    this.state.is_locked === false
                                        ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                        : this.state.is_locked &&
                                          this.state.detailBigView
                                        ? 'left-aside py-9 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                        : this.state.is_locked
                                        ? 'left-aside py-9 rounded-lg aside-fixed flex-row-auto '
                                        : 'left-aside py-5 rounded-lg aside-fixed flex-row-auto '
                                }
                            >
                                <>
                                    <DocumentDetail
                                        // 10067: Passing Physician Signature Props
                                        isPhyisicanSignatureDocument={this.getIsPhysicianSignatureDocument()}
                                        physicianSignatureDocumentType={
                                            this.state.pageTitle
                                        }
                                        onRowClickId={this.state.onRowClickId}
                                        rowData={this.state.rowData}
                                        rowDetails={this.state.rowDetails}
                                        physicianSignature={
                                            this.state.physicianSignature
                                        }
                                        setPhysicianSignatureCity={
                                            this.setPhysicianSignatureCity
                                        }
                                        setPhysicianSignatureFileName={
                                            this.setPhysicianSignatureFileName
                                        }
                                        setPhysicianSignatureNPI={
                                            this.setPhysicianSignatureNPI
                                        }
                                        setPhysicianSignatureOrderingPhysician={
                                            this
                                                .setPhysicianSignatureOrderingPhysician
                                        }
                                        setPhysicianSignatureState={
                                            this.setPhysicianSignatureState
                                        }
                                        setPhysicianSignatureSignedDate={
                                            this.setPhysicianSignatureSignedDate
                                        }
                                        isExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded={
                                            this.state
                                                .isExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded
                                        }
                                        cancelExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded={
                                            this
                                                .cancelExpandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded
                                        }
                                        showUpNextPhysicianSignatureDocument={
                                            this
                                                .showUpNextPhysicianSignatureDocument
                                        }
                                        // Edit Details
                                        isEditDetails={this.state.edit_details}
                                        openEditDetails={this.openEditDetails}
                                        closeEditDetails={this.closeEditDetails}
                                        startEditing={this.startEditing}
                                        cancelEditing={this.cancelEditing}
                                        isInEditingMode={
                                            this.state.isInEditingMode
                                        }
                                        // Partial Edit Mode
                                        isPartialEditModeEnabled={
                                            this.state.isPartialEditModeEnabled
                                        }
                                        turnPartialEditModeOff={
                                            this.turnPartialEditModeOff
                                        }
                                        turnPartialEditModeOn={
                                            this.turnPartialEditModeOn
                                        }
                                        turnEditModeButtonActive={
                                            this.turnEditModeButtonActive
                                        }
                                        turnEditModeButtonInactive={
                                            this.turnEditModeButtonInactive
                                        }
                                        isEditModeButtonActive={
                                            this.state.isEditModeButtonActive
                                        }
                                        // Mimick Stuff
                                        mimickCancelClick={
                                            this.mimickCancelClick
                                        }
                                        expandWasPressedEvent={
                                            this.state.expandWasPressedEvent
                                        }
                                        cancelExpandWasPressedEvent={
                                            this.cancelExpandWasPressedEvent
                                        }
                                        pdfPrintRef={this.pdfPrintRef}
                                        nextDocument={this.state.nextDocument}
                                        resetNextDoc={() => {
                                            this.setState({
                                                nextDocument: false,
                                            });
                                        }}
                                        isTriggerCollapseFromDetailBigView={
                                            this.state
                                                .isTriggerCollapseFromDetailBigView
                                        }
                                        terminateCollapseFromDetailBigViewRequest={
                                            this
                                                .terminateCollapseFromDetailBigViewRequest
                                        }
                                        selectedDocId={this.state.selectedDocId}
                                        username={username}
                                        dispname={dispname}
                                        notesVal={this.state.notesVal}
                                        openDeletePopUp={() => {
                                            this.openDeletePopUp.call(this);
                                        }}
                                        statusArray={statusArray}
                                        branches={this.state.branches}
                                        download={() => {
                                            download(this.state.onRowClickId);
                                        }}
                                        rowTransition={() => {
                                            this.rowTransition();
                                        }}
                                        selectedGroup={this.set_group}
                                        setBranch={this.setBranch}
                                        setInbox={this.set_inbox}
                                        groups={this.state.groups}
                                        saveTransition={() => {
                                            this.save_transition(true);
                                        }}
                                        openBigView={() => {
                                            this.openBigView();
                                        }}
                                        notesDDL={JSON.parse(
                                            localStorage.getItem('noteTypes')
                                        )}
                                        isLocked={this.state.is_locked}
                                        lockedBy={this.state.is_locked_by}
                                        onUnlock={this.unlockDocument}
                                        closeBigView={() => {
                                            this.closeBigView();
                                        }}
                                        menuGroup={this.state.menu_group}
                                        menuBranch={this.state.menu_branch}
                                        menuInbox={this.state.menu_inbox}
                                        ownerlist={this.state.owners}
                                        menuTags={this.state.menu_tags}
                                        menuSelectedTags={
                                            this.state.menu_selected_tag
                                        }
                                        tagColorArray={
                                            this.state.newTagColorArray
                                        }
                                        showBranch={this.state.show_branch}
                                        showDelete={this.state.show_delete}
                                        showDownload={this.state.show_download}
                                        showGroup={this.state.show_group}
                                        showInbox={this.state.show_inbox}
                                        showNotes={this.state.show_notes}
                                        showOwner={this.state.show_owner}
                                        showPdf={this.state.show_pdf}
                                        showPrint={this.state.show_print}
                                        showTag={this.state.show_tag}
                                        noteCount={this.state.noteCount}
                                        pageCount={this.state.pageCount}
                                        refreshTag={() => {
                                            this.refreshTag();
                                        }}
                                        enableLoader={this.enableLoader.bind(
                                            this
                                        )}
                                        menu_name={this.state.menu_name}
                                        processId={this.state.processId}
                                        statusId={this.state.statusId}
                                        branchActionId={
                                            this.state.branchActionId
                                        }
                                        groupActionId={this.state.groupActionId}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                            this.props.inboxPage({ ...state });
                                        }}
                                        pdf_preview={this.state.pdf_preview}
                                        calingFrom={this.props.calingFrom}
                                        allActions={
                                            this.state.NewPermission !==
                                                undefined &&
                                            this.state.NewPermission
                                                .allActions !== undefined
                                                ? this.state.NewPermission
                                                      .allActions
                                                : this.state.userPermission !==
                                                      undefined &&
                                                  this.state.userPermission
                                                      .allActions !== undefined
                                                ? this.state.userPermission
                                                      .allActions
                                                : undefined
                                        }
                                        count={this.props.count}
                                        refreshTable={() => this.refreshTable()}
                                        setLockBarVisibility={
                                            this.setLockBarVisibility
                                        }
                                        nextDocFromAttach={
                                            this.state.nextDocFromAttach
                                        }
                                        openClassifierSection={
                                            this.state.openClassifierSection
                                        }
                                        setOpenClassifierSection={
                                            this.setOpenClassifierSection
                                        }
                                    />
                                    {this.state.pdf_preview ? (
                                        <PdfPreview
                                            ref={this.pdfPrintRef}
                                            docId={this.state.selectedDocId}
                                            loader={(loader) => {
                                                this.setState({
                                                    blur_spinner: loader,
                                                });
                                            }}
                                            isLocked={this.state.is_locked}
                                            updateParent={this.getLockedDocIds}
                                        />
                                    ) : null}
                                </>
                            </div>
                        ) : this.state.row_transition === '' ? (
                            <div
                                id='left-aside'
                                className={
                                    this.state.detailBigView
                                        ? 'left-aside py-5 rounded-lg  aside-fixed flex-row-auto aside-small-view'
                                        : 'left-aside py-9 rounded-lg aside-fixed flex-row-auto'
                                }
                                style={{
                                    height:
                                        window.innerHeight -
                                        window.innerHeight / 7,
                                }}
                            >
                                <SpinnerThree show />
                            </div>
                        ) : null}

                        {this.state.edit_details ? (
                            <div
                                id='left-last-aside'
                                className={`${
                                    this.state.detailBigView
                                        ? 'expand-mode left-aside rounded-lg aside-fixed flex-row-auto'
                                        : ''
                                } ${
                                    this.state.is_locked &&
                                    this.state.isPartialEditModeEnabled
                                        ? 'padding-cleanup'
                                        : 'left-last-aside-padding'
                                }`}
                            >
                                <div
                                    className={
                                        this.state.isPartialEditModeEnabled &&
                                        this.state.is_locked === true
                                            ? 'p-5 position-relative'
                                            : ''
                                    }
                                >
                                    {this.state.isPartialEditModeEnabled &&
                                        this.state.is_locked === true && (
                                            <>
                                                <LockBar
                                                    docId={
                                                        this.state.selectedDocId
                                                    }
                                                    isCalledFromGrid={false}
                                                    onUnlock={
                                                        this.unlockDocument
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        marginBottom: '38px',
                                                    }}
                                                ></div>
                                            </>
                                        )}
                                    <DetailBigView
                                        // 10067: Passing Physician Signature Props
                                        isPhyisicanSignatureDocument={this.getIsPhysicianSignatureDocument()}
                                        physicianSignatureDocumentType={
                                            this.state.pageTitle
                                        }
                                        onRowClickId={this.state.onRowClickId}
                                        rowData={this.state.rowData}
                                        rowDetails={this.state.rowDetails}
                                        physicianSignature={
                                            this.state.physicianSignature
                                        }
                                        setPhysicianSignatureCity={
                                            this.setPhysicianSignatureCity
                                        }
                                        setPhysicianSignatureFileName={
                                            this.setPhysicianSignatureFileName
                                        }
                                        setPhysicianSignatureNPI={
                                            this.setPhysicianSignatureNPI
                                        }
                                        setPhysicianSignatureOrderingPhysician={
                                            this
                                                .setPhysicianSignatureOrderingPhysician
                                        }
                                        setPhysicianSignatureState={
                                            this.setPhysicianSignatureState
                                        }
                                        setPhysicianSignatureSignedDate={
                                            this.setPhysicianSignatureSignedDate
                                        }
                                        expandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded={
                                            this
                                                .expandEditDetailsWhenNewPhysicianSignatureDocumentIsLoaded
                                        }
                                        showUpNextPhysicianSignatureDocument={
                                            this
                                                .showUpNextPhysicianSignatureDocument
                                        }
                                        setIsLocked={this.setIsLocked}
                                        // Edit Details
                                        isEditDetails={this.state.edit_details}
                                        openEditDetails={this.openEditDetails}
                                        closeEditDetails={this.closeEditDetails}
                                        startEditing={this.startEditing}
                                        cancelEditing={this.cancelEditing}
                                        isInEditingMode={
                                            this.state.isInEditingMode
                                        }
                                        // Partial Edit Mode
                                        isPartialEditModeEnabled={
                                            this.state.isPartialEditModeEnabled
                                        }
                                        turnPartialEditModeOff={
                                            this.turnPartialEditModeOff
                                        }
                                        turnPartialEditModeOn={
                                            this.turnPartialEditModeOn
                                        }
                                        turnEditModeButtonActive={
                                            this.turnEditModeButtonActive
                                        }
                                        turnEditModeButtonInactive={
                                            this.turnEditModeButtonInactive
                                        }
                                        isEditModeButtonActive={
                                            this.state.isEditModeButtonActive
                                        }
                                        // Mimick Other Stuff
                                        isTriggerCollapseFromDetailBigView={
                                            this.state
                                                .isTriggerCollapseFromDetailBigView
                                        }
                                        triggerCollapseFromDetailBigView={
                                            this
                                                .triggerCollapseFromDetailBigView
                                        }
                                        cancelMimickCancelClick={
                                            this.cancelMimickCancelClick
                                        }
                                        isEditModeCanceledFromDocumentDetail={
                                            this.state
                                                .isEditModeCanceledFromDocumentDetail
                                        }
                                        expandWasPressedTrigger={
                                            this.expandWasPressedTrigger
                                        }
                                        pdfPrintRef={this.pdfPrintRef}
                                        refreshForNextDocument={(docid) => {
                                            this.refreshForNextDocument(docid);
                                        }}
                                        // 10098: Pass actions to DetailBigView to invoke them from there
                                        allActions={
                                            this.state.NewPermission !==
                                                undefined &&
                                            this.state.NewPermission
                                                .allActions !== undefined
                                                ? this.state.NewPermission
                                                      .allActions
                                                : this.state.userPermission !==
                                                      undefined &&
                                                  this.state.userPermission
                                                      .allActions !== undefined
                                                ? this.state.userPermission
                                                      .allActions
                                                : undefined
                                        }
                                        refreshTable={this.resetBigView}
                                        selectedDocId={this.state.selectedDocId}
                                        username={username}
                                        dispname={dispname}
                                        notesVal={this.state.notesVal}
                                        openDeletePopUp={() => {
                                            this.openDeletePopUp.call(this);
                                        }}
                                        statusArray={this.state.statusArray}
                                        branches={this.state.branches}
                                        download={() => {
                                            download(this.state.onRowClickId);
                                        }}
                                        rowTransition={() => {
                                            this.rowTransition();
                                        }}
                                        closeBigView={() => {
                                            this.closeBigView();
                                        }}
                                        selectedGroup={this.set_group}
                                        setBranch={this.setBranch}
                                        setInbox={this.set_inbox}
                                        groups={this.state.groups}
                                        saveTransition={() => {
                                            this.save_transition();
                                        }}
                                        openBigView={() => {
                                            this.openBigView();
                                        }}
                                        notesDDL={JSON.parse(
                                            localStorage.getItem('noteTypes')
                                        )}
                                        isLocked={this.state.is_locked}
                                        lockedBy={this.state.is_locked_by}
                                        menuGroup={this.state.menu_group}
                                        menuBranch={this.state.menu_branch}
                                        menuInbox={this.state.menu_inbox}
                                        showBranch={this.state.show_branch}
                                        showDelete={this.state.show_delete}
                                        showDownload={this.state.show_download}
                                        showGroup={this.state.show_group}
                                        showInbox={this.state.show_inbox}
                                        showNotes={this.state.show_notes}
                                        showOwner={this.state.show_owner}
                                        showPdf={this.state.show_pdf}
                                        showPrint={this.state.show_print}
                                        showTag={this.state.show_tag}
                                        noteCount={this.state.noteCount}
                                        pageCount={this.state.pageCount}
                                        menu_name={this.state.menu_name}
                                        processId={this.state.processId}
                                        statusId={this.state.statusId}
                                        branchActionId={
                                            this.state.branchActionId
                                        }
                                        groupActionId={this.state.groupActionId}
                                        updateState={(state) => {
                                            this.setState({ ...state });
                                            this.props.inboxPage({ ...state });
                                        }}
                                        calingFrom={this.props.calingFrom}
                                        selectedDetailsOfRow={
                                            this.state.selectedDetailsOfRow
                                        }
                                        statusCheck='inbox'
                                        userPermission={
                                            this.state.userPermission
                                        }
                                        count={this.props.count}
                                        refreshTableData={() =>
                                            this.refreshTable()
                                        }
                                        setLockBarVisibility={
                                            this.setLockBarVisibility
                                        }
                                        isDetailsLoading={
                                            this.state.isDetailsLoading
                                        }
                                        openClassifierSection={
                                            this.state.openClassifierSection
                                        }
                                        setOpenClassifierSection={
                                            this.setOpenClassifierSection
                                        }
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <SpinnerTwo show={this.state.main_spinner} />

                    <SpinnerBlur show={this.state.blur_spinner} />

                    {this.props.loading ? (
                        <SpinnerBlur show={this.props.loading} />
                    ) : null}

                    {this.state.single_edit === true ? (
                        <div
                            style={{
                                height: '950px',
                            }}
                        >
                            <EditWorkPage
                                style={{ position: 'sticky' }}
                                updateState={(state) => {
                                    this.setState({ ...state });
                                    this.props.inboxPage({ ...state });
                                }}
                            />
                        </div>
                    ) : null}
                </ThemeContext.Provider>

                {this.props.related_doc_detail ? (
                    <DocumentDetailViewComponent callingFrom='inboxPage' />
                ) : null}

                {this.props.multiple_edit_page ? (
                    <MultipleEditPage
                        refreshTable={this.refreshTable}
                        callingFrom='inboxPage'
                    />
                ) : null}
            </>
        );
    }
}
const mapDispatchToProps = {
    getOwnerForAuth: getOwnerForAuth,
    getListaction: getListaction,
    setSelectedRow: setSelectedRow,
    getRulesJson: getRulesJson,
    setRowData: (param) => setRowData(param),
    getCountData: getCountData,
    showLoader: (canShow) => showLoader(canShow),
    reRenderInbox: (canrender) => reRenderInbox(canrender),
    hideBigView: (hide) => hideBigView(hide),
    setMenuName: (name) => setMenuName(name),
    setPageData: (data) => setPageData(data),
    setDocOwner,
    setProcessId,
    setStatusId, //kalpana added
    setCallingPage,
    getOwners,
    updateList,
    updateInboxState,
    getDDLlist: getDDLlist,
    getConfigData: getConfigData,
    getSelectedRowDetails: getSelectedRowDetails,
    inboxPage,
    editDetailPage,
    logOut,
    clearInboxState,
    getRecordSetConfig: getRecordSetConfig,
    getCustomSearchConfig: getCustomSearchConfig,
};
const mapStateToProps = (state) => {
    return {
        customSearchQueries:
            state.configReducerPersist.configData
                .customSearchNamedIdsNeedingConfiguration,
        loading: state.inbox.loading,
        rowData: state.inbox.rowData,
        mainSpinner: state.persist.loader,
        rerenderInbox: state.persist.rerenderInbox,
        hidebigview: state.persist.hidebigview,
        count: state.persist.count,
        configData: stringEmptyOrUndefined(state.configReducerPersist)
            ? state.configReducerPersist.configData
            : {},
        currentMenuName: state.persist.currentMenuName,
        stateToken: state.inbox.stateToken,
        stateData: stringEmptyOrUndefined(state.inbox)
            ? state.inbox.stateData
            : {},
        inbox: state.inbox,
        ddl: state.persistDDL.DDL,
        related_doc_detail: state.inbox.inboxPage.related_doc_detail,
        multiple_edit_page: state.inbox.inboxPage.multiple_edit_page,
    };
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(InboxPage))
);
