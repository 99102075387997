/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl, checkIsActive, getConfig } from '../../../../_helpers';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { jwtDecode } from "jwt-decode";

import { PostMessageManualLogout, validateSession } from '../../../../../app/pages/api/api';
import ManageLockPage from '../../../../../app/components/ManageLock';
import { showErrorToast } from '../../../../../app/components/commonfunction';

function QuickActionsDropdown(props) {

    const location = useLocation();
    const configData = getConfig();
    const [navigate, setNavigate] = useState(false);
    const menuItems = configData.length > 0 ? configData[0].menuItems : [];
    const [model, setModel] = useState(false)

    const generateMessengerUrl = async () => {
        const getSessionIdFromJWT = () => {
            const jwtToken = localStorage.getItem('WV-Token');

            if (!jwtToken) {
                return null;
            }

            const decoded = jwtDecode(jwtToken);
            const sessionId = decoded?.unique_name ?? '';

            if (!!sessionId) {
                return sessionId;
            }
            return null;
        };

        const constructMessengerUrl = (sessionId) => {
            let userDetail = JSON.parse(localStorage.getItem('user_details') || '{}');

            const { datasource = '', appServerUrl = '' } = userDetail;

            const json = JSON.stringify({ sessionId: sessionId, appServerUrl: appServerUrl, exception: '' });
            const encodedJson = encodeURIComponent(json);

            const apiUrl = process.env.REACT_APP_API_MESSENGER_URL;

            if (!apiUrl || !sessionId || !datasource || !encodedJson) {
                showErrorToast('Messenger URL is not configured properly. Please contact administrator.');
                return '';
            }

            return `${apiUrl}/#/sso/${datasource}/${encodedJson}`;
        };

        const sessionId = getSessionIdFromJWT();

        if (sessionId) {
            const checkSessionResponse = await validateSession();
            if (checkSessionResponse && checkSessionResponse.status === 204) {
                return constructMessengerUrl(sessionId);
            } else if (checkSessionResponse && checkSessionResponse.status === 401) {
                showErrorToast('Your session has expired. Please log in again.');
                return '';
            } else {
                showErrorToast('Error occurred while trying to start the messaging app.');
                return '';
            }
        } else {
            console.error('Session ID not found in JWT.');
            return '';
        }
    };

    const handleMessengerClick = async () => {
        console.log("handleMessengerClick");
        const url = await generateMessengerUrl();

        if (!!url) {
            window.open(url, '_blank');
        }
    }

    const handleHelpClick = async () => {
        window.open('https://worldviewltd.com/knowledge', '_blank');
    }

    const handlePage = () => {
        setModel(!model)
    }
    var show_user_management = 0;
    var customMenuArray = [];
    if (menuItems && menuItems.length > 0) {
        menuItems.map((data, key) => {

            if (data.menuName.toLowerCase() == 'user administration') {
                show_user_management = 1;
            }
            customMenuArray.push(data.menuId);
        });
        localStorage.setItem('customMenuArray', customMenuArray);
    }


    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? 'menu-item-active' : '';
    };

    const [user_detail, setUser_detail] = useState(
        localStorage.getItem('user_details')
            ? JSON.parse(localStorage.getItem('user_details'))
            : ''
    );
    const bgImage = toAbsoluteUrl('/media/misc/bg-2.jpg');
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(
                    uiService.config,
                    'extras.quick-actions.layout'
                ) === 'offcanvas',
        };
    }, [uiService]);

    let uploadTypes = [];
    let config = localStorage.getItem('configData');
    config = JSON.parse(config);

    if (config !== undefined && config !== null) {
        uploadTypes = config.uploadTypes;
    }

    let userDetail = localStorage.getItem('user_details');
    let userName = '', claims, adminlocks = false;
    if (userDetail !== null) {
        userDetail = JSON.parse(localStorage.getItem('user_details'));
        userName = userDetail.userName;
        if (userDetail.claims !== undefined && userDetail.claims !== null) {
            claims = userDetail.claims
            if (claims.includes("adminlocks")) {
                adminlocks = true
            }
        }
    }
    if (
        localStorage.getItem('valid_user') == 'false' ||
        localStorage.getItem('valid_user') === null
    ) {
        return null;
    }

    const redirectManual = (link_url) => {
        var pathname = window.location.pathname;
        var path = pathname.split('/');
    };

    if (navigate) {
        return <Redirect to="/auth/login" push={true} />
    }
    return (
        <>
            {adminlocks &&
                <div className='align-items-center header-menu mr-5'>
                    <span className='dash-bdr align-self-center' onClick={() => handlePage()} >
                        <i title='Manage Locks' className='fa-regular fa-lock-keyhole' style={{ fontSize: "12px" }} />
                    </span>
                </div>
            }
            <div className='upload-part d-flex align-items-center header-menu mr-5 '>
                <ul className={`menu-nav  ${layoutProps.ulClasses}`}>
                    <li
                        key={'first-level-upload'}
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            '/upload',
                            '/sendtofax'
                        )}`}
                        data-menu-toggle='hover'
                        aria-haspopup='true'
                    >
                        {uploadTypes !== undefined && uploadTypes.length > 0 ? (
                            <span className='svg-icon svg-icon-xl svg-icon-primary'>
                                <SVG
                                    alt='upload-usersadsad'
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/Media/upload-user.svg'
                                    )}
                                />
                            </span>
                        ) : null}

                        {/* TODO: Add check to hide upload if there is only PS group. */}
                        <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                            <ul className='menu-subnav'>
                                {uploadTypes !== undefined && uploadTypes.length > 0
                                    ? uploadTypes.map((data) => {
                                        var text = '';
                                        var link_url = '';
                                        if (data.type_flag == 'Upload') {
                                            text = 'Upload Document';
                                            link_url =
                                                '/upload/' + data.docId;
                                        } else if (data.type_flag == 'Fax') {
                                            text = 'Send To Fax';
                                            link_url =
                                                '/send-to-fax/' + data.docId;
                                        }

                                        return (
                                            <li
                                                key={
                                                    'first-level-upload-browse-' +
                                                    data.docId
                                                }
                                                className={`menu-item ${getMenuItemActive(
                                                    '/upload/' + data.docId
                                                )}`}
                                            >
                                                <NavLink
                                                    className='menu-link'
                                                    to={link_url}
                                                    title={data.type_flag == 'Upload' ? 'Upload Document' : 'Send To Fax'}
                                                    onClick={redirectManual(
                                                        link_url
                                                    )}
                                                >
                                                    <span className='menu-text'>
                                                        {text}
                                                    </span>
                                                </NavLink>
                                            </li>
                                        );
                                    })
                                    : null}
                            </ul>
                        </div>

                    </li>
                </ul>
            </div>
            <div className='upload-part logged_popup d-flex align-items-center header-menu'>
                <ul className={`menu-nav  ${layoutProps.ulClasses}`} >
                    <li
                        key={'first-level-upload'}
                        className={`menu-item p-2 menu-item-submenu ${getMenuItemActive(
                            '/settings',
                            '/change-password'
                        )}`}
                        data-menu-toggle='hover'
                        aria-haspopup='true'
                    >

                        <span className='text-muted  svg-icon svg-icon-xl svg-icon-primary'>
                            {user_detail.userDisplayName}
                        </span>

                        <i className='ml-1 icon-lg la la-angle-down'></i>


                        <div
                            className={`menu-submenu menu-submenu-classic menu-submenu-right`}
                        >
                            <ul className='menu-subnav'>
                                {uploadTypes !== undefined && uploadTypes.length > 0 ? (
                                    <li
                                        key={'first-level-user-1'}
                                        className={`menu-item ${getMenuItemActive(
                                            '/users/my-account'
                                        )}`}
                                    >
                                        <NavLink
                                            className='menu-link'
                                            to='/users/my-account'
                                            title='Settings & Preferences'
                                        >
                                            {/*<i className="fas fa-file-upload  align-items-center pr-3"></i>*/}
                                            <span className='menu-text'>
                                                Settings & Preferences
                                            </span>
                                        </NavLink>
                                    </li>
                                ) : null}

                                <li
                                    key={'first-level-user-2'}
                                    className={`menu-item ${getMenuItemActive(
                                        '/users/users'
                                    )}`}
                                    style={{ 'display': show_user_management ? 'block' : 'none' }}

                                >
                                    <NavLink
                                        className='menu-link'
                                        title='User Management'
                                        to='/users/users'
                                    >
                                        {/*<i className="fas fa-fax align-items-center pr-3"></i>*/}
                                        <span className='menu-text'>
                                            User Management
                                        </span>
                                    </NavLink>
                                </li>
                                {!document.querySelector('#this-is-messenger') && (
                                    <li key={'first-level-user-3m'} className={`menu-item`} id='this-is-messenger'>
                                        <a className='menu-link' title='Messenger' onClick={handleMessengerClick}>
                                            <span className='menu-text'>Messenger</span>
                                        </a>
                                    </li>
                                )}
                                <li key={'first-level-user-3m'} className={`menu-item`}>
                                    <a className='menu-link' title='Help' onClick={handleHelpClick}>
                                        <span className='menu-text'>Help</span>
                                    </a>
                                </li>
                                <li
                                    key={'first-level-user-3'}
                                    className={`menu-item ${getMenuItemActive(
                                        '/auth/logout'
                                    )}`}
                                >
                                    <span
                                        className='menu-link'
                                        title='Logout'
                                        onClick={PostMessageManualLogout}
                                    >
                                        <i className='fas fa-sign-out-alt align-items-center pr-3'></i>
                                        <span className='menu-text'>
                                            Logout
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            {layoutProps.offcanvas && (
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip id='quick-actions-tooltip'>
                            Quick actions
                        </Tooltip>
                    }
                ></OverlayTrigger>
            )}
            {
                model && <ManageLockPage model={model} setModel={setModel} />
            }
        </>
    );
}


const mapDispatchToProps = {
    //  LOG_OUT: logOut,
};


export default connect(
    null,
    mapDispatchToProps)(withRouter(QuickActionsDropdown));
