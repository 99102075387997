import { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import ClassifierForm from './ClassifierForm';
import { isMobile } from 'react-device-detect';
import {
    outboxDocumentAction,
    createClassifierRecord,
    updateClassifierRecord,
} from '../../api/api';
import { getConfig } from '../../../../_metronic/_helpers';
import {
    mapClassifierApiData,
    mapApiDataToObjectModel,
} from '../utils/mapClassifierToApiData';
import {
    normalSuccessToast,
    showErrorToast,
    fetchClassifierRecordsetData,
    getDropDownDataFromApi,
    showSuccessToast,
} from '../../../components/commonfunction';
import Spinner from 'react-bootstrap/Spinner';
import { OptionType } from '../models/interfaces';

const AddClassifierModal = (props: any) => {
    const [initialValues, setInitialValues] = useState({
        classificationFeedback: '',
        documenttypecorrected: '',
        firstnamecorrected: '',
        lastnamecorrected: '',
        serviceline: '',
        referralpageNumber: '',
        dateofbirthcorrected: '',
        addresscorrected: '',
        citycorrected: '',
        statecorrected: '',
        documenthandle: props.selectedDocId[0],
        referraldate: '',
    });

    const redux_store: any = useSelector((state) => state);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [headerColumns, setHeaderColumns] = useState<any[]>([]);
    const [currentClassifier, setcurrentClassifier] = useState<any>([]);
    const [documenttypecorrected, setDocumenttypecorrected] = useState<any[]>(
        []
    );

    const formikRef = useRef<FormikProps<any>>(null);
    const [recordsetId, setrecordsetId] = useState<string>('');
    const [documentTypes, setDocumentTypes] = useState<OptionType[]>();
    const [feedbackTypes, setfeedbackTypes] = useState<OptionType[]>();
    const [serviceLines, setserviceLines] = useState<OptionType[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const configData = getConfig();

        configData[0].configuredRecordsets.forEach((data: any) => {
            if (data.name === 'Classifier') {
                setrecordsetId(data.recordsetId);
            }
        });
        fetchClassifierRecordsetData('Classifier')
            .then(async (classifierDataAsync) => {
                if (classifierDataAsync) {
                    const { data, headerColumns } = classifierDataAsync;
                    setHeaderColumns({ headerColumns }.headerColumns);
                    console.log('data', data);
                    const allclassifierdata = mapApiDataToObjectModel(data);
                    console.log('allclassifierdata', allclassifierdata);
                    const item = allclassifierdata.find(
                        (item) =>
                            String(item.documenthandle).trim() ===
                            String(props.selectedDocId[0]).trim()
                    );
                    setcurrentClassifier(item);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    }, [props.showClassifierForm]);
    // Update initial values when currentClassifier is available
    useEffect(() => {
        if (currentClassifier) {
            console.log('currentClassifier not null');
            console.log(currentClassifier);
            setInitialValues({
                classificationFeedback:
                    currentClassifier.classificationFeedback || '',
                documenttypecorrected:
                    currentClassifier.documenttypecorrected || '',
                firstnamecorrected: currentClassifier.firstnamedetected || '',
                lastnamecorrected: currentClassifier.lastnamedetected || '',
                serviceline: currentClassifier.serviceline || '',
                referralpageNumber: currentClassifier.referralpageNumber || '',
                dateofbirthcorrected:
                    formatDate(currentClassifier.dateofbirthdetected) || '',
                addresscorrected: currentClassifier.addresscorrected || '',
                citycorrected: currentClassifier.citycorrected || '',
                statecorrected: currentClassifier.statecorrected || '',
                documenthandle: props.selectedDocId[0] || '', // Fallback if not set
                referraldate: formatDate(currentClassifier.referraldate) || '',
            });
        }
    }, [currentClassifier, props.selectedDocId]);

    useEffect(() => {
        const fetchData = async () => {
            if (headerColumns.length > 0) {
                // Check if headerColumns is not empty
                try {
                    // Fetch data for 'Attachment Type'

                    const configData = getConfig();

                    const classifierFeedbackTypeField =
                        configData[0].fields.find(
                            (col: any) => col.name === 'Classifier Feedback'
                        )?.fieldId;
                    console.log(
                        'classifierFeedbackType',
                        classifierFeedbackTypeField
                    );
                    if (classifierFeedbackTypeField) {
                        await fetchDropDownDatafeedbackType(
                            classifierFeedbackTypeField
                        );
                    }

                    const attachmentKeyField = configData[0].fields.find(
                        (col: any) => col.name === 'Attachment Type'
                    )?.fieldId;
                    console.log('attachmentKeyField', attachmentKeyField);
                    if (attachmentKeyField) {
                        await fetchDropDownDataAttachment(attachmentKeyField);
                    }

                    // Fetch data for 'Service Line'
                    const serviceLineKeyField = configData[0].fields.find(
                        (col: any) => col.name === 'Service Line'
                    )?.fieldId;

                    if (serviceLineKeyField) {
                        await fetchDropDownDataserviceLine(serviceLineKeyField);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false); // Ensure loading is set to false
                }
            }
        };

        fetchData(); // Call the async function
    }, [headerColumns]);

    const fetchDropDownDatafeedbackType = async (lookupKeyField: any) => {
        const data: any = await getDropDownDataFromApi(lookupKeyField);
        if (data?.values) {
            const response: OptionType[] = data.values.map((item: string) => ({
                value: item ?? '',
                label: item ?? '',
            }));
            setfeedbackTypes(response);
        }
    };
    const fetchDropDownDataAttachment = async (lookupKeyField: any) => {
        const data: any = await getDropDownDataFromApi(lookupKeyField);
        if (data?.values) {
            const response: OptionType[] = data.values.map((item: string) => ({
                value: item ?? '',
                label: item ?? '',
            }));
            setDocumentTypes(response);
        }
    };
    const fetchDropDownDataserviceLine = async (lookupKeyField: any) => {
        const data: any = await getDropDownDataFromApi(lookupKeyField);
        if (data?.values) {
            const response: OptionType[] = data.values.map((item: string) => ({
                value: item ?? '',
                label: item ?? '',
            }));
            setserviceLines(response);
        }
    };
    const formatDate = (dateString: string) => {
        // Check if the date is in "YYYY/MM/DD" format
        const yyyyMmDdPattern = /^\d{4}\/\d{2}\/\d{2}$/;
        // Check if the date is in "MM/DD/YYYY" format
        const mmDdYyyyPattern = /^\d{2}\/\d{2}\/\d{4}$/;

        if (yyyyMmDdPattern.test(dateString)) {
            // If the date is in "YYYY/MM/DD", just replace '/' with '-'
            return dateString.replace(/\//g, '-');
        } else if (mmDdYyyyPattern.test(dateString)) {
            // If the date is in "MM/DD/YYYY", convert to "YYYY-MM-DD"
            const [month, day, year] = dateString.split('/');
            return `${year}-${month}-${day}`;
        } else {
            // If the format is not recognized, return the original date or handle as needed
            return dateString;
        }
    };
    const handleCloseAction = () => {
        props?.onClose?.();
    };

    const saveAction = async (values: object) => {
        console.log('values', values);
        console.log('headerColumns', headerColumns);
        const apiData = mapClassifierApiData(values, headerColumns);
        console.log('apiData', apiData);
        let actionId =
            redux_store.inbox.stateData.allActions.submit_classifier_feedback
                .actionId;
        let statusId = props.statusId;
        const docIds = Array.isArray(props.selectedDocId)
            ? props.selectedDocId.flat()
            : [props.selectedDocId];

        let status;
        const fields: any[] = [];

        headerColumns.forEach((column) => {
            const fieldData = apiData.find(
                (item) => item.fieldId === column.fieldId
            );
            fields.push({
                fieldId: column.fieldId,
                value: fieldData?.value ?? '', // Adjust this to the appropriate value
            });
        });
        if (!currentClassifier) {
            const response = await createClassifierRecord(fields, recordsetId);
            if (
                !response ||
                response.status === 400 ||
                response.status === 404
            ) {
                showErrorToast('Could not send feedback');
            } else {
                normalSuccessToast('Classifier feedback successful');
            }
        } else {
            const response = await updateClassifierRecord(
                fields,
                recordsetId,
                currentClassifier.id
            );
            if (
                !response ||
                response.status === 400 ||
                response.status === 404
            ) {
                showErrorToast('Could not send feedback');
            } else {
                normalSuccessToast('Classifier feedback successful');
            }
        }

        const params = {
            processId: props.processId,
            statusId: statusId,
            actionId: actionId,
            fields: {
                docIds: docIds,
                fields: [],
            },
        };

        let successDocId: any[] = [];
        let failedDocId: any[] = [];
        await outboxDocumentAction(params)
            .then(async (response) => {
                let result = await response.json();

                return result;
            })
            .then(async (data) => {
                if (data.hadFailures === false) {
                    await data.results.map(async (item: any, idx: number) => {
                        if (item.actionSucceeded === true) {
                            successDocId.push(item.docId);
                        }
                    });
                }
                if (data.hadFailures === true) {
                    await data.results.map(async (item: any, idx: number) => {
                        if (item.actionSucceeded === false) {
                            failedDocId.push(item.docId);
                        } else {
                            successDocId.push(item.docId);
                        }
                    });
                }
            });

        let message;
        let messageSucc: any;
        if (failedDocId.length !== 0) {
            if (failedDocId.length === 1) {
                message = `Error Sending feedback to classifier for Doc Id  ${failedDocId[0]} `;
            }

            showErrorToast(message);
        }
        if (successDocId.length !== 0) {
            props.updateState({
                showClassifierForm: false,
            });

            if (successDocId.length === 1) {
                messageSucc = `Sent feedback to classifier for ( ${successDocId[0]} )  successfully.`;
            } else {
                messageSucc = `Sent feedback to classifier for  (`;
                successDocId.map(async (item, idx) => {
                    if (
                        idx !== successDocId.length - 1 &&
                        idx !== successDocId.length - 2
                    ) {
                        messageSucc += `${item}, `;
                    } else if (idx === successDocId.length - 2) {
                        messageSucc += `${item}`;
                    } else {
                        messageSucc += ` and ${item} `;
                    }
                });
                messageSucc += `) updated successfully.`;
            }
            showSuccessToast('Feedback  successfully sent.');
        }
        props?.onClose?.();
    };

    return (
        <div className='py-7 d-flex flex-column justify-content-between'>
            {loading ? (
                <div className='d-flex justify-content-center'>
                    <Spinner animation='border' variant='primary' />
                    <span>Loading...</span>
                </div>
            ) : (
                <div className='listing-grid'>
                    <Formik
                        innerRef={formikRef}
                        validationSchema={validationSchema}
                        onSubmit={saveAction}
                        initialValues={initialValues} // Set initial values dynamically
                    >
                        {(formik) => (
                            <Form>
                                <ClassifierForm
                                    formik={formik}
                                    documentTypes={documentTypes}
                                    serviceLines={serviceLines}
                                    feedbackTypes={feedbackTypes}
                                    setDocumentTypeCorrected={
                                        setDocumenttypecorrected
                                    }
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
            <div>
                <hr />
                <div className='d-flex footer-btn align-items-stretch justify-content-between mb-2 pl-7 pr-7'>
                    <div className='d-flex'>
                        <Button
                            title='Cancel'
                            variant='secondary'
                            onClick={() => handleCloseAction()}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className='d-flex'>
                        <Button
                            title='Submit'
                            type='submit'
                            variant='success'
                            disabled={isSubmitting}
                            onClick={() => formikRef.current?.submitForm()}
                        >
                            {isSubmitting ? (
                                <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            ) : (
                                <span>Submit Feedback</span>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddClassifierModal;

const validationSchema = Yup.object().shape({
    classificationFeedback: Yup.string().required(
        'Classification Feedback is required'
    ),
});
